import { UseMutationOptions } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import { transferSampleParams } from "../models/requests/sample.request";
import Logger from "../util/logger.util";
import { UpdateSampleParams } from "../models/requests/processCapscan.request";
import { sampleService } from "../services/sampleCapscan.service";

export const transferSampleMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  transferSampleParams
> => ({
  mutationKey: ["transfer sample"],
  mutationFn: (transferSample) => sampleService.transferSample(transferSample),
  onSuccess: () => {
    Logger.info("Transfer sample done successfully");
  },
});

export const updateSampleMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  UpdateSampleParams
> => ({
  mutationKey: ["Update sample"],
  mutationFn: (sample:UpdateSampleParams) => sampleService.updateSample(sample),
  onSuccess: () => {
    Logger.info("Update sample done successfully");
  },
});

