import { useEffect, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import {
  useWizardContext,
  WizardActionType,
} from "../../../context/WizardContext";
import { WizardStep, WizardStepWithLabel } from "../WizardModal/WizardModal";
import WizardStepper from "../WizardStepper/WizardStepper";

type WizardProps = {
  className?: string;
  steps: WizardStepWithLabel[] | WizardStep[];
};

function Wizard({ className, steps }: WizardProps) {
  const {
    state: { currentStep, isLoading },
    dispatch,
  } = useWizardContext();

  const shouldRenderStepper = useMemo(() => {
    return (steps[0] as WizardStepWithLabel).label !== undefined;
  }, [steps]);

  const currentStepContent = useMemo(() => {
    return shouldRenderStepper
      ? (steps[currentStep] as WizardStepWithLabel).content
      : (steps[currentStep] as WizardStep);
  }, [currentStep, steps, shouldRenderStepper]);

  useEffect(() => {
    const totalSteps = steps.length;
    if (!totalSteps) {
      return;
    }
    dispatch({
      type: WizardActionType.SET_NUMBER_OF_STEPS,
      payload: totalSteps,
    });
    dispatch({
      type: WizardActionType.SET_CURRENT_STEP,
      payload: 0,
    });
    dispatch({
      type: WizardActionType.SET_FIRST_STEP,
      payload: true,
    });
  }, []); //eslint-disable-line

  return (
    <div className={className}>
      {shouldRenderStepper && (
        <WizardStepper
          steps={steps.map((step) => ({
            label: (step as WizardStepWithLabel)?.label,
          }))}
          currentStep={currentStep}
        />
      )}
      {currentStepContent}
      {isLoading && (
        <div className={"d-flex justify-content-center"}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </div>
  );
}

export default Wizard;
