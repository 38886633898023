import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import LoginPage from "../../pages/LoginPage";

export const authRoutes = [
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: <LoginPage />,
      },
    ],
  },
];
