/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateProcessDto } from '../models';
// @ts-ignore
import { VoidResponse } from '../models';
/**
 * ProcessApi - axios parameter creator
 * @export
 */
export const ProcessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProcessDto} createProcessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProcess: async (createProcessDto: CreateProcessDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProcessDto' is not null or undefined
            assertParamExists('createProcess', 'createProcessDto', createProcessDto)
            const localVarPath = `/api/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProcessDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessApi - functional programming interface
 * @export
 */
export const ProcessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProcessApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProcessDto} createProcessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProcess(createProcessDto: CreateProcessDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProcess(createProcessDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProcessApi - factory interface
 * @export
 */
export const ProcessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProcessApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProcessDto} createProcessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProcess(createProcessDto: CreateProcessDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.createProcess(createProcessDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProcessApi - object-oriented interface
 * @export
 * @class ProcessApi
 * @extends {BaseAPI}
 */
export class ProcessApi extends BaseAPI {
    /**
     * 
     * @param {CreateProcessDto} createProcessDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessApi
     */
    public createProcess(createProcessDto: CreateProcessDto, options?: AxiosRequestConfig) {
        return ProcessApiFp(this.configuration).createProcess(createProcessDto, options).then((request) => request(this.axios, this.basePath));
    }
}
