import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import VendorsIcon from "../../../../assets/vendors.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Dropdown } from "react-bootstrap"
import { FormModal } from "../../../../components/form/FormModal/FormModal";
import { BaseIcon } from "../../../../components/BaseIcon/BaseIcon";
import FormInput from "../../../../components/form/FormInput/FormInput";
import { FormGroup } from "../../../../components/form/FormGroup/FormGroup";
import { UpdateVendorParams, VendorParams } from "../../../../models/requests/vendor.request";
import useVendor from "../../../../hooks/useVendor";
import { makeDirtyObject } from "../../../../util/form.util";

type UserFormProps = {
  show: boolean;
  handleHide: () => void;
  refetch: () => void;
  vendor?: UpdateVendorParams;
  getAnalysis: string[];
};

export const Vendor = ({ show, handleHide, vendor, refetch, getAnalysis }: UserFormProps) => {
  const { addVendor, editVendor } = useVendor();
  const { control, handleSubmit, reset, getFieldState, setValue } = useForm<VendorParams>();

  const [showDropdown, setShowDropdown] = useState(false);

  const intl = useIntl();

    useEffect(() => {
        if (vendor) {
            reset(vendor);
        }
    }, [vendor, reset]);

    const openAnalysisTypes = () => {
        setShowDropdown(true);
    };

    const closeAnalysisTypes = () => {
        setShowDropdown(false);
    };

    const selectAnalysis = (analysis: string) => {
        setValue("analysisType", analysis);
    };

  const onSubmit = async (vendorFormValues: VendorParams | UpdateVendorParams) => {
    if (vendor) {
    const { id, ...obj } = makeDirtyObject(key => getFieldState(key).isDirty, vendorFormValues);
    await editVendor({ id: vendor.id, ...obj} as UpdateVendorParams);
    } else {
      await addVendor({ ...vendorFormValues } as VendorParams);
    }
    refetch();
    handleHide();
  };

  return (
    <FormModal
      onSubmit={handleSubmit(onSubmit)}
      id="form"
      header={
        <div>
          <BaseIcon icon={VendorsIcon} />
          <FormattedMessage
            id={vendor ? "ENVIVO.VENDORS.EDIT_VENDOR" : "ENVIVO.VENDORS.ADD_VENDOR"}
            defaultMessage={vendor ? "Edit Vendor" : "Add Vendor"}
          />
        </div>
      }
      footer={
        <>
          <Button onClick={handleHide} variant="outline-dark">
            <FormattedMessage
              id="BUTTON.CANCEL"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            type="submit"
            variant="outline-dark"
            className="border-dark"
            form="form"
          >
            <FormattedMessage
              id={vendor ? "ENVIVO.VENDORS.UPDATE_VENDOR" : "ENVIVO.VENDORS.CREATE_VENDOR"}
              defaultMessage={vendor ? "Update" : "Create"}
            />
          </Button>
        </>
      }
      show={show}
      handleClose={handleHide}
    >
      <FormGroup
        input={
          <FormInput
            type="text"
            name="vendorName"
            control={control}
            rules={{
              required: true,
            }}
            input={{
              type: "text",
              placeholder: intl.formatMessage({
                id: "ENVIVO.VENDORS.TABLE.COLUMN.VENDOR_NAME",
                defaultMessage: "Vendor Name",
              }),
            }}
          />
        }
      />
      <FormGroup
          input={
            <FormInput
                type="text"
                name="address"
                control={control}
                rules={{
                  required: true,
                }}
                input={{
                  placeholder: intl.formatMessage({
                    id: "ENVIVO.VENDORS.TABLE.COLUMN.ADDRESS",
                    defaultMessage: "Address",
                  }),
                }}
            />
          }
      />
      <FormGroup
        input={
          <FormInput
            type="text"
            name="contactPersonName"
            control={control}
            rules={{
              required: true,
            }}
            input={{
              placeholder: intl.formatMessage({
                id: "ENVIVO.VENDORS.CONTACT_PERSON_NAME",
                defaultMessage: "Contact Person Name",
              }),
            }}
          />
        }
      />
      <div className="d-flex justify-content-between">
        <FormGroup
          input={
            <FormInput
              type="text"
              name="contactEmail"
              control={control}
              rules={{
                required: true,
              }}
              input={{
                type: "email",
                placeholder: intl.formatMessage({
                  id: "ENVIVO.VENDORS.TABLE.COLUMN.CONTACT_EMAIL",
                  defaultMessage: "Contact Email",
                }),
              }}
            />
          }
        />
        <FormGroup
          input={
            <FormInput
              type="text"
              name="contactPhone"
              control={control}
              rules={{
                required: true,
              }}
              input={{
                type: "tel",
                pattern: "[+][0-9]*",
                placeholder: intl.formatMessage({
                  id: "ENVIVO.VENDORS.TABLE.COLUMN.CONTACT_PHONE",
                  defaultMessage: "Contact Phone",
                }),
              }}
            />
          }
        />
      </div>
      <FormGroup
        input={
          <FormInput
            type="text"
            name="analysisType"
            control={control}
            rules={{
              required: !vendor,
            }}
            input={{
              onClick: openAnalysisTypes,
              placeholder: intl.formatMessage({
                id: "ENVIVO.VENDORS.TABLE.COLUMN.ANALYSIS_TYPE",
                defaultMessage: "Analysis Type",
              }),
            }}
          />
        }
      />
        {showDropdown && (
            <Dropdown show={showDropdown} onToggle={setShowDropdown} onClick={closeAnalysisTypes}>
                <Dropdown.Menu>
                    {getAnalysis.map((analysis) => (
                        <Dropdown.Item key={analysis} onClick={() => selectAnalysis(analysis)}>
                            {analysis}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        )}
    </FormModal>
  );
};
