import { useState, useCallback } from "react";
import useTableHelper from "../../../../../hooks/useTableHelper";
import styles from "./ShipmentsTable.module.css";
import Table, {
  LoadingAndErrorHandler,
} from "../../../../../components/Table/Table";
import { useQuery } from "@tanstack/react-query";
import ShipmentRow from "./ShipmentRow/ShipmentRow";
import { shipmentsQuery } from "../../../../../queries/shipment.query";
import { ShipmentResponse } from "../../../../../models/responses/shipment.response";
import Distribution from "../Distribution/Distribution";
import { clinicDepartmentsWithClinicNamesQuery } from "../../../../../queries/site.query";
import { studyNamesQuery } from "../../../../../queries/study.query";

const columns = [
  "shipmentNumber",
  "clinic",
  "studyName",
  "numberOfKits",
  "dateOfReceiptBySite",
  "courier",
  "shipmentDate",
  "deliveryDate",
  "currentStatus",
  "export",
  "save",
];

export default function ShipmentsTable() {
  const { getTitle } = useTableHelper();
  const [pageNumber, setPageNumber] = useState(0);
  const [show, setShow] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [shipment, setShipment] = useState<ShipmentResponse>();

  const { data: clinicDepartments } = useQuery(
    clinicDepartmentsWithClinicNamesQuery()
  );
  const { data: studiesNames } = useQuery(studyNamesQuery());

  const {
    isLoading,
    isError,
    data: shipments,
    refetch,
  } = useQuery(shipmentsQuery(pageNumber, { enabled: !!clinicDepartments }));

  const getData = useCallback(() => {
    if (shipments && clinicDepartments) {
      return  shipments.data?.items.map((itm) => {
        const clinic = clinicDepartments.data?.filter(
          (x) => x.id === itm.clinicDepartmentId
        )[0];
        
       
        const studyName = studiesNames?.data?.filter(studyName => studyName.id === itm.departmentStudy?.studyId)[0];
        return { ...itm, clinic: clinic?.clinicName, studyName: studyName?.studyName };
      });
    }
  }, [shipments]);
  const getColumns = () => {
    return columns.map((column, index) => ({
      Header:
        column === "save" || column === "export"
          ? ""
          : getTitle("ENVIVO.SHIPMENT.TABLE", column),
      accessor: column,
    }));
  };
  const handlePaginate = useCallback((page: number) => {
    setPageNumber(page);
  }, []);

  return (
    <div className={`${styles.shipments} m-3`}>
      {shipments && (
        <Table
          columns={getColumns()}
          customTableContent={
            <tbody>
              {getData()?.map((item) => (
                <ShipmentRow
                  shipment={item}
                  refetch={refetch}
                  key={item.shipmentNumber}
                  setShow={(show: boolean) => setShow(show)}
                  setReadOnly={(readOnly: boolean) => setReadOnly(readOnly)}
                  setShipment={(shipment: ShipmentResponse ) =>
                    setShipment(shipment)
                  }
                />
              ))}
            </tbody>
          }
          className={`${styles.table} table-bordered`}
          pageCount={shipments?.data?.pageCount || 0}
          onPaginate={handlePaginate}
          disableSelectRow={true}
          isLoading={isLoading}
          isError={isError}
        />
      )}
      <LoadingAndErrorHandler isError={isError} isLoading={isLoading} />
      {show && (
        <Distribution
          show={show}
          handleHide={() => {
            setShow(false);
            refetch();
          }}
          shipment={shipment}
          readOnly={readOnly}
        />
      )}
    </div>
  );
}
