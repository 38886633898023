import { DataType } from "../components/Table/Table";

export const uniqueId = (function (): (prefix: string) => string {
  let num = 0;
  return function (prefix: string = ""): string {
    num += 1;
    return prefix + num;
  };
})();

export const getCapscanColor = (reference?: string) => {
  switch (reference) {
    case "CS1":
      return "Red";
    case "CS2":
      return "Green";
    case "CS3":
      return "White";
    case "CS4":
      return "Blue";
    default:
      return "";
  }
};


export function convertToCSV(data: DataType[], columns: string[]) {
  const header = columns.map((column) => column.toUpperCase()).join(",");
  const rows = data.map((row) =>
    columns.map((column) => {
      const value = row[column];
      if (value === undefined || value === null) {
        return "";
      }
      if (typeof value === "string") {
        return `"${value.replace(/"/g, '""')}"`;
      } else if (value instanceof Date) {
        return `"${value.toISOString()}"`;
      } else if (Array.isArray(value)) {
        return `"${value.join(",")}"`;
      } else if (typeof value === "object" && value !== null) {
        return `"${JSON.stringify(value).replace(/"/g, '""')}"`;
      }
      return String(value);
    })
  );
  const csv = [header, ...rows].join("\n");
  const csvData = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
  return csvData;
}


 