import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../../../../components/Button/Button";
import { useForm } from "react-hook-form";
import styles from "./LinkData.module.css";
import FormInput from "../../../../../components/form/FormInput/FormInput";
import RadioButtonGroup from "../../../../../components/form/RadioButtonGroup/RadioButtonGroup";
import LinkSequenceIcon from "../../../../../assets/link_sequence.svg";
import LinkOtherIcon from "../../../../../assets/link_other.svg";
import LinkDataIcon from "../../../../../assets/link_data.svg";
import { useState } from "react";
import useLinkData from "../../../../../hooks/useLinkData";
import { DataLinkResponse } from "../../../../../models/responses/dataLink.response";

export type LinkDataProps = {
  dataType: string;
  pasteBucket: string;
  endingCell: string;
  startingCell: string;
};

const dataTypeSequence = ["DNA", "RNA"];

const dataTypeOther = ["Metabolomics", "Proteomics"];

const LinkData = () => {
  const intl = useIntl();
  const {
    control,
    formState: { errors: formErrors, isValid },
    getValues,
  } = useForm<LinkDataProps>();
  const { linkData } = useLinkData();
  const [activeButton, setActiveButton] = useState("sequence");
  const [dataLinkStatus, setdataLinkStatus] = useState<DataLinkResponse>();

  const handleButtonClick = (button: string) => {
    setActiveButton(button);
  };

  async function handleLinkData(): Promise<void> {
    let params = getValues();
    let response = await linkData({
      url: params.pasteBucket,
      startCell: params.startingCell,
      endCell: params.endingCell,
      dataType: params.dataType,
    });
    setdataLinkStatus(response?.data);
  }

  return (
    <div className={styles.mainBox}>
      <div className={`d-flex ${styles.buttonLinkDataBox}`}>
        <div className={styles.buttonLinkData}>
          <Button
            variant="outline-light"
            size="sm"
            type="button"
            className={activeButton === "sequence" ? styles.buttonStyle : ""}
            onClick={() => handleButtonClick("sequence")}
          >
            <BaseIcon icon={LinkSequenceIcon} />
            <FormattedMessage
              id="ENVIVO.LINKDATA.LINK_SEQUENCE_DATA"
              defaultMessage="Link Sequence data"
            />
          </Button>
        </div>
        <div className={styles.buttonLinkData}>
          <Button
            variant="outline-light"
            size="sm"
            type="button"
            className={activeButton === "other" ? styles.buttonStyle : ""}
            onClick={() => handleButtonClick("other")}
          >
            <BaseIcon icon={LinkOtherIcon} />
            <FormattedMessage
              id="ENVIVO.LINKDATA.LINK_OTHER_DATA"
              defaultMessage="Link Other data"
            />
          </Button>
        </div>
      </div>
      {activeButton === "sequence" && (
        <>
          <div className={`d-flex ${styles.dataTypeButtons}`}>
            <div className={styles.dataTypeText}>Data Type</div>
            <RadioButtonGroup
              name="dataType"
              key="dataType"
              defValue={0}
              radioButtonsArray={dataTypeSequence.map(type =>({ label: type, value: type}))}
              control={control}
            />
          </div>
          <div className={styles.linkLabel}>Paste a valid link.</div>
        </>
      )}
      {activeButton === "other" && (
        <>
          <div className={`d-flex ${styles.dataTypeButtons}`}>
            <div className={styles.dataTypeText}>Data Type</div>
            <RadioButtonGroup
              name="dataType"
              key="dataType"
              defValue={0}
              radioButtonsArray={dataTypeOther.map(type =>({ label: type, value: type}))}
              control={control}
            />
          </div>
          <div className={`d-flex align-items-center ${styles.otherInputs}`}>
            <div className={styles.dataTypeText}>Retrive data from</div>
            <FormInput
              type="text"
              name="startingCell"
              control={control}
              rules={{
                required: true,
              }}
              input={{
                type: "text",
                placeholder: intl.formatMessage({
                  id: "ENVIVO.LINKDATA.STARTING_CELL",
                  defaultMessage: "Starting Cell",
                }),
                maxlength: 5,
                className: styles.cellInput,
              }}
            />
            <FormInput
              type="text"
              name="endingCell"
              control={control}
              rules={{
                required: true,
              }}
              input={{
                type: "text",
                placeholder: intl.formatMessage({
                  id: "ENVIVO.LINKDATA.ENDING_CELL",
                  defaultMessage: "Ending Cell",
                }),
                maxlength: 5,
                className: styles.cellInput,
              }}
            />
          </div>
          <div className={styles.linkLabel}>
            Click load to view the file and mark Samples
          </div>
        </>
      )}
      <div className="d-flex align-items-center">
        <BaseIcon icon={LinkDataIcon} />
        <FormInput
          type="text"
          name="pasteBucket"
          control={control}
          rules={{
            required: true,
          }}
          input={{
            type: "text",
            placeholder: intl.formatMessage({
              id: "ENVIVO.DataLink.Url",
              defaultMessage: "URL",
            }),
            className: styles.linkBoxInput,
          }}
        />
        <div className={`d-flex justify-content-end ${styles.buttonLink}`}>
          <Button
            onClick={() => handleLinkData()}
            className="border-dark"
            variant="outline-dark"
            size="sm"
            type="submit"
          >
            <FormattedMessage id="BUTTON.LINK" defaultMessage="LINK" />
          </Button>
        </div>
      </div>

      {dataLinkStatus && (
        <div>
          <table>
            <tr>
              <th>Sample</th>
              <th>Status</th>
            </tr>
            {dataLinkStatus.samplesStatus.map((status) => {
              return (
                <tr>
                  <td>{status.sampleNumber}</td>
                  <td>
                    {status.status == 0
                      ? "Linked Successfully"
                      : "Link Failure"}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      )}
    </div>
  );
};

export default LinkData;
