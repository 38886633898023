import { PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { RouteConstants } from "../../constants";
import { useAuthContext } from "../../context/AuthContext";

export function Protected({ children }: PropsWithChildren<{}>) {
  const {
    state,
  } = useAuthContext();
  const user = state?.user
  const location = useLocation();

  if (!user?.token) {
    return (
      <Navigate
        to={`/${RouteConstants.AUTH}/${RouteConstants.LOGIN}`}
        state={{ from: location }}
      />
    );
  }

  return <>{children}</>;
}
