import { ReactNode, Ref, forwardRef, useEffect, useState } from "react";
import { Table as BsTable, Pagination, Spinner } from "react-bootstrap";
import { BaseIcon } from "../BaseIcon/BaseIcon";
import { Button } from "../Button/Button";
import styles from "./Table.module.css";
import {
  useTable,
  Column,
  usePagination,
  Row,
  useExpanded,
  TableInstance,
} from "react-table";

export type ButtonType = {
  label: ReactNode;
  onClick: (
    setSelectedRow: (row?: Row<DataType>) => void,
    row?: Row<DataType>
  ) => void;
  icon?: string;
  disabled?: boolean;
};
export type DataType = { [key: string]: ReactNode |Date| DataType[] };

interface TableProps {
  data?: DataType[];
  columns: Column<DataType>[];
  pageCount?: number;
  onPaginate?: (page: number) => void;
  className?: string;
  buttons?: ButtonType[];
  isLoading?: boolean;
  isError?: boolean;
  tableHooks?: (typeof useExpanded | typeof usePagination)[];
  defaultExpandedRows?: { [key: string | number]: boolean };
  disableSelectRow?: boolean;
  customTableContent?: ReactNode;
  selectedRows?:  { [key: string | number]: boolean };
  setSelectedRows?:  React.Dispatch<React.SetStateAction<{[key: string]: boolean}>>;
  renderRowSubComponent?: (
    row: Row<DataType>,
    table: TableInstance<DataType>
  ) => ReactNode;
}
type LoadingAndErrorHandlerProps = {
  isError?: boolean;
  isLoading?: boolean;
};

export const LoadingAndErrorHandler = ({
  isError,
  isLoading,
}: LoadingAndErrorHandlerProps) =>
  isError ? (
    <div>Error With Receiving Data</div>
  ) : isLoading ? (
    <div>
      <Spinner as="span" animation="border" size="sm" className="m-auto" />
      Loading...
    </div>
  ) : (
    <></>
  );

const Table = forwardRef(({
  data = [],
  columns,
  className,
  pageCount,
  isLoading,
  isError,
  onPaginate,
  buttons,
  tableHooks = [usePagination],
  defaultExpandedRows = {},
  disableSelectRow,
  customTableContent,
  selectedRows,
  setSelectedRows,
  renderRowSubComponent
}: TableProps, ref: Ref<HTMLTableElement>) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        expanded: defaultExpandedRows,
      },
      manualPagination: true,
      pageCount: pageCount,
    },
    ...tableHooks
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = tableInstance;

  const [selectedRow, setSelectedRow] = useState<Row<DataType>>();
  const handleRowClick = (row: Row<DataType>) => {
    if (row.depth === 1 || disableSelectRow) return;
    setSelectedRow(row);

    if (setSelectedRows) {
      setSelectedRows((prevSelectedRows) => {
        const newRowState = { ...prevSelectedRows };

        if (prevSelectedRows[row.id]) {
          delete newRowState[row.id];
        } else {
          Object.keys(newRowState).forEach((key) => {
            delete newRowState[key];
          });
          newRowState[row.id] = true;
        }

        return newRowState;
      });
    }
  };

  useEffect(() => {
    if (renderRowSubComponent) {
      tableInstance.toggleAllRowsExpanded(true);
    }
  }, [data]);

  useEffect(() => {
    if (selectedRow && selectedRow.canExpand) {
      selectedRow.toggleRowExpanded();
    }
  }, [selectedRow]);

  useEffect(() => {
    if(onPaginate) {
      onPaginate(pageIndex + 1);
    }
  }, [pageIndex, onPaginate]);

  const ActionsButtons = () => (
    <div className="d-flex">
      {buttons?.map(({ label, onClick, icon, disabled }, index) => (
        <Button
          key={`btn ${index}`}
          variant="outline-dark"
          className="border-dark m-1 lh-sm h-1"
          onClick={() => onClick(setSelectedRow, selectedRow)}
          disabled={isError || isLoading || (disabled && !selectedRow)}
        >
          {icon && <BaseIcon icon={icon} className="me-1" />}
          {label}
        </Button>
      ))}
    </div>
  );

  const PaginationView = () => (
    <Pagination
      className={`justify-content-end mb-0 text-dark border-width-0 ${styles.pagination}`}
      size="sm"
    >
      <div className={`${styles.text}`}>
        {pageIndex + 1} of {pageCount}
      </div>
      <Pagination.Prev
        onClick={() => previousPage()}
        disabled={!canPreviousPage || isLoading || isError}
      />
      <Pagination.Next
        onClick={() => nextPage()}
        disabled={!canNextPage || isLoading || isError}
      />
    </Pagination>
  );

  const TableContent = () => {
    return (
      <tbody {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row);
          return (
            <>
              <tr
                {...row.getRowProps()}
                onClick={() => handleRowClick(row)}
                style={{
                  cursor: "pointer",
                  backgroundColor: selectedRows?.[row.id] || selectedRow === row ? "lightgray" : "white",
                  borderBottomColor: row.isExpanded ? "white" : "grey",
                  verticalAlign: "middle",
                }}
              >
                {row.cells.map((cell) => {
                  const columnId = cell.column.id;
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={
                        selectedRows
                          ? `text-nowrap p-2 ${styles.border}`
                          : "text-nowrap p-2"
                      }
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
              {row.isExpanded && renderRowSubComponent?.(row, tableInstance)}
            </>
          );
        })}
      </tbody>
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <ActionsButtons />
        {pageCount && <PaginationView/>}
      </div>
      <BsTable
        responsive
        className={`table w-100 ${className} ${styles.table}`}
        ref={ref}
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={selectedRows
                      ? `text-nowrap ${styles.head} ${styles.border}`
                      : `text-nowrap ${styles.head}`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {data && <TableContent />}
        {customTableContent}
      </BsTable>
      <LoadingAndErrorHandler isError={isError} isLoading={isLoading} />
    </div>
  );
});

export default Table;
