import { KitTypeApi } from "../api/apis/kit-type-api";
import { KitTypeDto } from "../api/models";
import { getTablePaged } from "../util/pagination.util";
import {
  KitTypeParams,
  UpdateKitTypeParams,
} from "../models/requests/kitType.request";
import { TableParams } from "../models/requests/table.request";
import { IApiResult } from "../models/responses/api-result.response";
import { TableResponse } from "../models/responses/table.response";
import Logger from "../util/logger.util";
import { KitTypeWithStudyResponse } from "../models/responses/kitType.response";

const logger = new Logger({ source: "Kit Type Service" });

export const kitTypeService = {
  addKitType: async (
    kitType: KitTypeParams
  ): Promise<IApiResult<void> | null> => await addKitType(kitType),
  getKitTypes: async ({
    pageNumber,
  }: TableParams): Promise<IApiResult<TableResponse<KitTypeDto>> | null> =>
    await getKitTypes({ pageNumber }),
  editKitType: async (
    kitType: UpdateKitTypeParams
  ): Promise<IApiResult<void> | null> => await editKitType(kitType),
  deleteKitType: async (id: string): Promise<IApiResult<void> | null> =>
    await deleteKitType(id),
  getKitTypesWithStudies: async (): Promise<IApiResult<KitTypeWithStudyResponse[]> | null> =>
    await getKitTypesWithStudies(),
};


const addKitType = async (
  kitType: KitTypeParams
): Promise<IApiResult<void> | null> => {
  const api = new KitTypeApi();
  const { data: response } = await api.createKitType(kitType);
  
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Add Kit Type invalid API response", { statusCode });
    return response;
  }
  return response;
};

const getKitTypes = async ({
  pageNumber,
}: TableParams): Promise<IApiResult<TableResponse<KitTypeDto>> | null> => {
  const pageSize = 100;
  logger.info(`getKitType kit types - page number ${pageNumber}`);
  
  const api = new KitTypeApi();
  const { data: response } = await api.getKitTypes(pageNumber, pageSize);

  return getTablePaged(response, pageSize, logger);


};

const editKitType = async (
  kitType: UpdateKitTypeParams
  ): Promise<IApiResult<void> | null> => {
    const api = new KitTypeApi();
    const { data: response } = await api.updateKitType(kitType);
    
    const { success, statusCode } = response;
    if (!success) {
      logger.error("Edit Kit Type invalid API response", { statusCode });
      return response;
    }
    return response;
  };
  
  const deleteKitType = async (id: string): Promise<IApiResult<void> | null> => {
    const api = new KitTypeApi();
    
    const { data: response } = await api.deleteKitType(id);
    const { success, statusCode } = response;
    if (!success) {
      logger.error("Delete Kit Type invalid API response", { statusCode });
      return response;
    }
  return response;
  };
  
  const getKitTypesWithStudies = async (): Promise<Promise<
    IApiResult<KitTypeWithStudyResponse[]>
  > | null> => {
    const api = new KitTypeApi();
    const { data: response } = await api.getKitTypesWithStudyInfo();
    const { success, statusCode } = response;
    if (!success) {
      logger.error("Get kit type with studies invalid API response", { statusCode });
      return response;
    }
    return response;
  };