import { FormModal } from "../../../../../components/form/FormModal/FormModal";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../../../components/Button/Button";
import { useFieldArray, useForm } from "react-hook-form";
import ProcessIcon from "../../../../../assets/process_samples.svg";
import AddIcon from "../../../../../assets/add_icon.svg";
import styles from "./ProcessOther.module.css";
import { useNavigate } from "react-router";
import ProcessSample from "./ProcessSample/ProcessSample";
import useProcessOther from "../../../../../hooks/useProcessOther";
import {
  getSelectedSuffix,
  getSuffixes,
} from "../../../../../util/suffix.util";

export type ProcessSampleProps = {
  sampleNumber: number;
  sampleType: string;
  kitSubjectId: string;
  note?: string;
  processCollectionDate?: Date;
  processingDate?: Date;
  storageDate?: Date;
  stoolScore?: { label: string; value: number }[];
  suffix: { label: string; value: boolean; volume: string }[];
  temperature?: string;
};

export type ProcessOtherProps = {
  samples: ProcessSampleProps[];
  subjectId?: string;
  kitBarcode?: string;
  setShow?: (show: boolean) => void;
  refetch?: () => void;
};

const ProcessOther = ({samples, kitBarcode, setShow, subjectId, refetch}: ProcessOtherProps) => {
  const { control, watch, setValue, handleSubmit, getValues } =
    useForm<ProcessOtherProps>({
      defaultValues: { samples:samples?? [] },
    });
  const navigate = useNavigate();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "samples",
  });
  const { addProcess } = useProcessOther();

  const addProcessSample = () => {
    append({
      sampleNumber: 0,
      sampleType: "",
      kitSubjectId: subjectId || "",
      stoolScore: [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
      ],
      suffix: getSuffixes(),
    });
  };

  const onSubmit = async (processState: ProcessOtherProps) => {
    let isError = false;
    const data = processState.samples.map((sample, index) => {
      const suffixes = getSelectedSuffix(sample.suffix);
      if (suffixes.length === 0) {
        alert(`Suffix of sample ${index + 1} must be specified`);
        isError = true;
      }
      return {
        ...sample,
        sampleType: Number(sample.sampleType),
        stoolScore: Number(sample.stoolScore),
        temperature: Number(sample.temperature),
        suffix: suffixes,
      };
    });
    if (isError) {
      return;
    }
    const response = await addProcess({ samples: data, kitBarcode: kitBarcode});
   
    if (  response?.success) {
      setShow ? setShow(false) : navigate(-1);
      refetch?.();
    }

     
  };

  return (
    <>
      <FormModal
        onSubmit={handleSubmit(onSubmit)}
        id="device"
        className={`modal-xl modal-dialog-centered modal-dialog-scrollable ${styles.customModel}`}
        dialogClassName="h-100"
        contentClassName={`${styles.content} h-100`}
        formClassName="h-100"
        fullscreen={true}
        header={
          <div>
            <BaseIcon icon={ProcessIcon} />{" "}
            <b>
              <FormattedMessage
                id="ENVIVO.CAPSCAN_OTHER_PROCESS"
                defaultMessage="Capscan Other"
              />
            </b>
          </div>
        }
        footer={
          <>
            <Button
              variant="outline-dark"
              className="border-dark"
              onClick={() =>
                setShow ? setShow(false) : navigate(-1)
              }
            >
              <FormattedMessage
                id={"ENVIVO.MODAL.CANCEL"}
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              variant="outline-dark"
              className="border-dark"
              form="device"
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              <FormattedMessage
                id={"ENVIVO.CAPSCAN_PROCESS.BUTTON.SAVE_AND_EXIT"}
                defaultMessage={"Save and Exit"}
              />
            </Button>
          </>
        }
        show={true}
        handleClose={() =>
          setShow ? setShow(false) : navigate(-1)
        }
      >
        {fields.map((field, index) => (
          <ProcessSample
            getValue={getValues}
            {...field}
            index={index}
            control={control}
            watch={watch}
            setValue={setValue}
            key={field.id}
            remove={remove}
          />
        ))}
        <div className={styles.addIcon}>
          <BaseIcon icon={AddIcon} onClick={addProcessSample} />
          Add Sample
        </div>
      </FormModal>
    </>
  );
};

export default ProcessOther;
