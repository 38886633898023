import { Link } from "react-router-dom";
import AppWidget from "../../components/AppWidget/AppWidget";
import { RouteConstants } from "../../constants";
import KitAssemblyIcon from "../../assets/kit_assembly.svg";
import DistributionIcon from "../../assets/distributionicon.svg";
import ReturnUnusedKitIcon from "../../assets/return_unused_kit.svg";
import ProcessCapscanIcon from "../../assets/process_capscan.svg";
import ProcessIcon from "../../assets/process_samples.svg";
import ReportsIcon from "../../assets/reports_new.svg";
import CameraIcon from "../../assets/photoshoot.svg";
import PrintIcon from "../../assets/print.svg";
import TransferIcon from "../../assets/transfer.svg";
import StorageLocationIcon from "../../assets/storage_location.svg";
import LinkDataIcon from "../../assets/link_data.svg";
import RePrintLabelIcon from "../../assets/reprint_label.svg";
import styles from "./Envivo.module.css";
import { useAuthContext } from "../../context/AuthContext";


function EnvivoPage() {

    const { state } = useAuthContext();
    const { role } = state?.user || {};
    let widgetWidth = "9rem"

    const adminOnly = role === "Admin"  || role== "Technician"? "" : "d-none"
    return (
        <div className={styles.main}>
            <div className={styles.block}>
                <div>Preparations</div>
                <div className={`d-flex ${styles.preparationsBlock}`}>
                    <Link to={`/${RouteConstants.FACTORY}`}>
                        <AppWidget title={"Print Label"} icon={PrintIcon} width={widgetWidth} />
                    </Link>
                    <>
                    <Link to={`${RouteConstants.RE_PRINT}`}>
                        <AppWidget title={"Reprint Label"} icon={RePrintLabelIcon} width={widgetWidth} />
                    </Link>
                        <Link to={`${RouteConstants.KIT_ASSEMBLY}`} className={adminOnly}>
                            <AppWidget title={"Kit Assembly"} icon={KitAssemblyIcon} width={widgetWidth} />
                        </Link>

                        <Link to={`${RouteConstants.DISTRIBUTION}`} className={adminOnly}>
                            <AppWidget title={"Distribution"} icon={DistributionIcon} width={widgetWidth} />
                        </Link>
              <Link to={`${RouteConstants.RETURN_KIT}`}>
                            <AppWidget title={"Return Kit"} icon={ReturnUnusedKitIcon} width={widgetWidth} />
                        </Link>
                    </>
                </div>
            </div>
            <div className={`d-flex flex-column ${styles.block}`}>
                <div className={styles.subBlockAfter}>
                    <div>After</div>
                    <div className={styles.afterBlock}>
                        <Link to={`${RouteConstants.PROCESS_CAPSCAN}`}>
                            <AppWidget title={"Process Capscan"} icon={ProcessCapscanIcon} width={widgetWidth} />
                        </Link>
                        <Link to={`${RouteConstants.PROCESS_OTHER}`}>
                            <AppWidget title={"Process"} icon={ProcessIcon} width={widgetWidth} />
                        </Link>
                        <Link to={`${RouteConstants.TRANSFER}`}>
                            <AppWidget title={"Transfer"} icon={TransferIcon} width={widgetWidth} />
                        </Link>
                        <Link to={`${RouteConstants.STORAGE_LOCATION}`}>
                            <AppWidget title={"Storage Location"} icon={StorageLocationIcon} width={widgetWidth} />
                        </Link>
                    </div>
                </div>
                <div className={styles.subBlockMore}>
                    <div>More</div>
                    <div className={styles.moreBlock}>
                        <Link to={`${RouteConstants.LINK_DATA}`} className={adminOnly}>
                            <AppWidget title={"Link Data"} icon={LinkDataIcon} width={widgetWidth} />
                        </Link>
                        <Link to={`${RouteConstants.SAMPLE_REPORTS}`} className={adminOnly}>
                            <AppWidget title={"Sample Reports"} icon={ReportsIcon} width={widgetWidth} />
                        </Link>
                        <Link to={`${RouteConstants.CAMERA_SETTINGS}`}>
                            <AppWidget title={"Camera Settings"} icon={CameraIcon} width={widgetWidth} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EnvivoPage;
