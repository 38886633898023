import { useState, useEffect } from "react";
import { FormModal } from "../../../../../../components/form/FormModal/FormModal";
import ImageCapture from "../../../../../../components/ImageCapture/ImageCapture";
import { Button } from "../../../../../../components/Button/Button";
import { FormattedMessage } from "react-intl";
import { BaseIcon } from "../../../../../../components/BaseIcon/BaseIcon";
import ProcessIcon from "../../../../../../assets/process_capscan.svg";
import { DEVICE_DEVICE } from "../../../More/CameraSettings/CameraSettings";
type DeviceFormProps = {
  show: boolean;
  handleHide: () => void;
  handleAccept: (imageUrl: string) => void;
};

const DeviceImage = ({ show, handleHide, handleAccept }: DeviceFormProps) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    setImageUrl("");
  }, [show]);

  return (
    <div style={{ zIndex: 20 }}>
      <FormModal
        id="form"
        className={`modal-xl modal-dialog-centered `}
        dialogClassName="h-100 w-100"
        contentClassName="h-75 w-100"
        formClassName="h-100"
        header={
          <div>
            <BaseIcon icon={ProcessIcon} />{" "}
            <b>
              <FormattedMessage
                id="ENVIVO.CAPSCAN_PROCESS"
                defaultMessage="Process Capscan"
              />
            </b>
          </div>
        }
        footer={
          <>
            <Button
              variant="outline-dark"
              className="border-dark"
              onClick={() => handleHide()}
            >
              <FormattedMessage id="BUTTON.CANCEL" defaultMessage="Cancel" />
            </Button>
            <Button
              disabled={!imageUrl}
              variant="outline-dark"
              className="border-dark"
              onClick={() => {
                handleAccept(imageUrl);
                handleHide();
              }}
            >
              <FormattedMessage id="BUTTON.ACCEPT" defaultMessage="Accept" />
            </Button>
          </>
        }
        show={show}
        handleClose={handleHide}
      >
        <ImageCapture
          mirrored={false}
          width={700}
          height={380}
          disabled={false}
          handleCapture={setImageUrl}
          deviceId={localStorage.getItem(DEVICE_DEVICE)}
        />
      </FormModal>
    </div>
  );
};

export default DeviceImage;
