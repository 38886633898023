import { UseMutationOptions } from "@tanstack/react-query";
import { KitTypeParams, UpdateKitTypeParams } from "../models/requests/kitType.request";
import { IApiResult } from "../models/responses/api-result.response";
import { kitTypeService } from "../services/kitType.service";
import Logger from "../util/logger.util";

export const addKitTypeMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  KitTypeParams
> => ({
  mutationKey: ["add"],
  mutationFn: async (kitType: KitTypeParams) => await kitTypeService.addKitType(kitType),
  onSuccess: () => {
    Logger.info("Add kitType done successfully");
  },
});

export const editKitTypeMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  { kitType: UpdateKitTypeParams }
> => ({
  mutationKey: ["edit"],
  mutationFn: async ({ kitType }) => await kitTypeService.editKitType(kitType),
  onSuccess: () => {
    Logger.info("Edit kitType done successfully");
  },
});

export const deleteKitTypeMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  string
> => ({
  mutationKey: ["delete"],
  mutationFn: async (id: string) => await kitTypeService.deleteKitType(id),
  onSuccess: () => {
    Logger.info("Delete kitType done successfully");
  },
});
