import { Outlet } from "react-router-dom";
import RootProviders from "./providers/RootProviders";
import globalAxios from 'axios';
import { tokenHandlerInterceptor } from "./util/api.util";
function App() {

  globalAxios.interceptors.request.use(tokenHandlerInterceptor)

  return (
    <RootProviders>
      <Outlet />
    </RootProviders>
  );
}

export default App;
