import { QueryType, ResolvedQuery } from "../models/query.model";
import { KitsParams } from "../models/requests/kit.request";
import { kitService } from "../services/kit.service";

type KitsQuery = QueryType<
  ResolvedQuery<typeof kitService.getkitsByStudyDepartment>
>;
type KitsQueryOptions = Omit<KitsQuery, "queryKey" | "queryFn">;

export const kitsByStudyDepartmentQuery = (
  { studyDepartmentId }: KitsParams,
  options?: KitsQueryOptions
): KitsQuery => ({
  ...options,
  queryKey: ["kits", studyDepartmentId],
  queryFn: () => kitService.getkitsByStudyDepartment({ studyDepartmentId }),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
});

export type KitByCapscanSnQuery = QueryType<
  ResolvedQuery<typeof kitService.getKitByCapscanSn>
>;
type KitByCapscanSnQueryOptions = Omit<
  KitByCapscanSnQuery,
  "queryKey" | "queryFn"
>;

export const kitByCapscanSnQuery = (
  sn: string,
  options?: KitByCapscanSnQueryOptions
): KitByCapscanSnQuery => ({
  ...options,
  queryKey: ["kits", sn],
  queryFn: async () => await kitService.getKitByCapscanSn(sn),
  keepPreviousData: true,
});

export type KitByBarcodeQuery = QueryType<
  ResolvedQuery<typeof kitService.getKitByBarcode>
>;
type KitByBarcodeQueryOptions = Omit<KitByBarcodeQuery, "queryKey" | "queryFn">;

export const kitByBarcodeQuery = (
  barcode: string,
  options?: KitByBarcodeQueryOptions
): KitByBarcodeQuery => ({
  ...options,
  queryKey: ["kits", barcode],
  queryFn: async () => await kitService.getKitByBarcode(barcode),
  keepPreviousData: true,
});
