import { ReactNode } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useWizardContext } from "../../../context/WizardContext";
import { Button } from "../../Button/Button";
import Wizard from "../Wizard/Wizard";

export type WizardStepWithLabel = {
  label: string;
  content: ReactNode;
};

export type WizardStep = ReactNode;

export type WizardModalProps = {
  show: boolean;
  onShow?: () => void;
  handleHide?: () => void;
  title?: ReactNode;
  className?: string;
  contentClassName?: string;
  size?: "sm" | "lg" | "xl";
  scrollable?: boolean;
  steps: WizardStepWithLabel[] | WizardStep[];
  wizardClassName?: string;
};

function WizardModal({
  show,
  onShow,
  handleHide,
  title,
  className,
  contentClassName,
  size = "lg",
  scrollable = true,
  steps,
  wizardClassName,
}: WizardModalProps) {
  const {
    state: { isFirstStep, isLastStep, isLoading },
    methods: { nextStep, prevStep, reset },
  } = useWizardContext();

  function onHide(): void {
    handleHide && handleHide();
    reset();
  };

  function onPrev(): void {
    prevStep();
  };

  function onNext(): void {
    nextStep();
  };

  return (
    <Modal
      className={className}
      contentClassName={contentClassName}
      show={show}
      size={size}
      scrollable={scrollable}
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-auto">
        <Wizard steps={steps} className={wizardClassName} />
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex">
          <Button className="mx-1" onClick={onHide} disabled={isLoading}>
            <FormattedMessage
              id="BUTTON.CANCEL"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            className="mx-1"
            onClick={onPrev}
            disabled={isFirstStep || isLoading}
          >
            <FormattedMessage
              id="BUTTON.BACK"
              defaultMessage="Back"
            />
          </Button>
          <Button className="mx-1" onClick={onNext} disabled={isLoading}>
            {isLastStep ? (
              <FormattedMessage
                id="BUTTON.FINISH"
                defaultMessage="Finish"
              />
            ) : (
              <FormattedMessage
                id="BUTTON.NEXT"
                defaultMessage="Next"
              />
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default WizardModal;
