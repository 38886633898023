import { BaseIcon } from "../BaseIcon/BaseIcon";
import { Button } from "../Button/Button";

type IconButtonProps = {
  icon: string;
  onClick?: () => void;
  className?: string;
};

export const IconButton = ({ icon, onClick, className }: IconButtonProps) => (
  <Button variant="light" onClick={onClick}>
    <BaseIcon icon={icon} className={className || "w-75"} />
  </Button>
);
