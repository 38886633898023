import { useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import { Button } from "../Button/Button";
import { BaseIcon } from "../BaseIcon/BaseIcon";
import { FormattedMessage } from "react-intl";
import CameraIcon from "../../assets/photoshoot.svg";
import CompleteddIcon from "../../assets/completed.svg";
import CameraOffIcon from "../../assets/camera_off.svg";
import ImageOcr from "./ImageOcr";

interface ImageCaptureProps {
  width: number;
  height: number;
  disabled: boolean;
  handleCapture: (src: string) => void ;
  handleOcr?: (src: string, captured: string) => void;
  handleCaptureStart?: () => void;
  imageUrl?: string;
  deviceId?: string | null;
  className?: string;
  scale?: number;
  ocrText? : string;
  mirrored: boolean
}

const ImageCapture = ({
  width,
  height,
  disabled,
  handleCapture,
  handleCaptureStart,
  imageUrl,
  deviceId,
  className,
  scale,
  handleOcr,
  mirrored
}: ImageCaptureProps) => {
  const webcamRef = useRef<Webcam | null>(null);
  const [capturedImage, setCapturedImage] = useState<string | undefined>(imageUrl);
  const [isCaptureClicked, setIsCaptureClicked] = useState(false);
  const [mediaError, setMediaError] = useState<string | DOMException>("");
  const [showImageOCR, setShowImageOCR] = useState<boolean>(false)

  useEffect(() => {
    setCapturedImage(imageUrl);
    setIsCaptureClicked(false);
  }, [imageUrl]);

  const captureImage = () => {
    if (!isCaptureClicked) {
      setIsCaptureClicked(true);
    }
    const imageSrc = webcamRef.current?.getScreenshot();
    if (!imageSrc) {
      handleCaptureStart && handleCaptureStart();
    }
    setCapturedImage(imageSrc || undefined);
    imageSrc && handleCapture(imageSrc)
  };


  const Cammera = () => {
    if (capturedImage) {
      return <></>
    } else {
      if (isCaptureClicked && !disabled && !mediaError) {
        return (
          <>
            <Webcam
              mirrored={mirrored}
              muted={false}
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                width: width * (scale || 1),
                height: height * (scale || 1),
                deviceId: deviceId || undefined,
              }}
              style={{ position: "absolute", right: "200%", zIndex: -1 }}
              onUserMediaError={setMediaError}
            />
            <Webcam
              mirrored={mirrored}
              muted={false}
              audio={false}
              screenshotFormat="image/png"
              videoConstraints={{
                width: width,
                height: height,
                deviceId: deviceId || undefined,
              }}
              onUserMediaError={setMediaError}
            />
          </>
        );
      } else {
        return (
          <div
            style={{ width, height }}
            className="bg-light ms-2 mt-3 d-flex flex-column align-items-center pt-5"
          >
            {mediaError && (
              <>
                <BaseIcon icon={CameraOffIcon} />{" "}
                <FormattedMessage
                  id="ENVIVO.CAPSCAN_PROCESS.CAMERA_OFF"
                  defaultMessage="Camera Off"
                />
              </>
            )}
          </div>
        );
      }
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex align-items-center gap-4">
        <Button
          variant="outline-dark"
          className="border rounded-4  mb-2"
          onClick={captureImage}
          size="sm"
          disabled={disabled}
        >
          <BaseIcon icon={CameraIcon} />{" "}
          <FormattedMessage
            id="ENVIVO.CAPSCAN_PROCESS.CAPTURE"
            defaultMessage="Capture"
          />
        </Button>
        <Button
          variant="outline-dark"
          className="border rounded-4 mb-2"
          onClick={() => setShowImageOCR(true)}
          size="sm"
          disabled={disabled}>
          <BaseIcon icon={CompleteddIcon} />{" "}
        </Button>
      </div>
      <ImageOcr
        handleClose={() => setShowImageOCR(false)}   
        handleOcr={cropped => handleOcr && handleOcr(capturedImage!, cropped)}
        show={showImageOCR}
        capturedImage={capturedImage}
          />
      <Cammera />
      {capturedImage && (
        <img
          width={width}
          height={height}
          className={`ms-2 ${className}`}
          src={capturedImage || ""} alt="Captured" />
      )}


    </div>
  );
};

export default ImageCapture;
