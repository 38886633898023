import { getTablePaged } from "../util/pagination.util";
import { TableParams } from "../models/requests/table.request";
import { UpdateUserParams, UserParams } from "../models/requests/user.request";
import { IApiResult } from "../models/responses/api-result.response";
import { TableResponse } from "../models/responses/table.response";
import { UserResponse } from "../models/responses/user.response";
import Logger from "../util/logger.util";
import { UserApi } from "../api/apis/user-api";

const logger = new Logger({ source: "User Service" });

export const userService = {
  getUsers: async ({
    pageNumber,
  }: TableParams): Promise<IApiResult<TableResponse<UserResponse>> | null> =>
    await getUsers({ pageNumber }),
  addUser: async (user: UserParams): Promise<IApiResult<UserResponse> | null> =>
    await addUser(user),
  editUser: async (
    user: UpdateUserParams
  ): Promise<IApiResult<UserResponse> | null> => await editUser(user),

  deleteUser: async (userId: string): Promise<IApiResult<void> | null> =>
    await deleteUser(userId),
};

const getUsers = async ({
  pageNumber,
}: TableParams): Promise<IApiResult<TableResponse<UserResponse>> | null> => {
  const usersPageCount = 100;
  logger.info(`get users - page number ${pageNumber}`);
  const api = new UserApi();
  const { data: response } = await api.getUserPaged(pageNumber, usersPageCount);
  return getTablePaged(response, usersPageCount, logger);
};

const addUser = async (
  user: UserParams
): Promise<IApiResult<UserResponse> | null> => {
  logger.info(`add user ${user.fullName}`);

  const api = new UserApi();
  const { data: response } = await api.addUser({
    ...user,
    role: Number(user.role),
  });
  const { success, statusCode } = response;

  if (!success) {
    logger.error("Add User invalid API response", { statusCode });
    return response;
  }
  return response;
};

const editUser = async (
  user: UpdateUserParams
): Promise<IApiResult<UserResponse> | null> => {
  logger.info(`edit user ${user.fullName}`);
  const api = new UserApi();
  const { data: response } = await api.updateUser({
    ...user,
    role: user.role ? Number(user.role) : undefined,
  });
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Edit User invalid API response", { statusCode });
    return null;
  }
  return response;
};

const deleteUser = async (userId: string): Promise<IApiResult<void> | null> => {
  logger.info(`delete user ${userId}`);
  const api = new UserApi();
  const { data: response } = await api.deleteUser(userId);
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Delete User invalid API response", { statusCode });
    return null;
  }
  return response;
};
