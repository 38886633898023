import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Form, useNavigate } from "react-router-dom";
import { Button } from "../Button/Button";
import { FormGroup } from "../form/FormGroup/FormGroup";
import FormInput from "../form/FormInput/FormInput";
import { useQuery } from "@tanstack/react-query";
import { RouteConstants } from "../../constants";
import { departmentStudiesQuery } from "../../queries/site.query";

export type ClinicalStudyFormInputs = {
  study: string;
};

type ClinicalStudyProps = { siteId?: string };

function ClinicalStudyForm({ siteId }: ClinicalStudyProps) {
  const {
    handleSubmit,
    control,
    formState: { errors: formErrors, isValid },
  } = useForm<ClinicalStudyFormInputs>();
  const navigate = useNavigate();
  
  const { data: studies } = useQuery(
    departmentStudiesQuery({ clinicDepartmentId: siteId || "" })
  );

  const getStudies = () =>
    studies?.data?.map((item) => ({
      key: item.id,
      value: item.studyName || "",
    }));

  function onStudySubmit({ study }: ClinicalStudyFormInputs): void {
    const studyObject = studies?.data?.find((item) => item.id === study);
    navigate(RouteConstants.CLINICAL, {
      state: {
        studyDepartmentId: study,
        studyName: studyObject?.studyName,
        subjectDetails: studyObject?.subjectDetails,
        jsonQuestions: studyObject?.jsonQuestions,
      },
    });
  }

  return (
    <Form className="my-2" onSubmit={handleSubmit(onStudySubmit)}>
      <FormGroup
        className="w-100"
        label={
          <div className="me-2">
            <FormattedMessage
              id="ENVIVO.STUDY.SELECT.STUDY"
              defaultMessage="Study"
            />
          </div>
        }
        input={
          <FormInput
            type="select"
            name="study"
            control={control}
            rules={{
              required: true,
            }}
            input={{
              placeholder: "Select study",
              items: getStudies(),
              isInvalid: !!formErrors?.study,
            }}
          />
        }
      />
      <Button
        type="submit"
        className="px-sm-4 mt-sm-0 mt-5 m-auto"
        disabled={!isValid}
      >
        <FormattedMessage id={"OK"} defaultMessage="OK" />
      </Button>
    </Form>
  );
}

export default ClinicalStudyForm;
