import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { TreeNode } from "./TreeNode";
import AddIcon from "../../../../../assets/add.svg";
import EditIcon from "../../../../../assets/edit.svg";
import DeleteIcon from "../../../../../assets/delete.svg";
import { FormattedMessage } from "react-intl";
import { deleteClinicDepartmentMutation } from "../../../../../mutations/site.mutation";
import { useMutation } from "@tanstack/react-query";
import { IconButton } from "../../../../../components/IconButton/IconButton";
import { DepartmentStudyDto } from "../../../../../api";
import ClinicDepartmentModal from "./ClinicDepartmentModal";
import DeleteModal from "../../../../../components/DeleteModal/DeleteModal";
import StudyDepartmentModal from "./StudyDepartmentModal";

export type ClinicDepartmentsInfo = {
  id: string;
  name: string;
  shipmentAddress: string;
  parentId: string;
  studies: DepartmentStudyDto[];
};

type SubLClinicProps = {
  clinicDepartment: ClinicDepartmentsInfo;
  clinicParent: string;
  refetch: () => void;
};

const ClinicDepartment = ({
  clinicDepartment,
  clinicParent,
  refetch,
}: SubLClinicProps) => {
  const [showClinicDepartmentModal, setShowClinicDepartmentModal] =
    useState(false);
  const [showStudyModal, setShowStudyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { mutateAsync: mutateDeleteClinicDepartment } = useMutation(
    deleteClinicDepartmentMutation()
  );

  const buttons = (
    <ButtonGroup>
      <IconButton icon={AddIcon} onClick={() => setShowStudyModal(true)} />
      <IconButton
        icon={EditIcon}
        onClick={() => setShowClinicDepartmentModal(true)}
      />
      <IconButton icon={DeleteIcon} onClick={() => setShowDeleteModal(true)} />
    </ButtonGroup>
  );

  const details = (
    <label>
      <b>
        <FormattedMessage
          id="ENVIVO.SITES.SUB_CLINIC.SHIPMENT_ADDRESS"
          defaultMessage="Shipment Address"
        />
        :
      </b>{" "}
      {clinicDepartment.shipmentAddress}
    </label>
  );

  async function removeDepartment(id: string) {
    await mutateDeleteClinicDepartment(id, {
      onSuccess: (response) => {
        if (response) {
          if (!response?.success) {
            alert(response?.message);
          }
          refetch();
        }
      },
    });
  }

  return (
    <>
      <TreeNode
        name={clinicDepartment.name}
        buttons={buttons}
        details={details}
      >
        {showStudyModal && (
          <StudyDepartmentModal
            clinicName={clinicParent}
            clinicDepartmentName={clinicDepartment.name}
            clinicDepartmentId={clinicDepartment.id}
            refetch={refetch}
            show={showStudyModal}
            setShow={setShowStudyModal}
          />
        )}
      </TreeNode>
      {showClinicDepartmentModal && (
        <ClinicDepartmentModal
          clinicDepartment={clinicDepartment}
          refetch={refetch}
          show={showClinicDepartmentModal}
          setShow={setShowClinicDepartmentModal}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          handleDelete={async () => {
            setShowDeleteModal(false);
            await removeDepartment(clinicDepartment.id);
          }}
          show={showDeleteModal}
          InternationalizationKey="ENVIVO.SITES.SUB_CLINIC.DELETE"
          handleClose={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
};

export default ClinicDepartment;
