import React, {
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import Logger from "../util/logger.util";

const logger = new Logger({ source: "WizardContext", off: true });

interface IWizardContext {
  state: WizardState;
  dispatch: Dispatch<WizardAction>;
  methods: WizardMethods;
}

type Handler = (() => Promise<void>) | (() => void) | null;

type WizardState = {
  currentStep: number;
  isFirstStep: boolean;
  isLastStep: boolean;
  numberOfSteps: number;
  isLoading: boolean;
};

type WizardMethods = {
  handleStep: (handler: Handler) => void;
  nextStep: () => Promise<void>;
  prevStep: () => void;
  reset: () => void;
};

export enum WizardActionType {
  SET_CURRENT_STEP = "SET_CURRENT_STEP",
  SET_FIRST_STEP = "SET_FIRST_STEP",
  SET_LAST_STEP = "SET_LAST_STEP",
  GO_TO_NEXT_STEP = "GO_TO_NEXT_STEP",
  GO_TO_PREV_STEP = "GO_TO_PREV_STEP",
  SET_NUMBER_OF_STEPS = "SET_NUMBER_OF_STEPS",
  SET_LOADING = "SET_LOADING",
  RESET = "RESET",
}

type WizardAction =
  | { type: WizardActionType.SET_CURRENT_STEP; payload: number }
  | { type: WizardActionType.SET_FIRST_STEP; payload: boolean }
  | { type: WizardActionType.SET_LAST_STEP; payload: boolean }
  | { type: WizardActionType.GO_TO_NEXT_STEP }
  | { type: WizardActionType.GO_TO_PREV_STEP }
  | { type: WizardActionType.RESET }
  | { type: WizardActionType.SET_LAST_STEP; payload: boolean }
  | { type: WizardActionType.SET_NUMBER_OF_STEPS; payload: number }
  | { type: WizardActionType.SET_LOADING; payload: boolean };

const initialWizardState: WizardState = {
  currentStep: 0,
  isFirstStep: false,
  isLastStep: false,
  numberOfSteps: 0,
  isLoading: false,
};

const initialWizardMethods = {
  handleStep: () => {},
  nextStep: async () => {},
  prevStep: () => {},
  reset: () => {},
};

const wizardStateReducer = (
  state: WizardState,
  action: WizardAction
): WizardState => {
  switch (action.type) {
    case WizardActionType.SET_CURRENT_STEP:
      return { ...state, currentStep: action.payload };
    case WizardActionType.SET_FIRST_STEP:
      return { ...state, isFirstStep: action.payload };
    case WizardActionType.SET_LAST_STEP:
      return { ...state, isLastStep: action.payload };
    case WizardActionType.GO_TO_NEXT_STEP:
      return state.isLastStep
        ? state
        : {
            ...state,
            currentStep: state.currentStep + 1,
          };
    case WizardActionType.GO_TO_PREV_STEP:
      return state.isFirstStep
        ? state
        : {
            ...state,
            currentStep: state.currentStep - 1,
          };
    case WizardActionType.RESET:
      return { ...state, ...initialWizardState };
    case WizardActionType.SET_NUMBER_OF_STEPS:
      return { ...state, numberOfSteps: action.payload };
    case WizardActionType.SET_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

const WizardContext = React.createContext<IWizardContext>({
  state: initialWizardState,
  dispatch: () => {},
  methods: initialWizardMethods,
});

export function WizardContextProvider({ children }: PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(wizardStateReducer, initialWizardState);

  const nextStepHandler = useRef<Handler>(() => {});

  // Callback to attach the step handler
  const handleStep = useRef((handler: Handler) => {
    nextStepHandler.current = handler;
  });

  const doNextStep = useRef(
    async (): Promise<void> => {
      if (!state.isLastStep && nextStepHandler.current) {
        try {
          dispatch({ type: WizardActionType.SET_LOADING, payload: true });
          await nextStepHandler.current();
          dispatch({ type: WizardActionType.SET_LOADING, payload: false });
          nextStepHandler.current = null;
          dispatch({ type: WizardActionType.GO_TO_NEXT_STEP });
        } catch (error) {
          logger.error(
            "Error when handling current step. Wizard won't proceed to next step."
          );
          dispatch({ type: WizardActionType.SET_LOADING, payload: false });
          throw error;
        }
      } else if (!state.isLastStep) {
        dispatch({ type: WizardActionType.GO_TO_NEXT_STEP });
      }
      if (state.isLastStep) {
        dispatch({ type: WizardActionType.RESET });
      }
    }
  );

  const doPrevStep = useRef((): void => {
    if (!state.isFirstStep) {
      nextStepHandler.current = null;
      dispatch({ type: WizardActionType.GO_TO_PREV_STEP });
    }
  });

  const resetWizard = useRef((): void => {
    dispatch({ type: WizardActionType.RESET });
  });

  const methods: WizardMethods = {
    handleStep: handleStep.current,
    nextStep: doNextStep.current,
    prevStep: doPrevStep.current,
    reset: resetWizard.current,
  };

  useEffect(() => {
    const { currentStep, numberOfSteps, isFirstStep, isLastStep } = state;
    if (currentStep === 0) {
      dispatch({
        type: WizardActionType.SET_FIRST_STEP,
        payload: true,
      });
      dispatch({ type: WizardActionType.SET_LAST_STEP, payload: false });
    }
    if (currentStep === numberOfSteps - 1) {
      dispatch({
        type: WizardActionType.SET_LAST_STEP,
        payload: true,
      });
    }
    if (isFirstStep && currentStep > 0) {
      dispatch({
        type: WizardActionType.SET_FIRST_STEP,
        payload: false,
      });
    } else if (isLastStep && currentStep < numberOfSteps - 1) {
      dispatch({
        type: WizardActionType.SET_LAST_STEP,
        payload: false,
      });
    }
  }, [state.currentStep]); //eslint-disable-line

  return (
    <WizardContext.Provider
      value={{
        state,
        dispatch,
        methods,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
}

export const useWizardContext = () => useContext(WizardContext);
