import { QueryType, ResolvedQuery } from "../models/query.model";
import { TableParams } from "../models/requests/table.request";
import { kitTypeService } from "../services/kitType.service";

type KitTypesQuery = QueryType<ResolvedQuery<typeof kitTypeService.getKitTypes>>;
type KitTypeQueryOptions = Omit<KitTypesQuery, "queryKey" | "queryFn">;
type KitTypesWithStudies = QueryType<ResolvedQuery<typeof kitTypeService.getKitTypesWithStudies>>;
type KitTypeWithStudiesQueryOptions = Omit<KitTypesWithStudies, "queryKey" | "queryFn">;


export const KitTypeQuery = (
  { pageNumber }: TableParams,
  options?: KitTypeQueryOptions
): KitTypesQuery => ({
  ...options,
  queryKey: ["kit-types", pageNumber],
  queryFn: () => kitTypeService.getKitTypes({ pageNumber }),
  keepPreviousData: true,
});


export const kitTypeWithStudiesQuery = (
  options?: KitTypeWithStudiesQueryOptions
): KitTypesWithStudies => ({
  ...options,
   queryKey: ["kit-types"],
   queryFn: () => kitTypeService.getKitTypesWithStudies(),
  keepPreviousData: true,
});



