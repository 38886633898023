/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateKitDto } from '../models';
// @ts-ignore
import { KitResponse } from '../models';
// @ts-ignore
import { KitWithStudyLetterResponse } from '../models';
// @ts-ignore
import { KitsResponse } from '../models';
// @ts-ignore
import { ReturnKitDto } from '../models';
// @ts-ignore
import { UpdateKitDto } from '../models';
// @ts-ignore
import { UpdateSubjectMetadata } from '../models';
// @ts-ignore
import { VoidResponse } from '../models';
/**
 * KitApi - axios parameter creator
 * @export
 */
export const KitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateKitDto} createKitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKit: async (createKitDto: CreateKitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createKitDto' is not null or undefined
            assertParamExists('createKit', 'createKitDto', createKitDto)
            const localVarPath = `/api/kit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createKitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getKit', 'id', id)
            const localVarPath = `/api/kit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitByBarcode: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getKitByBarcode', 'id', id)
            const localVarPath = `/api/kit/barcode/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitByCapscanSn: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getKitByCapscanSn', 'id', id)
            const localVarPath = `/api/kit/sn/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKits: async (page: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getKits', 'page', page)
            const localVarPath = `/api/kit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} studyDepartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitsByStudyDepartment: async (studyDepartmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyDepartmentId' is not null or undefined
            assertParamExists('getKitsByStudyDepartment', 'studyDepartmentId', studyDepartmentId)
            const localVarPath = `/api/kit/study`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (studyDepartmentId !== undefined) {
                localVarQueryParameter['studyDepartmentId'] = studyDepartmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReturnKitDto} returnKitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnKit: async (returnKitDto: ReturnKitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'returnKitDto' is not null or undefined
            assertParamExists('returnKit', 'returnKitDto', returnKitDto)
            const localVarPath = `/api/kit/return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnKitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateKitDto} updateKitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKit: async (updateKitDto: UpdateKitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateKitDto' is not null or undefined
            assertParamExists('updateKit', 'updateKitDto', updateKitDto)
            const localVarPath = `/api/kit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSubjectMetadata} updateSubjectMetadata 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetadata: async (updateSubjectMetadata: UpdateSubjectMetadata, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSubjectMetadata' is not null or undefined
            assertParamExists('updateMetadata', 'updateSubjectMetadata', updateSubjectMetadata)
            const localVarPath = `/api/kit/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubjectMetadata, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KitApi - functional programming interface
 * @export
 */
export const KitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KitApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateKitDto} createKitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createKit(createKitDto: CreateKitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createKit(createKitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKitByBarcode(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKitByBarcode(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKitByCapscanSn(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitWithStudyLetterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKitByCapscanSn(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKits(page: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKits(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} studyDepartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKitsByStudyDepartment(studyDepartmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKitsByStudyDepartment(studyDepartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReturnKitDto} returnKitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async returnKit(returnKitDto: ReturnKitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.returnKit(returnKitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateKitDto} updateKitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateKit(updateKitDto: UpdateKitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateKit(updateKitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateSubjectMetadata} updateSubjectMetadata 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMetadata(updateSubjectMetadata: UpdateSubjectMetadata, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMetadata(updateSubjectMetadata, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KitApi - factory interface
 * @export
 */
export const KitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KitApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateKitDto} createKitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKit(createKitDto: CreateKitDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.createKit(createKitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKit(id: string, options?: any): AxiosPromise<KitResponse> {
            return localVarFp.getKit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitByBarcode(id: string, options?: any): AxiosPromise<KitResponse> {
            return localVarFp.getKitByBarcode(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitByCapscanSn(id: string, options?: any): AxiosPromise<KitWithStudyLetterResponse> {
            return localVarFp.getKitByCapscanSn(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKits(page: number, options?: any): AxiosPromise<KitsResponse> {
            return localVarFp.getKits(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} studyDepartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitsByStudyDepartment(studyDepartmentId: string, options?: any): AxiosPromise<KitsResponse> {
            return localVarFp.getKitsByStudyDepartment(studyDepartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReturnKitDto} returnKitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnKit(returnKitDto: ReturnKitDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.returnKit(returnKitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateKitDto} updateKitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKit(updateKitDto: UpdateKitDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.updateKit(updateKitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateSubjectMetadata} updateSubjectMetadata 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetadata(updateSubjectMetadata: UpdateSubjectMetadata, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.updateMetadata(updateSubjectMetadata, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KitApi - object-oriented interface
 * @export
 * @class KitApi
 * @extends {BaseAPI}
 */
export class KitApi extends BaseAPI {
    /**
     * 
     * @param {CreateKitDto} createKitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitApi
     */
    public createKit(createKitDto: CreateKitDto, options?: AxiosRequestConfig) {
        return KitApiFp(this.configuration).createKit(createKitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitApi
     */
    public getKit(id: string, options?: AxiosRequestConfig) {
        return KitApiFp(this.configuration).getKit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitApi
     */
    public getKitByBarcode(id: string, options?: AxiosRequestConfig) {
        return KitApiFp(this.configuration).getKitByBarcode(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitApi
     */
    public getKitByCapscanSn(id: string, options?: AxiosRequestConfig) {
        return KitApiFp(this.configuration).getKitByCapscanSn(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitApi
     */
    public getKits(page: number, options?: AxiosRequestConfig) {
        return KitApiFp(this.configuration).getKits(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} studyDepartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitApi
     */
    public getKitsByStudyDepartment(studyDepartmentId: string, options?: AxiosRequestConfig) {
        return KitApiFp(this.configuration).getKitsByStudyDepartment(studyDepartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReturnKitDto} returnKitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitApi
     */
    public returnKit(returnKitDto: ReturnKitDto, options?: AxiosRequestConfig) {
        return KitApiFp(this.configuration).returnKit(returnKitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateKitDto} updateKitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitApi
     */
    public updateKit(updateKitDto: UpdateKitDto, options?: AxiosRequestConfig) {
        return KitApiFp(this.configuration).updateKit(updateKitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateSubjectMetadata} updateSubjectMetadata 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitApi
     */
    public updateMetadata(updateSubjectMetadata: UpdateSubjectMetadata, options?: AxiosRequestConfig) {
        return KitApiFp(this.configuration).updateMetadata(updateSubjectMetadata, options).then((request) => request(this.axios, this.basePath));
    }
}
