import { UseMutationOptions } from "@tanstack/react-query";
import { LoginParams } from "../models/requests/login.request";
import { IApiResult } from "../models/responses/api-result.response";
import { authService } from "../services/authentication.service";
import { UserLogin } from "../api";

export const loginMutation = (): UseMutationOptions<
  IApiResult<UserLogin> | null,
  unknown,
  LoginParams
> => ({
  mutationKey: ["login"],
  mutationFn: ({ username, password }: LoginParams) =>
    authService.login({ username, password }),
});
