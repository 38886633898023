import { StaticsDto } from "../api";
import { StaticsApi } from "../api/apis/statics-api";
import { IApiResult } from "../models/responses/api-result.response";

export const staticService = {
  getStaticList: async (
    type: string,
    filter?: string
  ): Promise<IApiResult<StaticsDto[]>> => {
    const api = new StaticsApi();
    const { data: response } = await api.getList(type, filter || "");
    return response;
  },
};
