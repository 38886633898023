import { useForm } from "react-hook-form";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { FormModal } from "../../../../../components/form/FormModal/FormModal";
import UsersIcon from "../../../../../assets/users.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "react-bootstrap";
import { FormGroup } from "../../../../../components/form/FormGroup/FormGroup";
import FormInput from "../../../../../components/form/FormInput/FormInput";
import {
  UpdateUserParams,
  UserParams,
} from "../../../../../models/requests/user.request";
import useUser from "../../../../../hooks/useUser";
import { useEffect } from "react";
import { makeDirtyObject } from "../../../../../util/form.util";
import { Role } from "../../../../../models/user.model";
import { useQuery } from "@tanstack/react-query";
import { clinicDepartmentsWithClinicNamesQuery } from "../../../../../queries/site.query";

type UserFormProps = {
  show: boolean;
  handleHide: () => void;
  refetch: () => void;
  user?: UpdateUserParams;
};

export const User = ({ show, handleHide, user, refetch }: UserFormProps) => {
  const { addUser, editUser } = useUser();
  const { control, handleSubmit, reset, getFieldState, watch } =
    useForm<UserParams>();
  const watchRole = watch("role");
  const intl = useIntl();

  const roleOptions = Object.keys(Role)
    .filter((val) => !isNaN(Number(val)))
    .map((enumVal) => ({ key: enumVal, value: Role[Number(enumVal)] }));

  const { data: clinicDepartments } = useQuery(clinicDepartmentsWithClinicNamesQuery());
  const onSubmit = async (userFormValues: UserParams | UpdateUserParams) => {
    if (user) {
      const obj = makeDirtyObject(
        (key) => getFieldState(key).isDirty,
        userFormValues
      );
      await editUser({
        id: user.id,
        ...obj,
      } as UpdateUserParams);
    } else {
      const { siteId } = userFormValues;
      await addUser({
        ...userFormValues,
        isActive: true,
        ...(userFormValues.role === Role.Clinical.toString() && {
          siteId: siteId,
        }),
      } as UserParams);
    }
    refetch();
    handleHide();
  };
  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [user, reset]);

  return (
    <FormModal
      onSubmit={handleSubmit(onSubmit)}
      id="form"
      header={
        <div>
          <BaseIcon icon={UsersIcon} />
          <FormattedMessage
            id="ENVIVO.USERS.ADD_USER"
            defaultMessage="Add User"
          />
        </div>
      }
      footer={
        <>
          <Button onClick={handleHide} variant="outline-dark">
            <FormattedMessage
              id={"ENVIVO.USERS.CANCEL"}
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            type="submit"
            variant="outline-dark"
            className="border-dark"
            form="form"
          >
            <FormattedMessage
              id={user ? "ENVIVO.MODAL.UPDATE" : "ENVIVO.MODAL.CREATE"}
              defaultMessage={user ? "Update" : "Create"}
            />
          </Button>
        </>
      }
      show={show}
      handleClose={handleHide}
    >
      <FormGroup
        input={
          <FormInput
            type="text"
            name="userName"
            control={control}
            rules={{
              required: true,
            }}
            input={{
              type: "email",
              placeholder: intl.formatMessage({
                id: "ENVIVO.USERS.ADD_USER.EMAIL",
                defaultMessage: "UserName (Email)",
              }),
            }}
          />
        }
      />
      <FormGroup
        input={
          <FormInput
            type="text"
            name="fullName"
            control={control}
            rules={{
              required: true,
            }}
            input={{
              placeholder: intl.formatMessage({
                id: "ENVIVO.USERS.ADD_USER.FULL_NAME",
                defaultMessage: "Full Name",
              }),
            }}
          />
        }
      />
      <FormGroup
        input={
          <FormInput
            type="select"
            name="role"
            control={control}
            rules={{
              required: true,
            }}
            input={{
              items: roleOptions,
              placeholder: intl.formatMessage({
                id: "ENVIVO.USERS.ADD_USER.USER_ROLE",
                defaultMessage: "User Role",
              }),
            }}
          />
        }
      />
      {watchRole === Role.Clinical.toString() && (
        <FormGroup
          input={
            <FormInput
              type="select"
              name="siteId"
              control={control}
              rules={{
                required: true,
              }}
              input={{
                items: clinicDepartments?.data?.map(({ id, name, clinicName }) => ({
                  key: id,
                  value: `${clinicName} -> ${name}`,
                })),
                placeholder: intl.formatMessage({
                  id: "ENVIVO.USERS.ADD_USER.SITE",
                  defaultMessage: "Site",
                }),
              }}
            />
          }
        />
      )}
      <FormGroup
        input={
          <FormInput
            type="text"
            name="password"
            control={control}
            rules={{
              required: !user,
            }}
            input={{
              type: "password",
              placeholder: intl.formatMessage({
                id: "ENVIVO.USERS.ADD_USER.PASSWORD",
                defaultMessage: "Password",
              }),
            }}
          />
        }
      />
      <FormGroup
        input={
          <FormInput
            type="text"
            name="phone"
            control={control}
            rules={{
              required: true,
            }}
            input={{
              placeholder: intl.formatMessage({
                id: "ENVIVO.USERS.ADD_USER.PHONE_NUMBER",
                defaultMessage: "Phone Number",
              }),
            }}
          />
        }
      />
    </FormModal>
  );
};
