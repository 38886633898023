import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import WizardModalWrapper from "../../../../../components/wizard/WizardModalWrapper/WizardModalWrapper";
import useStudyWizard, {
  GeneralDetailsForm,
  MoreQuestionsForm,
  SubjectDetailsForm,
} from "../../../../../hooks/useStudyWizard";
import AddStudy from "../../../../../assets/add_study.svg";
import { StudyResponse } from "../../../../../models/responses/study.response";
import { SubjectDetailsType } from "../../../../clinical/ClinicalRow/Metadata/MetaData";

type StudyProps = {
  show: boolean;
  handleHide: () => void;
  refetch: () => void;
  study?: StudyResponse | undefined;
};

export const subjectDetailsList: { label: string; key: SubjectDetailsType }[] =
  [
    { label: "Sex [M/F/0]", key: "Sex" },
    {
      label: "Pregnant (will add Yes/No/N/A to the form)",
      key: "Pregnant",
    },
    { label: "Age", key: "BirthDate" },
    { label: "Weight (KG)", key: "Weight" },
    { label: "Height (CM)", key: "Height" },
    { label: "Education", key: "Education" },
  ];

const Study = ({ show, handleHide, refetch, study }: StudyProps) => {
  const methods = useForm<GeneralDetailsForm & SubjectDetailsForm & MoreQuestionsForm>({
    defaultValues: {
      subjectDetails: subjectDetailsList.map((subject) => ({
        ...subject,
        value: !!study?.subjectDetails.find((subjectDetail) => subjectDetail === subject.key),
      })),
    },
  });

  const { steps } = useStudyWizard(handleHide, refetch, study);

  return (
    <FormProvider {...methods}>
      <form>
        <WizardModalWrapper
          show={show}
          handleHide={handleHide}
          title={
            <h6>
              <BaseIcon icon={AddStudy} />
              <FormattedMessage
                id={study ? "ENVIVO.STUDY.UPDATE" : "ENVIVO.STUDY.ADD"}
                defaultMessage={study ? "Update Study" : "Add Study"}
              />
            </h6>
          }
          steps={steps}
          size="lg"
        />
      </form>
    </FormProvider>
  );
};

export default Study;
