import { RouteObject } from "react-router-dom";
import { Protected } from "../../components/ProtectedRoute/ProtectedRoute";
import { RouteConstants } from "../../constants";
import ClinicalLayout from "../../layouts/ClinicalLayout/ClinicalLayout";
import ClinicalPage from "../../pages/clinical/ClinicalTable";

export const clinicalRoutes: RouteObject[] = [
  {
    path: RouteConstants.CLINICAL,
    element: (
      <Protected>
        <ClinicalLayout />
      </Protected>
    ),
    children: [
      {
        index: true,
        element: <ClinicalPage />,
      },
      
    ],
  },
];
