import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import { Form } from "react-bootstrap";

interface RadioButtonProps {
  defValue: number;
  radioButtonsArray: RadioButtonsBody[];
  className?: string;
}
interface RadioButtonsBody {
  label: string;
  value: number | string;
}
export default function RadioButtonGroup<T extends FieldValues>({
  defValue,
  radioButtonsArray,
  className,
  ...controlProps
}: RadioButtonProps & UseControllerProps<T>) {
  const { field } = useController(controlProps);

  return (
    <Form.Group
      controlId={field.name}
      className={`form-group d-flex direction-row ${className ||"mt-4"}`}
    >
      {radioButtonsArray.map((item) => (
        <Form.Check key={`${field.name}_${item.label}`}
          {...field}
          className="ms-2"
          type="radio"
          value={item.value}
          label={item.label}
          defaultChecked={defValue === item.value}
        />
      ))}
    </Form.Group>
  );
}
