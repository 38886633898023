import React from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { StylesManager } from "survey-core";
import { defaultCss } from "survey-core/plugins/bootstrap-integration";
import "bootstrap/dist/css/bootstrap.min.css";
import "survey-core/defaultV2.min.css";

type JsonFormBuilderProps = {
  json: any;
  onChange?: (data: any) => void;
  onSubmit?: (data: any) => void;
  defaultValues?: object;
};

defaultCss.rating.item = "btn btn-default my-rating";
StylesManager.applyTheme("bootstrap");

const JsonFormBuilder = ({
  json,
  onSubmit,
  onChange,
  defaultValues,
}: JsonFormBuilderProps) => {
  const survey = new Model(json);
  survey.mergeData(defaultValues);
  survey.onComplete.add(({ data }) => {
    onSubmit && onSubmit(data);
  });
  survey.onValueChanged.add(({ data }) => {
    onChange && onChange(data);
  });

  return <Survey model={survey} />;
};

export default JsonFormBuilder;
