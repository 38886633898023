import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { RouteConstants } from "../constants";

const appBaseRoutes = [
  RouteConstants.CLINICAL,
  RouteConstants.ENVIVO,
  RouteConstants.FACTORY,
];
function useRoutes() {
  const location = useLocation();

  // TODO - this function will return the default root route and will be used for redirect after a succesfull login
  const getDefaultRoute = useCallback((): string => {
    return "";
  }, []);

  const getCurrentBaseRoute = useCallback((): string | undefined => {
    return appBaseRoutes.find((appBaseRoute) =>
      location.pathname.includes(appBaseRoute)
    );
  }, [location.pathname]);
  return { location, getDefaultRoute, getCurrentBaseRoute };
}

export default useRoutes;
