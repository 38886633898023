/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CapscanResponse } from '../models';
// @ts-ignore
import { CapscansResponse } from '../models';
// @ts-ignore
import { CreateCapscanDto } from '../models';
// @ts-ignore
import { ReturnCapscanDto } from '../models';
// @ts-ignore
import { VoidResponse } from '../models';
/**
 * CapscanApi - axios parameter creator
 * @export
 */
export const CapscanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCapscanDto} createCapscanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCapscan: async (createCapscanDto: CreateCapscanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCapscanDto' is not null or undefined
            assertParamExists('createCapscan', 'createCapscanDto', createCapscanDto)
            const localVarPath = `/api/capscan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCapscanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapscan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCapscan', 'id', id)
            const localVarPath = `/api/capscan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapscans: async (page: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getCapscans', 'page', page)
            const localVarPath = `/api/capscan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReturnCapscanDto} returnCapscanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnCapscan: async (returnCapscanDto: ReturnCapscanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'returnCapscanDto' is not null or undefined
            assertParamExists('returnCapscan', 'returnCapscanDto', returnCapscanDto)
            const localVarPath = `/api/capscan/return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(returnCapscanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CapscanApi - functional programming interface
 * @export
 */
export const CapscanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CapscanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCapscanDto} createCapscanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCapscan(createCapscanDto: CreateCapscanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCapscan(createCapscanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCapscan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CapscanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCapscan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCapscans(page: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CapscansResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCapscans(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReturnCapscanDto} returnCapscanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async returnCapscan(returnCapscanDto: ReturnCapscanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.returnCapscan(returnCapscanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CapscanApi - factory interface
 * @export
 */
export const CapscanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CapscanApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCapscanDto} createCapscanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCapscan(createCapscanDto: CreateCapscanDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.createCapscan(createCapscanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapscan(id: string, options?: any): AxiosPromise<CapscanResponse> {
            return localVarFp.getCapscan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapscans(page: number, options?: any): AxiosPromise<CapscansResponse> {
            return localVarFp.getCapscans(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReturnCapscanDto} returnCapscanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnCapscan(returnCapscanDto: ReturnCapscanDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.returnCapscan(returnCapscanDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CapscanApi - object-oriented interface
 * @export
 * @class CapscanApi
 * @extends {BaseAPI}
 */
export class CapscanApi extends BaseAPI {
    /**
     * 
     * @param {CreateCapscanDto} createCapscanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CapscanApi
     */
    public createCapscan(createCapscanDto: CreateCapscanDto, options?: AxiosRequestConfig) {
        return CapscanApiFp(this.configuration).createCapscan(createCapscanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CapscanApi
     */
    public getCapscan(id: string, options?: AxiosRequestConfig) {
        return CapscanApiFp(this.configuration).getCapscan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CapscanApi
     */
    public getCapscans(page: number, options?: AxiosRequestConfig) {
        return CapscanApiFp(this.configuration).getCapscans(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReturnCapscanDto} returnCapscanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CapscanApi
     */
    public returnCapscan(returnCapscanDto: ReturnCapscanDto, options?: AxiosRequestConfig) {
        return CapscanApiFp(this.configuration).returnCapscan(returnCapscanDto, options).then((request) => request(this.axios, this.basePath));
    }
}
