import moment from "moment-timezone";

export const getDates = (dateStrings: { [k: string]: string | undefined }) =>
  Object.fromEntries(
    Object.entries(dateStrings).map(([key, value]) => {
      const updatedValue = (value && new Date(value)) || undefined;
      return [key, updatedValue];
    })
  );

export const getFormattedLocalDate = (
  date: Date | string,
  format: string = "YYYY-MM-DD HH:mm"
) => {
  return moment(date).tz(moment.tz.guess()).format(format);
};

export const getFormattedDate = (
  date: Date | string,
  format: string = "YYYY-MM-DD HH:mm"
) => {
  return moment.utc(date).format(format);
};

export const getFormattedDates = (
  dateStrings: {
    [k: string]: Date | string | undefined;
  },
  format?: string
) =>
  Object.fromEntries(
    Object.entries(dateStrings).map(([key, value]) => {
      const updatedValue = (value && getFormattedDate(value, format)) || "";
      return [key, updatedValue];
    })
  );

export const setPropertiesOfDate = (
  date: string | undefined,
  updatedDate: string | undefined,
  updatedTime: string | undefined
): string => {
  const originalDate = date ? new Date(date) : new Date();
  if (updatedDate) {
    const date = new Date(updatedDate);
    originalDate.setDate(date.getDate());
    originalDate.setMonth(date.getMonth());
    originalDate.setFullYear(date.getFullYear());
  }
  if (updatedTime) {
    const timeArray = updatedTime.split(":");
    originalDate.setHours(Number(timeArray[0]));
    originalDate.setMinutes(Number(timeArray[1]));
  }
  return originalDate.toString();
};


export const getTotalHoursBetweenDates = (dt1:Date, dt2:Date)=>{
  if (dt1 && dt2) {
    const ingestedFormatDate = new Date(dt1);
    const ingestedDateTime = ingestedFormatDate.getTime();
    const recoveredFormatDate = new Date(dt2);
    const recoveredDateTime = recoveredFormatDate.getTime();
    const timeDifference =
      Math.abs(recoveredDateTime - ingestedDateTime) / 3600000;
    return  timeDifference.toFixed(2);
  }
  return null;
}

export const parseTimeToNumber = (transitTime: string): number => {
  const result = transitTime.match("^((?:[0-9]{1,10})):([0-5][0-9]) hrs$");
  if (result) {
    const hoursNumber = parseInt(result[1], 10);
    const minutesNumber = parseInt(result[2], 10);

    const convertedTime = hoursNumber + minutesNumber / 60;
    return convertedTime;
  } else {
    return NaN;
  }
};
export const parseNumberToTime = (number: number | undefined): string => {
  if (!number) return "";
  const hours = Math.floor(number);
  const minutes = Math.round((number - hours) * 60);

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} hrs`;
};
