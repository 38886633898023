import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Form } from "react-router-dom";
import useAuthentication from "../../hooks/useAuthentication";
import { Button } from "../Button/Button";
import { FormGroup } from "../form/FormGroup/FormGroup";
import FormInput from "../form/FormInput/FormInput";

type LoginFormInputs = { username: string; password: string };

function LoginForm() {
  const { control, handleSubmit, formState: { errors: formErrors }, } = useForm<LoginFormInputs>();
  const { loading, login } = useAuthentication();

  const onSubmit = async ({ username, password }: LoginFormInputs) => {
    await login(username, password);
  };

  return (
    <Form className="my-2" onSubmit={handleSubmit(onSubmit)}>
      <div className="py-2">
        <FormGroup
          label={
            <FormattedMessage id="LOGIN.USERNAME" defaultMessage="Username" />
          }
          input={
            <FormInput
              type="text"
              name="username"
              control={control}
              rules={{
                required: true,
              }}
              input={{
                  disabled: loading,
                  isInvalid: !!formErrors?.username,
            }}
            />
          }
        />
        <FormGroup
          label={
            <FormattedMessage id="LOGIN.PASSWORD" defaultMessage="Password" />
          }
          input={
            <FormInput
              type="text"
              name="password"
              control={control}
              rules={{ required: true }}
              input={{
                type: "password",
                disabled: loading,
                isInvalid: !!formErrors?.password,
              }}
            />
          }
        />
      </div>
      <Button type="submit" className="px-sm-4 mt-sm-0 mt-5 ms-auto">
        <FormattedMessage id={"LOGIN"} defaultMessage="LOGIN" />
        {loading && (
          <div className="ps-2">
            <Spinner as="span" animation="border" size="sm" />
          </div>
        )}
      </Button>
    </Form>
  );
}

export default LoginForm;
