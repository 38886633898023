import ClinicIcon from "../../../../../assets/clinic_center.svg";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import "rc-tree/assets/index.css";
import { useMutation } from "@tanstack/react-query";
import { FormattedMessage, useIntl } from "react-intl";
import { Footer } from "./TreeNode";
import { FormModal } from "../../../../../components/form/FormModal/FormModal";
import FormInput from "../../../../../components/form/FormInput/FormInput";
import { useForm } from "react-hook-form";
import { ClinicParams } from "../../../../../models/requests/site.request";
import {addClinicMutation, editClinicMutation} from "../../../../../mutations/site.mutation";
import {ClinicInfoProps} from "./Clinic";

type ClinicModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  refetch: () => void;
  clinic?: ClinicInfoProps;
};

const ClinicModal = ({ show, setShow, refetch, clinic }: ClinicModalProps) => {
  const { mutateAsync: mutateAddClinic } = useMutation(addClinicMutation());
  const { mutateAsync: mutateEditClinic } = useMutation(editClinicMutation())
  const { control, handleSubmit, reset } = useForm<ClinicParams>();
  const intl = useIntl();
  const header = (
    <header>
      <BaseIcon icon={ClinicIcon} />
      <b>
        {"  "}
        <FormattedMessage
          id={clinic ? "ENVIVO.SITES.CLINIC.EDIT" : "ENVIVO.SITES.CLINIC.ADD"}
          defaultMessage={clinic ? "Edit Clinic" : "Add Clinic"}
        />
      </b>
    </header>
  );

  const onSubmit = async ({ name }: ClinicParams) => {
    if (clinic) {
      await mutateEditClinic(
          { clinic: { id: clinic.id, name } },
          {
            onSuccess: (response) => {
              if (!response?.success) {
                alert(response?.message);
              } else {
                setShow(false);
                refetch();
              }
            },
          }
      );
    } else {
      await mutateAddClinic(
          {name},
          {
            onSuccess: (response) => {
              if (response) {
                if (!response.success) alert(response.message);
                else {
                  reset();
                  setShow(false);
                  refetch();
                }
              }
            },
          }
      );
    }
  };

  return (
    <FormModal
      header={header}
      footer={<Footer onClick={() => setShow(false)} form={"clinic"} clinic={clinic} />}
      show={show}
      handleClose={() => setShow(false)}
      id="clinic"
      className="modal-lg pt-5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="w-50 m-auto mb-5">
        <FormInput
          defaultValue={clinic?.name}
          type="text"
          name="name"
          control={control}
          rules={{ required: true }}
          input={{
            placeholder: intl.formatMessage({
              id: "ENVIVO.SITES.CLINIC.NAME",
              defaultMessage: "Name",
            }),
          }}
        />
      </div>
    </FormModal>
  );
};

export default ClinicModal;