import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { FormLabel } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { useEffect, useRef, useState } from "react";
import styles from "./RePrint.module.css";
import { Button } from "../../../../../components/Button/Button";
import { FormGroup } from "../../../../../components/form/FormGroup/FormGroup";
import FormInput from "../../../../../components/form/FormInput/FormInput";
import { FormModal } from "../../../../../components/form/FormModal/FormModal";
import RePrintIcon from "../../../../../assets/reprint_label.svg";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import RePrintImage from "./RePrintImage";
import { capscanSnQuery } from "../../../../../queries/capscan.query";
import { getFormattedDate } from "../../../../../util/date.util";

const descriptions = {
  sn: "Scan SN to reprint its label",
};

const placeholders = {
  lot: "Lot Number",
  reference: "Reference",
  sn: "SN",
};

export type RePrintProps = {
  sn: string;
  lot: string;
  reference: string;
  expirationDate: string;
};

const RePrint = () => {
  const {
    control,
    handleSubmit,
    formState,
    getValues,
    setFocus,
    register,
    watch,
    setValue,
  } = useForm<RePrintProps>();
  const imageRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const navigate = useNavigate();
  const [renderImage, setRenderImage] = useState(false);

  useEffect(() => {
    if (!formState.isSubmitSuccessful && getValues("sn") === "") {
      setFocus("sn");
    }
  }, [formState.isSubmitSuccessful, getValues, setFocus]);

  const watchScannedSn = watch("sn");

    const { data: capscan, refetch: refetchCapscan } = useQuery(
        capscanSnQuery(watchScannedSn || "", {
            enabled: false,
            refetchOnWindowFocus: false,
        })
    );

  const handleEnterKit = async (e: React.KeyboardEvent<Element>) => {
    e.preventDefault();
    refetchCapscan();
  };

    useEffect(() => {
        if (capscan) {
            setValue("reference", capscan?.data?.reference || "");
            setValue("lot", capscan?.data?.lot || "");
        }
        if (!capscan?.data && capscan?.message) {
            alert(capscan.message);
        }
    }, [capscan]);

    useEffect(() => {
        if (imageRef.current && renderImage) {
            handlePrint();
        }
    }, [imageRef.current, renderImage]);

  const handlePrint = useReactToPrint({
    content: () => (imageRef && imageRef.current ? imageRef.current : null),
    pageStyle: `@page { size: letter; margin-top: 36mm; margin-left: 6.5mm}`,
    onAfterPrint: () => {
      setRenderImage(false);
    },
  });

  const onSubmit = async () => {
    if (!formState.isSubmitSuccessful) {
      setRenderImage(true);
    }
  };

  const getPlaceHolder = (id: string, defaultMessage: string): string => {
    return intl.formatMessage({
      id: id,
      defaultMessage: defaultMessage,
    });
  };

  const header = (
    <>
      <div className="font-weight-bold">
        <BaseIcon icon={RePrintIcon} className="w-25 ms-1 mt-1" />
        <FormLabel className="ps-2 fw-bold">
          <FormattedMessage
            id="ENVIVO.REPRINT.REPRINT_LABEL"
            defaultMessage="Reprint Label"
          />
        </FormLabel>
      </div>
    </>
  );

  const footer = (
    <>
      <Button
        variant="outline-dark"
        className={`border-dark ${styles["first-button"]}`}
        onClick={() => navigate(-1)}
      >
        <FormattedMessage id="ENVIVO.REPRINT.CANCEL" defaultMessage="Cancel"/>
      </Button>
      <Button
        type="submit"
        variant="outline-dark"
        className="border-dark"
        form="form"
      >
        <FormattedMessage id="ENVIVO.REPRINT.PRINT" defaultMessage="Print" />
      </Button>
    </>
  );

  const image = (
    <div hidden>
      {renderImage && capscan?.data && (
        <RePrintImage
          sn={capscan.data.sn}
          expirationDate={getFormattedDate(capscan.data.expirationDate, "YYYY-MM-DD")}
          lot={capscan.data.lot}
          reference={capscan.data.reference}
          ref={imageRef}
        />
      )}
    </div>
  );


  return (
    <div className="mx-auto">
      <FormModal
        header={header}
        footer={footer}
        onSubmit={handleSubmit(onSubmit)}
        show={true}
        handleClose={() => navigate(-1)}
        id="form"
        className="modal-md mt-5"
      >
        {image}
        <div className="mx-auto w-50">
          <FormGroup
              input={
                <FormInput
                    type="text"
                    control={control}
                    rules={{ required: true }}
                    {...register("sn")}
                    input={{
                      placeholder: getPlaceHolder(
                          "ENVIVO.REPRINT.SN",
                          placeholders["sn"]
                      ),
                      maxlength: 5,
                      onEnterKey: handleEnterKit,
                    }}

                />
              }
              groupDescription={
                <div className={styles.description}>
                  <FormattedMessage
                      id={"ENVIVO.REPRINT.SN_LABEL"}
                      defaultMessage={descriptions["sn"]}
                  />
                </div>
              }
          />
            <FormInput
                type="text"
                name="lot"
                control={control}
                rules={{ required: true }}
                input={{
                    type: "text",
                    placeholder: getPlaceHolder(
                        "ENVIVO.REPRINT.LOT",
                        placeholders["lot"]
                    ),
                    disabled: true,
                    className: styles.lot,
                }}

            />
            <FormInput
                type="text"
                name="reference"
                control={control}
                rules={{ required: true }}
                input={{
                    type: "text",
                    placeholder: getPlaceHolder(
                        "ENVIVO.REPRINT.REFERENCE",
                        placeholders["reference"]
                    ),
                    disabled: true,
                }}

            />
        </div>
      </FormModal>
    </div>
  );
};

export default RePrint;
