/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateShipmentDto } from '../models';
// @ts-ignore
import { ExportResponse } from '../models';
// @ts-ignore
import { ShipmentResponse } from '../models';
// @ts-ignore
import { ShipmentsResponse } from '../models';
// @ts-ignore
import { UpdateShipmentDto } from '../models';
// @ts-ignore
import { VoidResponse } from '../models';
/**
 * ShipmentApi - axios parameter creator
 * @export
 */
export const ShipmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateShipmentDto} createShipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShipment: async (createShipmentDto: CreateShipmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createShipmentDto' is not null or undefined
            assertParamExists('createShipment', 'createShipmentDto', createShipmentDto)
            const localVarPath = `/api/shipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createShipmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} shipmentNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportShipment: async (shipmentNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shipmentNumber' is not null or undefined
            assertParamExists('exportShipment', 'shipmentNumber', shipmentNumber)
            const localVarPath = `/api/shipment/export/{shipmentNumber}`
                .replace(`{${"shipmentNumber"}}`, encodeURIComponent(String(shipmentNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShipment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getShipment', 'id', id)
            const localVarPath = `/api/shipment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShipments: async (page: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getShipments', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getShipments', 'pageSize', pageSize)
            const localVarPath = `/api/shipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateShipmentDto} updateShipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShipment: async (updateShipmentDto: UpdateShipmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateShipmentDto' is not null or undefined
            assertParamExists('updateShipment', 'updateShipmentDto', updateShipmentDto)
            const localVarPath = `/api/shipment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateShipmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShipmentApi - functional programming interface
 * @export
 */
export const ShipmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShipmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateShipmentDto} createShipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createShipment(createShipmentDto: CreateShipmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createShipment(createShipmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} shipmentNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportShipment(shipmentNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportShipment(shipmentNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShipment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShipment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShipments(page: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipmentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShipments(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateShipmentDto} updateShipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateShipment(updateShipmentDto: UpdateShipmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateShipment(updateShipmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShipmentApi - factory interface
 * @export
 */
export const ShipmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShipmentApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateShipmentDto} createShipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShipment(createShipmentDto: CreateShipmentDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.createShipment(createShipmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} shipmentNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportShipment(shipmentNumber: string, options?: any): AxiosPromise<ExportResponse> {
            return localVarFp.exportShipment(shipmentNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShipment(id: string, options?: any): AxiosPromise<ShipmentResponse> {
            return localVarFp.getShipment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShipments(page: number, pageSize: number, options?: any): AxiosPromise<ShipmentsResponse> {
            return localVarFp.getShipments(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateShipmentDto} updateShipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShipment(updateShipmentDto: UpdateShipmentDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.updateShipment(updateShipmentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShipmentApi - object-oriented interface
 * @export
 * @class ShipmentApi
 * @extends {BaseAPI}
 */
export class ShipmentApi extends BaseAPI {
    /**
     * 
     * @param {CreateShipmentDto} createShipmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentApi
     */
    public createShipment(createShipmentDto: CreateShipmentDto, options?: AxiosRequestConfig) {
        return ShipmentApiFp(this.configuration).createShipment(createShipmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} shipmentNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentApi
     */
    public exportShipment(shipmentNumber: string, options?: AxiosRequestConfig) {
        return ShipmentApiFp(this.configuration).exportShipment(shipmentNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentApi
     */
    public getShipment(id: string, options?: AxiosRequestConfig) {
        return ShipmentApiFp(this.configuration).getShipment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentApi
     */
    public getShipments(page: number, pageSize: number, options?: AxiosRequestConfig) {
        return ShipmentApiFp(this.configuration).getShipments(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateShipmentDto} updateShipmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShipmentApi
     */
    public updateShipment(updateShipmentDto: UpdateShipmentDto, options?: AxiosRequestConfig) {
        return ShipmentApiFp(this.configuration).updateShipment(updateShipmentDto, options).then((request) => request(this.axios, this.basePath));
    }
}
