import { Card } from "react-bootstrap";
import { BaseIcon } from "../BaseIcon/BaseIcon";
import styles from "./AppWidget.module.css";

type AppWidgetProps = {
  title: string;
  icon?: string;
  width?: string;
};

function AppWidget({ title, icon, width }: AppWidgetProps) {
  return (
    <Card role="button" className={`shadow pointer ${styles.cardStyles}`} style={{ width }}>
      <Card.Body className="d-flex flex-column align-items-center justify-content-center m-auto">
        <BaseIcon icon={icon ?? ""} className="icon-sm" />
        <Card.Text className="text-dark mt-2 text-center lh-sm">
          {title}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default AppWidget;
