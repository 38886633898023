import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { ButtonGroup } from "react-bootstrap";
import { deleteClinicMutation } from "../../../../../mutations/site.mutation";
import { TreeNode } from "./TreeNode";
import AddIcon from "../../../../../assets/add.svg";
import EditIcon from "../../../../../assets/edit.svg";
import DeleteIcon from "../../../../../assets/delete.svg";
import { IconButton } from "../../../../../components/IconButton/IconButton";
import { ClinicDepartmentDto } from "../../../../../api";
import ClinicModal from "./ClinicModal";
import ClinicDepartmentModal from "./ClinicDepartmentModal";
import DeleteModal from "../../../../../components/DeleteModal/DeleteModal";

type ClinicProps = {
  clinic: ClinicInfoProps;
  refetch: () => void;
};

export type ClinicInfoProps = {
  name: string;
  id: string;
  clinicDepartments: ClinicDepartmentDto[];
};

const Clinic = ({ clinic, refetch }: ClinicProps) => {
  const [show, setShow] = useState(false);
  const [showClinicModal, setShowClinicModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { mutateAsync: mutateDeleteClinic } = useMutation(
    deleteClinicMutation()
  );

  const buttons = (
    <ButtonGroup>
      <IconButton icon={AddIcon} onClick={() => setShow(true)} />
      <IconButton icon={EditIcon} onClick={() => setShowClinicModal(true)} />
      <IconButton icon={DeleteIcon} onClick={() => setShowDeleteModal(true)} />
    </ButtonGroup>
  );

  async function onRemove(id: string) {
    await mutateDeleteClinic(id, {
      onSuccess: (response) => {
        if (response) {
          if (!response?.success) {
            alert(response?.message);
          } else {
            refetch();
          }
        }
      },
    });
  }

  return (
    <>
      <TreeNode name={clinic.name} buttons={buttons}>
        {show && (
          <ClinicDepartmentModal
            clinic={clinic}
            refetch={refetch}
            show={show}
            setShow={setShow}
          />
        )}
      </TreeNode>
      {showClinicModal && (
        <ClinicModal
          clinic={clinic}
          show={showClinicModal}
          setShow={setShowClinicModal}
          refetch={refetch}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          handleDelete={async () => {
            setShowDeleteModal(false);
            await onRemove(clinic.id);
          }}
          show={showDeleteModal}
          InternationalizationKey="ENVIVO.SITES.CLINIC.DELETE"
          handleClose={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
};

export default Clinic;
