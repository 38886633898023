import { getTablePaged } from "../util/pagination.util";
import { TableParams } from "../models/requests/table.request";
import { IApiResult } from "../models/responses/api-result.response";
import { TableResponse } from "../models/responses/table.response";
import Logger from "../util/logger.util";
import { VendorParams } from "../models/requests/vendor.request";
import { VendorDto } from "../api/models/vendor-dto";
import { VendorApi } from "../api/apis/vendor-api";
import { UpdateVendorDto } from "../api/models/update-vendor-dto";
import { GetVendorNameDto } from "../api";

const logger = new Logger({ source: "Vendor Service" });

export const vendorService = {
  getVendors: async ({
    pageNumber,
  }: TableParams): Promise<IApiResult<TableResponse<VendorDto>> | null> =>
    await getVendors({ pageNumber }),
  getVendorsNames: async (): Promise<IApiResult<GetVendorNameDto[]> | null> =>
    await getVendorsNames(),
  addVendor: async (
    vendor: VendorParams
  ): Promise<IApiResult<VendorDto> | null> => await addVendor(vendor),
  editVendor: async (vendor: VendorParams): Promise<IApiResult<void> | null> =>
    await editVendor(vendor),

  deleteVendor: async (vendorId: string): Promise<IApiResult<void> | null> =>
    await deleteVendor(vendorId),
};

const getVendors = async ({
  pageNumber,
}: TableParams): Promise<IApiResult<TableResponse<VendorDto>> | null> => {
  const vendorsPageCount = 10000;
  logger.info(`get vendors - page number ${pageNumber}`);
  const api = new VendorApi();
  const { data: response } = await api.getVendors(pageNumber, vendorsPageCount);
  return getTablePaged(response, vendorsPageCount, logger);
};

const getVendorsNames = async (): Promise<IApiResult<GetVendorNameDto[]> | null> => {
  logger.info(`get vendors`);
  const api = new VendorApi();
  const { data: response } = await api.getVendorsNames();
  return response;
};

const addVendor = async (
  vendor: VendorParams
): Promise<IApiResult<VendorDto> | null> => {
  logger.info(`add vendor ${vendor.vendorName}`);

  const api = new VendorApi();
  const { data: response } = await api.createVendor(vendor);
  const { success, statusCode } = response;

  if (!success) {
    logger.error("Add vendor invalid API response", { statusCode });
    return response;
  }
  return response;
};

const editVendor = async (
  vendor: UpdateVendorDto
): Promise<IApiResult<void> | null> => {
  logger.info(`edit vendor ${vendor.vendorName}`);
  const api = new VendorApi();
  const { data: response } = await api.updateVendor(vendor);
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Edit Vendor invalid API response", { statusCode });
    return null;
  }
  return response;
};

const deleteVendor = async (
  vendorId: string
): Promise<IApiResult<void> | null> => {
  logger.info(`delete vendor ${vendorId}`);
  const api = new VendorApi();
  const { data: response } = await api.deleteVendor(vendorId);
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Delete Vendor invalid API response", { statusCode });
    return null;
  }
  return response;
};
