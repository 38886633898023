import { FormattedMessage } from "react-intl";
import { toIntlId, itnlIdToString } from "../util/text.util";
import { IApiResult } from "../models/responses/api-result.response";
import { TableResponse } from "../models/responses/table.response";
import { Row } from "react-table";
import { DataType } from "../components/Table/Table";

const useTableHelper = () => {
  const getTitle = (prefix: string, name: string) => (
    <FormattedMessage
      id={`${prefix}.${toIntlId(name)}`}
      defaultMessage={itnlIdToString(name)}
    />
  );

  const getColumns = (prefix: string, columnsNames: string[]) => {
    return columnsNames.map((column) => ({
      Header: getTitle(prefix, column),
      accessor: column,
    }));
  };

  const findSelectedItemById = (
    row?: Row<DataType>,
    response?: IApiResult<TableResponse<any>> | null
  ) => response?.data?.items.find((item) => item.id === row?.original?.id);

  const handleRowActionClick = (
    setShowModal: (show: boolean) => void,
    setSelectedItem: (item: any) => void,
    setSelectedRow: (row?: Row<DataType>) => void,
    row?: Row<DataType>,
    response?: IApiResult<TableResponse<any>> | null
  ) => {
    setSelectedItem(findSelectedItemById(row, response) || undefined);
    setShowModal(true);
    setSelectedRow(undefined);
  };

  return { getTitle, getColumns, findSelectedItemById, handleRowActionClick };
};

export default useTableHelper;
