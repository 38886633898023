/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateKitTypeDto } from '../models';
// @ts-ignore
import { KitTypeResponse } from '../models';
// @ts-ignore
import { KitTypeWithStudyInfoResponse } from '../models';
// @ts-ignore
import { KitTypesResponse } from '../models';
// @ts-ignore
import { UpdateKitTypeDto } from '../models';
// @ts-ignore
import { VoidResponse } from '../models';
/**
 * KitTypeApi - axios parameter creator
 * @export
 */
export const KitTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateKitTypeDto} createKitTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKitType: async (createKitTypeDto: CreateKitTypeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createKitTypeDto' is not null or undefined
            assertParamExists('createKitType', 'createKitTypeDto', createKitTypeDto)
            const localVarPath = `/api/kitType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createKitTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKitType: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteKitType', 'id', id)
            const localVarPath = `/api/kitType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/api/kitType/id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitTypes: async (page: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getKitTypes', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getKitTypes', 'pageSize', pageSize)
            const localVarPath = `/api/kitType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitTypesWithStudyInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kitType/withStudies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateKitTypeDto} updateKitTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKitType: async (updateKitTypeDto: UpdateKitTypeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateKitTypeDto' is not null or undefined
            assertParamExists('updateKitType', 'updateKitTypeDto', updateKitTypeDto)
            const localVarPath = `/api/kitType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKitTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KitTypeApi - functional programming interface
 * @export
 */
export const KitTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KitTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateKitTypeDto} createKitTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createKitType(createKitTypeDto: CreateKitTypeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createKitType(createKitTypeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteKitType(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteKitType(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKitTypes(page: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKitTypes(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKitTypesWithStudyInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitTypeWithStudyInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKitTypesWithStudyInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateKitTypeDto} updateKitTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateKitType(updateKitTypeDto: UpdateKitTypeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateKitType(updateKitTypeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KitTypeApi - factory interface
 * @export
 */
export const KitTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KitTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateKitTypeDto} createKitTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKitType(createKitTypeDto: CreateKitTypeDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.createKitType(createKitTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKitType(id: string, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.deleteKitType(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): AxiosPromise<KitTypeResponse> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitTypes(page: number, pageSize: number, options?: any): AxiosPromise<KitTypesResponse> {
            return localVarFp.getKitTypes(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKitTypesWithStudyInfo(options?: any): AxiosPromise<KitTypeWithStudyInfoResponse> {
            return localVarFp.getKitTypesWithStudyInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateKitTypeDto} updateKitTypeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKitType(updateKitTypeDto: UpdateKitTypeDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.updateKitType(updateKitTypeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KitTypeApi - object-oriented interface
 * @export
 * @class KitTypeApi
 * @extends {BaseAPI}
 */
export class KitTypeApi extends BaseAPI {
    /**
     * 
     * @param {CreateKitTypeDto} createKitTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitTypeApi
     */
    public createKitType(createKitTypeDto: CreateKitTypeDto, options?: AxiosRequestConfig) {
        return KitTypeApiFp(this.configuration).createKitType(createKitTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitTypeApi
     */
    public deleteKitType(id: string, options?: AxiosRequestConfig) {
        return KitTypeApiFp(this.configuration).deleteKitType(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitTypeApi
     */
    public get(id: string, options?: AxiosRequestConfig) {
        return KitTypeApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitTypeApi
     */
    public getKitTypes(page: number, pageSize: number, options?: AxiosRequestConfig) {
        return KitTypeApiFp(this.configuration).getKitTypes(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitTypeApi
     */
    public getKitTypesWithStudyInfo(options?: AxiosRequestConfig) {
        return KitTypeApiFp(this.configuration).getKitTypesWithStudyInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateKitTypeDto} updateKitTypeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitTypeApi
     */
    public updateKitType(updateKitTypeDto: UpdateKitTypeDto, options?: AxiosRequestConfig) {
        return KitTypeApiFp(this.configuration).updateKitType(updateKitTypeDto, options).then((request) => request(this.axios, this.basePath));
    }
}
