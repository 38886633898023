import { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "@tanstack/react-query";
import AddIcon from "../../../../assets/add.svg";
import EditIcon from "../../../../assets/edit.svg";
import DeleteIcon from "../../../../assets/delete.svg";
import styles from "./KitTypes.module.css";
import useTableHelper from "../../../../hooks/useTableHelper";
import Table, {
  DataType,
  LoadingAndErrorHandler,
} from "../../../../components/Table/Table";
import { KitTypeQuery } from "../../../../queries/kitType.query";
import { KitType } from "./KitType/KitType";
import { Row, useExpanded, usePagination } from "react-table";
import { KitCapscanDefinition, KitTypeDto } from "../../../../api";
import { CellProps } from "react-table";
import { Table as BsTable } from "react-bootstrap";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import useKitType from "../../../../hooks/useKitType";

const columnsNames = ["kitVersion", "studyName", "ifuRevision", "description"];

type CapscansSubTableProps = { capscans: KitCapscanDefinition[] };
const CapscansSubTable = ({ capscans }: CapscansSubTableProps) =>
  capscans.length > 0 ? (
    <BsTable>
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="ENVIVO.KIT_TYPE.TABLE.CAPSCAN_TYPE"
              defaultMessage="CapScan Type"
            />
          </th>
          <th>
            <FormattedMessage
              id="ENVIVO.KIT_TYPE.TABLE.SAMPLING_TIME"
              defaultMessage="Sample Time"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {capscans.map((capscan, index) => (
          <tr key={index}>
            <td>{capscan.type}</td>
            <td>{capscan.samplingTime}</td>
          </tr>
        ))}
      </tbody>
    </BsTable>
  ) : null;

const KitTypeTable = () => {
  const { remove } = useKitType();

  const [pageNumber, setPageNumber] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState<{ [key: string]: boolean }>({});
  const [selectedKitType, setSelectedKitType] = useState<
    KitTypeDto | undefined
  >();

  const {
    isLoading,
    isError,
    data: kitTypes,
    refetch,
  } = useQuery(KitTypeQuery({ pageNumber }));
  const buttons = [
    {
      label: <FormattedMessage id="TABLE.BUTTON.ADD" defaultMessage="Add" />,
      onClick: () => {
        setShowModal(true);
      },
      icon: AddIcon,
    },
    {
      label: <FormattedMessage id="TABLE.BUTTON.EDIT" defaultMessage="Edit" />,
      onClick: (
        setSelectedRow: (row?: Row<DataType>) => void,
        row?: Row<DataType>
      ) => {
        row &&
          handleRowActionClick(
            setShowModal,
            setSelectedKitType,
            setSelectedRow,
            row,
            kitTypes
          );
      },
      icon: EditIcon,
      disabled: true,
    },
    {
      label: (
        <FormattedMessage id="TABLE.BUTTON.DELETE" defaultMessage="Delete" />
      ),
      onClick: (
        setSelectedRow: (row?: Row<DataType>) => void,
        row?: Row<DataType>
      ) => {
        row &&
          handleRowActionClick(
            setShowDelete,
            setSelectedKitType,
            setSelectedRow,
            row,
            kitTypes
          );
      },
      icon: DeleteIcon,
      disabled: true,
    },
  ];

  const { getColumns, handleRowActionClick } = useTableHelper();

  const handlePaginate = useCallback((page: number) => {
    setPageNumber(page);
  }, []);

  const getData = () => {
    if (kitTypes?.data?.items) {
      const { items } = kitTypes?.data;
      return items!.map((kitType) => {
        const { capscans, ...rest } = kitType;
        const isSelected = selectedRows[kitType.id] || false;
        return {
          ...rest,
          isSelected,
          subRows: [{ studyName: <CapscansSubTable capscans={capscans} /> }],
        };
      });
    }
    return undefined;
  };

  const columns = () => {
    return [
      {
        id: "checkbox",
        Cell: ({ row }: CellProps<DataType>) =>
            row.canExpand ? (
                <input
                    type="checkbox"
                    checked={selectedRows[row.id]}
                    onChange={(event) => handleCheckboxChange(event, row)}
                    id={row.id}
                />
            ) : null,
      },
      ...getColumns("ENVIVO.KIT_TYPE.TABLE.COLUMN", columnsNames),
    ];
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, row: Row<DataType>) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedRows({ [row.id]: true });
    } else {
      setSelectedRows({});
    }
  };

  return (
    <div className={styles.kitTypes}>
      {kitTypes?.data && (
        <Table
          data={getData()}
          columns={columns()}
          className={styles.table}
          pageCount={kitTypes?.data?.pageCount || 0}
          onPaginate={handlePaginate}
          buttons={buttons}
          tableHooks={[useExpanded, usePagination]}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}
      <LoadingAndErrorHandler isError={isError} isLoading={isLoading} />
      {showModal && (
        <KitType
          show={showModal}
          handleHide={() => {
            setShowModal(false);
            setSelectedKitType(undefined);
          }}
          refetch={refetch}
          kitType={selectedKitType}
        />
      )}

      {showDelete && (
        <DeleteModal
          show={showDelete}
          handleClose={() => {
            setShowDelete(false);
          }}
          InternationalizationKey="ENVIVO.KIT_TYPE.DELETE_KIT_TYPE"
          handleDelete={async () => {
            setShowDelete(false);
            const response = await remove(selectedKitType?.id!);
            response?.success && refetch();
            setSelectedKitType(undefined);
          }}
        />
      )}
    </div>
  );
};

export default KitTypeTable;
