import { useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ClinicIcon from "../assets/clinic.svg";
import LabIcon from "../assets/lab.svg";
import LabelIcon from "../assets/label.svg";
import AppWidget from "../components/AppWidget/AppWidget";
import ClinicalStudyForm from "../components/ClinicalStudyForm/ClinicalStudyForm";
import { RouteConstants } from "../constants";
import { useAuthContext } from "../context/AuthContext";

const widgetWidth = "20rem";

function Home() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    state: authState,
  } = useAuthContext();
  const { role, siteId } = authState?.user || {};

  const { selectClinical = false } = state || {};

  function onClinicalClick(): void {
    handleNavigationToSelectStudy();
  }

  const handleNavigationToSelectStudy = useCallback((): void => {
    if (!selectClinical) {
      navigate("", { state: { selectClinical: true } });
    }
  }, [navigate, selectClinical]);

  useEffect(() => {
    if (role && role === "Clinical" && !selectClinical) {
      handleNavigationToSelectStudy();
    }
  }, [role, handleNavigationToSelectStudy, navigate, selectClinical]);

  return (
    <div className="d-flex flex-column flex-lg-row justify-content-around vh-100 m-auto p-5">
      <>
        {
          (role === "Clinical") && <div
            style={{ width: widgetWidth }}
            onClick={onClinicalClick}>
            <AppWidget title={"Clinical"} icon={ClinicIcon} width={widgetWidth} />
            {selectClinical && <ClinicalStudyForm siteId={siteId}/>}
          </div>
        }
        {
          (role && (role === "Admin" || role === "Technician")) && <div>
            <Link to={RouteConstants.ENVIVO}>
              <AppWidget title={"Envivo"} icon={LabIcon} width={widgetWidth} />
            </Link>
          </div>

        }
        {
          (role === "Factory") && <div >
            <Link to={RouteConstants.FACTORY}>
              <AppWidget title={"Factory"} icon={LabelIcon} width={widgetWidth} />
            </Link>
          </div>
        }
      </>
    </div>
  );
}

export default Home;
