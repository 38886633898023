import styles from "./StorageLocation.module.css";
import { Control } from "react-hook-form";
import { StorageLocationProps } from "./StorageLocation";
import FormInput from "../../../../../components/form/FormInput/FormInput";
import { Spinner } from "react-bootstrap";

type CaptureBoxProps = {
  boxValues?: string[][];
  control?: Control<StorageLocationProps, any>;
  boxSize: number;
  tubeWidth: number;
  tubeHeight?: number;
  loading?: boolean;
};

const CaptureBox = ({ boxValues, control, boxSize, loading, tubeWidth, tubeHeight}: CaptureBoxProps) => {
  const rows = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"];
  const columns = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  const smallBoxes = rows.slice(0, boxSize).map((row, rowIndex) =>
  columns.slice(0, boxSize).map((column, columnIndex) => (
      <div
        key={`${row}-${column}`}
        className={`d-flex align-items-center justify-content-center ${styles.smallBox}`}
        style={{width: tubeWidth, height: tubeHeight ? tubeHeight : tubeWidth}}
      >
        {control && (
          <FormInput
            defaultValue={
              boxValues &&
              !(rowIndex > boxValues.length - 1) &&
              !(columnIndex > boxValues[rowIndex].length - 1)
                ? boxValues[rowIndex][columnIndex]
                : ""
            }
            control={control}
            name={`boxSamples.${rowIndex}.${columnIndex}`}
            type="text"
            input={{
              style: {
                fontSize: "13px",
                borderRadius: 0,
                width: tubeWidth,
                height: tubeHeight ? tubeHeight : tubeWidth,
                margin: 0,
              },
            }}
          />
        )}
      </div>
    ))
  );

  const rowLabels = rows.slice(0, boxSize).map((row) => (
    <div
      key={row}
      className={`d-flex align-items-center justify-content-center ${styles[`label`]}`}
      style={{width: tubeWidth, height: tubeHeight ? tubeHeight : tubeWidth}}
    >
      {row}
    </div>
  ));

  const columnLabels = columns.slice(0, boxSize).map((column) => (
    <div
      key={column}
      className={`d-flex align-items-center justify-content-center ${styles[`label`]}`}
      style={{width: tubeWidth, height: tubeWidth}}
    >
      {column}
    </div>
  ));
  return (
    <div className="d-flex align-items-start">
      <div className={`d-flex flex-column align-items-end ${styles[`rowLabels${boxSize}${control ? '' : 'Left'}`]}`}>
        {rowLabels}
      </div>
      <div className={`d-flex flex-wrap ${!control && styles[`box${boxSize}`]}`}>
        <div className="d-flex justify-content-between">{columnLabels}</div>
        {loading && <Spinner className={`${styles.spin} ${styles[`spinner${boxSize}`]}`}/>}
        {smallBoxes.map((row, index) => (
          <div key={rows[index]} className="d-flex flex-nowrap">
            {row}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CaptureBox;
