import { UseMutationOptions } from "@tanstack/react-query";
import {
  AddKitParams,
  ReturnKitParams,
  UpdateKitParams,
} from "../models/requests/kit.request";
import { IApiResult } from "../models/responses/api-result.response";
import { kitService } from "../services/kit.service";
import Logger from "../util/logger.util";
import { MetadataParams } from "../models/requests/metadata.request";

export const editKitMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  { kit: UpdateKitParams }
> => ({
  mutationKey: ["edit kit"],
  mutationFn: ({ kit }) => kitService.editKit(kit),
  onSuccess: () => {
    Logger.info("Edit kit done successfully");
  },
});

export const addKitMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  { kit: AddKitParams }
> => ({
  mutationKey: ["add"],
  mutationFn: ({ kit }) => kitService.addKit(kit),
  onSuccess: () => {
    Logger.info("Add kit done successfully");
  },
});

export const returnKitMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  ReturnKitParams
> => ({
  mutationKey: ["add"],
  mutationFn: (kit) => kitService.returnKit(kit),
  onSuccess: () => {
    Logger.info("Add kit done successfully");
  },
});

export const editMetadataMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  MetadataParams
> => ({
  mutationKey: ["update metadata"],
  mutationFn: (metadata) => kitService.editMetadata(metadata),
  onSuccess: () => {
    Logger.info("Update metadata done successfully");
  },
});
