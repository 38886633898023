import { Role } from "../models/user.model";
export type UserRole = "Admin" | "Technician" | "Factory" | "Clinical";

export const getUserRoleName = (
  role?: number | Role | UserRole
): UserRole | undefined => {
  const numericRole = Number(role);
  switch (numericRole) {
    case Role.Admin:
      return "Admin";
    case Role.Technician:
      return "Technician";
    case Role.Factory:
      return "Factory";
    case Role.Clinical:
      return "Clinical";
  }
  return undefined;
};
