import { RouteObject } from "react-router-dom";
import { Protected } from "../../components/ProtectedRoute/ProtectedRoute";
import { RouteConstants } from "../../constants";
import EnvivoLayout from "../../layouts/EnvivoLayout/EnvivoLayout";
import StudiesTable from "../../pages/envivo/Management/Studies/StudiesTable";
import EnvivoPage from "../../pages/envivo/EnvivoPage";
import UsersTable from "../../pages/envivo/Management/Users/UsersTable";
import SitesTree from "../../pages/envivo/Management/Sites/SitesTree";
import KitTypeTable from "../../pages/envivo/Management/KitType/KitTypesTable";
import ShipmentsTable from "../../pages/envivo/Home/Preparations/ShipmentsTable/ShipmentsTable";
import ProcessCapscan from "../../pages/envivo/Home/After/ProcessCapscan/ProcessCapscan";
import Distribution from "../../pages/envivo/Home/Preparations/Distribution/Distribution";
import { KitAssembly } from "../../pages/envivo/Management/KitAssembly/KitAssembly";
import ReturnKit from "../../pages/envivo/Home/After/ReturnKit/ReturnKit";
import ProcessOther from "../../pages/envivo/Home/After/ProcessOther/ProcessOther";
import Transfer from "../../pages/envivo/Home/After/Transfer/Transfer";
import StorageLocation from "../../pages/envivo/Home/After/StorageLocation/StorageLocation";
import CameraSettings from "../../pages/envivo/Home/More/CameraSettings/CameraSettings";
import LinkData from "../../pages/envivo/Home/After/LinkData/LinkData";
import VendorsTable from "../../pages/envivo/Management/Vendors/VendorsTable";
import Report from "../../pages/envivo/Home/More/Reports/Report";
import RePrint from "../../pages/envivo/Home/Preparations/RePrint/RePrint";

export const envivoRoutes: RouteObject[] = [
  {
    path: RouteConstants.ENVIVO,
    element: (
      <Protected>
        <EnvivoLayout />
      </Protected>
    ),
    children: [
      {
        index: true,
        element: <EnvivoPage />,
      },
      {
        path: RouteConstants.APPLICATION_MANAGEMENT,
        element: <EnvivoPage />,
      },
      {
        path: "kit-assembly",
        element: <KitAssembly />,
      },
      {
        path: "distribution",
        element: <Distribution />,
      },

      {
        path: "process",
        element: <ProcessCapscan />,
      },
      {
        path: "process-other",
        element: <ProcessOther samples={[]}/>,
      },
      {
        path: "link-data",
        element: <LinkData />,
      },
      {
        path: "re-print",
        element: <RePrint />,
      },
      {
        path: "storage-location",
        element: <StorageLocation />,
      },
      {
        path: "camera-settings",
        element: <CameraSettings />,
      },
      {
        path: "return-kit",
        element: <ReturnKit />,
      },
      {
        path: "kits",
        element: <div>Kits</div>,
      },
      {
        path: "versions",
        element: <div>Kits</div>,
      },
      {
        path: "box",
        element: <div>Box</div>,
      },
      {
        path: "sample-reports",
        element: <Report />,
      },
      {
        path: "metadata",
        element: <div>Metadata</div>,
      },
      {
        path: "users",
        element: <UsersTable />,
      },
      {
        path: "sites",
        element: <SitesTree />,
      },
      {
        path: "vendors",
        element: <VendorsTable />,
      },
      {
        path: "kitsVersions",
        element: <KitTypeTable />,
      },

      {
        path: "studies",
        element: <StudiesTable />,
      },
      {
        path: "shipments",
        element: <ShipmentsTable />,
      },
      {
        path: "transfer",
        element: <Transfer />,
      },
    ],
  },
];
