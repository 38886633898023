import { CapscanApi } from "../api/apis/capscan-api";
import {
  CapscanParams,
  ReturnCapscanParams,
} from "../models/requests/capscan.request";
import { IApiResult } from "../models/responses/api-result.response";
import { CapscanResponse } from "../models/responses/capscan.response";
import Logger from "../util/logger.util";
import { CapscanDto } from "../api";

const logger = new Logger({ source: "Capscan Service" });

export const capscanService = {
  add: async (
    capscan: CapscanParams
  ): Promise<IApiResult<CapscanResponse> | null> => await addCapscan(capscan),
  returnCapscan: async (
    capscan: ReturnCapscanParams
  ): Promise<IApiResult<void> | null> =>
    await returnCapscan(capscan),
  getCapscan: async (id: string): Promise<IApiResult<CapscanDto> | null> => await getCapscan(id),
};

const addCapscan = async (
  capscan: CapscanParams
): Promise<IApiResult<CapscanResponse> | null> => {
  logger.info(`add capscan ${capscan.sn}...`);

  const api = new CapscanApi();
  const response = await api.createCapscan(capscan);
  const { success, statusCode } = response.data;

  if (!success) {
    logger.error("Post Capscan invalid API response", { statusCode });
  }

  return response.data;
};

const returnCapscan = async (
  capscan: ReturnCapscanParams
): Promise<IApiResult<void> | null> => {
  logger.info(`return capscan ${capscan.capscanSn}...`);

  const api = new CapscanApi();
  const response = await api.returnCapscan(capscan);
  const { success, statusCode } = response.data;

  if (!success) {
    logger.error("Post Capscan invalid API response", { statusCode });
  }

  return response.data;
};

const getCapscan = async (
    id: string
): Promise<IApiResult<CapscanDto> | null> => {
  logger.info(`return capscan`);

  const api = new CapscanApi();
  const response = await api.getCapscan(id);
  const { success, statusCode } = response.data;

  if (!success) {
    logger.error("Get Capscan invalid API response", { statusCode });
  }

  return response.data;
};
