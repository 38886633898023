import { useMutation } from "@tanstack/react-query";
import { AuthActionType, useAuthContext } from "../context/AuthContext";
import { loginMutation } from "../mutations/auth.mutation";
import Logger from "../util/logger.util";
import { Role } from "../models/user.model";
import { UserRole } from "../util/role.util";

function useAuthentication() {
  const { dispatch } = useAuthContext();

  const { mutate: mutateLogin, isLoading } = useMutation(loginMutation());

  async function login(username: string, password: string): Promise<void> {
    mutateLogin(
      { username, password },
      {
        onSuccess: (response) => {
          Logger.info("login success...");
          if (!response?.success) {
            alert("Incorrect Username or Password");
          }
          const data = response?.data;
          if (!data) {
            return false;
          }
          const { fullName, access_token, siteId } =
            data;
          dispatch({
            type: AuthActionType.SET_USER,
            payload: {
              email: username,
              fullName: fullName,
              token: access_token,
              role: Role[data.role] as UserRole,
              siteId: siteId,
            },
          });
        },
      }
    );
  }

  return { loading: isLoading, login };
}

export default useAuthentication;
