import {
  WizardContextProvider
} from "../../../context/WizardContext";
import WizardModal, { WizardModalProps } from "../WizardModal/WizardModal";

type WizardModalWrapperProps = WizardModalProps;

function WizardModalWrapper(props: WizardModalWrapperProps) {
  return (
    <WizardContextProvider>
      <WizardModal {...props}></WizardModal>
    </WizardContextProvider>
  );
}

export default WizardModalWrapper;
