import { InternalAxiosRequestConfig } from "axios";
import LocalStorage from "./local-storage.util";
import { Constants } from "../constants";
import { isExpired } from "react-jwt";
import { AuthState } from "../context/AuthContext";

export function tokenHandlerInterceptor(config: InternalAxiosRequestConfig) {
  const authData = LocalStorage.get<AuthState>(Constants.AuthStorageKey);
  if (authData?.user?.token && isExpired(authData?.user?.token)) {
    const controller = new AbortController();
    controller.abort();
    config.signal = controller.signal;
    LocalStorage.remove([Constants.AuthStorageKey]);
  } else if (authData?.user?.token) {
    config.headers.Authorization = "Bearer " + authData?.user?.token;
  }
  return config;
}

 