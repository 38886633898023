import LabelIcon from "../../../assets/label.svg";
import RePrintLabelIcon from "../../../assets/reprint_label.svg";
import { Link, useLocation } from "react-router-dom";
import AppWidget from "../../../components/AppWidget/AppWidget";
const widgetWidth = "20rem";
const FactoryPage = () => (
  <div className="d-flex gap-4 justify-content-center align-items-center w-100">

    <div className="h-fit-content m-15">
      <Link to={`${useLocation().pathname}/print`}>
        <AppWidget title={"Print Label"} icon={LabelIcon} width={widgetWidth} />
      </Link>
    </div>
    <div className="h-fit-content m-15">
      <Link to={`${useLocation().pathname}/re-print`}>
        <AppWidget title={"Reprint Label"} icon={RePrintLabelIcon} width={widgetWidth} />
      </Link>
    </div>
  </div>
);


export default FactoryPage;
