import { SampleProcessApi } from "../api/apis/sample-process-api";
import { UpdateSampleDto } from "../api/models";
import { IApiResult } from "../models/responses/api-result.response";
import Logger from "../util/logger.util";

const logger = new Logger({ source: "Sample Service" });

export const sampleProcessService = {
  updateSampleProcess: async (
    sample: UpdateSampleDto
  ): Promise<IApiResult<void> | null> => await updateSampleProcess(sample),
};

const updateSampleProcess = async (
  sample: UpdateSampleDto
): Promise<IApiResult<void> | null> => {
  const api = new SampleProcessApi();
  const { data: response } = await api.updateProcessSample(sample);

  const { success, statusCode } = response;
  if (!success) {
    logger.error("Sample Filter invalid API response", { statusCode });
    return response;
  }
  return response;
};
