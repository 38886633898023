import { getTablePaged } from "../util/pagination.util";
import {
  ShipmentParams,
  UpdateShipmentParams,
} from "../models/requests/shipment.request";
import { IApiResult } from "../models/responses/api-result.response";
import { ShipmentResponse } from "../models/responses/shipment.response";
import { TableResponse } from "../models/responses/table.response";
import { getDates } from "../util/date.util";
import Logger from "../util/logger.util";
import { ExportResponse } from "../api/models/export-response";
import { ShipmentApi } from "../api/apis/shipment-api";
const logger = new Logger({ source: "Shipment Service" });

export const shipmentService = {
  getShipment: async (
    shipmentNumber: string
  ): Promise<IApiResult<ShipmentResponse | null>> =>
    await getShipment(shipmentNumber),
  addShipment: async (
    shipment: ShipmentParams
  ): Promise<IApiResult<void> | null> => await addShipment(shipment),
  editShipment: async (
    shipment: UpdateShipmentParams
  ): Promise<IApiResult<void> | null> => await editShipment(shipment),
  getShipments: async (
    pageNumber: number
  ): Promise<IApiResult<TableResponse<ShipmentResponse>> | null> =>
    await getShipments(pageNumber),
  exportShipment: async (
      shipmentNumber: string
    ): Promise<ExportResponse | null> =>
      await exportShipment(shipmentNumber),
};

const getShipment = async (
  shipmentNumber: string
): Promise<IApiResult<ShipmentResponse | null>> => {
  logger.info(`get shipment ${shipmentNumber}`);
  const api = new ShipmentApi();
  const { data: response } = await api.getShipment(shipmentNumber);
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Get shipment invalid API response", { statusCode });
    return { ...response, data: undefined };
  }
  return response;
};

const addShipment = async (
  shipment: ShipmentParams
): Promise<IApiResult<void> | null> => {
  const api = new ShipmentApi();
  const { data: response } = await api.createShipment(shipment);
  const { success, statusCode } = response;
  if (!success) {
    logger.error("add shipment invalid API response", { statusCode });
    return response;
  }
  return response;
};

const editShipment = async (
  shipment: UpdateShipmentParams
): Promise<IApiResult<void> | null> => {
  const { dateOfReceiptBySite, shipmentDate, deliveryDate, ...shipmentData } =
    shipment;
  const dates = getDates({
    dateOfReceiptBySite,
    shipmentDate,
    deliveryDate,
  });

  const api = new ShipmentApi();
  const { data: response } = await api.updateShipment({
    ...shipmentData,
    ...dates,
  });
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Update shipment invalid API response", { statusCode });
    return response;
  }
  return response;
};

const getShipments = async (
  pageNumber: number
): Promise<IApiResult<TableResponse<ShipmentResponse>> | null> => {
  const shipmentsPageCount = 100;
  logger.info(`get users - page number ${pageNumber}`);
  const api = new ShipmentApi();
  const { data: response } = await api.getShipments(
    pageNumber,
    shipmentsPageCount
  );
  return getTablePaged(response, shipmentsPageCount, logger);
};

const exportShipment = async (
  shipmentNumber: string
): Promise<ExportResponse | null>  => {
  logger.info(`export shipment number ${shipmentNumber}`);
  const api = new ShipmentApi();

  const { data: response } = await api.exportShipment(
    shipmentNumber
  );
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Update shipment invalid API response", { statusCode });
    return response;
  }
  return response;

};
