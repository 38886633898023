import { StudyApi } from "../api/apis/study-api";
import { StudyName } from "../api/models/study-name";
import {
  StudyParams,
  UpdateStudyParams,
} from "../models/requests/study.request";
import { TableParams } from "../models/requests/table.request";
import { IApiResult } from "../models/responses/api-result.response";
import { StudyResponse } from "../models/responses/study.response";
import { TableResponse } from "../models/responses/table.response";
import Logger from "../util/logger.util";
import { getTablePaged } from "../util/pagination.util";
const logger = new Logger({ source: "Study Service" });

export const studyService = {
  getStudies: async ({
    pageNumber,
  }: TableParams): Promise<IApiResult<TableResponse<StudyResponse>> | null> =>
    await getStudies({ pageNumber }),

  addStudy: async (study: StudyParams): Promise<IApiResult<undefined> | null> =>
    await addStudy(study),
  editStudy: async (
    study: UpdateStudyParams
  ): Promise<IApiResult<undefined> | null> => await editStudy(study),
  getStudyNames: async (): Promise<IApiResult<StudyName[]> | null> =>
    await getStudyNames(),
};

const getStudyNames = async (): Promise<Promise<
  IApiResult<StudyName[]>
> | null> => {
  const api = new StudyApi();

  const { data: response } = await api.getStudyNames();

  const { success, statusCode, data, message } = response;

  return { success, statusCode, data: data, message };
};

const getStudies = async ({
  pageNumber,
}: TableParams): Promise<IApiResult<TableResponse<StudyResponse>> | null> => {
  const studiesPageSize = 100;
  logger.info(`get studies - page number ${pageNumber}`);

  const api = new StudyApi();
  const { data : res } = await api.getStudies(pageNumber, studiesPageSize);
  const response =  getTablePaged(res, studiesPageSize, logger);

  if (!response?.success) {
    return { ...res, data: undefined };
  }

  const items = response.data!.items;  
  return { ...response, data: { ...response.data, items, pageCount: response.data?.pageCount || 0 } };
};

const addStudy = async (
  study: StudyParams
): Promise<IApiResult<undefined> | null> => {
  logger.info(`add study ${study}`);
  const api = new StudyApi();

  const { data: response } = await api.createStudy(study);
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Add Study invalid API response", { statusCode });
    return { ...response, data: undefined };
  }
  return response;
};

const editStudy = async (
  study: UpdateStudyParams
): Promise<IApiResult<undefined> | null> => {
  logger.info(`edit study ${study.id}`);
  const api = new StudyApi();

  const { data: response } = await api.updateStudy(study);
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Edit Study invalid API response", { statusCode });
    return response;
  }
  return response;
};
