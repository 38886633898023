import { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from "react-hook-form";
import styles from "./ProcessSample.module.css";
import FormInput from "../../../../../../components/form/FormInput/FormInput";
import RadioButtonGroup from "../../../../../../components/form/RadioButtonGroup/RadioButtonGroup";
import { ProcessOtherProps } from "../ProcessOther";
import { BaseIcon } from "../../../../../../components/BaseIcon/BaseIcon";
import TerminatedIcon from "../../../../../../assets/terminated.svg";
import { FormGroup } from "../../../../../../components/form/FormGroup/FormGroup";

export const bodyItems = ["Stool", "Plasma", "Saliva"];

type ProcessSampleLocalProps = {
  index: number;
  key: string;
  control: Control<ProcessOtherProps, any>;
  watch: UseFormWatch<ProcessOtherProps>;
  setValue: UseFormSetValue<ProcessOtherProps>;
  getValue: UseFormGetValues<ProcessOtherProps>;
  remove: (index: number) => void;
};

export const samplingTimeRadioButtons = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
];

const ProcessSample = ({
  index,
  control,
  watch,
  setValue,
  key,
  remove,
}: ProcessSampleLocalProps) => {
  const intl = useIntl();
  const [isStool, setIsStool] = useState(false);
  const { fields: suffixFields, update: updateSuffix } = useFieldArray({
    control,
    name: `samples.${index}.suffix`,
  });
  const watchNone = watch(`samples.${index}.suffix.8.value`);
  const watchSampleType = watch(`samples.${index}.sampleType`);

  useEffect(() => {
    if (watchNone) {
      suffixFields
        .filter((_, i) => i !== 8)
        .forEach((item, i) => {
          updateSuffix(i, { ...item, value: false });
        });
    }
  }, [watchNone, updateSuffix]);

  useEffect(() => {
    if (watchSampleType === bodyItems.indexOf("Stool").toString()) {
      setIsStool(true);
    } else {
      setIsStool(false);
      setValue(`samples.${index}.stoolScore`, undefined);
    }
  }, [watchSampleType, index, setValue]);

  return (
    <div className={styles.main} key={key}>
      <div className={`p-0 ms-auto position-absolute ${styles.top}`}>
        <BaseIcon
          icon={TerminatedIcon}
          onClick={() => {
            remove(index);
          }}
          className={`icon-sx ${styles.delete}`}
        />
      </div>
      <div className={styles.subMain}>
        <FormInput
          type="text"
          name={`samples.${index}.sampleNumber`}
          control={control}
          rules={{
            required: true,
          }}
          input={{
            placeholder: intl.formatMessage({
              id: "ENVIVO.CAPSCAN_PROCESS.SAMPLE.SAMPLE_NUMBER",
              defaultMessage: "Sample Number",
            }),
            className: styles.subjectBox,
          }}
        />
        <FormInput
          type="select"
          name={`samples.${index}.sampleType`}
          control={control}
          rules={{
            required: true,
          }}
          input={{
            items: bodyItems.map((item, index) => ({
              key: index,
              value: item,
            })),
            placeholder: intl.formatMessage({
              id: "ENVIVO.CAPSCAN_OTHER_PROCESS.SAMPLE_TYPE",
              defaultMessage: "Sample Type",
            }),
            className: styles.subjectBox,
          }}
        />
        <FormInput
          type="text"
          name={`samples.${index}.kitSubjectId`}
          control={control}
          rules={{
            required: true,
          }}
          input={{
            type: "text",
            placeholder: intl.formatMessage({
              id: "ENVIVO.CAPSCAN_OTHER_PROCESS.TID",
              defaultMessage: "TID",
            }),
            className: styles.subjectBox,
          }}
        />
       
        <FormInput
          type="text"
          name={`samples.${index}.temperature`}
          control={control}
          input={{
            type: "text",
            placeholder: intl.formatMessage({
              id: "ENVIVO.CAPSCAN_OTHER_PROCESS.TEMPERATURE",
              defaultMessage: "Temperature (°C)",
            }),
            maxlength: 5,
            className: styles.subjectBox,
          }}
        />
         <FormGroup
          input={
            <FormInput
              type="text"
              name={`samples.${index}.storageDate`}
              control={control}
              input={{
                type: "datetime-local",
                className: `${styles.input} ${styles.general}`,
              }}
            />
          }
          label={
            <FormattedMessage
              id="ENVIVO.CAPSCAN_OTHER_PROCESS.STORAGE_DATE"
              defaultMessage="Storage Date"
            />
          }
        />
        <FormGroup
          input={
            <FormInput
              type="text"
              name={`samples.${index}.processingDate`}
              control={control}
              input={{
                type: "datetime-local",
                className: `${styles.input} ${styles.general}`,
              }}
            />
          }
          label={
            <FormattedMessage
              id="ENVIVO.CAPSCAN_OTHER_PROCESS.PROCESSING_DATE"
              defaultMessage="Processing Date"
            />
          }
        />

        <FormGroup
          input={
            <FormInput
              type="text"
              name={`samples.${index}.processCollectionDate`}
              control={control}
              input={{
                type: "datetime-local",
                className: `${styles.input} ${styles.general}`,
              }}
            />
          }
          label={
            <FormattedMessage
              id="ENVIVO.CAPSCAN_OTHER_PROCESS.PROCESS_COLLECTION_DATE"
              defaultMessage="Process Collection Date"
            />
          }
        />
      </div>
      <div className={styles.subMain}>
        <div className="d-flex">
          <div className="border rounded-1">
            <div className={`${styles.radioLabel} ms-2`}>Suffix</div>
            <div className="d-flex ms-3">
              {suffixFields.map((item, suffixIndex) => {
                return (
                  <div key={item.id}>
                    <FormGroup
                      breakpoint={null}
                      className="d-flex"
                      groupDescription={
                        <label htmlFor={item.label} className={styles.suffix}>
                          {item.label}
                        </label>
                      }
                      input={
                        <FormInput
                          type="checkbox"
                          name={`samples.${index}.suffix.${suffixIndex}.value`}
                          control={control}
                          input={{
                            disabled: item.label !== "None" && watchNone,
                          }}
                        />
                      }
                    />

                    {item.label !== "None" && (
                      <FormGroup
                        className={`${styles.volume} d-flex`}
                        breakpoint={null}
                        input={
                          <FormInput
                            type="text"
                            name={`samples.${index}.suffix.${suffixIndex}.volume`}
                            control={control}
                            input={{
                              pattern: "[a-zA-Z0-9<>s]*",
                              disabled: watchNone,
                            }}
                          />
                        }
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          {isStool && (
            <div className="ms-4 border rounded-1 pe-2">
              {" "}
              <div className={`${styles.radioLabel} ms-1`}>
                Bristol Stool Score
              </div>
              <RadioButtonGroup
                defValue={-1}
                radioButtonsArray={samplingTimeRadioButtons}
                name={`samples.${index}.stoolScore`}
                control={control}
              />
            </div>
          )}
        </div>
        <FormInput
          type="text"
          name={`samples.${index}.note`}
          control={control}
          input={{
            type: "text",
            placeholder: intl.formatMessage({
              id: "ENVIVO.CAPSCAN_OTHER_PROCESS.ADD_NOTE",
              defaultMessage: "Add Note",
            }),
            className: `${styles.textarea} ms-1`,
          }}
        />
      </div>
    </div>
  );
};

export default ProcessSample;
