import { Link, Outlet } from "react-router-dom";
import { BaseIcon } from "../../components/BaseIcon/BaseIcon";
import SideBar from "../../components/SideBar/SideBar";
import { useAuthContext } from "../../context/AuthContext";
import EnvivoProviders from "../../providers/EnvivoProviders";
import EnvivoIcon from "../../assets/envivo_logo_sm.png";
import styles from "./EnvivoLayout.module.css";
import LogOut from "../LogOut";

function EnvivoLayout() {
  const { state } = useAuthContext();
  const user = state?.user
  const { role } = user || {};

  const isAdminOrTech = role === "Admin" || role === "Technician";

  return (
    <EnvivoProviders>
      <header className="d-flex justify-content-between shadow-sm bg-body-tertiary rounded position-relative border p-3">
      <Link to={""}>
          <BaseIcon icon={EnvivoIcon} />
        </Link>
          <div className={`d-flex align-items-center justify-content-between`}>
              <div className={styles.circle}>
                  <span>{user?.fullName[0]}</span>
              </div>
            <div className={styles.info}>
                <div>{user?.fullName}</div>
                <div>{user?.role}</div>
                <LogOut />
            </div>
          </div>
      </header>
      <div className={`d-flex`}>
        <div className={`d-flex ${!isAdminOrTech && "d-none"}`}>
        <SideBar />
        </div>
        <main className="d-flex flex-grow overflow-auto">
          <Outlet />
        </main>
      </div>
    </EnvivoProviders>
  );
}

export default EnvivoLayout;
