import { ReactNode } from "react";
import styles from "./TreeNode.module.css";
import { Button } from "../../../../../components/Button/Button";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {ClinicInfoProps} from "./Clinic";
import {ClinicDepartmentsInfo} from "./ClinicDepartment";

type FooterProps = {
    onClick: () => void;
    form: string;
    clinic?: ClinicInfoProps;
    clinicDepartments?: ClinicDepartmentsInfo;
};

export const Footer = ({ onClick, form, clinic, clinicDepartments }: FooterProps) => (
  <footer className="d-flex">
    <Button variant="outline-dark" onClick={onClick} className="me-1">
      <FormattedMessage id="BUTTON.CANCEL" defaultMessage="Cancel" />
    </Button>
    <Button variant="success" type="submit" form={form}>
      <FormattedMessage
          id={clinic || clinicDepartments ? "BUTTON.UPDATE" : "BUTTON.CREATE"}
          defaultMessage={clinic || clinicDepartments ? "Update" : "Create"} />
    </Button>
  </footer>
);

type TreeNodeProps = {
  name: string;
  details?: ReactNode;
  className?: string;
  buttons: ReactNode;
  children?: ReactNode;
};

export const TreeNode = ({
  name,
  details,
  buttons,
  children,
}: TreeNodeProps) => {
  return (
    <>
      <Card
        className={`d-flex flex-row justify-content-between p-1 ms-2 shadow ${styles.item}`}
      >
        <h6 className="ps-1 mt-1">{name}</h6>
        {details}
        {buttons ? buttons : ""}
      </Card>
      {children}
    </>
  );
};
