import { SampleCapscanApi } from "../api/apis/sample-capscan-api";
import { UpdateSampleCapscanDto } from "../api/models/update-sample-capscan-dto";
import { transferSampleParams } from "../models/requests/sample.request";
import { IApiResult } from "../models/responses/api-result.response";
import Logger from "../util/logger.util";

const logger = new Logger({ source: "Sample Service" });

export const sampleService = {
  transferSample: async (
    sample: transferSampleParams
  ): Promise<IApiResult<void> | null> => await transferSample(sample),

  updateSample: async (
    sample: UpdateSampleCapscanDto
  ): Promise<IApiResult<void> | null> => await updateSample(sample),
};

const transferSample = async (
  sample: transferSampleParams
): Promise<IApiResult<void> | null> => {
  const api = new SampleCapscanApi();
  const { data: response } = await api.transferSample(sample);

  const { success, statusCode } = response;
  if (!success) {
    logger.error("Transfer sample invalid API response", { statusCode });
    return response;
  }
  return response;
};

const updateSample = async (
  sample: UpdateSampleCapscanDto
): Promise<IApiResult<void> | null> => {
  const api = new SampleCapscanApi();
  const { data: response } = await api.updateSample(sample);

  const { success, statusCode } = response;
  if (!success) {
    logger.error("Sample Filter invalid API response", { statusCode });
    return response;
  }
  return response;
};
