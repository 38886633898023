import { UseMutationOptions } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import Logger from "../util/logger.util";
import { shipmentService } from "../services/shipment.service";
import { ShipmentParams, UpdateShipmentParams } from "../models/requests/shipment.request";

export const addShipmentMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  ShipmentParams
> => ({
  mutationKey: ["add shipment"],
  mutationFn: (shipment) => shipmentService.addShipment(shipment),
  onSuccess: () => {
    Logger.info("Add shipment done successfully");
  },
});

export const editShipmentMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  UpdateShipmentParams
> => ({
  mutationKey: ["edit shipment"],
  mutationFn: (shipment) => shipmentService.editShipment(shipment),
  onSuccess: () => {
    Logger.info("Edit shipment done successfully");
  },
});

export const exportShipmentMutation = (): UseMutationOptions<
  IApiResult<string> | null,
  unknown,
  string
> => ({
  mutationKey: ["export shipment"],
  mutationFn: (shipmentNumber) => shipmentService.exportShipment(shipmentNumber),
  onSuccess: () => {
    Logger.info("Export shipment done successfully");
  },
});