import { FormattedMessage } from "react-intl";
import { staticService } from "../../../../services/static.service";
import { UseFormSetValue } from "react-hook-form";
import { MetadataForm } from "./MetaData";
import AsyncSelect from "react-select/async";

type ConditionProps = {
  setValue: UseFormSetValue<MetadataForm>;
  conditions: DetailsType[];
  medicine: DetailsType[];
};

export type DetailsType = { key: string; value: string };

const Details = ({ setValue, conditions, medicine }: ConditionProps) => {
  const getList = async (
    inputValue: string,
    type: "condition" | "medicine"
  ) => {
    if (inputValue.length < 3) {
      return [];
    }
    const { data } = await staticService.getStaticList(type, inputValue);
    return (
      data?.map(({ key, value }) => {
        return {
          label: value,
          value: key,
        };
      }) || []
    );
  };

  const getNoOptionsMsg = (inputValue: string) =>
    inputValue.length > 2
      ? "No results found."
      : "Search for results by typing at least 3 characters.";

  return (
    <div className="ms-3 mb-4">
      <h5 className="mt-5">
        <FormattedMessage
          id="CLINICAL.METADATA.DETAILS"
          defaultMessage="Details"
        />
      </h5>
      <div className="mt-4">
        <FormattedMessage
          id="CLINICAL.METADATA.MEDICINE"
          defaultMessage="Medicine"
        />
        <AsyncSelect
          isMulti
          cacheOptions
          closeMenuOnSelect={false}
          loadOptions={(input) => getList(input, "medicine")}
          defaultValue={medicine.map(({ key, value }) => ({
            label: value,
            value: key,
          }))}
          onChange={(values) =>
            setValue(
              "medicine",
              values.map(({ label, value }) => ({
                key: label,
                value: label,
              }))
            )
          }
          noOptionsMessage={({ inputValue }) => getNoOptionsMsg(inputValue)}
          formatOptionLabel={({ label, value }) => (
              <div style={{display: "flex"}}>
                  <div style={{flex: 1}}>{label}</div>
                  <div style={{flex: 1}}>{value}</div>
              </div>
          )}
        />
      </div>
      <div className="mt-4">
        <FormattedMessage
          id="CLINICAL.METADATA.CONDITIONS"
          defaultMessage="Conditions"
        />
        <AsyncSelect
          isMulti
          cacheOptions
          defaultValue={conditions.map(({ key, value }) => ({
            label: value,
            value: key,
          }))}
          closeMenuOnSelect={false}
          loadOptions={(input) => getList(input, "condition")}
          onChange={(values) =>
            setValue(
              "conditions",
              values.map(({ label, value }) => ({
                value: label,
                key: value,
              }))
            )
          }
          noOptionsMessage={({ inputValue }) => getNoOptionsMsg(inputValue)}
        />
      </div>
    </div>
  );
};
export default Details;
