/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateDataLinkDto } from '../models';
// @ts-ignore
import { DataLinkDto } from '../models';
// @ts-ignore
import { DataLinkResponse } from '../models';
/**
 * DataLinkApi - axios parameter creator
 * @export
 */
export const DataLinkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDataLinkDto} createDataLinkDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataLink: async (createDataLinkDto: CreateDataLinkDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDataLinkDto' is not null or undefined
            assertParamExists('createDataLink', 'createDataLinkDto', createDataLinkDto)
            const localVarPath = `/api/dataLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDataLinkDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataLinkApi - functional programming interface
 * @export
 */
export const DataLinkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataLinkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDataLinkDto} createDataLinkDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDataLink(createDataLinkDto: CreateDataLinkDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDataLink(createDataLinkDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataLinkApi - factory interface
 * @export
 */
export const DataLinkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataLinkApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDataLinkDto} createDataLinkDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataLink(createDataLinkDto: CreateDataLinkDto, options?: any): AxiosPromise<DataLinkResponse> {
            return localVarFp.createDataLink(createDataLinkDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataLinkApi - object-oriented interface
 * @export
 * @class DataLinkApi
 * @extends {BaseAPI}
 */
export class DataLinkApi extends BaseAPI {
    /**
     * 
     * @param {CreateDataLinkDto} createDataLinkDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataLinkApi
     */
    public createDataLink(createDataLinkDto: CreateDataLinkDto, options?: AxiosRequestConfig) {
        return DataLinkApiFp(this.configuration).createDataLink(createDataLinkDto, options).then((request) => request(this.axios, this.basePath));
    }
}
