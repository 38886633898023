import { UseMutationOptions } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import { LinkDataParams } from "../models/requests/linkData.request";
import { linkDataService } from "../services/linkData.service";
import { DataLinkResponse } from "../models/responses/dataLink.response";

export const linkDataMutation = (): UseMutationOptions<
  IApiResult<DataLinkResponse> | null,
  unknown,
LinkDataParams
> => ({
  mutationKey: ["link data"],
  mutationFn: async (linkData: LinkDataParams) =>  await  linkDataService.linkData(linkData)});


