import { Outlet } from "react-router-dom";
import ClinicalProviders from "../../providers/ClinicalProviders";
import LogOut from "../LogOut";

// type ContextType = { app: App };

function ClinicalLayout() {
  return (
    <ClinicalProviders>
      <header className="d-flex justify-content-between shadow-sm mb-5 bg-body-tertiary rounded position-relative border p-3">
        <h5>Clinical Center</h5>
        <LogOut />
      </header>
      <main>
        <Outlet />
      </main>
    </ClinicalProviders>
  );
}
export default ClinicalLayout;
