import FormInput from "../../../../../../components/form/FormInput/FormInput";
import { useIntl } from "react-intl";
import { FormGroup } from "../../../../../../components/form/FormGroup/FormGroup";
import { ProcessCapscanProps } from "../ProcessCapscan";
import styles from "../ProcessCapscan.module.css";
import TerminatedIcon from "../../../../../../assets/terminated.svg";
import AssignedImage from "../../../../../../assets/completed.svg";
import { BaseIcon } from "../../../../../../components/BaseIcon/BaseIcon";
import { useEffect } from "react";
import {
  Control,
  FieldArrayWithId,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from "react-hook-form";

export const bodyLocations = [
  "Stomach",
  "Proximal",
  "Mid",
  "Distal",
  "Colon",
  "Undefined",
];

type SampleProps = {
  remove: (index: number) => void;
  index: number;
  onClick: (index: number) => void;
  isSelected: boolean;
  control: Control<ProcessCapscanProps, any>;
  watch: UseFormWatch<ProcessCapscanProps>;
  setValue: UseFormSetValue<ProcessCapscanProps>;
  getValue: UseFormGetValues<ProcessCapscanProps>;
};

export default function Sample({
  remove,
  index,
  onClick,
  isSelected,
  imageUrl,
  control,
  watch,
  setValue,
  getValue,
}: FieldArrayWithId<ProcessCapscanProps, "samples", "id"> & SampleProps) {
  const { fields: suffixFields, update: updateSuffix } = useFieldArray({
    control,
    name: `samples.${index}.suffix`,
  });

  const watchNone = watch(`samples.${index}.suffix.8.value`);

  useEffect(() => {
    if (watchNone) {
      suffixFields
        .filter((_, i) => i !== 8)
        .forEach((item, i) => {
          updateSuffix(i, { ...item, value: false, volume: "" });
        });
      setValue(`samples.${index}.sampleNumber`, "");
    }
  }, [watchNone, setValue, updateSuffix]);
  const intl = useIntl();
  return (
    <div
      className="border mt-1 me-2 p-3 d-flex flex-column position-relative"
      onClick={() => onClick(index)}
      style={{ backgroundColor: isSelected ? "lightgray" : "white" }}
    >
      <div className={`p-0 ms-auto position-absolute ${styles.top}`}>
        {imageUrl && <BaseIcon icon={AssignedImage} className="icon-sm" />}
        <BaseIcon
          icon={TerminatedIcon}
          onClick={() => {
            remove(index);
          }}
          className={`icon-sm ${styles.delete}`}
        />
      </div>
      <div className="d-flex">
        <FormInput
          type="text"
          name={`samples.${index}.studyLetter`}
          control={control}
          rules={{
            required: true,
          }}
          input={{
            placeholder: intl.formatMessage({
              id: "ENVIVO.CAPSCAN_PROCESS.SAMPLE.STUDY",
              defaultMessage: "Study",
            }),
            className: styles.input,
            disabled: true,
          }}
        />
        <FormInput
          type="text"
          name={`samples.${index}.sampleNumber`}
          control={control}
          rules={{
            required: !watchNone,
          }}
          input={{
            disabled: watchNone,
            placeholder: intl.formatMessage({
              id: "ENVIVO.CAPSCAN_PROCESS.SAMPLE.SAMPLE_NUMBER",
              defaultMessage: "Sample Number",
            }),
            className: `${styles.input} ${styles.subject}`,
          }}
        />
        <div className="position-relative">
          <div className="d-flex ms-3 flex-column">
            <div className="d-flex ms-3">
              {suffixFields.map((item, suffixIndex) => {
                return (
                  <div key={item.id}>
                    <FormGroup
                      className="d-flex"
                      groupDescription={
                        <label htmlFor={item.label} className={styles.suffix}>
                          {item.label}
                        </label>
                      }
                      breakpoint={null}
                      input={
                        <FormInput
                          type="checkbox"
                          name={`samples.${index}.suffix.${suffixIndex}.value`}
                          control={control}
                          input={{
                            disabled: item.label !== "None" && watchNone,
                          }}
                        />
                      }
                    />
                    {item.label !== "None" && (
                      <FormGroup
                        className={`${styles.volume} d-flex`}
                        breakpoint={null}
                        input={
                          <FormInput
                            type="text"
                            name={`samples.${index}.suffix.${suffixIndex}.volume`}
                            control={control}
                            input={{
                              pattern: "[a-zA-Z0-9<>s]*",
                              disabled: !getValue(
                                `samples.${index}.suffix.${suffixIndex}.value`
                              ),
                            }}
                          />
                        }
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex mt-2">
        <FormInput
          type="text"
          name={`samples.${index}.pH`}
          control={control}
          input={{
            placeholder: intl.formatMessage({
              id: "ENVIVO.CAPSCAN_PROCESS.SAMPLE.PH",
              defaultMessage: "pH",
            }),
            type: "number",
            className: styles.input,
          }}
        />
        <FormInput
          type="select"
          name={`samples.${index}.bodyLocation`}
          control={control}
          input={{
            items: bodyLocations.map((item) => ({ key: item, value: item })),
            placeholder: intl.formatMessage({
              id: "ENVIVO.CAPSCAN_PROCESS.SAMPLE.BODY_LOCATION",
              defaultMessage: "Location in the body",
            }),
            className: `${styles.input} ${styles.location}`,
          }}
        />
        <FormInput
          type="text"
          name={`samples.${index}.comment`}
          control={control}
          input={{
            placeholder: intl.formatMessage({
              id: "ENVIVO.CAPSCAN_PROCESS.SAMPLE.NOTE",
              defaultMessage: "Note",
            }),
            className: `${styles.input} ${styles.textarea}`,
          }}
        />
      </div>
    </div>
  );
}
