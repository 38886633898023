import styles from "./Status.module.css";
import ActiveIcon from "../../assets/active.svg";
import PendingIcon from "../../assets/pending.svg";
import TerminatedIcon from "../../assets/terminated.svg";
import CompletedIcon from "../../assets/completed.svg";
import SuspendedIcon from "../../assets/suspended.svg";
import NotAssignedIcon from "../../assets/not_assigned.svg";
import AssignedToPatientIcon from "../../assets/assigned_to_patient.svg";
import SamplingCompletedIcon from "../../assets/sampling_completed.svg";
import ReturnUnusedKitIcon from "../../assets/return_unused_kit.svg";
import MarkAsLostIcon from "../../assets/mark_as_lost.svg";
import SampleSentIcon from "../../assets/sample_sent.svg";
import ShippedIcon from "../../assets/shipment.svg";
import { BaseIcon } from "../BaseIcon/BaseIcon";

export const statuses = {
  ACTIVE: "Active",
  PENDING: "Pending",
  COMPLETED: "Completed",
  TERMINATED: "Terminated",
  SUSPENDED: "Suspended",
  NOT_ASSIGNED: "Not Assigned",
  ASSIGNED_TO_PATIENT: "Assigned to Patient",
  SAMPLING_COMPLETED: "Sampling Completed",
  RETURN_UNUSED_KIT: "Return Unused Kit",
  MARK_AS_LOST: "Mark as Lost",
  SAMPLE_SENT_TO_ENVIVO: "Sample Sent To Envivo",
  SHIPPED: "Shipped",
  RECEIVED: "Received",
  DESTROY: "Destroy",
  
};

type StatusTypes = (typeof statuses)[keyof typeof statuses];

type StatusProps = {
  status?: StatusTypes;
};

const Status = ({ status }: StatusProps) => {
  const className = status && styles[status];
  const getIcon = () => {
    switch (status) {
      case statuses.ACTIVE:
        return ActiveIcon;
      case statuses.PENDING:
        return PendingIcon;
      case statuses.COMPLETED:
        return CompletedIcon;
      case statuses.TERMINATED:
        return TerminatedIcon;
      case statuses.SUSPENDED:
        return SuspendedIcon;
      case statuses.NOT_ASSIGNED:
        return NotAssignedIcon;
      case statuses.ASSIGNED_TO_PATIENT:
        return AssignedToPatientIcon;
      case statuses.SAMPLING_COMPLETED:
        return SamplingCompletedIcon;
      case statuses.RETURN_UNUSED_KIT:
        return ReturnUnusedKitIcon;
      case statuses.MARK_AS_LOST:
        return MarkAsLostIcon;
      case statuses.SAMPLE_SENT_TO_ENVIVO:
        return SampleSentIcon;
      case statuses.SHIPPED:
        return ShippedIcon;
      case statuses.RECEIVED:
        return SamplingCompletedIcon;
      default:
        return "";
    }
  };

  return (
    <div className={className}>
      <BaseIcon icon={getIcon()} className={styles.icon} /> {" "}{status}
    </div>
  );
};

export default Status;
