import { useMutation } from "@tanstack/react-query";
import {
  addStudyMutation,
  editStudyMutation,
} from "../mutations/study.mutation";
import {
  StudyParams,
  UpdateStudyParams,
} from "../models/requests/study.request";
import { IApiResult } from "../models/responses/api-result.response";

const useStudy = () => {
  const { mutateAsync: addStudy } = useMutation(addStudyMutation());
  const { mutateAsync: editKitType } = useMutation(editStudyMutation());

  async function add(study: StudyParams): Promise<IApiResult<void> | null> { 
    return await addStudy(study, {
      onSuccess: (response) => {
        if (!response?.success) {
          alert(response?.message);
          return response;
        }
      },
    });
  }

  async function edit(
    study: UpdateStudyParams,
  ): Promise<IApiResult<void> | null>  {
    return await editKitType(study, {
      onSuccess: (response) => {
        if (!response?.success) {
          alert(response?.message);
        }
        return response;
      },
    });
  }

  return { add, edit };
};

export default useStudy;
