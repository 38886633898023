import { useMutation } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import { transferSampleMutation, updateSampleMutation } from "../mutations/sample.mutation";
import { transferSampleParams } from "../models/requests/sample.request";
import { UpdateSampleCapscanDto } from "../api";
import { UpdateSampleParams } from "../models/requests/processCapscan.request";

const useSample = () => {
  const { mutateAsync: mutateTransferSample } = useMutation(
    transferSampleMutation()
  );
  const { mutateAsync: mutateUpdateSample } = useMutation(
    updateSampleMutation()
  );

  async function transferSample(
    transferSample: transferSampleParams
  ): Promise<IApiResult<void> | null> {
    return await mutateTransferSample(transferSample, {
      onSettled: (response, error) => {
        if (error || !response?.success) {
          alert(response?.message || error);
        }
        return response;
      },
    });
  }

  async function updateSample(
    sample: UpdateSampleParams
  ): Promise<IApiResult<void> | null> {
    return  mutateUpdateSample(sample,{
      onSettled: (response, error) => {
        if (error || !response?.success) {
          alert(response?.message || error);
        }
        return response;
      },
    });
  }

  return { transferSample, updateSample};
};

export default useSample;
