import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import App from "../App";
import { Protected } from "../components/ProtectedRoute/ProtectedRoute";
import Home from "../pages/Home";
import { authRoutes } from "../util/routes/auth-routes";
import { clinicalRoutes } from "../util/routes/clinical-routes.util";
import { envivoRoutes } from "../util/routes/envivo-routes.util";
import { factoryRoutes } from "../util/routes/factory-routes.util";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: (
          <Protected>
            <Home />
          </Protected>
        ),
      },
      ...authRoutes,
      ...clinicalRoutes,
      ...envivoRoutes,
      ...factoryRoutes,
    ],
  },
];

const router = createBrowserRouter(routes);

export default function RoutingProvider() {
  return <RouterProvider router={router} />;
}
