import { useEffect, useState } from "react";
import ReactCrop, { PixelCrop } from "react-image-crop";
import CompleteddIcon from "../../../../../assets/completed.svg";
import { FormModal } from "../../../../../components/form/FormModal/FormModal";
import { Button } from "../../../../../components/Button/Button";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { getOcrWorker, recognizeOcr } from "../../../../../util/text.util";
import Tesseract from "tesseract.js";

type BoxOcrProps = {
  boxSize: number;
  ocrResult: (src: string, x: number, y: number) => void;
  image: string;
  handleClose: () => void;
  show: boolean;
};

const BoxOcr = ({
  boxSize,
  image,
  ocrResult,
  handleClose,
  show,
}: BoxOcrProps) => {
  const [crop, setCrop] = useState<PixelCrop>({
    height: 82,
    width: 82,
    unit: "px",
    x: boxSize === 14 ? 270 : 238,
    y: boxSize === 14 ? 408 : 360,
  });
  const [crupedImage, setCrupedImage] = useState<string>();
  const [index, setIndex] = useState<number[]>();
  const [whiteList, setwhiteList] = useState<string>("ABCDEFG0123456789");
  const [startOcr, setStartOcr] = useState<string>();

  const [resolvedText, setResolvedText] = useState<string>();
  let worker: Tesseract.Worker | null = null;
  const wait = () => new Promise((resolve) => setTimeout(resolve, 500));
  const doOCR = async () => {
    var spaceBetween = boxSize === 14 ? 82 : 140;
    for (let row = 0; row < boxSize; row++) {
      for (let col = 0; col < boxSize; col++) {
        setCrop({ ...crop, x: crop.x + row * spaceBetween, y: crop.y + col * spaceBetween });
        setIndex([row, col]);
        await wait();
      }
    }
    handleClose();
  };
 
  useEffect(() => {
    if (index) {
      cropImage(index[0], index[1]);
    }
  }, [index]);

  const cropImage = async (row: number, col: number) => {
    var imageret = new Promise<string>((resolve, reject) => {
      const srcImage = new Image();
      srcImage.src = image;

      srcImage.onload = async () => {
        const canvas = document.createElement("canvas");
        canvas.width = crop.width;
        canvas.height = crop.height;

        const ctx = canvas.getContext("2d")!;

        ctx.drawImage(
          srcImage,
          crop.x,
          crop.y,
          crop.width,
          crop.height,
          0,
          0,
          crop.width,
          crop.height
        );

        //const imageData = ctx.getImageData(0, 0, crop.width, crop.height);
        //const data = imageData?.data;

        // for (let i = 0; i < data.length; i += 4) {
        //   const r = data[i];
        //   const g = data[i + 1];
        //   const b = data[i + 2];
        //   const grayscale = 0.299 * r + 0.587 * g + 0.114 * b;

        //   data[i] = data[i + 1] = data[i + 2] = grayscale;
        // }

        //ctx.putImageData(imageData, 0, 0);
        //StackBlur.canvasRGBA(canvas, 0, 0, crop.width, crop.height, 1);

        let croppedImageUrl = canvas.toDataURL("image/jpeg");
        setCrupedImage(croppedImageUrl);
        if (!worker) {
          worker = await getOcrWorker();
        }
         var sampleText = await recognizeOcr(worker, croppedImageUrl, whiteList);
        ocrResult(sampleText, row, col);
        setResolvedText(sampleText);
        resolve(croppedImageUrl);
      };
    });
    return await imageret;
  };

  return (
    <div style={{ zIndex: 20 }}>
      <FormModal
        handleClose={() => handleClose()}
        className={`modal-xl modal-dialog-scrollable`}
        dialogClassName="h-100 w-100"
        contentClassName="h-100 w-100"
        id="form"
        show={show}
        header={
          <>
            <div>
              <Button
                variant="outline-dark"
                className="border rounded-4 mb-2"
                onClick={async () =>await doOCR()}
                size="sm"
              >
                <BaseIcon icon={CompleteddIcon} />{" "}
              </Button>
              White List:
              <input
                value={whiteList}
                onChange={(e) => setwhiteList(e.target.value)}
              />
            </div>
          </>
        }
        footer={<></>}
      >
        {index && index[1] + index[0] * boxSize}
        {crupedImage && <img src={crupedImage} alt="Cropped" />}
        {resolvedText && resolvedText}
        <ReactCrop
          crop={crop}
          onChange={(pixel_crop, _) => setCrop(pixel_crop)}
        >
          <img
            className="w-100 h-100"
            src={image}
            alt="Captured"
            style={{ minHeight: "1720px", minWidth: "1720px" }}
          />
        </ReactCrop>
      </FormModal>
    </div>
  );
};
export default BoxOcr;
