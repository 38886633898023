import { QueryOptions } from "@tanstack/react-query";
import { QueryType, ResolvedQuery } from "../models/query.model";
import { StudyQueryParams } from "../models/requests/site.request";
import { IApiResult } from "../models/responses/api-result.response";
import { StudyDepartmentResponse } from "../models/responses/site.response";
import { siteService } from "../services/site.service";

type SitesQuery = QueryType<ResolvedQuery<typeof siteService.getAll>>;
type SitesQueryOptions = Omit<SitesQuery, "queryKey" | "queryFn">;

export const sitesQuery = (options?: SitesQueryOptions): SitesQuery => ({
  ...options,
  queryKey: ["clinics"],
  queryFn: () => siteService.getAll(),
});

type StudiesQuery = QueryType<ResolvedQuery<typeof siteService.getStudies>>;
type StudiesQueryOptions = Omit<StudiesQuery, "queryKey" | "queryFn">;

export const departmentStudiesQuery = (
  { clinicDepartmentId }: StudyQueryParams,
  options?: StudiesQueryOptions
): StudiesQuery => ({
  ...options,
  queryKey: ["sub clinic", clinicDepartmentId],
  queryFn: ({ queryKey }: QueryOptions<IApiResult<StudyDepartmentResponse[]>>) => {
    const [, clinicDepartmentId] = queryKey as string[];
    return siteService.getStudies({
      clinicDepartmentId,
    });
  },
});

type ClinicDepartmentsQuery = QueryType<
  ResolvedQuery<typeof siteService.getClinicDepartments>
>;
type ClinicDepartmentsQueryOptions = Omit<
  ClinicDepartmentsQuery,
  "queryKey" | "queryFn"
>;

export const clinicDepartmentsQuery = (
  options?: ClinicDepartmentsQueryOptions
): ClinicDepartmentsQuery => ({
  ...options,
  queryKey: ["clinic departments"],
  queryFn: () => siteService.getClinicDepartments(),
});

export const clinicDepartmentsWithClinicNamesQuery = (
  options?: ClinicDepartmentsQueryOptions
): ClinicDepartmentsQuery => ({
  ...options,
  queryKey: ["clinic departments with clinic names"],
  queryFn: () => siteService.getClinicDepartmentsWithClinicNames(),
});
