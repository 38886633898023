import { QueryType, ResolvedQuery } from "../models/query.model";
import { capscanService } from "../services/capscan.service";

export type CapscanSnQuery = QueryType<ResolvedQuery<typeof capscanService.getCapscan>>;
type CapscanSnQueryOptions = Omit<CapscanSnQuery, "queryKey" | "queryFn">;

export const capscanSnQuery = (
  id: string,
  options?: CapscanSnQueryOptions
): CapscanSnQuery => ({
  ...options,
  queryKey: ["capscan", id],
  queryFn: async () => await capscanService.getCapscan(id),
  keepPreviousData: true,
});
