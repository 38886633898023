import { useState, useEffect } from "react";
import { FormModal } from "../../../../../../../components/form/FormModal/FormModal";
import { BaseIcon } from "../../../../../../../components/BaseIcon/BaseIcon";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../../../../../../components/Button/Button";
import { useForm } from "react-hook-form";
import SampleIcon from "../../../../../../../assets/sample.svg";
import styles from "./SampleProcessModal.module.css";
import FormInput from "../../../../../../../components/form/FormInput/FormInput";
import { makeDirtyObject } from "../../../../../../../util/form.util";
import { FormGroup } from "../../../../../../../components/form/FormGroup/FormGroup";
import {
  bodyItems,
  samplingTimeRadioButtons,
} from "../../../../After/ProcessOther/ProcessSample/ProcessSample";
import RadioButtonGroup from "../../../../../../../components/form/RadioButtonGroup/RadioButtonGroup";
import useProcessSample from "../../../../../../../hooks/useProcessSample";
import { getFormattedLocalDate } from "../../../../../../../util/date.util";

export type ProcessSampleProps = {
  id: string;
  sampleNumber: number;
  sampleType: string;
  kitSubjectId: string;
  note?: string;
  processCollectionDate?: string;
  processingDate?: string;
  storageDate?: string;
  kitBarcode?: string;
  stoolScore?: number | null;
  temperature?: number;
};

export type SampleParams = {
  handleHide: () => void;
  refetch: () => void;
};
const SampleProcessModal = (props: ProcessSampleProps & SampleParams) => {
  const { control, handleSubmit, reset, watch, setValue, getFieldState } =
    useForm<ProcessSampleProps>();
  const { updateProcessSample } = useProcessSample();
  const [isStool, setIsStool] = useState(false);
  const watchSampleType = watch(`sampleType`);
  const intl = useIntl();
  const isStoolType = (type: string) =>
    Number(type) === bodyItems.indexOf("Stool");
  const onSubmit = async (formProps: ProcessSampleProps) => {
    const state = makeDirtyObject(
      (key) => getFieldState(key).isDirty,
      formProps
    );
    const {
      temperature,
      processCollectionDate,
      processingDate,
      storageDate,
      stoolScore,
      sampleType,

      ...rest
    } = state;

    const response = await updateProcessSample({
      ...rest,
      ...(temperature && { temperature: Number(temperature) }),
      ...(sampleType && { temperature: Number(sampleType) }),

      ...(processCollectionDate && {
        processCollectionDate: new Date(processCollectionDate),
      }),
      ...(processingDate && {
        processingDate: new Date(processingDate),
      }),
      ...(storageDate && {
        storageDate: new Date(storageDate),
      }),
      ...(sampleType &&
        !isStoolType(sampleType) && {
          stoolScore: null,
        }),
      ...(isStoolType(formProps.sampleType) &&
        stoolScore !== undefined && { stoolScore: Number(stoolScore) }),
      id: props.id,
      kitBarcode: props.kitBarcode
    });

    if (response?.success) {
      props.refetch();
      props.handleHide();
    } else {
      alert(response?.message);
    }
  };

  useEffect(() => {
    if (
      watchSampleType
        ? isStoolType(watchSampleType)
        : isStoolType(props.sampleType)
    ) {
      setIsStool(true);
    } else {
      setIsStool(false);
      setValue(`stoolScore`, undefined);
    }
  }, [watchSampleType, props.sampleType]);

  useEffect(() => {
    reset(props);
  }, [props, reset]);

  const Sample = () => (
    <div className={`d-flex justify-content-between  flex-column`}>
      <div className={`d-flex justify-content-between  p-2`}>
        <FormGroup
          input={
            <FormInput
              type="select"
              name={`sampleType`}
              control={control}
              rules={{
                required: true,
              }}
              input={{
                items: bodyItems.map((item, index) => ({
                  key: index,
                  value: item,
                })),
                className: styles.select,
              }}
            />
          }
          className={styles.input}
          label={
            <FormattedMessage
              id="ENVIVO.CAPSCAN_OTHER_PROCESS.SAMPLE_TYPE"
              defaultMessage="Sample Type"
            />
          }
        />
        <FormGroup
          input={
            <FormInput
              type="text"
              name={`kitSubjectId`}
              control={control}
              rules={{
                required: true,
              }}
              input={{
                type: "text",
              }}
            />
          }
          className={styles.input}
          label={
            <FormattedMessage
              id="ENVIVO.CAPSCAN_OTHER_PROCESS.TID"
              defaultMessage="TID"
            />
          }
        />
        <FormGroup
          input={
            <FormInput
              type="text"
              name={`temperature`}
              control={control}
              input={{
                type: "text",
                maxlength: 5,
              }}
            />
          }
          className={styles.input}
          label={
            <FormattedMessage
              id="ENVIVO.CAPSCAN_OTHER_PROCESS.TEMPERATURE"
              defaultMessage="Temperature (°C)"
            />
          }
        />
        <FormGroup
          input={
            <FormInput
              defaultValue={
                props.processingDate &&
                getFormattedLocalDate(props.processingDate, "YYYY-MM-DDThh:mm")
              }
              type="text"
              name={`storageDate`}
              control={control}
              input={{
                type: "datetime-local",
              }}
            />
          }
          className={`${styles.input} ${styles.general}`}
          label={
            <FormattedMessage
              id="ENVIVO.CAPSCAN_OTHER_PROCESS.STORAGE_DATE"
              defaultMessage="Storage Date"
            />
          }
        />
        <FormGroup
          input={
            <FormInput
              defaultValue={
                props.processingDate &&
                getFormattedLocalDate(props.processingDate, "YYYY-MM-DDThh:mm")
              }
              type="text"
              name={`processingDate`}
              control={control}
              input={{
                type: "datetime-local",
              }}
            />
          }
          className={`${styles.input} ${styles.general}`}
          label={
            <FormattedMessage
              id="ENVIVO.CAPSCAN_OTHER_PROCESS.PROCESSING_DATE"
              defaultMessage="Processing Date"
            />
          }
        />
        <FormGroup
          input={
            <FormInput
              defaultValue={
                props.processCollectionDate &&
                getFormattedLocalDate(
                  props.processCollectionDate,
                  "YYYY-MM-DDThh:mm"
                )
              }
              type="text"
              name={`processCollectionDate`}
              control={control}
              input={{
                type: "datetime-local",
              }}
            />
          }
          className={`${styles.input} `}
          label={
            <FormattedMessage
              id="ENVIVO.CAPSCAN_OTHER_PROCESS.PROCESS_COLLECTION_DATE"
              defaultMessage="Process Collection Date"
            />
          }
        />
      </div>
      <div className="d-flex p-2">
        <div>
          {isStool && (
            <div
              style={{ width: "337px", height: "auto" }}
              className={`${styles.input}`}
            >
              <div>Bristol Stool Score</div>
              <RadioButtonGroup
                defValue={props.stoolScore || -1}
                radioButtonsArray={samplingTimeRadioButtons}
                name={`stoolScore`}
                control={control}
                className={styles.input}
              />
            </div>
          )}
        </div>
        <FormGroup
          input={
            <FormInput
              type="text"
              name={`note`}
              control={control}
              input={{
                type: "textarea",
                className: styles.textarea,
              }}
            />
          }
          className={`${styles.textarea} ${styles.input}`}
          label={
            <FormattedMessage
              id="ENVIVO.CAPSCAN_OTHER_PROCESS.ADD_NOTE"
              defaultMessage="Add Note"
            />
          }
        />
      </div>
    </div>
  );

  return (
    <>
      <FormModal
        onSubmit={handleSubmit(onSubmit)}
        id="sample"
        className={`modal-xl modal-dialog-centered modal-dialog-scrollable mt-5`}
        header={
          <div>
            <BaseIcon icon={SampleIcon} />{" "}
            <b>
              <FormattedMessage id="ENVIVO.SAMPLE" defaultMessage="Sample" />
            </b>
          </div>
        }
        footer={
          <>
            <Button
              variant="outline-dark"
              className="border-dark"
              onClick={props.handleHide}
            >
              <FormattedMessage
                id="ENVIVO.MODAL.CANCEL"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              variant="outline-dark"
              className="border-dark"
              form="sample"
              type="submit"
            >
              <FormattedMessage id="BUTTON.SAVE" defaultMessage={"Save"} />
            </Button>
          </>
        }
        show={true}
        handleClose={props.handleHide}
      >
        <div>
          <div className={`${styles.imgBox} d-flex`}>
            <Sample />
          </div>
        </div>
      </FormModal>
    </>
  );
};

export default SampleProcessModal;
