import React, {
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { useNavigate } from "react-router-dom";
import { AuthenticatedUser } from "../models/user.model";
import Logger from "../util/logger.util";
import useLocalStorage from "../hooks/useLocalStorage";
import { Constants } from "../constants";

const logger = new Logger({ source: "AuthContext", off: true });

export type AuthState = {
  user: AuthenticatedUser | null;
};

export enum AuthActionType {
  LOGOUT = "LOGOUT",
  SET_USER = "SET_USER",
}

type AuthReducerAction =
  | {
      type: AuthActionType.LOGOUT;
      payload?: undefined;
    }
  | { type: AuthActionType.SET_USER; payload: AuthenticatedUser };

interface IAuthContext {
  state: AuthState |undefined;
  dispatch: Dispatch<AuthReducerAction>;
}

const authInitState: AuthState = {
  user: null,
};

const AuthContext = React.createContext<IAuthContext>({
  state: authInitState ,
  dispatch: () => {},
});

const authReducer = (
  state: AuthState | undefined,
  action: AuthReducerAction
): AuthState  | undefined=> {

  
  logger.info(`Reducing the auth state with action: ${action}`);
  switch (action.type) {
    case AuthActionType.LOGOUT:
      return { ...state, ...authInitState };
    case AuthActionType.SET_USER:
      
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export function AuthContextProvider({ children }: PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(authReducer, authInitState);
  const [authState, set] = useLocalStorage<AuthState>(
    Constants.AuthStorageKey
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.user) {
      set(state)
    }
  }, [state]); //eslint-disable-line

  useEffect(() => {
      navigate("/");
  }, [authState]); //eslint-disable-line

  return (
    <AuthContext.Provider value={{ state: authState, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext);
