/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateClinicDepartmentDto } from '../models';
// @ts-ignore
import { CreateClinicDto } from '../models';
// @ts-ignore
import { CreateDepartmentStudy } from '../models';
// @ts-ignore
import { SiteClinicDepartmentsResponse } from '../models';
// @ts-ignore
import { SiteClinicsResponse } from '../models';
// @ts-ignore
import { SiteStudiesResponse } from '../models';
// @ts-ignore
import { UpdateClinicDepartmentDto } from '../models';
// @ts-ignore
import { UpdateClinicDto } from '../models';
// @ts-ignore
import { UpdateDepartmentStudy } from '../models';
// @ts-ignore
import { VoidResponse } from '../models';
/**
 * SiteApi - axios parameter creator
 * @export
 */
export const SiteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateClinicDepartmentDto} createClinicDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClinicDepartment: async (createClinicDepartmentDto: CreateClinicDepartmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClinicDepartmentDto' is not null or undefined
            assertParamExists('addClinicDepartment', 'createClinicDepartmentDto', createClinicDepartmentDto)
            const localVarPath = `/api/site/clinicDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClinicDepartmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDepartmentStudy} createDepartmentStudy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDepartmentStudy: async (createDepartmentStudy: CreateDepartmentStudy, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDepartmentStudy' is not null or undefined
            assertParamExists('addDepartmentStudy', 'createDepartmentStudy', createDepartmentStudy)
            const localVarPath = `/api/site/study`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDepartmentStudy, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateClinicDto} createClinicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClinic: async (createClinicDto: CreateClinicDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClinicDto' is not null or undefined
            assertParamExists('createClinic', 'createClinicDto', createClinicDto)
            const localVarPath = `/api/site/clinic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClinicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClinic: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteClinic', 'id', id)
            const localVarPath = `/api/site/clinic/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClinicDepartment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteClinicDepartment', 'id', id)
            const localVarPath = `/api/site/ClinicDepartment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStudy: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteStudy', 'id', id)
            const localVarPath = `/api/site/study/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClinicDepartments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/site/clinicDepartments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClinicDepartmentsWithClinicNames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/site/clinicDepartments/clinicNames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClinicsAndClinicDepartments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/site`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentStudiesOfClinicDepartment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDepartmentStudiesOfClinicDepartment', 'id', id)
            const localVarPath = `/api/site/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateClinicDto} updateClinicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClinic: async (updateClinicDto: UpdateClinicDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateClinicDto' is not null or undefined
            assertParamExists('updateClinic', 'updateClinicDto', updateClinicDto)
            const localVarPath = `/api/site/clinic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClinicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateClinicDepartmentDto} updateClinicDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClinicDepartment: async (updateClinicDepartmentDto: UpdateClinicDepartmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateClinicDepartmentDto' is not null or undefined
            assertParamExists('updateClinicDepartment', 'updateClinicDepartmentDto', updateClinicDepartmentDto)
            const localVarPath = `/api/site/clinicDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClinicDepartmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDepartmentStudy} updateDepartmentStudy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartmentStudy: async (updateDepartmentStudy: UpdateDepartmentStudy, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDepartmentStudy' is not null or undefined
            assertParamExists('updateDepartmentStudy', 'updateDepartmentStudy', updateDepartmentStudy)
            const localVarPath = `/api/site/study`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDepartmentStudy, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteApi - functional programming interface
 * @export
 */
export const SiteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateClinicDepartmentDto} createClinicDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addClinicDepartment(createClinicDepartmentDto: CreateClinicDepartmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addClinicDepartment(createClinicDepartmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateDepartmentStudy} createDepartmentStudy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDepartmentStudy(createDepartmentStudy: CreateDepartmentStudy, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDepartmentStudy(createDepartmentStudy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateClinicDto} createClinicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClinic(createClinicDto: CreateClinicDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClinic(createClinicDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClinic(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClinic(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClinicDepartment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClinicDepartment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStudy(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStudy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllClinicDepartments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteClinicDepartmentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllClinicDepartments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllClinicDepartmentsWithClinicNames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteClinicDepartmentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllClinicDepartmentsWithClinicNames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllClinicsAndClinicDepartments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteClinicsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllClinicsAndClinicDepartments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentStudiesOfClinicDepartment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteStudiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentStudiesOfClinicDepartment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateClinicDto} updateClinicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClinic(updateClinicDto: UpdateClinicDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClinic(updateClinicDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateClinicDepartmentDto} updateClinicDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClinicDepartment(updateClinicDepartmentDto: UpdateClinicDepartmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClinicDepartment(updateClinicDepartmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDepartmentStudy} updateDepartmentStudy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDepartmentStudy(updateDepartmentStudy: UpdateDepartmentStudy, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDepartmentStudy(updateDepartmentStudy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteApi - factory interface
 * @export
 */
export const SiteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateClinicDepartmentDto} createClinicDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClinicDepartment(createClinicDepartmentDto: CreateClinicDepartmentDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.addClinicDepartment(createClinicDepartmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDepartmentStudy} createDepartmentStudy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDepartmentStudy(createDepartmentStudy: CreateDepartmentStudy, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.addDepartmentStudy(createDepartmentStudy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateClinicDto} createClinicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClinic(createClinicDto: CreateClinicDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.createClinic(createClinicDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClinic(id: string, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.deleteClinic(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClinicDepartment(id: string, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.deleteClinicDepartment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStudy(id: string, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.deleteStudy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClinicDepartments(options?: any): AxiosPromise<SiteClinicDepartmentsResponse> {
            return localVarFp.getAllClinicDepartments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClinicDepartmentsWithClinicNames(options?: any): AxiosPromise<SiteClinicDepartmentsResponse> {
            return localVarFp.getAllClinicDepartmentsWithClinicNames(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllClinicsAndClinicDepartments(options?: any): AxiosPromise<SiteClinicsResponse> {
            return localVarFp.getAllClinicsAndClinicDepartments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentStudiesOfClinicDepartment(id: string, options?: any): AxiosPromise<SiteStudiesResponse> {
            return localVarFp.getDepartmentStudiesOfClinicDepartment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateClinicDto} updateClinicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClinic(updateClinicDto: UpdateClinicDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.updateClinic(updateClinicDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateClinicDepartmentDto} updateClinicDepartmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClinicDepartment(updateClinicDepartmentDto: UpdateClinicDepartmentDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.updateClinicDepartment(updateClinicDepartmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDepartmentStudy} updateDepartmentStudy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartmentStudy(updateDepartmentStudy: UpdateDepartmentStudy, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.updateDepartmentStudy(updateDepartmentStudy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteApi - object-oriented interface
 * @export
 * @class SiteApi
 * @extends {BaseAPI}
 */
export class SiteApi extends BaseAPI {
    /**
     * 
     * @param {CreateClinicDepartmentDto} createClinicDepartmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public addClinicDepartment(createClinicDepartmentDto: CreateClinicDepartmentDto, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).addClinicDepartment(createClinicDepartmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDepartmentStudy} createDepartmentStudy 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public addDepartmentStudy(createDepartmentStudy: CreateDepartmentStudy, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).addDepartmentStudy(createDepartmentStudy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateClinicDto} createClinicDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public createClinic(createClinicDto: CreateClinicDto, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).createClinic(createClinicDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public deleteClinic(id: string, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).deleteClinic(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public deleteClinicDepartment(id: string, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).deleteClinicDepartment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public deleteStudy(id: string, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).deleteStudy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public getAllClinicDepartments(options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).getAllClinicDepartments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public getAllClinicDepartmentsWithClinicNames(options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).getAllClinicDepartmentsWithClinicNames(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public getAllClinicsAndClinicDepartments(options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).getAllClinicsAndClinicDepartments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public getDepartmentStudiesOfClinicDepartment(id: string, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).getDepartmentStudiesOfClinicDepartment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateClinicDto} updateClinicDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public updateClinic(updateClinicDto: UpdateClinicDto, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).updateClinic(updateClinicDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateClinicDepartmentDto} updateClinicDepartmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public updateClinicDepartment(updateClinicDepartmentDto: UpdateClinicDepartmentDto, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).updateClinicDepartment(updateClinicDepartmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDepartmentStudy} updateDepartmentStudy 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public updateDepartmentStudy(updateDepartmentStudy: UpdateDepartmentStudy, options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).updateDepartmentStudy(updateDepartmentStudy, options).then((request) => request(this.axios, this.basePath));
    }
}
