import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./VendorsTable.module.css";
import AddIcon from "../../../../assets/add.svg";
import EditIcon from "../../../../assets/edit.svg";
import DeleteIcon from "../../../../assets/delete.svg";
import useTableHelper from "../../../../hooks/useTableHelper";
import Table, { DataType } from "../../../../components/Table/Table";
import { Row } from "react-table";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import { UpdateVendorParams } from "../../../../models/requests/vendor.request";
import { Vendor } from "./Vendor";
import { vendorQuery } from "../../../../queries/vendor.query";
import useVendor from "../../../../hooks/useVendor";

const columnsNames = [
  "vendorName",
  "contactPersonName",
  "contactEmail",
  "contactPhone",
  "analysisType",
  "address",
];

export default function VendorsTable() {
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<UpdateVendorParams | undefined>();

  const [vendorsPageNumber, setVendorsPageNumber] = useState(0);
  const {
    isLoading,
    isError,
    data: vendors,
    refetch,
  } = useQuery(vendorQuery({ pageNumber: vendorsPageNumber }));

  const { deleteVendor } = useVendor();

  const getButtons = () => {
    return [
      {
        label: <FormattedMessage id="TABLE.BUTTON.ADD" defaultMessage="Add" />,
        onClick: () => (setShowModal(true)),
        icon: AddIcon,
      },
      {
        label: (
          <FormattedMessage id="TABLE.BUTTON.EDIT" defaultMessage="Edit" />
        ),
        onClick: (
          setSelectedRow: (row?: Row<DataType>) => void,
          row?: Row<DataType>
        ) => {
          row &&
            handleRowActionClick(
              setShowModal,
              setSelectedVendor,
              setSelectedRow,
              row,
              vendors
            );
        },
        icon: EditIcon,
        disabled: true,
      },
      {
        label: (
          <FormattedMessage id="TABLE.BUTTON.DELETE" defaultMessage="DELETE" />
        ),
        onClick: (
          setSelectedRow: (row?: Row<DataType>) => void,
          row?: Row<DataType>
        ) => {
          row &&
            handleRowActionClick(
              setShowDelete,
              setSelectedVendor,
              setSelectedRow,
              row,
              vendors
            );
        },
        icon: DeleteIcon,
        disabled: true,
      },
    ];
  };

  
  const getVendorsData = () => {
    return vendors?.data?.items?.map((item) => {
      return {
        ...item,
        vendorName: item.vendorName,
        contactPersonName: item.contactPersonName,
        contactEmail: item.contactEmail,
        contactPhone: item.contactPhone,
        address: item.address,
        analysisType: item.analysisType,
      };
    });
  };

  const getAnalysis = () => {
    const allaAnalysisTypes: string[] = [];

    vendors?.data?.items?.forEach((item) => {
      if (item.analysisType) {
        allaAnalysisTypes.push(item.analysisType);
      }
    });

    return allaAnalysisTypes;
  };

  const { getColumns, handleRowActionClick } = useTableHelper();

  return (
    <div className={styles.users}>
          <Table
            columns={getColumns("ENVIVO.VENDORS.TABLE.COLUMN", columnsNames)}
            data={getVendorsData()}
            pageCount={vendors?.data?.pageCount || 0}
            isLoading={isLoading}
            isError={isError}
            onPaginate={(page: number) => setVendorsPageNumber(page)}
            buttons={getButtons()}
          />
          {showModal && (
            <Vendor
              show={showModal}
              handleHide={() => {
                setShowModal(false);
                setSelectedVendor(undefined);
              }}
              vendor={selectedVendor}
              refetch={refetch}
              getAnalysis={getAnalysis()}
            />
          )}
          {showDelete && (
            <DeleteModal
              show={showDelete}
              handleClose={() => setShowDelete(false)}
              InternationalizationKey="ENVIVO.USERS.DELETE_USER"
              handleDelete={async () => {
                setShowDelete(false);
                const response = await deleteVendor(selectedVendor?.id!);
                response?.success && refetch();
                setSelectedVendor(undefined);
              }}
            />
          )}
    </div>
  );
}
