import { KeyboardEvent, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../../../../components/Button/Button";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { FormModal } from "../../../../../components/form/FormModal/FormModal";
import { useFieldArray, useForm } from "react-hook-form";
import DistributionIcon from "../../../../../assets/distribution.svg";
import DeleteIcon from "../../../../../assets/delete.svg";
import FormInput from "../../../../../components/form/FormInput/FormInput";
import { useQuery } from "@tanstack/react-query";
import {
  clinicDepartmentsQuery,
  departmentStudiesQuery,
} from "../../../../../queries/site.query";
import styles from "./Distribution.module.css";
import { ListGroup } from "react-bootstrap";
import { makeDirtyObject } from "../../../../../util/form.util";
import { FormGroup } from "../../../../../components/form/FormGroup/FormGroup";
import useShipment from "../../../../../hooks/useShipment";
import { ShipmentResponse } from "../../../../../models/responses/shipment.response";
import { useNavigate } from "react-router";
type KitsType = { barcode: string }[];

type DistributionFormProps = {
  clinicDepartmentId: string;
  studyDepartmentId: string;
  shipmentNumber: string;
  scannedKit: string;
  selectedKit?: string;
  kits: KitsType;
  deletedKits: KitsType;
};
type DistributionProps = {
  show?: boolean;
  handleHide?: () => void;
  shipment?: ShipmentResponse;
  readOnly?: boolean;
};

const Distribution = ({
  show,
  handleHide,
  shipment,
  readOnly,
}: DistributionProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    getFieldState,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm<DistributionFormProps>({
    defaultValues: { kits: [], deletedKits: [] },
  });
  const { fields, append, remove } = useFieldArray({ control, name: "kits" });
  const {
    fields: deletedFields,
    append: appendDeletedField,
    remove: removeDeletedField,
  } = useFieldArray({ control, name: "deletedKits" });
  const navigate = useNavigate();

  const watchClinic = watch("clinicDepartmentId");
  const watchStudy = watch("studyDepartmentId");
  const watchScannedKit = watch("scannedKit");
  const watchSelectedKit = watch("selectedKit");
  const watchShipmentNumber = watch("shipmentNumber");

  const intl = useIntl();

  const { data: clinics } = useQuery(clinicDepartmentsQuery());
  const { data: studies } = useQuery(
    departmentStudiesQuery(
      {
        clinicDepartmentId: watchClinic,
      },
      { enabled: !!watchClinic }
    )
  );

  const { addShipment, editShipment } = useShipment();
  useEffect(() => {
    if (shipment) {
      reset({
        ...shipment,
        kits: shipment.kits.map((kit) => ({ barcode: kit })) || [],
      });
    }
  }, [reset, shipment]);


  const handleCancel = () => {
    if (handleHide) {
      handleHide()
    }
    else {
      navigate(-1);
    }
  };

  const getStudies = () =>
    studies?.data?.map((item) => ({
      key: item.id,
      value: item.studyName || "",
    }));

  const getClinics = () =>
    clinics?.data?.map((item) => ({
      key: item.id,
      value: item.name,
    }));

  const getKits = (kits: KitsType): string[] =>
    kits.map((item) => item.barcode);

  const makeDirtyKits = (
    shipmentKits: string[] = [],
    stateKits: KitsType = [],
    deletedKits: KitsType = []
  ): { kits?: string[]; deletedKits?: string[] } => {
    const isKitsEqual =
      shipmentKits.length === stateKits.length &&
      shipmentKits.every((value) => stateKits.includes({ barcode: value }));
    return isKitsEqual
      ? {}
      : {
        kits: getKits(stateKits),
        deletedKits: getKits(deletedKits),
      };
  };

  const onSubmit = async (formState: DistributionFormProps) => {
    const { scannedKit, selectedKit, ...state } = formState;
    if (shipment) {
      const dirtyState = makeDirtyObject(
        (key: keyof DistributionFormProps) => getFieldState(key).isDirty,
        state
      );
      const { kits, deletedKits, ...updatedState } = dirtyState;
      const dirtyKits = makeDirtyKits(
        shipment.kits,
        formState.kits,
        formState.deletedKits
      );

      if (isDirty || Object.keys(dirtyKits).length > 0) {
        const response = await editShipment({
          id: shipment.id,
          ...dirtyKits,
          ...updatedState,
          shipmentNumber: shipment.shipmentNumber,
          studyDepartmentId: shipment.studyDepartmentId
        });
        if (response?.success) {
          handleCancel();
        }
      }
    } else {
      const kits = getKits(formState.kits);
      const response = await addShipment({ ...state, kits });
      if (response?.success) {
        handleCancel();
      }
    }
  };

  const handleEnterKit = async (e: KeyboardEvent) => {
    e.preventDefault();
    if (watchScannedKit) {
      const index = fields.findIndex((kit) => kit.barcode === watchScannedKit);
      if (index === -1) {
        append({ barcode: watchScannedKit });
      }
      const indexToDelete = deletedFields.findIndex(
        (kit) => kit.barcode === watchScannedKit
      );
      indexToDelete !== -1 && removeDeletedField(indexToDelete);
      setValue("selectedKit", watchScannedKit);
      setValue("scannedKit", "");
    }
  };

  return (
    <FormModal
      onSubmit={handleSubmit(onSubmit)}
      id="distribution"
      className={`modal-xl modal-dialog-centered modal-dialog-scrollable`}
      dialogClassName="h-100 w-100"
      contentClassName="h-75 w-100"
      formClassName="h-100"
      header={
        <div>
          <BaseIcon icon={DistributionIcon} />{" "}
          <b>
            <FormattedMessage
              id="ENVIVO.DISTRIBUTION"
              defaultMessage="Distribution"
            />
          </b>
        </div>
      }
      footer={
        <>
          {!readOnly && (
            <Button
              variant="outline-dark"
              className="border-dark"
              type="submit"
              form="distribution"
            >
              <FormattedMessage id={"BUTTON.SAVE"} defaultMessage="SAVE" />
            </Button>
          )}
          <Button
            variant="outline-dark"
            className="border-dark"
            onClick={() => handleCancel()}
          >
            <FormattedMessage
              id={readOnly ? "BUTTON.CLOSE" : "BUTTON.CANCEL"}
              defaultMessage={readOnly ? "Close" : "Cancel"}
            />
          </Button>
        </>
      }
      show={true}
      handleClose={() => handleCancel()}
    >
      <div className="d-flex">
        <FormInput
          type="select"
          name="clinicDepartmentId"
          control={control}
          rules={{
            required: true,
          }}
          input={{
            disabled: readOnly,
            placeholder: intl.formatMessage({
              id: "ENVIVO.DISTRIBUTION.CLINIC_NAME",
              defaultMessage: "Clinic Name",
            }),
            items: getClinics(),
            className: styles.input,
          }}
        />
        <FormInput
          type="select"
          name="studyDepartmentId"
          control={control}
          rules={{
            required: true,
          }}
          input={{
            disabled: readOnly,
            placeholder: intl.formatMessage({
              id: "ENVIVO.DISTRIBUTION.STUDY_NAME",
              defaultMessage: "Study Name",
            }),
            items: getStudies(),
            className: styles.input,
          }}
        />
        <FormInput
          type="text"
          name="shipmentNumber"
          control={control}
          rules={{
            required: true,
          }}
          input={{
            disabled: readOnly || !!shipment,
            placeholder: intl.formatMessage({
              id: "ENVIVO.DISTRIBUTION.SHIPMENT_NUMBER",
              defaultMessage: "Shipment Number",
            }),
            className: styles.input,
          }}
        />
      </div>
      <div className="d-flex mt-3">
        <div>
          <FormattedMessage
            id="ENVIVO.DISTRIBUTION.SCAN_KITS_BARCODE"
            defaultMessage="Scan Kits Barcode to collect the kits for distribution"
          />
          <div className="d-flex">
            <FormGroup
              input={
                <FormInput
                  type="text"
                  name="scannedKit"
                  control={control}
                  input={{
                    disabled:
                      !(
                        watchShipmentNumber &&
                        watchClinic &&
                        watchStudy
                      ) || readOnly,
                    placeholder: intl.formatMessage({
                      id: "ENVIVO.DISTRIBUTION.SCAN_KIT",
                      defaultMessage: "Scan Kit",
                    }),
                    className: `${styles.input} mt-2 w-100 fs-5`,
                    onEnterKey: handleEnterKit,
                  }}
                />
              }
            />
          </div>
        </div>

        <ListGroup className={`ms-5 w-25 border `}>
          <ListGroup.Item className="ps-2">
            <b className="d-flex border-left border-right-dark">
              <FormattedMessage
                id="ENVIVO.DISTRIBUTION.KITS_LISTS"
                defaultMessage="Kits list"
              />
              <div className="ms-3">{fields.length}</div>
            </b>
          </ListGroup.Item>
          <div className={styles.list}>
            {fields.map(({ barcode, id }, index) => (
              <ListGroup.Item
                key={id}
                className={`border-top-0 border-bottom-0 d-flex ${watchSelectedKit ===
                  barcode && styles.item}`}
                onClick={() => {
                  if (readOnly) return;
                  setValue("selectedKit", barcode);
                }}
              >
                <div className="w-75">{barcode}</div>
                {!readOnly && (
                  <BaseIcon
                    icon={DeleteIcon}
                    onClick={() => {
                      remove(index);
                      appendDeletedField({ barcode });
                    }}
                  />
                )}
              </ListGroup.Item>
            ))}
          </div>
        </ListGroup>
      </div>
    </FormModal>
  );
};

export default Distribution;
