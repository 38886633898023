import { forwardRef } from "react";
import JsBarcode from "jsbarcode";
import { Container } from "react-bootstrap";
import styles from "./PrintLabelImage.module.css";
import image from "../../../../../assets/label.jpg";
import { referencesList } from "../../../../../hooks/useCapscan";
import { PrintLabelProps } from "../PrintLabelForm/PrintLabel";

const PrintLabelImage = forwardRef<HTMLDivElement, PrintLabelProps>(
  ({ sn, lot, reference, expirationDate }: PrintLabelProps, ref) => {
    const generateBarcode = () => {
      const canvas = document.createElement("canvas");
      JsBarcode(canvas, sn, {
        displayValue: false,
        height: 30,
        width: 1,
        margin: 0,
      });
      return canvas.toDataURL("image/png");
    };
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        ref={ref}
      >
        <img src={image} alt="" width={192} />
        <img
          width={160}
          src={generateBarcode()}
          className={`${styles.barcode}`}
          alt="barcode"
        />
        <div className={`${styles.text} ${styles.sn}`}>{sn}</div>
        <h1 className={`${styles.text} ${styles.reference}`}>
          {referencesList[reference].value}
        </h1>
        <h1 className={styles.text}>{lot}</h1>
        <h1 className={`${styles.text} ${styles.date}`}>{expirationDate} </h1>
      </Container>
    );
  }
);

export default PrintLabelImage;
