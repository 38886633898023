/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SamplesByFilterResponse } from '../models';
// @ts-ignore
import { TransferSampleCapscanDto } from '../models';
// @ts-ignore
import { UpdateSampleCapscanDto } from '../models';
// @ts-ignore
import { VoidResponse } from '../models';
/**
 * SampleCapscanApi - axios parameter creator
 * @export
 */
export const SampleCapscanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} includeImages 
         * @param {string} [study] 
         * @param {string} [patient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterSamples: async (includeImages: boolean, study?: string, patient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'includeImages' is not null or undefined
            assertParamExists('getFilterSamples', 'includeImages', includeImages)
            const localVarPath = `/api/sampleCapscan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (study !== undefined) {
                localVarQueryParameter['study'] = study;
            }

            if (patient !== undefined) {
                localVarQueryParameter['patient'] = patient;
            }

            if (includeImages !== undefined) {
                localVarQueryParameter['includeImages'] = includeImages;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TransferSampleCapscanDto} transferSampleCapscanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferSample: async (transferSampleCapscanDto: TransferSampleCapscanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transferSampleCapscanDto' is not null or undefined
            assertParamExists('transferSample', 'transferSampleCapscanDto', transferSampleCapscanDto)
            const localVarPath = `/api/sampleCapscan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transferSampleCapscanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSampleCapscanDto} updateSampleCapscanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSample: async (updateSampleCapscanDto: UpdateSampleCapscanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSampleCapscanDto' is not null or undefined
            assertParamExists('updateSample', 'updateSampleCapscanDto', updateSampleCapscanDto)
            const localVarPath = `/api/sampleCapscan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSampleCapscanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SampleCapscanApi - functional programming interface
 * @export
 */
export const SampleCapscanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SampleCapscanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} includeImages 
         * @param {string} [study] 
         * @param {string} [patient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilterSamples(includeImages: boolean, study?: string, patient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SamplesByFilterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilterSamples(includeImages, study, patient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TransferSampleCapscanDto} transferSampleCapscanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transferSample(transferSampleCapscanDto: TransferSampleCapscanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transferSample(transferSampleCapscanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateSampleCapscanDto} updateSampleCapscanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSample(updateSampleCapscanDto: UpdateSampleCapscanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSample(updateSampleCapscanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SampleCapscanApi - factory interface
 * @export
 */
export const SampleCapscanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SampleCapscanApiFp(configuration)
    return {
        /**
         * 
         * @param {boolean} includeImages 
         * @param {string} [study] 
         * @param {string} [patient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterSamples(includeImages: boolean, study?: string, patient?: string, options?: any): AxiosPromise<SamplesByFilterResponse> {
            return localVarFp.getFilterSamples(includeImages, study, patient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TransferSampleCapscanDto} transferSampleCapscanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferSample(transferSampleCapscanDto: TransferSampleCapscanDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.transferSample(transferSampleCapscanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateSampleCapscanDto} updateSampleCapscanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSample(updateSampleCapscanDto: UpdateSampleCapscanDto, options?: any): AxiosPromise<VoidResponse> {
            return localVarFp.updateSample(updateSampleCapscanDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SampleCapscanApi - object-oriented interface
 * @export
 * @class SampleCapscanApi
 * @extends {BaseAPI}
 */
export class SampleCapscanApi extends BaseAPI {
    /**
     * 
     * @param {boolean} includeImages 
     * @param {string} [study] 
     * @param {string} [patient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SampleCapscanApi
     */
    public getFilterSamples(includeImages: boolean, study?: string, patient?: string, options?: AxiosRequestConfig) {
        return SampleCapscanApiFp(this.configuration).getFilterSamples(includeImages, study, patient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TransferSampleCapscanDto} transferSampleCapscanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SampleCapscanApi
     */
    public transferSample(transferSampleCapscanDto: TransferSampleCapscanDto, options?: AxiosRequestConfig) {
        return SampleCapscanApiFp(this.configuration).transferSample(transferSampleCapscanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateSampleCapscanDto} updateSampleCapscanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SampleCapscanApi
     */
    public updateSample(updateSampleCapscanDto: UpdateSampleCapscanDto, options?: AxiosRequestConfig) {
        return SampleCapscanApiFp(this.configuration).updateSample(updateSampleCapscanDto, options).then((request) => request(this.axios, this.basePath));
    }
}
