/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BoxMessageResponse } from '../models';
// @ts-ignore
import { BoxResponse } from '../models';
// @ts-ignore
import { CreateBoxDto } from '../models';
// @ts-ignore
import { UpdateBoxDto } from '../models';
/**
 * BoxApi - axios parameter creator
 * @export
 */
export const BoxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBoxDto} createBoxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBox: async (createBoxDto: CreateBoxDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBoxDto' is not null or undefined
            assertParamExists('createBox', 'createBoxDto', createBoxDto)
            const localVarPath = `/api/box`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBoxDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxByBarcode: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBoxByBarcode', 'id', id)
            const localVarPath = `/api/box/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBoxDto} updateBoxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBox: async (updateBoxDto: UpdateBoxDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateBoxDto' is not null or undefined
            assertParamExists('updateBox', 'updateBoxDto', updateBoxDto)
            const localVarPath = `/api/box`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBoxDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BoxApi - functional programming interface
 * @export
 */
export const BoxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BoxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBoxDto} createBoxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBox(createBoxDto: CreateBoxDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBox(createBoxDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoxByBarcode(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoxByBarcode(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBoxDto} updateBoxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBox(updateBoxDto: UpdateBoxDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBox(updateBoxDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BoxApi - factory interface
 * @export
 */
export const BoxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BoxApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBoxDto} createBoxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBox(createBoxDto: CreateBoxDto, options?: any): AxiosPromise<BoxMessageResponse> {
            return localVarFp.createBox(createBoxDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxByBarcode(id: string, options?: any): AxiosPromise<BoxResponse> {
            return localVarFp.getBoxByBarcode(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBoxDto} updateBoxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBox(updateBoxDto: UpdateBoxDto, options?: any): AxiosPromise<BoxMessageResponse> {
            return localVarFp.updateBox(updateBoxDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BoxApi - object-oriented interface
 * @export
 * @class BoxApi
 * @extends {BaseAPI}
 */
export class BoxApi extends BaseAPI {
    /**
     * 
     * @param {CreateBoxDto} createBoxDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxApi
     */
    public createBox(createBoxDto: CreateBoxDto, options?: AxiosRequestConfig) {
        return BoxApiFp(this.configuration).createBox(createBoxDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxApi
     */
    public getBoxByBarcode(id: string, options?: AxiosRequestConfig) {
        return BoxApiFp(this.configuration).getBoxByBarcode(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateBoxDto} updateBoxDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxApi
     */
    public updateBox(updateBoxDto: UpdateBoxDto, options?: AxiosRequestConfig) {
        return BoxApiFp(this.configuration).updateBox(updateBoxDto, options).then((request) => request(this.axios, this.basePath));
    }
}
