import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { FormLabel } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { useEffect, useRef, useState } from "react";
import styles from "./PrintLabel.module.css";
import useCapscan, { referencesList } from "../../../../../hooks/useCapscan";
import { Button } from "../../../../../components/Button/Button";
import { FormGroup } from "../../../../../components/form/FormGroup/FormGroup";
import FormInput from "../../../../../components/form/FormInput/FormInput";
import { FormModal } from "../../../../../components/form/FormModal/FormModal";
import PrintLabelImage from "../PrintLabelImage/PrintLabelImage";
import PrintIcon from "../../../../../assets/print.svg";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { getFormattedDate } from "../../../../../util/date.util";
import { useNavigate } from "react-router-dom";
import { AppsConstants } from "../../../../../constants";

const descriptions = {
  lot: "Insert Lot Number up to 6 digits",
  capscanDate: "Insert CapScan Creation Date",
  expirationDate: "Insert Expiration date",
  reference: "Select from list",
};

const placeholders = {
  lot: "Lot Number",
  expirationDate: "Expiration Date",
  reference: "Reference",
  sn: "SN",
};

export type PrintLabelProps = {
  sn: string;
  lot: string;
  reference: number;
  expirationDate: string;
};

const PrintLabel = () => {
  const {
    control,
    handleSubmit,
    formState,
    getValues,
    reset,
    setFocus,
    register,
    setError,
  } = useForm<PrintLabelProps>();
  const [showModal, setShowModal] = useState(true);
  const imageRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const { addCapscan } = useCapscan();
  const [renderImage, setRenderImage] = useState(false);
  const labelMargin = "6.5";
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!formState.isSubmitSuccessful && getValues("sn") === "") {
      setFocus("sn");
    }
  }, [formState.isSubmitSuccessful, getValues, setFocus]);

  const handlePrint = useReactToPrint({
    content: () => imageRef?.current,
    pageStyle: `@page { size: letter; margin-top: 36mm; margin-left: ${labelMargin}mm}`,
    onAfterPrint: () => {
      reset({
        ...getValues(),
        sn: "",
      });
      setRenderImage(false);
    },
  });

  const onSubmit = async ({
    lot,
    sn,
    expirationDate,
    reference,
  }: PrintLabelProps) => {
    if (!formState.isSubmitSuccessful) {
      setRenderImage(true);
      const response = await addCapscan({
        lot: lot,
        reference: referencesList[reference].value,
        sn: sn,
        expirationDate: new Date(expirationDate),
        labeled: false,
      });
      if (response?.success) {
        handlePrint();
      } else {
        setRenderImage(false);
        setError("sn", {});
      }
    }
  };

  const getPlaceHolder = (id: string, defaultMessage: string): string => {
    return intl.formatMessage({
      id: id,
      defaultMessage: defaultMessage,
    });
  };
 const onClickClose = ()=>{
   setShowModal(false);
   navigate("/" + AppsConstants.FACTORY)

 }
  const header = (
    <>
      <div className="font-weight-bold">
        <BaseIcon icon={PrintIcon} className="w-25 ms-1 mt-1" />
        <FormLabel className="ps-2 fw-bold">
          <FormattedMessage
            id="FACTORY.PRINT.PRINT_LABEL"
            defaultMessage="Print Label"
          />
        </FormLabel>
      </div>
      <Button variant="outline-dark" className="border-0" value="X" onClick={() => onClickClose()}/>
    </>
  );

  const footer = (
    <>
      <Button
        variant="outline-dark"
        className={`border-dark ${styles["first-button"]}`}
        onClick={() => onClickClose()}
      >
        <FormattedMessage id="FACTORY.PRINT.CANCEL" defaultMessage="Cancel"/>
      </Button>
      <Button
        type="submit"
        variant="outline-dark"
        className="border-dark"
        form="form"
      >
        <FormattedMessage id="FACTORY.PRINT.PRINT" defaultMessage="Print" />
      </Button>
    </>
  );

  const image = (
    <div hidden>
      {renderImage && (
        <PrintLabelImage
          expirationDate={getFormattedDate(getValues("expirationDate"), 'YYYY-MM-DD')}
          lot={getValues("lot")}
          reference={getValues("reference")}
          sn={getValues("sn")}
          ref={imageRef}
        />
      )}
    </div>
  );
  return (
    <div className="mx-auto">
      <FormModal
        header={header}
        footer={footer}
        onSubmit={handleSubmit(onSubmit)}
        show={showModal}
        handleClose={() => onClickClose()}
        id="form"
        className="modal-lg mt-5"
      >
        {image}
        <div className="mx-auto w-50">
          <FormGroup
            input={
              <FormInput
                type="text"
                name="lot"
                control={control}
                rules={{
                  required: true,
                  pattern: /^\b\d{6}\b/,
                }}
                input={{
                  placeholder: getPlaceHolder(
                    "FACTORY.PRINT.LOT_NUMBER",
                    placeholders["lot"]
                  ),
                  maxlength: 12,
                }}
              />
            }
            groupDescription={
              <div className={styles.description}>
                <FormattedMessage
                  id={"FACTORY.PRINT.LOT_NUMBER.DESCRIPTION"}
                  defaultMessage={descriptions["lot"]}
                />
              </div>
            }
          />

          <FormGroup
            input={
              <FormInput
                type="text"
                name="expirationDate"
                control={control}
                rules={{ required: true }}
                input={{
                  type: "date",
                  placeholder: getPlaceHolder(
                    "FACTORY.PRINT.EXPIRATION_DATE",
                    placeholders["expirationDate"]
                  ),
                }}
              />
            }
            groupDescription={
              <div className={styles.description}>
                <FormattedMessage
                  id={"FACTORY.PRINT.EXPIRATION_DATE.DESCRIPTION"}
                  defaultMessage={descriptions["expirationDate"]}
                />
              </div>
            }
          />

          <FormGroup
            input={
              <FormInput
                type="select"
                name="reference"
                control={control}
                rules={{ required: true }}
                input={{
                  placeholder: getPlaceHolder(
                    "FACTORY.PRINT.REFERENCE",
                    placeholders["reference"]
                  ),
                  items: referencesList,
                }}
              />
            }
            groupDescription={
              <div className={styles.description}>
                <FormattedMessage
                  id={"FACTORY.PRINT.REFERENCE.DESCRIPTION"}
                  defaultMessage={descriptions["reference"]}
                />
              </div>
            }
          />

          <FormGroup
            input={
              <FormInput
                type="text"
                control={control}
                rules={{ required: true }}
                {...register("sn")}
                input={{
                  placeholder: getPlaceHolder(
                    "FACTORY.PRINT.SN",
                    placeholders["sn"]
                  ),
                  maxlength: 5,
                }}
              />
            }
          />
        </div>
      </FormModal>
    </div>
  );
};

export default PrintLabel;
