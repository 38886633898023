import { IApiResult } from "../models/responses/api-result.response";
import Logger from "../util/logger.util";
import {
  CreateBoxParams,
  UpdateBoxParams,
} from "../models/requests/storageLocation.request";
import { BoxMessageResponse } from "../api/models/box-message-response";
import { BoxDto } from "../api/models/box-dto";
import { BoxApi } from "../api/apis/box-api";

const logger = new Logger({ source: "Box Service" });

export const boxService = {
  addBox: async (box: CreateBoxParams): Promise<BoxMessageResponse | null> =>
    await addBox(box),
  getBox: async (boxBarcode: string): Promise<IApiResult<BoxDto> | null> =>
    await getBoxByBarcode(boxBarcode),
  editBox: async (box: UpdateBoxParams): Promise<BoxMessageResponse | null> =>
    await editBox(box),
};

const addBox = async (
  box: CreateBoxParams
): Promise<BoxMessageResponse | null> => {
  const api = new BoxApi();
  const { data: response } = await api.createBox(box);
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Add box invalid API response", { statusCode });
    return response;
  }
  return response;
};

const getBoxByBarcode = async (
  boxBarcode: string
): Promise<IApiResult<BoxDto> | null> => {
  logger.info(`get box with barcode ${boxBarcode}`);
  const api = new BoxApi();
  const { data: response } = await api.getBoxByBarcode(boxBarcode);
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Get box by barcode invalid API response", { statusCode });
    return response;
  }
  return response;
};

const editBox = async (
  box: UpdateBoxParams
): Promise<BoxMessageResponse | null> => {
  const api = new BoxApi();
  const { data: response } = await api.updateBox(box);

  const { success, statusCode } = response;
  if (!success) {
    logger.error("Edit box invalid API response", { statusCode });
    return response;
  }
  return response;
};
