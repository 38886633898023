import { IApiResult } from "../models/responses/api-result.response";
import { TableResponse } from "../models/responses/table.response";
import Logger from "../util/logger.util";
interface Table<T extends Object> {
  totalEntitiesAmount?: number;
  items?: T[];
}
export const getNumberOfPages = (
  totalEntitiesAmount: number,
  pageSize: number
) => {
  return Math.ceil(totalEntitiesAmount / pageSize);
};

export function getTablePaged<T extends Object>(
  response: IApiResult<Table<T>>,
  pageCountTable: number,
  logger: Logger
): IApiResult<TableResponse<T>> | null {
  const { success, statusCode, data } = response;
  if (!success) {
    logger.error("Get items paged invalid API response", { statusCode });
    return null;
  }
  if (data) {
    const pageCount = getNumberOfPages(
      data!.totalEntitiesAmount!,
      pageCountTable
    );

    return { ...response, data: { ...data, items: data!.items!, pageCount } };
  }
  return { ...response, data: undefined };
}
