import {
  AddKitParams,
  KitsParams,
  ReturnKitParams,
  UpdateKitParams,
} from "../models/requests/kit.request";
import { IApiResult } from "../models/responses/api-result.response";
import { KitDto } from "../api/models/kit-dto";
import Logger from "../util/logger.util";
import { KitApi } from "../api/apis/kit-api";
import { KitWithStudyLetterDto } from "../api";
import { KitResponse } from "../models/responses/kit.response";
import { MetadataParams } from "../models/requests/metadata.request";
const logger = new Logger({ source: "Kit Service" });

export const kitService = {
  getkitsByStudyDepartment: async ({
    studyDepartmentId,
  }: KitsParams): Promise<IApiResult<KitDto[]> | null> =>
    await getkitsByStudyDepartment({ studyDepartmentId }),
  getKitByCapscanSn: async (
    sn: string
  ): Promise<IApiResult<KitWithStudyLetterDto> | null> =>
    await getKitByCapscanSn(sn),
  getKitByBarcode: async (
    barcode: string
  ): Promise<IApiResult<KitResponse> | null> => await getKitByBarcode(barcode),
  editKit: async (kit: UpdateKitParams): Promise<IApiResult<void> | null> =>
    await editKit(kit),
  addKit: async (kit: AddKitParams): Promise<IApiResult<void> | null> =>
    await addKit(kit),
  returnKit: async (kit: ReturnKitParams): Promise<IApiResult<void> | null> =>
    await returnKit(kit),
  editMetadata: async (
    metadata: MetadataParams
  ): Promise<IApiResult<void> | null> => await editMetadata(metadata),
};

const getkitsByStudyDepartment = async ({
  studyDepartmentId,
}: KitsParams): Promise<IApiResult<KitDto[]> | null> => {
  logger.info(`get kits of study ${studyDepartmentId}`);

  const api = new KitApi();

  const { data: response } = await api.getKitsByStudyDepartment(
    studyDepartmentId
  );
  const { success, statusCode } = response;

  if (!success) {
    logger.error("Get kits invalid API response", { statusCode });
  }
  return response;
};

const getKitByCapscanSn = async (
  sn: string
): Promise<IApiResult<KitWithStudyLetterDto> | null> => {
  logger.info(`get kit by capscan sn ${sn}`);

  const api = new KitApi();

  let { data: response } = await api.getKitByCapscanSn(sn);
  let { success, statusCode } = response;
  if (!success) {
    logger.error("Get kits invalid API response", { statusCode });
    return { ...response, data: undefined };
  }
  return response;
};

const getKitByBarcode = async (
  barcode: string
): Promise<IApiResult<KitResponse> | null> => {
  logger.info(`get kit by barcode ${barcode}`);

  const api = new KitApi();
  let { data: response } = await api.getKitByBarcode(barcode);
  let { success, statusCode } = response;
  if (!success) {
    logger.error("Get kits invalid API response", { statusCode });
    return { ...response, data: undefined };
  }
  return response;
};

const editKit = async (
  kit: UpdateKitParams
): Promise<IApiResult<void> | null> => {
  const api = new KitApi();
  const { data: response } = await api.updateKit(kit);
  return response;
};

const addKit = async (kit: AddKitParams): Promise<IApiResult<void> | null> => {
  const api = new KitApi();
  const { data: response } = await api.createKit(kit);
  return response;
};

const returnKit = async (
  kit: ReturnKitParams
): Promise<IApiResult<void> | null> => {
  const api = new KitApi();
  const { data: response } = await api.returnKit(kit);
  return response;
};

const editMetadata = async (
  metadata: MetadataParams
): Promise<IApiResult<void> | null> => {
  const api = new KitApi();
  const { data: response } = await api.updateMetadata(metadata);
  return response;
};
