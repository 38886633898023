import { useFieldArray, useForm } from "react-hook-form";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { FormModal } from "../../../../../components/form/FormModal/FormModal";
import AddIcon from "../../../../../assets/add.svg";
import DeleteIcon from "../../../../../assets/delete.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "react-bootstrap";
import { FormGroup } from "../../../../../components/form/FormGroup/FormGroup";
import FormInput from "../../../../../components/form/FormInput/FormInput";
import { KitTypeParams } from "../../../../../models/requests/kitType.request";
import useKitType from "../../../../../hooks/useKitType";
import { referencesList } from "../../../../../hooks/useCapscan";
import { studyNamesQuery } from "../../../../../queries/study.query";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { makeDirtyObject } from "../../../../../util/form.util";
import RadioButtonGroup from "../../../../../components/form/RadioButtonGroup/RadioButtonGroup";
import { KitTypeDto } from "../../../../../api";
import styles from "../KitTypes.module.css";

type ModalFormProps = {
  show: boolean;
  handleHide: () => void;
  refetch: () => void;
  kitType?: KitTypeDto;
};
const referencesListByValues = referencesList.map(({ value }) => ({
  key: value,
  value,
}));

const samplingTimeRadioButtons = [
  { value: 0, label: "Morning" },
  { value: 1, label: "Afternoon" },
  { value: 2, label: "Evening" },
  { value: 3, label: "Night" },
];
export const KitType = ({
  show,
  handleHide,
  refetch,
  kitType,
}: ModalFormProps) => {
  const { data: studyNames, isLoading: isStudyLoading } = useQuery(
    studyNamesQuery()
  );

  const { add, edit } = useKitType();
  const methods = useForm<KitTypeParams>();
  const { control, handleSubmit, reset, formState, getFieldState } = methods;
  const intl = useIntl();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "capscans",
  });

  const getStudies = () =>
    studyNames?.data?.map((item) => ({
      key: item.id,
      value: item.studyName,
    }));

  const handleCancel = () => handleHide();

  const onSubmit = async (kitTypeFormValues: KitTypeParams) => {
    kitTypeFormValues.kitVersion =
      kitTypeFormValues.kitVersion && Number(kitTypeFormValues.kitVersion);
    kitTypeFormValues.capscans?.forEach(
      (cap) => (cap.samplingTime = Number(cap.samplingTime))
    );
    if (kitType) {
      let dirtyObj = makeDirtyObject(
        (key: keyof KitTypeParams) => getFieldState(key).isDirty,
        kitTypeFormValues
      );
      const response = await edit({ id: kitType.id!, ...dirtyObj });
      if (response?.success) {
        refetch();
        handleHide();
      }
    } else {
      const response = await add(kitTypeFormValues);
      if (response?.success) {
        refetch();
        handleHide();
      }
    }
    refetch();
  };
  const addCapscan = () => {
    append({ type: "", samplingTime: -1 });
  };
  const removeCapscan = (index: number) => {
    if (index >= 0) {
      remove(index);
    }
  };

  useEffect(() => {
    if (kitType) {
      reset(kitType);
    }
  }, [kitType]);

  return (
    <FormModal
      onSubmit={handleSubmit(onSubmit)}
      className="modal-lg modal-dialog-scrollable mt-3 modal-dialog-centered "
      contentClassName="w-75"
      id="form"
      header={
        <div>
          <BaseIcon icon={AddIcon} className="me-1" />
          <FormattedMessage
            id={kitType ? "ENVIVO.KIT_TYPE.UPDATE" : "ENVIVO.KIT_TYPE.ADD"}
            defaultMessage={kitType ? "Update Kit Version" : "Add New"}
          />
        </div>
      }
      footer={
        <>
          <Button variant="outline-dark" onClick={() => handleCancel()}>
            <FormattedMessage
              id="ENVIVO.MODAL.CANCEL"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            type="submit"
            variant="outline-dark"
            className="border-dark"
            form="form"
            disabled={formState.isSubmitting}
          >
            <FormattedMessage
              id={kitType ? "ENVIVO.MODAL.UPDATE" : "ENVIVO.MODAL.CREATE"}
              defaultMessage={kitType ? "Update" : "Create"}
            />
          </Button>
        </>
      }
      show={show}
      handleClose={handleCancel}
    >
      <FormattedMessage
        id={"ENVIVO.KIT_TYPE.MODAL.KIT_DETAILS"}
        defaultMessage={"Kit Details"}
      />
      <div className="d-flex">
        <FormGroup
          input={
            <FormInput
              type="text"
              name="kitVersion"
              control={control}
              rules={{
                required: true,
              }}
              input={{
                type: "number",
                placeholder: intl.formatMessage({
                  id: "ENVIVO.KIT_TYPE.MODAL.VERSION",
                  defaultMessage: "Version",
                }),
                className: `${styles.input} ${styles.version} ms-0`,
              }}
            />
          }
        />
        <FormGroup
          className="col"
          input={
            <FormInput
              type="text"
              name="ifuRevision"
              control={control}
              rules={{
                required: true,
              }}
              input={{
                placeholder: intl.formatMessage({
                  id: "ENVIVO.KIT_TYPE.MODAL.IFU",
                  defaultMessage: "IFU",
                }),
                className: `${styles.input} ${styles.ifu}`,
              }}
            />
          }
        />
      </div>
      <>
        {!isStudyLoading && (
          <FormGroup
            input={
              <FormInput
                type="select"
                name="studyId"
                control={control}
                rules={{
                  required: true,
                }}
                input={{
                  items: getStudies(),
                  placeholder: intl.formatMessage({
                    id: "ENVIVO.KIT_TYPE.MODAL.STUDY",
                    defaultMessage: "Study",
                  }),
                  className: `${styles.input} w-75 ms-0`,
                }}
              />
            }
          />
        )}
      </>
      <FormGroup
        input={
          <FormInput
            type="text"
            name="description"
            control={control}
            rules={{
              required: true,
            }}
            input={{
              className: "w-75 rounded-0",
              type: "textarea",
              placeholder: intl.formatMessage({
                id: "ENVIVO.KIT_TYPE.MODAL.DESCRIPTION",
                defaultMessage: "Description",
              }),
            }}
          />
        }
      />
      <div className="mt-4">
        <FormattedMessage
          id={"ENVIVO.KIT_TYPE.MODAL.CAPSCAN_DETAILS"}
          defaultMessage={"CapScan Details"}
        />
      </div>
      {fields.map((field, index) => {
        return (
          <div key={field.id} className="form-group d-flex direction-row">
            <FormGroup
              className="col"
              input={
                <FormInput
                  type="select"
                  control={control}
                  name={`capscans.${index}.type`}
                  rules={{ required: true }}
                  input={{
                    className:`${styles.input} ms-0`,
                    items: referencesListByValues,
                    placeholder: intl.formatMessage({
                      id: "ENVIVO.KIT_TYPE.MODAL.CAPSCAN_TYPE",
                      defaultMessage: "Capscan Type",
                    }),
                  }}
                />
              }
            />
            <div className="mt-2 d-flex">
              <RadioButtonGroup
                name={`capscans.${index}.samplingTime`}
                key={`capscans.${index}`}
                defValue={field.samplingTime}
                radioButtonsArray={samplingTimeRadioButtons}
                control={control}
              />
              <BaseIcon
                className={`${styles.delicon}`}
                icon={DeleteIcon}
                onClick={() => removeCapscan(index)}
              />
            </div>
          </div>
        );
      })}
      <div className="mt-2 ">
        <BaseIcon icon={AddIcon} onClick={addCapscan} />{" "}
        <FormattedMessage
          id={"ENVIVO.KIT_TYPE.ADD_CAPSCAN"}
          defaultMessage={"Add Capscan"}
        />
        <pre hidden={true}>
          Dirty Fields: {JSON.stringify(formState.dirtyFields)}
        </pre>
      </div>
    </FormModal>
  );
};
