import { useCallback, useEffect, useState } from "react";
import { FormModal } from "../../../../../components/form/FormModal/FormModal";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../../../../components/Button/Button";
import FormInput from "../../../../../components/form/FormInput/FormInput";
import { useFieldArray, useForm } from "react-hook-form";
import ProcessIcon from "../../../../../assets/process_capscan.svg";
import AddIcon from "../../../../../assets/add.svg";
import CameraIcon from "../../../../../assets/photoshoot.svg";
import Sample from "./Sample/Sample";
import styles from "./ProcessCapscan.module.css";
import { FormGroup } from "../../../../../components/form/FormGroup/FormGroup";
import ImageCapture from "../../../../../components/ImageCapture/ImageCapture";
import DeviceImage from "./DeviceImage/DeviceImage";
import ImageViewer from "react-simple-image-viewer";
import useProcessCapscan from "../../../../../hooks/useProcessCapscan";
import { getFormattedLocalDate } from "../../../../../util/date.util";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { kitByCapscanSnQuery } from "../../../../../queries/kit.query";
import { SamplesParams } from "../../../../../models/requests/processCapscan.request";
import { TUBES_DEVICE } from "../../More/CameraSettings/CameraSettings";
import { Spinner } from "react-bootstrap";
import "react-image-crop/dist/ReactCrop.css";
import Tesseract from "tesseract.js";

import {
  getSelectedSuffix,
  getSuffixes,
} from "../../../../../util/suffix.util";
import { getOcrWorker, recognizeOcr } from "../../../../../util/text.util";
import { AppsConstants } from "../../../../../constants";

type SamplesProps = {
  capscanSn: string;
  sampleNumber?: string;
  suffix: { label: string; value: boolean; volume: string }[];
  pH: string;
  bodyLocation: string;
  studyLetter: string;
  studyId: string;
  imageUrl: string;
  comment: string;
};

export type ProcessCapscanProps = {
  sn: string;
  processDate: string;
  subjectId: string;
  imageUrl: string;
  samples: SamplesProps[];
  ocrSample: string;
  selectedSampleImgUrl: string;
};

const ProcessCapscan = () => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    setError,
    setFocus,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm<ProcessCapscanProps>({ defaultValues: { samples: [] } });
  const { fields, append, remove, update } = useFieldArray({
    name: "samples",
    control,
  });
  let worker: Tesseract.Worker | null = null;
  const intl = useIntl();
  const watchImage = watch("imageUrl");
  const watchSelectedSampleImgUrl = watch("selectedSampleImgUrl");
  const navigate = useNavigate();
  const { add } = useProcessCapscan();
  const [show, setShow] = useState(true);
  const [closeForm, setCloseForm] = useState(true);
  const [showDeviceModal, setShowDeviceModal] = useState(false);
  const [loadingOcr, setLoadingOcr] = useState(false);
  const watchScannedSn = watch("sn");
  const watchSamples = watch(`samples`);
  const watchOcrNumber = watch("ocrSample");
  const [disableAddSample, setDisableAddSample] = useState(true);
  const [selectedSampleIndex, setSelectedSampleIndex] = useState<
    number | undefined
  >(undefined);
  const [disableAccept, setDisableAccept] = useState<boolean>(true);

  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const { data: kit, refetch: refetchKit } = useQuery(
    kitByCapscanSnQuery(watchScannedSn, {
      enabled: false,
      refetchOnWindowFocus: false,
    })
  );

  const resetFormSamples = useCallback(() => {
    fields.forEach((element, index) => {
      remove(index);
    });
    reset();
    setCloseForm(closeForm);
  }, [remove, reset, remove, setCloseForm, closeForm]);

  const handleEnterKit = async (e: React.KeyboardEvent<Element>) => {
    e.preventDefault();
    refetchKit();
  };

  useEffect(() => {
    if (kit) {
      setValue("subjectId", kit?.data?.subjectId || "");
    }
    if (!kit?.success && kit?.message) {
      alert(kit?.message);
    }
  }, [kit]);

  useEffect(() => {
    setFocus("sn");
  }, [closeForm]);

  useEffect(() => {
    if (watchScannedSn) {
      setDisableAddSample(false);
    } else {
      setDisableAddSample(true);
    }
  }, [watchScannedSn]);

  useEffect(() => {
    if (watchSelectedSampleImgUrl) {
      setDisableAccept(false);
    } else {
      setDisableAccept(true);
    }
  }, [watchSelectedSampleImgUrl]);

  const getSampleNumber = (): string => {
    if (!watchSamples || watchSamples.length === 0) {
      return "";
    }
    let ret =
      Number(watchSamples[watchSamples.length - 1]?.sampleNumber || 0) + 1;
    return ret.toString().padStart(4, "0");
  };

  const addSample = () => {
    append({
      capscanSn: "",
      studyLetter: kit?.data?.studyLetter || "",
      sampleNumber: getSampleNumber(),
      suffix: getSuffixes(),
      pH: "",
      bodyLocation: "",
      imageUrl: "",
      studyId: kit?.data?.studyId || "",
      comment: "",
    });
  };

  const getImageUrl = () => {
    return selectedSampleIndex !== undefined
      ? getValues(`samples.${selectedSampleIndex}.imageUrl`)
      : undefined;
  };

  const getSelectedSampleImageUrl = () => {
    return selectedSampleIndex !== undefined
      ? getValues("selectedSampleImgUrl")
      : undefined;
  };

  const acceptSample = () => {
    const regex = /([A-Z]{1,2})(\d{1,4})([A-Z]{1})/;
    if (!regex.test(watchOcrNumber)) return null;
    const matches = watchOcrNumber.match(regex);
    if (!matches) {
      return;
    }
    const [full, study, number, suffix] = matches;
    const sampleIndex = watchSamples.findIndex(
      (sample) =>
        Number(sample.sampleNumber) === Number(number) &&
        sample.studyLetter === study &&
        sample.suffix.find((s) => s.label === suffix)?.value
    );
    if (sampleIndex === -1) {
      alert("Match sample not found. Please try again.");
    }
    const sample = getValues(`samples.${sampleIndex}`);
    update(sampleIndex, {
      ...sample,
      sampleNumber: Number(number).toString().padStart(4, "0"),
      imageUrl: getSelectedSampleImageUrl() || "",
    });
  };

  const handleOcr = async (capturedImage: string, croppedImage: string) => {
    setLoadingOcr(true);
    console.log("begin... ");

    const allSampleLetters = watchSamples.map(
      (sample) =>
        `${sample.studyLetter}${sample.sampleNumber}${sample.suffix
          .filter((s) => s.value)
          .map((s) => s.label)}`
    );
    const whiteListDistinct = new Set(allSampleLetters.join().split("").sort());
    const whiteList = Array.from(whiteListDistinct).join("");
    if (!worker) {
      worker = await getOcrWorker();
    }
    const text = await recognizeOcr(worker, croppedImage, whiteList);

    console.log("text: " + text);
    const sample = text.replace(/[^a-zA-Z0-9]/g, "");
    setValue("ocrSample", sample || "");
    setLoadingOcr(false);
    setValue("selectedSampleImgUrl", capturedImage);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      resetFormSamples();
    }
  }, [isSubmitSuccessful, resetFormSamples]);
   
  const onSubmit = async (formState: ProcessCapscanProps) => {
    let processStudyId = "";
    const samples: SamplesParams[] = [];
    formState.samples.forEach((sample, index) => {
      sample.capscanSn = formState.sn;
      const suffixes = getSelectedSuffix(sample.suffix);
      if (suffixes.length === 0) {
        setError(`samples.${index}.suffix`, {});
        alert("Suffix must be specified");
        return;
      }
      const { suffix, studyId, sampleNumber, ...sampleProps } = sample;
      processStudyId = studyId;
      samples.push({
        ...sampleProps,
        sampleNumber: Number(sampleNumber) || undefined,
        suffixVolume: suffixes,
      });
    });
    const response = await add({
      capscanSn: formState.sn,
      processDate: formState.processDate,
      imageUrl: formState.imageUrl,
      kitSubjectId: formState.subjectId,
      samples: samples,
      studyId: processStudyId,
    });
    if (response?.success) {
      reset({
        sn: "",
        subjectId: "",
        imageUrl: "",
        samples: [],
        ocrSample: "",
        selectedSampleImgUrl: "",
      });
      reset();
      closeForm && setShow(false);
    } else setError("sn", {});
  };

  const ImageButton = () => {
    return (
      <>
        {watchImage ? (
          <div className="d-flex flex-column">
            <img
              className={`me-2 d-flex flex-column border rounded-2 ${styles.device}  ${styles.img}`}
              src={watchImage}
              alt="sample"
              onClick={() => setIsViewerOpen(true)}
            />
            <button
              className={`text-primary border-primary ms-auto me-4 ${styles.linkButton}`}
              onClick={() => setShowDeviceModal(true)}
            >
              <FormattedMessage
                id="ENVIVO.CAPSCAN_PROCESS.CHANGE_IMAGE"
                defaultMessage="Change Image"
              />
            </button>
          </div>
        ) : (
          <Button
            variant="outline-dark"
            size="sm"
            className={` me-2 d-flex flex-column border rounded-4 ${styles.device}`}
            onClick={() => {
              setShowDeviceModal(true);
            }}
          >
            <BaseIcon icon={CameraIcon} />
            <div>
              <FormattedMessage
                id="ENVIVO.CAPSCAN_PROCESS.DEVICE_PICTURE"
                defaultMessage="Device Picture"
              />
            </div>
          </Button>
        )}
        {isViewerOpen && (
          <ImageViewer
            src={[watchImage]}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={() => setIsViewerOpen(false)}
          />
        )}
      </>
    );
  };

  return (
    <>
      <FormModal
        backdrop={"static"}
        onSubmit={handleSubmit(onSubmit)}
        id="device"
        className={`modal-xl modal-dialog-centered modal-dialog-scrollable`}
        dialogClassName="h-100 w-100"
        contentClassName={`${styles.modalHeight} w-100`}
        formClassName="h-100"
        header={
          <div>
            <BaseIcon icon={ProcessIcon} />{" "}
            <b>
              <FormattedMessage
                id="ENVIVO.CAPSCAN_PROCESS"
                defaultMessage="Process Capscan"
              />
            </b>
          </div>
        }
        footer={
          <>
            <Button
              variant="outline-dark"
              className="border-dark"
              onClick={() => navigate(-1)}
            >
              <FormattedMessage
                id={"ENVIVO.MODAL.CANCEL"}
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              variant="outline-dark"
              className="border-dark"
              form="device"
              type="submit"
              onClick={() => setCloseForm(false)}
            >
              <FormattedMessage
                id={"ENVIVO.CAPSCAN_PROCESS.BUTTON.SAVE_AND_ADD"}
                defaultMessage={"Save and Add"}
              />
            </Button>
            <Button
              variant="outline-dark"
              className="border-dark"
              form="device"
              type="submit"
              onClick={() => setCloseForm(true)}
            >
              <FormattedMessage
                id={"ENVIVO.CAPSCAN_PROCESS.BUTTON.SAVE_AND_EXIT"}
                defaultMessage={"Save and Exit"}
              />
            </Button>
          </>
        }
        show={show}
        handleClose={() => navigate("/" + AppsConstants.ENVIVO)}
      >
        <div className="d-flex direction-row justify-content-between h-100">
          <div>
            <div className="d-flex">
              <FormInput
                type="text"
                name="sn"
                control={control}
                rules={{
                  required: true,
                }}
                input={{
                  type: "text",
                  placeholder: intl.formatMessage({
                    id: "ENVIVO.CAPSCAN_PROCESS.SCANNED_SN",
                    defaultMessage: "Scanned SN",
                  }),
                  maxlength: 5,
                  className: styles.input,
                  onEnterKey: handleEnterKit,
                }}
              />
              <FormInput
                type="text"
                name="processDate"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={getFormattedLocalDate(new Date(), "YYYY-MM-DD")}
                input={{
                  type: "text",
                  placeholder: intl.formatMessage({
                    id: "ENVIVO.CAPSCAN_PROCESS.PROCESSED_DATE",
                    defaultMessage: "Processed Date",
                  }),
                  className: `${styles.input} ${styles.disabled}`,
                  disabled: true,
                }}
              />
              <FormInput
                type="text"
                name="subjectId"
                control={control}
                rules={{
                  required: true,
                }}
                input={{
                  type: "text",
                  placeholder: intl.formatMessage({
                    id: "ENVIVO.CAPSCAN_PROCESS.TID",
                    defaultMessage: "TID",
                  }),
                  className: `${styles.input} ${styles.subject}`,
                  disabled: true,
                }}
              />
              <ImageButton />
            </div>
            <div>
              {fields.map((field, index) => (
                <Sample
                  getValue={getValues}
                  {...field}
                  watch={watch}
                  setValue={setValue}
                  remove={remove}
                  index={index}
                  onClick={setSelectedSampleIndex}
                  isSelected={selectedSampleIndex === index}
                  key={field.id}
                  control={control}
                />
              ))}
            </div>
            <div className="mt-2 ">
              <button
                className="btn border-0"
                disabled={disableAddSample}
                onClick={addSample}
                type="button"
              >
                <BaseIcon icon={AddIcon} />{" "}
              </button>
              <FormattedMessage
                id={"ENVIVO.CAPSCAN_PROCESS.ADD_CAPSCAN_SAMPLE"}
                defaultMessage={"Add Capscan Sample"}
              />
            </div>
          </div>
          <div className="d-flex border-start h-100">
            <div>
              <div className="d-flex justify-content-between ms-2 mt-2">
                <div className="me-5">
                  <FormattedMessage
                    id="ENVIVO.CAPSCAN_PROCESS.CAMERA_VIEW"
                    defaultMessage="Camera View"
                  />
                </div>
              </div>
              <ImageCapture
                width={250}
                height={350}
                disabled={false}
                handleCaptureStart={() => {
                  setValue("ocrSample", "");
                }}
                imageUrl={getImageUrl()}
                handleOcr={handleOcr}
                handleCapture={(img) => setValue("selectedSampleImgUrl", img)}
                deviceId={localStorage.getItem(TUBES_DEVICE)}
                className={styles.image}
                scale={3}
                mirrored={true}
              />
              <div className="d-flex mt-auto">
                <FormGroup
                  label={
                    <label className="ms-3">
                      <FormattedMessage
                        id="ENVIVO.CAPSCAN_PROCESS.SAMPLE.SAMPLE_NUMBER"
                        defaultMessage="Sample Number"
                      />
                    </label>
                  }
                  input={
                    <FormInput
                      type="text"
                      name="ocrSample"
                      control={control}
                      input={{
                        className: styles.input,
                        disabled: loadingOcr,
                      }}
                    />
                  }
                />
                <Button
                  className="border h-50 m-auto rounded-4 mb-2"
                  variant="light"
                  size="sm"
                  onClick={acceptSample}
                  disabled={disableAccept}
                >
                  {loadingOcr ? (
                    <Spinner className={styles.spinner} />
                  ) : (
                    <FormattedMessage
                      id="BUTTON.ACCEPT"
                      defaultMessage="Accept"
                    />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </FormModal>
      {show && (
        <DeviceImage
          show={showDeviceModal}
          handleHide={() => setShowDeviceModal(false)}
          handleAccept={(imageUrl: string) => setValue("imageUrl", imageUrl)}
        />
      )}
    </>
  );
};

export default ProcessCapscan;
