import { useMutation } from "@tanstack/react-query";
import {
  addKitMutation,
  editKitMutation,
  editMetadataMutation,
  returnKitMutation,
} from "../mutations/kit.mutation";
import {
  AddKitParams,
  UpdateKitParams,
  ReturnKitParams,
} from "../models/requests/kit.request";
import { IApiResult } from "../models/responses/api-result.response";
import { response } from "express";
import { MetadataParams } from "../models/requests/metadata.request";

const useKit = () => {
  const { mutateAsync: editKitMutate } = useMutation(editKitMutation());
  const { mutateAsync: addKitMutate } = useMutation(addKitMutation());
  const { mutateAsync: returnKitMutate } = useMutation(returnKitMutation());
  const { mutateAsync: editMetadataMutate } = useMutation(
    editMetadataMutation()
  );

  async function add(kit: AddKitParams): Promise<IApiResult<void> | null> {
    return await addKitMutate(
      { kit },
      {
        onSettled: (response, error) => {
          if (error || !response?.success) {
            alert(response?.message || error);
          }
          return response;
        },
      }
    );
  }
  async function edit(kit: UpdateKitParams): Promise<IApiResult<void> | null> {
    return await editKitMutate(
      { kit },
      {
        onSettled: (response, error) => {
          if (error || !response?.success) {
            alert(response?.message || error);
          }
          return response;
        },
      }
    );
  }
  async function returnKit(
    kit: ReturnKitParams
  ): Promise<IApiResult<void> | null> {
    return await returnKitMutate(kit, {
      onSettled: (response, error) => {
        if (error || !response?.success) {
          alert(response?.message || error);
        }
        return response;
      },
    });
  }
  async function editMetadata(
    metadata: MetadataParams
  ): Promise<IApiResult<void> | null> {
    return await editMetadataMutate(metadata, {
      onSettled: (response, error) => {
        if (error || !response?.success) {
          alert(response?.message || error);
        }
        return response;
      },
    });
  }

  return { edit, add, returnKit, editMetadata };
};

export default useKit;
