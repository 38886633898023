import { ChangeEventHandler, forwardRef } from "react";
import Form from "react-bootstrap/Form";
import { FormControlProps } from "react-bootstrap/FormControl";

type BsFormProps = Omit<
  FormControlProps,
  | "bsCustomPrefix"
  | "htmlSize"
  | "plaintext"
  | "value"
  | "custom"
  | "type"
  | "isValid"
  | "bsPrefix"
>;

export type KeyValue = {
  key: number | string;
  value: string;
};

export type SelectInputProps = {
  value?: string | null;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  items?: KeyValue[];
  placeholder?: string;
  className?: string;
} & BsFormProps;

export const SelectInput = forwardRef(
  (
    {
      value,
      items,
      onChange,
      placeholder,
      isInvalid,
      className,
      disabled,
      ...rest
    }: SelectInputProps,
    ref
  ) => {
    return (
      <Form.Label
        as="select"
        className={`app-form-input px-1 app-form-input-dropdown ${className} 
        ${isInvalid ? "invalid" : ""} ${value && "value-selected"} ${disabled && "disabled"}`}
        value={value || "-1"}
        onChange={onChange}
        size="sm"
        ref={ref}
        disabled={disabled}
        {...rest}
      >
        {placeholder && (
          <option value="-1" disabled hidden>
            {placeholder}
          </option>
        )}
        {items?.map((item,index) => (
          <option key={`${item.key}_${index}`} value={item.key}>
            {item.value}
          </option>
        ))}
      </Form.Label>
    );
  }
);
