import { PropsWithChildren, ReactNode } from "react";

import envivoLogo from "../../assets/envivo_logo_sm.png";

import styles from "./BrandCard.module.css";
import { BaseIcon } from "../BaseIcon/BaseIcon";

export type BrandCardProps = {
  className?: string;
  showBottomLogo?: boolean;
  showDivider?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  contentClassName?: string;
};

export const BrandCard = ({
  className,
  contentClassName,
  header,
  footer,
  showBottomLogo = true,
  showDivider,
  children,
}: PropsWithChildren<BrandCardProps>) => {
  return (
    <div
      className={`${
        styles.wrapper
      } position-relative d-flex justify-content-between card p-4 app-shadow app-border ${
        className ?? ""
      }`}
    >
      {header}
      {showDivider && <div className={styles.divider}></div>}
      <div
        className={`p-0 px-sm-5 py-sm-2 z-index-max ${contentClassName ?? ""}`}
      >
        {children}
      </div>
      <footer className="d-flex align-items-center justify-content-between">
        {footer}
        {showBottomLogo && (
          <div className="d-flex justify-content-end flex-grow-1">
            <BaseIcon icon={envivoLogo} className={styles.bottomLogo} />
          </div>
        )}
      </footer>
    </div>
  );
};
