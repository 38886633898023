import { useRef, useState } from "react";
import styles from "./SampleReport.module.css";
import { useQuery } from "@tanstack/react-query";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-router-dom";
import { useForm } from "react-hook-form";
import useTableHelper from "../../../../../../hooks/useTableHelper";
import { filterProcessSamplesQuery } from "../../../../../../queries/sampleReport.query";
import Table, { DataType } from "../../../../../../components/Table/Table";
import FormInput from "../../../../../../components/form/FormInput/FormInput";
import { Button } from "../../../../../../components/Button/Button";
import { studyNamesQuery } from "../../../../../../queries/study.query";
import { getFormattedLocalDate } from "../../../../../../util/date.util";
import { Row } from "react-table";
import EditIcon from "../../../../../../assets/edit.svg";
import SampleProcessModal from "./SampleProcess/SampleProcessModal";
import { sexList } from "../../../../../clinical/ClinicalRow/Metadata/General";
import { convertToCSV } from "../../../../../../util/shared.util";

export type FilterProcessSamplesFormInputs = {
  subjectId: string;
  study: string;
};

const sampleTypes = ["Stool", "Plasma", "Saliva"];

export const columnsNames = [
  "sampleNumber",
  "sampleType",
  "kitSubjectId",
  "suffix",
  "volume",
  "boxBarcode",
  "boxPosition",
  "freezer",
  "rack",
  "links",
  "processingDate",
  "processCollectionDate",
  "temperature",
  "stoolScore",
  "sex",
  "age",
  "weight",
  "height",
  "medicines",
  "conditions",
  "note",
];

const OtherSampleReport = () => {
  const tableRef = useRef<HTMLTableElement>(null);
  const { data: studyNames } = useQuery(studyNamesQuery());
  const { watch, handleSubmit, control } =
    useForm<FilterProcessSamplesFormInputs>();
  const { getColumns } = useTableHelper();
  const intl = useIntl();
  const watchKitSubjectId = watch("subjectId");
  const watchStudyId = watch("study");
  const [selected, setSelected] = useState<any | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);

  const { data: response, refetch } = useQuery(
    filterProcessSamplesQuery({
      kitSubjectId: watchKitSubjectId,
      studyId: watchStudyId,
    })
  );

  const { handleRowActionClick } = useTableHelper();

  const getData = () => {
    if (response?.data) {
      const { data } = response;

      return data!.map((sample) => ({
        ...sample,
        sampleType: sampleTypes[sample.sampleType],
        processCollectionDate: getFormattedLocalDate(
          sample.processCollectionDate
        ),
        processingDate: getFormattedLocalDate(sample.processingDate),
        metadata: undefined,
        age: sample.metadata?.dateOfBirth,
        sex: sexList[sample.metadata?.sex],
        boxBarcode: sample.box?.boxBarcode,
        boxPosition: sample.box?.position,
        freezer: sample.box?.freezerNumber,
        rack: sample.box?.rackNumber,
        box: undefined,
        links: sample.links?.map((link) => link.link).join(", "),
        conditions: sample.metadata?.conditions
          ?.map((cond) => `${cond.key}: ${cond.value}`)
          .join(", "),
        medicines: sample.metadata?.medicine
          ?.map((med) => med.value)
          .join(", "),
        weight: sample.metadata?.weight,
        height: sample.metadata?.height,
        sampleNumber: `${sample.studyLetter}${sample.sampleNumber
          .toString()
          .padStart(4, "0")}${sample.suffix}`,
      }));
    }
    return [];
  };

  const getStudies = () =>
    studyNames?.data?.map((item) => ({
      key: item.id,
      value: item.studyName,
    }));

  const onSubmit = () => {
    const csvData = convertToCSV(getData(), columnsNames);
    const link = document.createElement("a");
    link.setAttribute("href", csvData);
    link.setAttribute(
      "download",
      `table_data_${new Date().toLocaleString()}.csv`
    );
    link.click();
  };

  const buttons = [
    {
      label: (
        <FormattedMessage id="Tm ABLE.BUTTON.EDIT" defaultMessage="Edit" />
      ),
      onClick: (
        setSelectedRow: (row?: Row<DataType>) => void,
        row?: Row<DataType>
      ) => {
        const tableRes = response && {
          ...response,
          data: { items: response?.data ? response.data : [], pageCount: 1 },
        };
        row &&
          handleRowActionClick(
            setShowModal,
            setSelected,
            setSelectedRow,
            row,
            tableRes
          );
      },
      icon: EditIcon,
      disabled: true,
    },
  ];

  return (
    <div className={styles.studies}>
      <div>
        <div>
          <Form
            className="my-2 justify-content-end d-flex"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={styles.mainBox}>
              <div>
                <FormInput
                  type="text"
                  name="subjectId"
                  control={control}
                  input={{
                    placeholder: "SubjectId",
                    className: styles.inputPatient,
                  }}
                />
              </div>
              <div>
                <FormInput
                  type="select"
                  name="study"
                  control={control}
                  input={{
                    placeholder: intl.formatMessage({
                      id: "STUDY",
                      defaultMessage: "Study",
                    }),
                    items: getStudies(),
                    className: styles.inputStudy,
                  }}
                />
              </div>
              <Button
                type="submit"
                variant="outline-dark"
                className="border-dark"
              >
                <FormattedMessage
                  id="ENVIVO.REPORTS.GENERATE"
                  defaultMessage="Generate"
                />
              </Button>
            </div>
          </Form>
        </div>
        <Table
          ref={tableRef}
          columns={getColumns("ENVIVO.REPORTS.TABLE.COLUMN", columnsNames)}
          data={getData()}
          className={styles.table}
          buttons={buttons}
        />
      </div>{" "}
      {showModal && selected && (
        <SampleProcessModal
          {...{
            id: selected.id,
            sampleNumber: selected.sampleNumber,
            sampleType: selected.sampleType.toString(),
            kitSubjectId: selected.kitSubjectId,
            note: selected.note,
            processCollectionDate: selected.processCollectionDate,
            processingDate: selected.processingDate,
            stoolScore: selected.stoolScore,
            suffix: selected.suffix,
            temperature: selected.temperature,
            handleHide: () => setShowModal(false),
            refetch: refetch,
          }}
        />
      )}
    </div>
  );
};

export default OtherSampleReport;
