import { UseMutationOptions } from "@tanstack/react-query";
import { StudyParams, UpdateStudyParams } from "../models/requests/study.request";
import { IApiResult } from "../models/responses/api-result.response";
import { studyService } from "../services/study.service";

export const addStudyMutation = (): UseMutationOptions<
  IApiResult<undefined> | null,
  unknown,
  StudyParams
> => ({
  mutationKey: ["add study"],
  mutationFn: async (study: StudyParams) => {
    const response = await studyService.addStudy(study);
    return response;
  },
});

export const editStudyMutation = (): UseMutationOptions<
  IApiResult<undefined> | null,
  unknown,
  UpdateStudyParams
> => ({
  mutationKey: ["add study"],
  mutationFn: async (study: UpdateStudyParams) => {
    const response = await studyService.editStudy(study);
    return response;
  },
});
