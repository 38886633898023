import { ProcessCapscanApi } from "../api/apis/process-capscan-api";
import { ProcessCapscanParams } from "../models/requests/processCapscan.request";
import { IApiResult } from "../models/responses/api-result.response";
import Logger from "../util/logger.util";


const logger = new Logger({ source: "Process Capscan Service" });

export const processCapscanService = {
  addProcessCapscan: async (
    process: ProcessCapscanParams): Promise<IApiResult<void> | null> => await addProcessCapscan(process),
};

const addProcessCapscan = async (
  process: ProcessCapscanParams
): Promise<IApiResult<void> | null> => {
  const api = new ProcessCapscanApi();
  const {processDate,...processProps} = process;
  const processDateInDateFormat = new Date(processDate);
  const { data: response } = await api.createProcessCapscan({...processProps,processDate:processDateInDateFormat});
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Add process capscan invalid API response", { statusCode });
    return response;
  }
  return response;
};
