import { UseMutationOptions } from "@tanstack/react-query";
import {
  CapscanParams,
  ReturnCapscanParams,
} from "../models/requests/capscan.request";
import { IApiResult } from "../models/responses/api-result.response";
import { CapscanResponse } from "../models/responses/capscan.response";
import { capscanService } from "../services/capscan.service";
import {CapscanDto} from "../api";

export const addCapscanMutation = (): UseMutationOptions<
  IApiResult<CapscanResponse> | null,
  unknown,
  CapscanParams
> => ({
  mutationKey: ["add capscan"],
  mutationFn: async (capscan: CapscanParams) =>
    await capscanService.add(capscan),
});

export const returnCapscanMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  ReturnCapscanParams
> => ({
  mutationKey: ["return capscan"],
  mutationFn: async (capscan: ReturnCapscanParams) =>
    await capscanService.returnCapscan(capscan),
});

export const getCapscanMutation = (): UseMutationOptions<
    IApiResult<CapscanDto> | null,
    unknown,
    string
    > => ({
  mutationKey: ["get capscan"],
  mutationFn: async (id) =>
      await capscanService.getCapscan(id),
});
