export class Constants {
  static readonly JwtKey = "token";
  static readonly UpdateLocalStorageEventKey = "local_storage_update";
  static readonly AuthStorageKey = "authenticated_user";
  static readonly CaptureBoxPosition = "capture_box_position";
  static readonly DeviceImageDimensions = "device_image_dimensions";
  static readonly SampleImageDimensions = "sample_image_dimensions";
}

export class AppsConstants {
  static readonly CLINICAL = "clinical";
  static readonly ENVIVO = "envivo";
  static readonly FACTORY = "factory";
}
export class RouteConstants {
  static readonly AUTH = "auth";
  static readonly LOGIN = "login";
  static readonly CLINICAL = "clinical";
  static readonly ENVIVO = "envivo";
  static readonly KIT_ASSEMBLY = "kit-assembly";
  static readonly DISTRIBUTION = "distribution";
  static readonly PROCESS_CAPSCAN = "process";
  static readonly PROCESS_OTHER_CAPSCAN = "process-other";
  static readonly RETURN_KIT = "return-kit";
  static readonly PROCESS_OTHER = "process-other";
  static readonly TRANSFER = "transfer";
  static readonly STORAGE_LOCATION = "storage-location";
  static readonly CAMERA_SETTINGS = "camera-settings";
  static readonly LINK_DATA = "link-data";
  static readonly SAMPLE = "sample";
  static readonly SAMPLE_REPORTS = "sample-reports";
  static readonly RE_PRINT = "re-print";

  static readonly FACTORY = "factory";
  static readonly APPLICATION_MANAGEMENT = "management"
}
