import { ProcessApi } from "../api/apis/process-api";
import { CreateProcessDto } from "../api/models";
import { IApiResult } from "../models/responses/api-result.response";
import Logger from "../util/logger.util";

const logger = new Logger({ source: "Process Capscan Service" });

export const processOtherService = {
  addProcessOther: async (
    process: CreateProcessDto
  ): Promise<IApiResult<void> | null> => await addProcessOther(process),
};

const addProcessOther = async (
  process: CreateProcessDto
): Promise<IApiResult<void> | null> => {
  const api = new ProcessApi();
  const samples = process.samples.map((sample) => ({
    ...sample,
    processCollectionDate: sample.processCollectionDate,
    processingDate: sample.processingDate,
  }));
  const { data: response } = await api.createProcess({...process, samples});
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Add process capscan invalid API response", { statusCode });
    return response;
  }
  return response;
};
