import { UserRole } from "../util/role.util";

export type AuthenticatedUser = {
  token: string;
  fullName: string;
  email: string;
  role?: UserRole;
  siteId?: string;
};


export enum Role {
  Admin,
  Technician,
  Clinical,
  Factory
}
