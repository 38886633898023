import { useState, useEffect } from "react";
import Webcam from "react-webcam";
import Select, { SingleValue } from "react-select";
import { FormModal } from "../../../../../components/form/FormModal/FormModal";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

type Option = {
  value: string;
  label: string;
};

type CameraType = "box" | "tubes" | "device";

export const BOX_DEVICE = "boxDevice";
export const TUBES_DEVICE = "tubesDevice";
export const DEVICE_DEVICE = "deviceDevice";

const CameraSettings = () => {
  const width = 150;
  const height = 150;
  const [boxCamera, setBoxCamera] = useState<Option>();
  const [tubesCamera, setTubesCamera] = useState<Option>();
  const [deviceCamera, setDeviceCamera] = useState<Option>();

  const [cameraList, setCameraList] = useState<Option[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCameraList = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameras = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setCameraList(
          cameras.map((camera, index) => ({
            value: camera.deviceId,
            label: camera.label || `Camera ${index + 1}`,
          }))
        );
      } catch (error) {
        console.error("Error fetching camera list:", error);
      }
    };
    fetchCameraList();
  }, []);

  const handleHide = () => navigate(-1);

  const handleCameraChange = (
    selectedOption: SingleValue<Option>,
    cameraType: CameraType
  ) => {
    if (selectedOption)
      if (cameraType === "box") {
        setBoxCamera(selectedOption);
      } else if (cameraType === "tubes") {
        setTubesCamera(selectedOption);
      } else {
        setDeviceCamera(selectedOption);
      }
  };

  const handleAccept = () => {
    boxCamera && localStorage.setItem(BOX_DEVICE, boxCamera.value);
    tubesCamera && localStorage.setItem(TUBES_DEVICE, tubesCamera.value);
    deviceCamera && localStorage.setItem(DEVICE_DEVICE, deviceCamera.value);
    handleHide();
  };

  return (
    <FormModal
      show={true}
      handleClose={handleHide}
      header={
        <div>
          <b>
            <FormattedMessage
              id="ENVIVO.CAPSCAN_PROCESS"
              defaultMessage="Process Capscan"
            />
          </b>
        </div>
      }
      footer={
        <>
          <Button
            variant="outline-dark"
            className="border-dark"
            onClick={handleHide}
          >
            <FormattedMessage id="BUTTON.CANCEL" defaultMessage="Cancel" />
          </Button>
          <Button
            variant="outline-dark"
            className="border-dark"
            onClick={handleAccept}
          >
            <FormattedMessage id="BUTTON.ACCEPT" defaultMessage="Accept" />
          </Button>
        </>
      }
      id={"camera-select"}
    >
      <div className="w-75">
        <b className="mt-1 mb-3">Select Camera of Box</b>
        <Select
          className="mt-1 mb-1"
          options={cameraList}
          onChange={(option) => handleCameraChange(option, "box")}
          value={boxCamera}
          placeholder="Select Camera"
        />
        <div style={{ width, height }} className="bg-light">
          {boxCamera && (
            <Webcam
              audio={false}
              videoConstraints={{
                width,
                height,
                deviceId: boxCamera.value,
              }}
            />
          )}
        </div>
      </div>
      <div className="w-75 mt-3">
        <b className="mt-1 mb-3">Select Camera of Tubes</b>
        <Select
          className="mt-1 mb-1"
          options={cameraList}
          onChange={(option) => handleCameraChange(option, "tubes")}
          value={tubesCamera}
          placeholder="Select Camera"
        />
        {tubesCamera ? (
          <Webcam
            audio={false}
            videoConstraints={{
              width,
              height,
              deviceId: tubesCamera.value,
            }}
          />
        ) : (
          <div style={{ width, height }} className="bg-light"></div>
        )}
      </div>
      <div className="w-75 mt-3">
        <b className="mt-1 mb-3">Select Camera of Device</b>
        <Select
          className="mt-1 mb-1"
          options={cameraList}
          onChange={(option) => handleCameraChange(option, "device")}
          value={deviceCamera}
          placeholder="Select Camera"
        />
        {deviceCamera ? (
          <Webcam
            audio={false}
            videoConstraints={{
              width,
              height,
              deviceId: deviceCamera.value,
            }}
          />
        ) : (
          <div style={{ width, height }} className="bg-light"></div>
        )}
      </div>
    </FormModal>
  );
};

export default CameraSettings;
