import { UseMutationOptions } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import Logger from "../util/logger.util";
import { FilterProcessSamplesParams, FilterSamplesParams } from "../models/requests/sampleReport.request";
import { sampleReportService } from "../services/sampleReports.service";
import { FullSampleDataDto, SampleDto } from "../api";

export const filterSampleMutation = (): UseMutationOptions<
  IApiResult<FullSampleDataDto[]> | null,
  unknown,
    FilterSamplesParams
> => ({
  mutationKey: ["filter samples"],
  mutationFn: (filterSample) => sampleReportService.getFilterSample(filterSample),
  onSuccess: () => {
    Logger.info("Filter samples done successfully");
  },
});

export const filterProcessSampleMutation = (): UseMutationOptions<
    IApiResult<SampleDto[]> | null,
    unknown,
    FilterProcessSamplesParams
    > => ({
  mutationKey: ["filter process samples"],
  mutationFn: (filterProcessSample) => sampleReportService.getFilterProcessSample(filterProcessSample),
  onSuccess: () => {
    Logger.info("Filter process samples done successfully");
  },
});
