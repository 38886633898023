import { forwardRef, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { FormGroup } from "../components/form/FormGroup/FormGroup";
import FormInput from "../components/form/FormInput/FormInput";
import { useWizardContext } from "../context/WizardContext";
import { StudyResponse } from "../models/responses/study.response";
import useStudy from "./useStudy";
import { SubjectDetailsType } from "../pages/clinical/ClinicalRow/Metadata/MetaData";
import { makeDirtyObject } from "../util/form.util";

export type GeneralDetailsForm = {
  letter: string;
  studyName: string;
  protocolNumber: string;
  protocolFullName: string;
  protocolShortName: string;
};

type GeneralDetailsFormKeys = keyof GeneralDetailsForm;

export type SubjectDetailsForm = {
  subjectDetails: { label: string; key: SubjectDetailsType; value: boolean }[];
};

export type MoreQuestionsForm = {
  jsonQuestions: string;
};

const useStudyWizard = (
  handleHide: () => void,
  refetch: () => void,
  study?: StudyResponse
) => {
  const steps = [
    { label: "General Details", content: <GeneralDetails study={study} /> },
    {
      label: "Subject Details",
      content: <SubjectDetails />,
    },
    {
      label: "More Questions",
      content: (
        <MoreQuestions
          handleHide={handleHide}
          refetch={refetch}
          study={study}
        />
      ),
    },
  ];
  return { steps };
};

export default useStudyWizard;

type GeneralDetailsProps = {
  study?: StudyResponse;
};
const GeneralDetails = forwardRef(({ study }: GeneralDetailsProps) => {
  const intl = useIntl();
  const { control, trigger, setValue } = useFormContext<
    GeneralDetailsForm & SubjectDetailsForm
  >();

  const {
    methods: { handleStep },
  } = useWizardContext();

  useEffect(() => {
    if (study) {
      const { subjectDetails, ...rest } = study;
      Object.keys(rest).forEach((key) => {
        const convertedKey = key as unknown as GeneralDetailsFormKeys;
        setValue(convertedKey, study[convertedKey], {
          shouldDirty: false,
        });
      });
    }
  }, [setValue, study]);

  handleStep(async () => {
    const isValid = await trigger([
      "letter",
      "studyName",
      "protocolNumber",
      "protocolFullName",
      "protocolShortName",
    ]);
    if (!isValid) {
      throw new Error("general details inputs are invalid");
    }
  });

  return (
    <div className="d-flex flex-column justify-content-center">
      <FormGroup
        breakpoint="xs"
        input={
          <FormInput
            type="text"
            name="letter"
            control={control}
            rules={{ required: true }}
            input={{
              placeholder: intl.formatMessage({
                id: "ENVIVO.STUDY.ADD.GENERAL.STUDY_LETTER",
                defaultMessage: "Study letter",
              }),
            }}
          />
        }
      />
      <FormGroup
        breakpoint="sm"
        input={
          <FormInput
            type="text"
            name="studyName"
            control={control}
            rules={{ required: true }}
            input={{
              placeholder: intl.formatMessage({
                id: "ENVIVO.STUDY.ADD.GENERAL.STUDY_NAME",
                defaultMessage: "Study Name",
              }),
            }}
          />
        }
      />
      <FormGroup
        breakpoint="sm"
        input={
          <FormInput
            type="text"
            name="protocolNumber"
            control={control}
            rules={{ required: true }}
            input={{
              placeholder: intl.formatMessage({
                id: "ENVIVO.STUDY.ADD.GENERAL.PROTOCOL_NUMBER",
                defaultMessage: "Protocol Number",
              }),
            }}
          />
        }
      />
      <FormGroup
        breakpoint="sm"
        input={
          <FormInput
            type="text"
            name="protocolFullName"
            control={control}
            rules={{ required: true }}
            input={{
              type: "textarea",
              placeholder: intl.formatMessage({
                id: "ENVIVO.STUDY.ADD.GENERAL.PROTOCOL_FULL_NAME",
                defaultMessage: "Protocol Full Name",
              }),
            }}
          />
        }
      />
      <FormGroup
        breakpoint="sm"
        input={
          <FormInput
            type="text"
            name="protocolShortName"
            control={control}
            rules={{ required: true }}
            input={{
              type: "textarea",
              placeholder: intl.formatMessage({
                id: "ENVIVO.STUDY.ADD.GENERAL.PROTOCOL_SHORT_NAME",
                defaultMessage: "Protocol Short Name",
              }),
            }}
          />
        }
      />
    </div>
  );
});

const SubjectDetails = forwardRef(() => {
  const { control, register } = useFormContext<SubjectDetailsForm>();

  const { fields } = useFieldArray({
    control,
    name: "subjectDetails",
  });

  return (
    <div>
      {fields.map(({ label, key }, index) => {
        return (
          <div key={index}>
            <FormGroup
              className="d-flex ms-5 h-25"
              groupDescription={
                <label htmlFor={label} className="pt-1 mt-3 ms-2">
                  <FormattedMessage
                    id={`ENVIVO.STUDY.ADD.SUBJECT.${key}`}
                    defaultMessage={label}
                  />
                </label>
              }
              input={
                <FormInput
                  type="checkbox"
                  {...register(`subjectDetails.${index}.value`)}
                />
              }
            />
          </div>
        );
      })}
    </div>
  );
});

type LastStepProps = {
  handleHide: () => void;
  refetch: () => void;
  study?: StudyResponse;
};

const MoreQuestions = ({ handleHide, refetch, study }: LastStepProps) => {
  const {
    control,
    formState: { dirtyFields, errors },
    reset,
    handleSubmit,
    setError,
    getFieldState,
  } = useFormContext<
    GeneralDetailsForm & SubjectDetailsForm & MoreQuestionsForm
  >();
  const intl = useIntl();
  const { add, edit } = useStudy();

  const onSubmit = async (
    formValues: GeneralDetailsForm & SubjectDetailsForm & MoreQuestionsForm
  ) => {
    if (formValues.jsonQuestions) {
      try {
        JSON.parse(formValues.jsonQuestions);
      } catch (e) {
        setError("jsonQuestions", { message: "Invalid JSON" });
        return;
      }
    }
    const details: string[] = [];
    formValues.subjectDetails.map(
      (subject) => subject.value && details.push(subject.key)
    );
    if (study) {
      const dirtyState = makeDirtyObject(
        (key) => getFieldState(key).isDirty,
        formValues
      );
      const response = await edit({
        id: study.id,
        ...dirtyState,
        ...(dirtyState.subjectDetails && { subjectDetails: details }),
      });
      if (response?.success) {
        refetch();
        reset();
        handleHide();
      }
    } else {
      const response = await add({
        ...formValues,
        subjectDetails: details,
      });
      if (response?.success) {
        refetch();
        reset();
        handleHide();
      }
    }
  };

  const {
    methods: { handleStep },
  } = useWizardContext();

  handleStep(async () => {
    handleSubmit(onSubmit)();
  });

  return (
    <FormGroup
      input={
        <FormInput
          type="text"
          name="jsonQuestions"
          control={control}
          input={{
            type: "textarea",
            placeholder: intl.formatMessage({
              id: "ENVIVO.STUDY.ADD.GENERAL.JSON",
              defaultMessage: "Paste JSON content here",
            }),
          }}
        />
      }
      validation={errors.jsonQuestions?.message}
    />
  );
};
