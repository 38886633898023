import { FieldValues } from "react-hook-form";

export const makeDirtyObject = <T extends FieldValues>(
  isDirty: (key: keyof T) => boolean,
  formValues: T
) : T => {
  let ret = Object.keys(formValues)
    .filter((key) => isDirty(key))
    .map((key) => [key, formValues[key as keyof T]]);
  return Object.fromEntries(ret);
};

export const resetObject = <T>(obj: any) => {

 let ret =  Object.assign({},obj);
  Object.keys(ret).forEach((key) => {
     
      switch (typeof obj[key]) {
        case "number": {
          ret[key]  = NaN;
          break;
        }
        case "string": {
          ret[key]  = "";
          break;
        }
        case "boolean": {
          ret[key]  = "";
          break;
        }
        case "object": {
          ret[key]  = null
          break;
        
      }
    };

     
  });
  return ret as T;
};
