import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { usersQuery } from "../../../../queries/user.query";
import { Tab, Tabs } from "react-bootstrap";
import styles from "./UsersTable.module.css";
import UsersIcon from "../../../../assets/users.svg";
import Status, { statuses } from "../../../../components/Status/Status";
import AddIcon from "../../../../assets/add.svg";
import EditIcon from "../../../../assets/edit.svg";
import DeleteIcon from "../../../../assets/delete.svg";
import { BaseIcon } from "../../../../components/BaseIcon/BaseIcon";
import useTableHelper from "../../../../hooks/useTableHelper";
import Table, { DataType } from "../../../../components/Table/Table";
import { User } from "./User/User";
import { Row } from "react-table";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import useUser from "../../../../hooks/useUser";
import { UpdateUserParams } from "../../../../models/requests/user.request";
import { getUserRoleName } from "../../../../util/role.util";

const columnsNames = [
  "fullName",
  "email",
  "role",
  "siteName",
  "phone",
  "address",
  "status",
];

 

type TableType =  "users";

export default function UsersTable() {
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UpdateUserParams | undefined>();

  const [usersPageNumber, setUsersPageNumber] = useState(0);
  const {
    isLoading,
    isError,
    data: users,
    refetch,
  } = useQuery(usersQuery({ pageNumber: usersPageNumber }));
   
  const { deleteUser } = useUser();

  const getButtons = (tableType: TableType) => {
    return [
      {
        label: <FormattedMessage id="TABLE.BUTTON.ADD" defaultMessage="Add" />,
        onClick: () => (tableType === "users" ? setShowModal(true) : {}),
        icon: AddIcon,
      },
      {
        label: (
          <FormattedMessage id="TABLE.BUTTON.EDIT" defaultMessage="Edit" />
        ),
        onClick: (
          setSelectedRow: (row?: Row<DataType>) => void,
          row?: Row<DataType>
        ) => {
          row &&
            handleRowActionClick(
              setShowModal,
              setSelectedUser,
              setSelectedRow,
              row,
              users
            );
        },
        icon: EditIcon,
        disabled: true,
      },
      {
        label: (
          <FormattedMessage id="TABLE.BUTTON.DELETE" defaultMessage="DELETE" />
        ),
        onClick: (
          setSelectedRow: (row?: Row<DataType>) => void,
          row?: Row<DataType>
        ) => {
          row &&
            handleRowActionClick(
              setShowDelete,
              setSelectedUser,
              setSelectedRow,
              row,
              users
            );
        },
        icon: DeleteIcon,
        disabled: true,
      },
    ];
  };

  
  const getUsersData = () => {
    return users?.data?.items?.map((item) => {
      return {
        ...item,
        email: item.userName,
        role: getUserRoleName(Number(item.role)),
        status: (
          <Status
            status={item.isActive ? statuses.ACTIVE : statuses.SUSPENDED}
          />
        ),
      };
    });
  };

  

  const { getColumns, handleRowActionClick } = useTableHelper();

  return (
    <div className={styles.users}>
      <Tabs
        defaultActiveKey="users"
        className="mb-3 d-flex justify-content-center"
      >
        <Tab
          eventKey="users"
          title={
            <div>
              <BaseIcon icon={UsersIcon ?? ""} />
              <FormattedMessage
                id="ENVIVO.USERS.TABLE.USERS"
                defaultMessage={"Users"}
              />
            </div>
          }
        >
          <Table
            columns={getColumns("ENVIVO.USERS.TABLE.COLUMN", columnsNames)}
            data={getUsersData()}
            pageCount={users?.data?.pageCount || 0}
            isLoading={isLoading}
            isError={isError}
            onPaginate={(page: number) => setUsersPageNumber(page)}
            buttons={getButtons("users")}
          />
          {showModal && (
            <User
              show={showModal}
              handleHide={() => {
                setShowModal(false);
                setSelectedUser(undefined);
              }}
              user={selectedUser}
              refetch={refetch}
            />
          )}
          {showDelete && (
            <DeleteModal
              show={showDelete}
              handleClose={() => setShowDelete(false)}
              InternationalizationKey="ENVIVO.USERS.DELETE_USER"
              handleDelete={async () => {
                setShowDelete(false);
                const response = await deleteUser(selectedUser?.id!);
                response?.success && refetch();
                setSelectedUser(undefined);
              }}
            />
          )}
        </Tab>
     
      </Tabs>
    </div>
  );
}
