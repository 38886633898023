import { FormattedMessage } from "react-intl";
import { Tab, Tabs } from "react-bootstrap";
import ProcessIcon from "../../../../../assets/process_samples.svg";
import ProcessCapscanIcon from "../../../../../assets/process_capscan.svg";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import ReportTable from "./CapscanReport/CapscanReportTable";
import ProcessReport from "./SampleReport/OtherSampleReport";
import styles from "./Report.module.css";

export default function Report() {

    return (
        <div>
            <Tabs
                defaultActiveKey="capscan"
                className="mb-3 d-flex justify-content-center"
            >
                <Tab
                    eventKey="capscan"
                    title={
                        <div className={styles.tabInfo}>
                            <BaseIcon icon={ProcessCapscanIcon ?? ""} />
                            <FormattedMessage
                                id="CapscanReport"
                                defaultMessage={"Capscan Report"}
                            />
                        </div>
                    }
                >
                    <ReportTable />
                </Tab>
                <Tab
                    eventKey="process"
                    title={
                        <div className={styles.tabInfo}>
                            <BaseIcon icon={ProcessIcon ?? ""} />
                            <FormattedMessage
                                id="OtherSamplesReport"
                                defaultMessage={"Other Samples Report"}
                            />
                        </div>
                    }
                >
                    <ProcessReport />
                </Tab>

            </Tabs>
        </div>
    );
}
