import { UseMutationOptions } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import Logger from "../util/logger.util";
import { sampleProcessService } from "../services/sampleProcess.service";
import { UpdateSampleDto } from "../api";

export const updateCapscanSampleMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  UpdateSampleDto
> => ({
  mutationKey: ["Update sample"],
  mutationFn: (sample: UpdateSampleDto) =>
    sampleProcessService.updateSampleProcess(sample),
  onSuccess: () => {
    Logger.info("Update sample done successfully");
  },
});
