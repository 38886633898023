import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import useKit from "../../../../hooks/useKit";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "@tanstack/react-query";
import { kitTypeWithStudiesQuery } from "../../../../queries/kitType.query";
import { BaseIcon } from "../../../../components/BaseIcon/BaseIcon";
import { FormModal } from "../../../../components/form/FormModal/FormModal";
import { FormGroup } from "../../../../components/form/FormGroup/FormGroup";
import FormInput from "../../../../components/form/FormInput/FormInput";
import { useNavigate } from "react-router";
import KitAssemblyIcon from "../../../../assets/kit_assembly.svg";

type KitFormParam = {
  kitBarcode: string;
  studyId: string;
  capscans: KitCapscan[] | undefined;
  ifuRevision: string;
};

type KitCapscan = {
  id?: string;
  sn: string;
  type: string;
};

export const KitAssembly = () => {
  const intl = useIntl();
  const { control, handleSubmit, reset, setValue, watch } =
    useForm<KitFormParam>();

  const capscansArray = useFieldArray({ name: "capscans", control: control });
  const { add } = useKit();
  const navigate = useNavigate();

  const kitBarcode = watch("kitBarcode");
  const studyId = watch("studyId");

  const { data: kitTypesWithNames } = useQuery(kitTypeWithStudiesQuery());

  const getStudiesList = () =>
    kitTypesWithNames?.data?.map((item) => ({
      key: item.studyId,
      value: `${item.protocolNumber} ${item.studyName}`,
    }));

  const getKitType = (studyId: string) =>
    kitTypesWithNames?.data?.find((item) => item.studyId === studyId);

  const getCapscansFromKitType = (
    capscans: { type: string; samplingTime: number }[]
  ): { sn: string; type: string }[] => {
    return capscans.map((capscan) => ({ sn: "", ...capscan }));
  };

  useEffect(() => {
    if (studyId) {
      const kitType = getKitType(studyId);
      setValue("ifuRevision", kitType?.ifuRevision!);
      setValue("capscans", getCapscansFromKitType(kitType?.capscans!));
    }
  }, [studyId]);

  const onSubmit = async (kitFormValues: KitFormParam | undefined) => {
    
    if (kitFormValues && kitFormValues.capscans?.length) {
      let res = await add({
        kitBarcode: kitFormValues.kitBarcode,
        kitTypeId: getKitType(kitFormValues.studyId)?.id || "",
        capscans: kitFormValues.capscans!.map((capscan) => ({
          sn: capscan.sn,
          id: capscan.id,
          reference: capscan.type,
        })),
      });
      if (res?.success) {
        reset({
          kitBarcode: "",
          studyId: kitFormValues.studyId,
          ifuRevision: kitFormValues.ifuRevision,
          capscans: getCapscansFromKitType(
            getKitType(kitFormValues.studyId)!.capscans
          ),
        });
      }
    }
  };

  return (
    <FormModal
      onSubmit={handleSubmit(onSubmit)}
      className="modal-lg"
      id="form"
      header={
        <div>
          <BaseIcon icon={KitAssemblyIcon} className="w-25" />{" "}
          <FormattedMessage
            id="ENVIVO.KIT_ASSEMBLY"
            defaultMessage="Kit Assembly"
          />
        </div>
      }
      footer={
        <>
          <Button
            type="submit"
            variant="outline-dark"
            className="border-dark"
            form="form"
          >
            <FormattedMessage
              id={"ENVIVO.MODAL.SAVE"}
              defaultMessage={"Save"}
            />
          </Button>
          <Button variant="outline-dark" onClick={() => navigate(-1)}>
            <FormattedMessage id="ENVIVO.MODAL.EXIT" defaultMessage="Exit" />
          </Button>
        </>
      }
      show={true}
      handleClose={() => navigate(-1)}
    >
      <div>
        <div className="form-group row">
          <FormGroup
            className="col"
            groupDescription={intl.formatMessage({
              id: "ENVIVO.KIT_ASSEMBLY.SCAN_KIT_NUMBER",
              defaultMessage: "Scan Kit Number",
            })}
            input={
              <FormInput
                type="text"
                name="kitBarcode"
                control={control}
                rules={{
                  required: true,
                }}
                input={{
                  type: "text",
                  placeholder: intl.formatMessage({
                    id: "ENVIVO.KIT_ASSEMBLY.KIT_NUMBER",
                    defaultMessage: "Kit Number",
                  }),
                }}
              />
            }
          />

          <FormGroup
            className="col"
            groupDescription={
              <FormattedMessage
                id="ENVIVO.KIT_ASSEMBLY.STUDY_NAME"
                defaultMessage="Study Name"
              />
            }
            input={
              <FormInput
                type="select"
                name="studyId"
                control={control}
                rules={{
                  required: true,
                }}
                input={{
                  items: getStudiesList(),
                  placeholder: intl.formatMessage({
                    id: "ENVIVO.KIT_ASSEMBLY.STUDY_NAME",
                    defaultMessage: "Study Name",
                  }),
                  disabled: !kitBarcode,
                }}
              />
            }
          />
          <FormGroup
            className="col"
            groupDescription={
              <FormattedMessage
                id="ENVIVO.KIT_ASSEMBLY.IFU"
                defaultMessage="IFU"
              />
            }
            input={
              <FormInput
                control={control}
                type="text"
                name="ifuRevision"
                input={{
                  placeholder: intl.formatMessage({
                    id: "ENVIVO.KIT_ASSEMBLY.IFU",
                    defaultMessage: "IFU",
                  }),
                  disabled: true,
                }}
              />
            }
          />
        </div>
        <div className="d-flex mt-2">
          <b>
            {" "}
            <FormattedMessage
              id="ENVIVO.KIT_ASSEMBLY.KIT_DEVICES"
              defaultMessage="Kit Devices"
            />
          </b>
          <div className="ms-5">
            {" "}
            <FormattedMessage
              id="ENVIVO.KIT_ASSEMBLY.SCAN_CAPSCANS_EXPLANATION"
              defaultMessage="Scan CapScan SN of devices that will be added to kit"
            />
          </div>
        </div>

        {capscansArray.fields?.map((field, index) => {
          return (
            <div key={field.id} className="form-group row border mb-2">
              <FormGroup
                className="col"
                groupDescription={
                  <div className="fs-6 mb-2">
                    <FormattedMessage
                      id="ENVIVO.KIT_ASSEMBLY.SCAN_CAPSCAN"
                      defaultMessage="Scan CapScan"
                    />
                  </div>
                }
                input={
                  <FormInput
                    name={`capscans.${index}.sn`}
                    type="text"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    input={{
                      type: "text",
                      placeholder: intl.formatMessage({
                        id: "ENVIVO.KIT_ASSEMBLY.CPASCAN_SN",
                        defaultMessage: "CapScan SN",
                        description: "Scan CapScan",
                      }),
                    }}
                  />
                }
              />
              <FormGroup
                className="col"
                input={
                  <FormInput
                    type="text"
                    name={`capscans.${index}.type`}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    input={{
                      placeholder: intl.formatMessage({
                        id: "ENVIVO.KIT_ASSEMBLY.CPASCAN_TYPE",
                        defaultMessage: "Type",
                      }),
                      disabled: true,
                    }}
                  />
                }
              />
              <br></br>
              <br></br>
              <br></br>
            </div>
          );
        })}
      </div>
    </FormModal>
  );
};
