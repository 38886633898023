import { useMutation } from "@tanstack/react-query";
import {
  addKitTypeMutation,
  deleteKitTypeMutation,
  editKitTypeMutation,
} from "../mutations/kitType.mutation";
import {
  KitTypeParams,
  UpdateKitTypeParams,
} from "../models/requests/kitType.request";
import { IApiResult } from "../models/responses/api-result.response";

const useKitType = () => {
  const { mutateAsync: addKitType } = useMutation(addKitTypeMutation());
  const { mutateAsync: editKitType } = useMutation(editKitTypeMutation());
  const { mutateAsync: deleteKitType } = useMutation(deleteKitTypeMutation());

  async function add(kitType: KitTypeParams): Promise<IApiResult<void> | null> {
    return await addKitType(kitType, {
      onSuccess: (response) => {
        if (!response?.success) {
          alert(response?.message);
        }
        return response;
      },
    });
  }

  async function edit(
    kitTyp: UpdateKitTypeParams
  ): Promise<IApiResult<void> | null> {
    return await editKitType(
      { kitType: kitTyp },
      {
        onSuccess: (response) => {
          if (!response?.success) {
            alert(response?.message);
          }
          return response;
        },
      }
    );
  }

  async function remove(id: string): Promise<IApiResult<void> | null> {
    return await deleteKitType(id, {
      onSuccess: (response) => {
        if (!response?.success) {
          alert(response?.message);
        }
        return response;
      },
    });
  }

  return { add, edit, remove };
};

export default useKitType;
