import { IApiResult } from "../models/responses/api-result.response";
import Logger from "../util/logger.util";
import { LinkDataParams } from "../models/requests/linkData.request";
import { DataLinkResponse } from "../models/responses/dataLink.response";
import { DataLinkApi } from "../api/apis/data-link-api";

const logger = new Logger({ source: "linkData Service" });

export const linkDataService = {
 
  linkData: async (linkData: LinkDataParams): Promise<IApiResult<DataLinkResponse> | null> =>
    await processLinkData(linkData),
};

 
const processLinkData = async (linkData: LinkDataParams): Promise<IApiResult<DataLinkResponse> | null> => {
  logger.info(`link data`);
  const api = new DataLinkApi();
  const { data: response } = await api.createDataLink(linkData)
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Delete User invalid API response", { statusCode });
    return response;
  }
  return response;
};
