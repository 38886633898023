import { useMutation } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import { addVendorMutation, deleteVendorMutation, editVendorMutation } from "../mutations/vendor.mutation";
import { UpdateVendorParams, VendorParams } from "../models/requests/vendor.request";
import { VendorResponse } from "../models/responses/vendor.response";
import {VendorDto} from "../api";


function useVendor() {
  const { mutateAsync: mutateAddVendor } = useMutation(addVendorMutation());
  const { mutateAsync: mutateEditVendor } = useMutation(editVendorMutation());
  const { mutateAsync: mutateDeleteVendor } = useMutation(deleteVendorMutation());

  async function addVendor(vendor: VendorParams): Promise<IApiResult<VendorDto> |null>  {
  return  await mutateAddVendor(vendor, {
      onSuccess: (response) => {
        if (!response?.success) {
          alert(response?.message);
        }
        return response!;
      },
    });
  }

  async function editVendor(
    vendor: UpdateVendorParams): Promise<IApiResult<void> |null> {
   return await mutateEditVendor(
     { vendor },
     {
       onSuccess: (response) => {
         if (!response?.success) {
           alert(response?.message);
         }
         return response;
       },
     }
   );
  }

  async function deleteVendor(id: string): Promise<IApiResult<void> | null> {
    return await mutateDeleteVendor(id, {
      onSuccess: (response) => {
        if (!response?.success) {
          alert(response?.message);
        }
        return response
      },
    });
  }

  return {
    addVendor,
    editVendor,
    deleteVendor
  };
}

export default useVendor;
