import { useMutation } from "@tanstack/react-query";
import {addClinicDepartmentMutation, editClinicDepartmentMutation} from "../../../../../mutations/site.mutation";
import { Footer } from "./TreeNode";
import ClinicDepartmentIcon from "../../../../../assets/sub_clinic.svg";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { ClinicDepartmentParams } from "../../../../../models/requests/site.request";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { FormModal } from "../../../../../components/form/FormModal/FormModal";
import { FormGroup } from "../../../../../components/form/FormGroup/FormGroup";
import FormInput from "../../../../../components/form/FormInput/FormInput";
import {ClinicInfoProps} from "./Clinic";
import {ClinicDepartmentsInfo} from "./ClinicDepartment";

type ClinicDepartmentProps = {
    clinicDepartment?: ClinicDepartmentsInfo;
    refetch: () => void;
    show: boolean;
    setShow: (show: boolean) => void;
    clinic?: ClinicInfoProps;
};

const ClinicDepartmentModal = ({ clinic, clinicDepartment, refetch, show, setShow }: ClinicDepartmentProps) => {
  const { mutateAsync: mutateAddClinicDepartment } = useMutation(
    addClinicDepartmentMutation()
  );
  const { mutateAsync: mutateEditClinicDepartment } = useMutation(editClinicDepartmentMutation());
  const intl = useIntl();
  const { control, handleSubmit, reset } = useForm<ClinicDepartmentParams>();

  const header = (
    <header>
      <BaseIcon icon={ClinicDepartmentIcon} />{" "}
      <b>
        {clinicDepartment?.name} {">"}{" "}
        <FormattedMessage
          id={clinicDepartment ? "ENVIVO.SITES.SUB_CLINIC.EDIT" : "ENVIVO.SITES.SUB_CLINIC.ADD"}
          defaultMessage={clinicDepartment ? "Edit Sub Clinic" : "Add Sub Clinic"}
        />
      </b>
    </header>
  );

  const onSubmit = async ({
    name,
    shipmentAddress,
  }: ClinicDepartmentParams) => {
    if (clinic) {
        await mutateAddClinicDepartment(
            {name, shipmentAddress, clinicId: clinic.id},
            {
                onSuccess: (response) => {
                    if (response) {
                        if (!response.success) alert(response.message);
                        else {
                            reset();
                            refetch();
                            setShow(false);
                        }
                    }
                },
            }
        );
    } else if(clinicDepartment) {
        await mutateEditClinicDepartment(
            { clinicDep: {
                id: clinicDepartment.id,
                name,
                shipmentAddress,
                parentId: clinicDepartment.parentId,
               
            } },
            {
                onSuccess: (response) => {
                    if (response) {
                        if (!response.success) alert(response.message);
                        else {
                            reset();
                            refetch();
                            setShow(false);
                        }
                    }
                },
            }
        );
    }
  };

  return (
          <FormModal
            show={show}
            header={header}
            footer={<Footer onClick={() =>
                setShow(false)}
                form={"clinicDepartment"}
                clinicDepartments={clinicDepartment}
            />}
            id="clinicDepartment"
            handleClose={() => setShow(false)}
            className="modal-lg mt-5"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mb-5 pb-5 w-50 m-auto">
              <FormGroup
                input={
                  <FormInput
                    defaultValue={clinicDepartment?.name}
                    type="text"
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    input={{
                      placeholder: intl.formatMessage({
                        id: "ENVIVO.SITES.SUB_CLINIC.NAME",
                        defaultMessage: "Name",
                      }),
                    }}
                  />
                }
              />
              <FormGroup
                input={
                  <FormInput
                    defaultValue={clinicDepartment?.shipmentAddress}
                    type="text"
                    name="shipmentAddress"
                    control={control}
                    rules={{ required: true }}
                    input={{
                      placeholder: intl.formatMessage({
                        id: "ENVIVO.SITES.SUB_CLINIC.SHIPMENT_ADDRESS",
                        defaultMessage: "Shipment Address",
                      }),
                    }}
                  />
                }
              />
            </div>
          </FormModal>
  );
};

export default ClinicDepartmentModal;
