import { useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { FormattedMessage } from "react-intl";
import { Button } from "../../../../../components/Button/Button";
import CameraIcon from "../../../../../assets/photoshoot.svg";
import CompleteddIcon from "../../../../../assets/completed.svg";
import CameraOffIcon from "../../../../../assets/camera_off.svg";
import BoxOcr from "./BoxOcr";
interface ImageCaptureProps {
  width: number;
  height: number;
  disabled: boolean;
  handleCapture: (src: string) => void;
  ocrResult: (src: string, x: number, y: number) => void;
  handleCaptureStart?: () => void;
  imageUrl?: string;
  deviceId?: string | null;
  className?: string;
  scale?: number;
  boxSize: number;
}

const BoxCapture = ({
  width,
  height,
  disabled,
  handleCapture,
  handleCaptureStart,
  imageUrl,
  deviceId,
  className,
  scale,
  ocrResult,
  boxSize
}: ImageCaptureProps) => {
  const webcamRef = useRef<Webcam | null>(null);
  const [capturedImage, setCapturedImage] = useState<string | undefined>(
    imageUrl
  );
  const [isCaptureClicked, setIsCaptureClicked] = useState(false);
  const [mediaError, setMediaError] = useState<string | DOMException>("");
  const [showImageOCR, setShowImageOCR] = useState<boolean>(false);

  useEffect(() => {
    setCapturedImage(imageUrl);
    setIsCaptureClicked(false);
  }, [imageUrl]);

  const captureImage = async () => {
    if (!isCaptureClicked) {
      setIsCaptureClicked(true);
    }
    const imageSrc = webcamRef.current?.getScreenshot();

    if (!imageSrc) {
      handleCaptureStart && handleCaptureStart();
      return;
    }
    setCapturedImage(imageSrc || undefined);
    imageSrc && handleCapture(imageSrc);
     
  };

  const Cammera = () => {
    if (capturedImage) {
      return <></>;
    } else {
      if (isCaptureClicked && !disabled && !mediaError) {
        return (
          <>
            <Webcam
              muted={false}
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                width: width * (scale || 1),
                height: height * (scale || 1),
                deviceId: deviceId || undefined,
              }}
              style={{ position: "absolute", zIndex: -1 }}
              onUserMediaError={setMediaError}
            />
            <Webcam
              muted={false}
              audio={false}
              screenshotFormat="image/png"
              videoConstraints={{
                width: width,
                height: height,
                deviceId: deviceId || undefined,
              }}
              onUserMediaError={setMediaError}
            />
          </>
        );
      } else {
        return (
          <div
            style={{ width, height }}
            className="bg-light ms-2 mt-3 d-flex flex-column align-items-center pt-5"
          >
            {mediaError && (
              <>
                <BaseIcon icon={CameraOffIcon} />{" "}
                <FormattedMessage
                  id="ENVIVO.CAPSCAN_PROCESS.CAMERA_OFF"
                  defaultMessage="Camera Off"
                />
              </>
            )}
          </div>
        );
      }
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex align-items-center gap-4">
        <Button
          variant="outline-dark"
          className="border rounded-4  mb-2"
          onClick={captureImage}
          size="sm"
          disabled={disabled}
        >
          <BaseIcon icon={CameraIcon} />{" "}
          <FormattedMessage
            id="ENVIVO.CAPSCAN_PROCESS.CAPTURE"
            defaultMessage="Capture"
          />
        </Button>
        <Button
          variant="outline-dark"
          className="border rounded-4 mb-2"
          onClick={() => setShowImageOCR(true)}
          size="sm"
          disabled={!!!capturedImage}
        >
          <BaseIcon icon={CompleteddIcon} />{" "}
        </Button>
      </div>
       
      {capturedImage && showImageOCR && (
        <BoxOcr
          boxSize={boxSize}
          handleClose={() => setShowImageOCR(false)}
          ocrResult={ocrResult}
          show={showImageOCR}
          image={capturedImage}
        />
      )}
      <Cammera />
      {capturedImage && (
        <img
          width={width}
          height={height}
          className={`ms-2 ${className}`}
          src={capturedImage || ""}
          alt="Captured"
        />
      )}
    </div>
  );
};

export default BoxCapture;
