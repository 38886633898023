import { useMutation } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import { addShipmentMutation, editShipmentMutation, exportShipmentMutation } from "../mutations/shipment";
import { ShipmentParams, UpdateShipmentParams } from "../models/requests/shipment.request";

const useShipment = () => {
  const { mutateAsync: mutateAddShipment } = useMutation(addShipmentMutation());
  const { mutateAsync: mutateEditShipment } = useMutation(
    editShipmentMutation()
  );
  const { mutateAsync: mutateExportShipment } = useMutation(exportShipmentMutation())

  async function addShipment(
    shipment: ShipmentParams
  ): Promise<IApiResult<void> | null> {
    return await mutateAddShipment(shipment, {
      onSuccess: (response) => {
        if (!response?.success) {
          alert(response?.message);
        }
        return response;
      },
    });
  }

  async function editShipment(
    shipment: UpdateShipmentParams
  ): Promise<IApiResult<void> | null> {
    return await mutateEditShipment(shipment, {
      onSuccess: (response) => {
        if (!response?.success) {
          alert(response?.message);
        }
        return response;
      },
    });
  }

  async function exportShipment(
    shipmentNumber: string
  ): Promise<IApiResult<string> | null> {
    return await mutateExportShipment(shipmentNumber, {
      onSuccess: (response) => {
        if (!response?.success) {
          alert(response?.message);
        }
        return response;
      },
    });
  }
  return {  editShipment, addShipment , exportShipment};
};

export default useShipment;
