import { Control } from "react-hook-form";
import ProcessOther from "../../envivo/Home/After/ProcessOther/ProcessOther";
import { bodyItems } from "../../envivo/Home/After/ProcessOther/ProcessSample/ProcessSample";
import EditableCell from "../../../components/EditTable/EditableCell/EditableCell";
import { ClinicalProps } from "./ClinicalRow";
import { CapScan, KitResponse } from "../../../models/responses/kit.response";
import {
  getFormattedLocalDate,
} from "../../../util/date.util";
import AddIcon from "../../../assets/add.svg";
import EditIcon from "../../../assets/edit.svg";
import { BaseIcon } from "../../../components/BaseIcon/BaseIcon";
import { useState } from "react";
import { KitSampleDto } from "../../../api";
import SampleProcessModal from "../../envivo/Home/More/Reports/SampleReport/SampleProcess/SampleProcessModal";
import styles from "../ClinicalTable.module.css";
import { getCapscanColor } from "../../../util/shared.util";

type OtherSamplesSubTableProps = {
  kitItem: KitResponse;
  capscans: CapScan[];
  control: Control<ClinicalProps, any>;
  refetch: () => void; 
};

export const OtherSamplesSubTable = ({
  kitItem,
  capscans,
  control,
  refetch
}: OtherSamplesSubTableProps) => {
  const [showSample, setShowSample] = useState<KitSampleDto | undefined>(
    undefined
  );

  const len = capscans.length;
  const stoolSamples = kitItem.samples
    ?.filter((s) => Number(s.sampleType) === bodyItems.indexOf("Stool"))
    .slice(0, len);
  const salivaSamples = kitItem.samples
    ?.filter((s) => Number(s.sampleType) === bodyItems.indexOf("Saliva"))
    .slice(0, 1);
  const plasmaSamples = kitItem.samples
    ?.filter((s) => Number(s.sampleType) === bodyItems.indexOf("Plasma"))
    .slice(0, 1);

  const openSample = (sample: KitSampleDto) => {
    setShowSample(sample);
  };
  return len > 0 ? (
    <>
      <td className="border-end border-dark">
        <EditableCell
          icon={AddIcon}
          defaultValue={[]}
          type={"modal"}
          control={control}
          name="otherSamples"
          renderModal={(setShow) => (
            <ProcessOther
              subjectId={kitItem.subjectId}
              samples={[]}
              setShow={setShow}
              kitBarcode={kitItem.kitBarcode}
              refetch={refetch}
            />
          )}
        />
      </td>
      <td width={"10%"}>
        {stoolSamples?.map((sample, index) => (
          <>
            <div key={`stool ${index}`} className="d-flex text-nowrap">
              <BaseIcon
                icon={EditIcon}
                className={`${styles.editIcon}`}
                onClick={() => openSample(sample)}
              />
              {sample.storageDate && (
                <>
                  <div className="mx-2">
                    <b>Storage Date:</b> {getFormattedLocalDate(sample.storageDate)}.
                  </div>
                </>
              )}
              {sample.temperature && (
                <>
                  <div className="mx-2">
                    <b>Temperature:</b> {sample.temperature}.
                  </div>
                </>
              )}
              {sample.stoolScore && (
                <>
                  <div className="mx-2">
                    <b>Bristol Stool Score:</b> {sample.stoolScore}.
                  </div>
                </>
              )}
              <>
                  <div className="mx-2">
                    <b>Capsacn Type:</b> {capscans[index]?.reference} {" "}{getCapscanColor(capscans[index]?.reference)}.
                  </div>
                </>
            </div>
          </>
        ))}
      </td>
      <td width={"10%"}>
        {salivaSamples?.map((sample, index) => (
          <>
            <div key={`saliva ${index}`} className="d-flex text-nowrap">
              <BaseIcon
                icon={EditIcon}
                className={`${styles.editIcon}`}
                onClick={() => openSample(sample)}
              />
              {sample.processCollectionDate && (
                <>
                  <div className="mx-2">
                    <b>Collection date:</b>
                    {getFormattedLocalDate(sample.processCollectionDate)}.
                  </div>
                </>
              )}
              {sample.storageDate && (
                <>
                  <div className="mx-2">
                    <b>Storage Date:</b>
                    {getFormattedLocalDate(sample.storageDate)}.
                  </div>
                </>
              )}
              {sample.temperature && (
                <>
                  <div className="mx-2">
                    <b>Temperature:</b> {sample.temperature}.
                  </div>
                </>
              )}
            </div>
          </>
        ))}
      </td>
      <td width={"10%"}>
        {plasmaSamples?.map((sample, index) => (
           <>
           <div key={`plasma ${index}`} className="d-flex text-nowrap">
             <BaseIcon
               icon={EditIcon}
               className={`${styles.editIcon}`}
               onClick={() => openSample(sample)}
             />
             {sample.processCollectionDate && (
               <>
                 <div className="mx-2">
                   <b>Collection date: </b>
                   {getFormattedLocalDate(sample.processCollectionDate)}.
                 </div>
               </>
             )}
             {sample.storageDate && (
               <>
                 <div className="mx-2">
                   <b>Storage Date: </b>
                   {getFormattedLocalDate(sample.storageDate)}.
                 </div>
               </>
             )}
             {sample.temperature && (
               <>
                 <div className="mx-2">
                   <b>Temperature:</b> {sample.temperature}.
                 </div>
               </>
             )}
           </div>
         </>
        ))}
      </td>
      {showSample && (
        <SampleProcessModal
          {...{
            id: showSample.sampleId,
            sampleNumber: showSample.sampleNumber,
            sampleType: showSample.sampleType.toString(),
            kitSubjectId: kitItem.subjectId,
            processCollectionDate: getFormattedLocalDate(
              showSample.processCollectionDate
            ),
            stoolScore: showSample.stoolScore,
            kitBarcode: kitItem.kitBarcode,
            suffix: showSample.suffix,
            temperature: showSample.temperature,
            handleHide: () => setShowSample(undefined),
            refetch: () => refetch(),
          }}
        />
      )}
    </>
  ) : (
    <td colSpan={11} />
  );
};
