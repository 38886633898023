import { useMutation } from "@tanstack/react-query";
import { CapscanParams, ReturnCapscanParams } from "../models/requests/capscan.request";
import { addCapscanMutation, returnCapscanMutation } from "../mutations/capscan.mutation";
import Logger from "../util/logger.util";
import { IApiResult } from "../models/responses/api-result.response";
import { CapscanResponse } from "../models/responses/capscan.response";

export const referencesList = [
  { key: 0, value: "CS1" },
  { key: 1, value: "CS2" },
  { key: 2, value: "CS3" },
  { key: 3, value: "CS4" }
];

function useCapscan() {
  const { mutateAsync: mutateAddCapscan } = useMutation(addCapscanMutation());
  const { mutateAsync: mutateReturnCapscan } = useMutation(returnCapscanMutation());

  async function addCapscan(
    capscan: CapscanParams
  ): Promise<IApiResult<CapscanResponse> | null> {
    return await mutateAddCapscan(capscan, {
      onSuccess: (response) => {
        if (!response?.success) {
          alert(response?.message);
        }
        return response;
      },
    });
  }

  async function returnCapscan (
    capscan: ReturnCapscanParams
  ): Promise<IApiResult<void> | null> {
    return await mutateReturnCapscan(capscan, {
      onSuccess: (response) => {
        if (!response?.success) {
          alert(response?.message);
        }
        return response;
      },
    });
  }
  return { addCapscan, returnCapscan };
}

export default useCapscan;
