import { KitSampleDto, SampleDataLink, SampleDto } from "../../api";
import { getFormattedDate } from "../../util/date.util";
import { ProcessSampleProps } from "../envivo/Home/After/ProcessOther/ProcessOther";
import { bodyItems } from "../envivo/Home/After/ProcessOther/ProcessSample/ProcessSample";

export const columns = [
  "tid",
  "kitNumber",
  "metadata",
  "capScan",
  "otherSamples",
  "kitStatus",
  "comment",
  "actions",
];

export const capScapColumns = [
  " ",
  "ingestedDate",
  "ingestedTime",
  "recoveredDate",
  "recoveredTime",
  "transitTime",
  "storageDate",
  "storageTime",
  "temperature",
  "waterAdded",
  "capScanType",
];


export const otherSamplesColumns = ["stool", "saliva", "plasma"];

export const stoolColumns = [
  " ",
  "storageDate",
  "temperature",
  "bristolStoolScore",
  "capScanType",
];
export const plasamaAndSalivaColumns = [
  " ",
  "storageDate",
  "temperature",
  "collectionDate",
];

export const generateHeaders = (
  getTitle: (prefix: string, name: string) => JSX.Element
) => {
  return columns.map((column: string) => {
    switch (column) {
      case "capScan": {
        return (
          <th
            colSpan={capScapColumns.length}
            style={{ backgroundColor: "lightblue" }}
          >
            CapScan
          </th>
        );
      }
      case "otherSamples": {
        return (
          <th
            colSpan={otherSamplesColumns.length + 1}
            style={{ backgroundColor: "lightskyblue" }}
          >
            Other Samples
          </th>
        );
      }
      default:
        return (
          <th rowSpan={2} style={{ backgroundColor: "lightgray" }}>
            {getTitle("CLINICAL.TABLE.COLUMN", column)}
          </th>
        );
    }
  });
};

export const generateCapscanHeaders = (
  getTitle: (prefix: string, name: string) => JSX.Element
) => {
  return capScapColumns.map((capscan) => (
    <th style={{ backgroundColor: "lightblue", top: "40px" }}>
      {capscan === "capScanType"
        ? "CapScan Type"
        : getTitle("CLINICAL.TABLE.CAPSCANS.COLUMN", capscan)}
    </th>
  ));
};

export const generateOtherSamplesHeaders = (
  getTitle: (prefix: string, name: string) => JSX.Element
) => {
  return (
    <>
      <th style={{ backgroundColor: "lightskyblue", top: "40px" }}> </th>
      <th style={{ backgroundColor: "lightskyblue", top: "40px" }}>Stool</th>
      <th style={{ backgroundColor: "lightskyblue", top: "40px" }}>Saliva</th>
      <th style={{ backgroundColor: "lightskyblue", top: "40px" }}>Plasma</th>
    </>
  );
};

export const getSampleString = (sample: KitSampleDto) => {
  const { storageDate, temperature, stoolScore, sampleType } = sample;
  
  const stoolScoreStr =
    Number(sampleType) === bodyItems.indexOf("Stool") ? `${stoolScore}` : "";
  
    const storage = !storageDate
    ? ""
    : `${storageDate && getFormattedDate(storageDate)}`;
  
    const temperatureStr = !temperature ? "" : `${temperature}`;

  return `Storage date: ${storage}, Temperature: ${temperatureStr}, Bristol Stool Score: ${stoolScoreStr}`;
};

// export const generatePlasmaHeaders = (
//   getTitle: (prefix: string, name: string) => JSX.Element
// ) => {
//   return capScapColumns.map((capscan) => (
//     <th style={{ backgroundColor: "lightblue", top: "40px" }}>
//       {capscan === "capScanType"
//         ? "CapScan Type"
//         : getTitle("CLINICAL.TABLE.CAPSCANS.COLUMN", capscan)}
//     </th>
//   ));
// };
