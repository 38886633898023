import { useEffect, useState, useCallback } from "react";
import Tree from "rc-tree";
import StudyIcon from "../../../../assets/studies.svg";
import SiteIcon from "../../../../assets/site.svg";
import ClinicIcon from "../../../../assets/clinic_center.svg";
import ClinicDepartmentIcon from "../../../../assets/sub_clinic.svg";
import AddIcon from "../../../../assets/add.svg";
import { BaseIcon } from "../../../../components/BaseIcon/BaseIcon";
import "rc-tree/assets/index.css";
import { useQueries, useQuery } from "@tanstack/react-query";
import {
  sitesQuery,
  departmentStudiesQuery,
} from "../../../../queries/site.query";
import { Navbar, Spinner } from "react-bootstrap";
import { DataNode, EventDataNode, Key } from "rc-tree/lib/interface";
import Clinic from "./SitesNode/Clinic";
import ClinicDepartment from "./SitesNode/ClinicDepartment";
import { Study } from "./SitesNode/Study";
import { FormattedMessage } from "react-intl";
import { IconButton } from "../../../../components/IconButton/IconButton";
import {
  ClinicDepartmentResponse,
  StudyDepartmentResponse,
} from "../../../../models/responses/site.response";
import ClinicModal from "./SitesNode/ClinicModal";

const SitesTree = () => {
  const { isLoading, isError, data: clinics, refetch } = useQuery(sitesQuery());
  const [fetched, setFetched] = useState(false);
  const handleRefetch = useCallback(() => {
    refetch().then(() => {
      setFetched(true);
    });
  }, [refetch]);

  const [show, setShow] = useState(false);
  const [clinicDepartments, setClinicDepartments] = useState<
    {
      clinicId: string;
      clinicDepartmentId: string;
    }[]
  >([]);

  const createClinicDepartmentsArray = useCallback(() => {
    const clinicDepartmentsArray: {
      clinicId: string;
      clinicDepartmentId: string;
    }[] = [];
    clinics?.data?.forEach((clinic) =>
      clinic.clinicDepartments?.forEach((clinicDepartment) =>
        clinicDepartmentsArray.push({
          clinicId: clinicDepartment.parentId,
          clinicDepartmentId: clinicDepartment.id,
        })
      )
    );
    setClinicDepartments(clinicDepartmentsArray);
  }, [clinics?.data]);

  const studiesQueries = useQueries({
    queries: clinicDepartments?.map(({ clinicDepartmentId }) =>
      departmentStudiesQuery({ clinicDepartmentId }, { enabled: false })
    ),
  });
  const [treeData, setTreeData] = useState<DataNode[]>();

  const addStudiesToTree = useCallback(
    (
      clinicDepartmentKey: string,
      clinicName: string,
      clinicDepartment: ClinicDepartmentResponse,
      refetch: () => void,
      data: StudyDepartmentResponse[] | undefined
    ) => {
      setTreeData(
        treeData?.map((clinicNode) => {
          const newClinicDepartments = clinicNode?.children?.map(
            (clinicDepartmentNode) => {
              if (clinicDepartmentNode.key !== clinicDepartmentKey) {
                return clinicDepartmentNode;
              }
              return {
                ...clinicDepartmentNode,
                children: data?.map((study, index) => ({
                  key: `study${index}-${clinicDepartmentKey}`,
                  icon: <BaseIcon icon={StudyIcon} className="mb-1" />,
                  className: "mt-4",
                  title: (
                    <Study
                      study={study}
                      clinicName={clinicName}
                      clinicDepartmentName={clinicDepartment.name}
                      clinicDepartmentId={clinicDepartment.id}
                      refetch={refetch}
                    />
                  ),
                })),
              };
            }
          );

          return {
            ...clinicNode,
            children: newClinicDepartments,
          };
        })
      );
    },
    [treeData]
  );

  const handleClinicDepartmentExpand = async (
    expandedKeys: Key[],
    info: {
      node: EventDataNode<DataNode>;
    }
  ) => {
    const clinicDepartmentKey = info.node.key.toString();
    if (clinicDepartmentKey.includes("clinicDepartment") && clinics?.data) {
      const [clinicDepartment, clinic] = clinicDepartmentKey.split("-");

      const clinicDepartmentIndex = parseInt(
        clinicDepartment.replace("clinicDepartment", "")
      );
      const clinicIndex = parseInt(clinic.replace("clinic", ""));
      const clinicData = clinics?.data[clinicIndex];
      const index = clinicDepartments.findIndex(
        ({ clinicDepartmentId }) =>
          clinicDepartmentId ===
          clinicData.clinicDepartments[clinicDepartmentIndex].id
      );
      const response =
        studiesQueries[index].data ||
        (await studiesQueries[index].refetch()).data;
      if (response) {
        addStudiesToTree(
          clinicDepartmentKey,
          clinicData.name,
          clinicData.clinicDepartments[clinicDepartmentIndex],
          handleRefetch,
          response.data
        );
      }
    }
  };

  useEffect(() => {
    if (!treeData || fetched) {
      const data = clinics?.data?.map((clinic, index) => {
        return {
          key: `clinic${index}`,
          className: "mt-4",
          icon: <BaseIcon icon={ClinicIcon} className="mb-1" />,
          title: <Clinic clinic={clinic} refetch={handleRefetch} />,
          children: clinic.clinicDepartments?.map(
            (clinicDepartment, subIndex) => {
              const key = `clinicDepartment${subIndex}-clinic${index}`;
              const refetch = async () => {
                const res = await studiesQueries[
                  clinicDepartments.findIndex(
                    ({ clinicDepartmentId }) =>
                      clinicDepartmentId === clinicDepartment.id
                  )
                ]?.refetch();
                addStudiesToTree(
                  key,
                  clinic.name,
                  clinicDepartment,
                  refetch,
                  res.data?.data
                );
              };
              return {
                key: key,
                icon: <BaseIcon icon={ClinicDepartmentIcon} className="mb-1" />,
                className: "mt-4",
                title: (
                  <ClinicDepartment
                    clinicDepartment={clinicDepartment}
                    clinicParent={clinic.name}
                    refetch={handleRefetch}
                  />
                ),
                isLeaf: false,
              };
            }
          ),
        };
      });
      if (data) {
        createClinicDepartmentsArray();
        setTreeData(data);
      }
      setFetched(false);
    }
  }, [
    addStudiesToTree,
    clinics?.data,
    createClinicDepartmentsArray,
    fetched,
    handleRefetch,
    refetch,
    studiesQueries,
    clinicDepartments,
    treeData,
  ]);

  return (
    <div>
      <Navbar bg="light" className="fw-bold d-flex justify-content-between">
        <div>
          <BaseIcon icon={SiteIcon} />
          <FormattedMessage
            id="ENVIVO.SITES.ENVIVO_SITE"
            defaultMessage={"Envivo Site"}
          />
        </div>
        <IconButton icon={AddIcon} onClick={() => setShow(true)} />
      </Navbar>
      {isError && (
        <FormattedMessage
          id="ENVIVO.SITES.ERROR"
          defaultMessage="Error with Getting Sites Data"
        />
      )}
      {isLoading ? (
        <Spinner />
      ) : (
        <Tree
          treeData={treeData}
          defaultExpandAll
          checkStrictly
          selectable={false}
          showIcon
          autoExpandParent
          onExpand={handleClinicDepartmentExpand}
        />
      )}
      <ClinicModal show={show} setShow={setShow} refetch={handleRefetch} />
    </div>
  );
};
export default SitesTree;
