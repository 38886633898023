import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PropsWithChildren } from "react";
import { IntlProvider } from "react-intl";
import { AuthContextProvider } from "../context/AuthContext";
import English from "../i18n/languages/en.json";

export const queryClient = new QueryClient();

function RootProviders({ children }: PropsWithChildren) {
  return (
    <IntlProvider messages={English} locale={"en"}>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          {children}
          <ReactQueryDevtools
            initialIsOpen={false}
            position="top-right"
            panelPosition="right"
          />
        </AuthContextProvider>
      </QueryClientProvider>
    </IntlProvider>
  );
}

export default RootProviders;
