import { useMutation } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import { UpdateSampleDto } from "../api";
import { updateCapscanSampleMutation } from "../mutations/sampleCapscan.mutation";

const useProcessSample = () => {
  const { mutateAsync: mutateUpdateSample } = useMutation(
    updateCapscanSampleMutation()
  );

  async function updateProcessSample(
    sample: UpdateSampleDto
  ): Promise<IApiResult<void> | null> {
    return  mutateUpdateSample(sample,{
      onSettled: (response, error) => {
        if (error || !response?.success) {
          alert(response?.message || error);
        }
        return response;
      },
    });
  }

  return { updateProcessSample };
};

export default useProcessSample;
