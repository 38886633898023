import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../../../../components/Button/Button";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { FormModal } from "../../../../../components/form/FormModal/FormModal";
import { useForm } from "react-hook-form";
import TransferIcon from "../../../../../assets/transfer.svg";
import FormInput from "../../../../../components/form/FormInput/FormInput";
import styles from "./Transfer.module.css";
import { useNavigate } from "react-router";
import { FormGroup } from "../../../../../components/form/FormGroup/FormGroup";
import useSample from "../../../../../hooks/useSample";

type TransferFormProps = {
  sourceSample: string;
  destinationSample: string;
  deleteSourceSample: boolean;
};

const Transfer = () => {
  const { control, handleSubmit } = useForm<TransferFormProps>();

  const intl = useIntl();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };
  const { transferSample } = useSample();

  const onSubmit = async (formState: TransferFormProps) => {
    const response = await transferSample(formState);
    if (response?.success) {
      handleClose();
    }
  };

  return (
    <FormModal
      onSubmit={handleSubmit(onSubmit)}
      id="distribution"
      className={`modal-md modal-dialog-centered modal-dialog-scrollable`}
      header={
        <div>
          <BaseIcon icon={TransferIcon} />{" "}
          <b>
            <FormattedMessage id="ENVIVO.TRANSFER" defaultMessage="Transfer" />
          </b>
        </div>
      }
      footer={
        <div className="d-flex">
          <Button
            variant="outline-dark"
            className="border-dark me-2"
            onClick={handleClose}
          >
            <FormattedMessage id={"BUTTON.CANCEL"} defaultMessage="Cancel" />
          </Button>
          <Button
            variant="outline-dark"
            className="border-dark"
            type="submit"
            form="distribution"
          >
            <FormattedMessage id={"BUTTON.SAVE"} defaultMessage="Save" />
          </Button>
        </div>
      }
      show={true}
      handleClose={handleClose}
    >
      <div>
        <FormGroup
          groupDescription={
            <FormattedMessage
              id="ENVIVO.TRANSFER.INSERT"
              defaultMessage="Insert Sample Name (with study letter)"
            />
          }
          input={
            <FormInput
              type="text"
              name="sourceSample"
              control={control}
              rules={{
                required: true,
              }}
              input={{
                placeholder: intl.formatMessage({
                  id: "ENVIVO.TRANSFER.SOURCE",
                  defaultMessage: "Source Tube Sample",
                }),
                className: styles.input,
              }}
            />
          }
        />
        <FormGroup
          groupDescription={
            <FormattedMessage
              id="ENVIVO.TRANSFER.INSERT_DEST"
              defaultMessage="Insert Sample Number (without study letter)"
            />
          }
          input={
            <FormInput
              type="text"
              name="destinationSample"
              control={control}
              rules={{
                required: true,
              }}
              input={{
                placeholder: intl.formatMessage({
                  id: "ENVIVO.TRANSFER.DESTINATION",
                  defaultMessage: "Destination Tube",
                }),
                className: styles.input,
              }}
            />
          }
        />
        <FormGroup
          className="d-flex"
          groupDescription={
            <label className="ms-1 mt-4">
              <FormattedMessage
                id="ENVIVO.TRANSFER.DELETE"
                defaultMessage="Delete source sample from database"
              />
            </label>
          }
          input={
            <FormInput
              type="checkbox"
              name="deleteSourceSample"
              control={control}
            />
          }
        />
      </div>
    </FormModal>
  );
};

export default Transfer;
