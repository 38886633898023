const suffixes = ["A", "B", "C", "D", "E", "F", "G", "H", "None"];
export const getSuffixes = () =>
  suffixes.map((item) => ({
    label: item,
    value: false,
    volume:""
  }));

export const getSelectedSuffix = (
  suffix: { label: string; value: boolean, volume:string }[]
) => suffix.filter((suffix) => suffix.value).map((suffix) =>( {suffix: suffix.label,volume: suffix.volume}));
