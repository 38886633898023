import { UseMutationOptions } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import Logger from "../util/logger.util";
import { ProcessCapscanParams } from "../models/requests/processCapscan.request";
import { processCapscanService } from "../services/processCapscan.service";

export const addProcessCapscanMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  ProcessCapscanParams
> => ({
  mutationKey: ["add"],
  mutationFn: (processCapscan: ProcessCapscanParams) => processCapscanService.addProcessCapscan(processCapscan),
  onSuccess: () => {
    Logger.info("Add process capscan done successfully");
  },
});