import { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import HomeIcon from "../../assets/home.svg";
import AppManagementIcon from "../../assets/management.svg";
import MetadataIcon from "../../assets/metadata.svg";
import ReportsIcon from "../../assets/reports.svg";
import UsersIcon from "../../assets/users.svg";
import SitesIcon from "../../assets/sites.svg";
import VendorsIcon from "../../assets/vendors.svg";
import StudiesIcon from "../../assets/studies.svg";
import KitsVersionsIcon from "../../assets/kits_versions.svg";
import ShipmentIcon from "../../assets/shipment.svg";
import { BaseIcon } from "../BaseIcon/BaseIcon";
import { useAuthContext } from "../../context/AuthContext";

type MenuItemType = {
  id: string;
  icon: string;
  label: ReactElement;
  link: ReactElement;
};

const mainMenuItems: MenuItemType[] = [
  {
    id: "home",
    icon: HomeIcon,
    label: (
      <FormattedMessage id="ENVIVO.MENU.ITEM.HOME" defaultMessage={"Home"} />
    ),
    link: <Link to={""} className="w-100" />,
  },
   
  {
    id: "reports",
    icon: ReportsIcon,
    label: (
      <FormattedMessage
        id="ENVIVO.MENU.ITEM.REPORTS"
        defaultMessage={"Reports"}
      />
    ),
    link: <Link to={"sample-reports"} className="w-100" />,
  },
  {
    id: "shipments",   
    icon: ShipmentIcon,
    label: (
      <FormattedMessage
        id="ENVIVO.MENU.ITEM.SHIPMENT"
        defaultMessage={"Shipments"}
      />
    ),
    link: <Link to={"shipments"} className="w-100" />,
  },
];

const managementMenuItems = [
  {
    id: "users",
    icon: UsersIcon,
    label: (
      <FormattedMessage
        id="ENVIVO.MENU.ITEM.APPLICATION.USERS"
        defaultMessage={"Users"}
      />
    ),
    link: <Link to={"users"} className="w-100" />,
  },
  {
    id: "sites",
    icon: SitesIcon,
    label: (
      <FormattedMessage
        id="ENVIVO.MENU.ITEM.APPLICATION.SITES"
        defaultMessage={"Sites/Centers/Facilities"}
      />
    ),
    link: <Link to={"sites"} className="w-100" />,
  },
  {
    id: "vendors",
    icon: VendorsIcon,
    label: (
      <FormattedMessage
        id="ENVIVO.MENU.ITEM.APPLICATION.VENDORS"
        defaultMessage={"Vendors"}
      />
    ),
    link: <Link to={"vendors"} className="w-100" />,
  },
  {
    id: "kitsVersions",
    icon: KitsVersionsIcon,
    label: (
      <FormattedMessage
        id="ENVIVO.MENU.ITEM.APPLICATION.KITS"
        defaultMessage={"Kits Versions"}
      />
    ),
    link: <Link to={"kitsVersions"} className="w-100" />,
  },
  {
    id: "studies",
    icon: StudiesIcon,
    label: (
      <FormattedMessage
        id="ENVIVO.MENU.ITEM.APPLICATION.STUDIES"
        defaultMessage={"Studies"}
      />
    ),
    link: <Link to={"studies"} className="w-100" />,
  },
];

function SideBar() {
  const [activeMenuItem, setActiveMenuItem] = useState<string>();
  const {
    state
  } = useAuthContext();
  const user = state?.user

  const { role } = user || {};
const isAdmin = role === "Admin"; 

  const getMenuItem = (item: MenuItemType) => {
    return (
      <MenuItem
        key={item.id}
        component={item.link}
        onClick={() => setActiveMenuItem(item.id)}
        className={`d-flex align-items-center ${activeMenuItem === item.id &&
          "text-primary"}`}
      >
        <BaseIcon icon={item.icon} className="icon-xm me-2" />
        {item.label}
      </MenuItem>
    );
  };

  return (
    <Sidebar breakPoint="md" rootStyles={{ height: "100vh", fontSize: 12 }}>
      <Menu>
        {mainMenuItems.map((item: MenuItemType) => getMenuItem(item))}
        <SubMenu
          label={
            <FormattedMessage
              id="ENVIVO.MENU.ITEM.APPLICATION.MANAGEMENT"
              defaultMessage={"Management"}
            />
          }
          icon={<BaseIcon icon={AppManagementIcon} className="icon-xm" />}
        >
          {managementMenuItems.filter(item=> item.id !== "users" || isAdmin).map((item: MenuItemType) => getMenuItem(item))}
        </SubMenu>
      </Menu>
    </Sidebar>
  );
}

export default SideBar;
