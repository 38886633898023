import { UseMutationOptions } from "@tanstack/react-query";
import {
  ClinicParams,
  StudyClinicParams,
  ClinicDepartmentParams,
  ClinicEditParams,
  StudyClinicEditParams,
} from "../models/requests/site.request";
import { IApiResult } from "../models/responses/api-result.response";
import {
  ClinicResponse,
  StudyDepartmentResponse,
  ClinicDepartmentResponse,
  Clinic,
} from "../models/responses/site.response";
import { siteService } from "../services/site.service";
import Logger from "../util/logger.util";
import { UpdateClinicDepartmentDto } from "../api";

export const addStudyMutation = (): UseMutationOptions<
  IApiResult<StudyDepartmentResponse> | null,
  unknown,
  StudyClinicParams
> => ({
  mutationKey: ["add study"],
  mutationFn: async (study: StudyClinicParams) =>
    await siteService.addStudy(study),
});

export const editStudyMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  StudyClinicEditParams 
> => ({
  mutationKey: ["edit study"],
  mutationFn: async (study) => siteService.editStudy(study),
  onSuccess: () => {
    Logger.info("Edit Study done successfully");
  },
});

export const addClinicDepartmentMutation = (): UseMutationOptions<
  IApiResult<ClinicDepartmentResponse> | null,
  unknown,
  ClinicDepartmentParams
> => ({
  mutationKey: ["add sub clinic"],
  mutationFn: async (clinicDepartment: ClinicDepartmentParams) =>
    await siteService.addClinicDepartment(clinicDepartment),
});

export const deleteClinicMutation = (): UseMutationOptions<
    IApiResult<Clinic> | null,
    unknown,
    string
    > => ({
  mutationKey: ["delete"],
  mutationFn: async (id: string) => siteService.deleteClinic(id),
  onSuccess: () => {
    Logger.info("Delete Clinic done successfully");
  },
});

export const deleteStudyMutation = (): UseMutationOptions<
    IApiResult<Clinic> | null,
    unknown,
    string
    > => ({
  mutationKey: ["delete"],
  mutationFn: async (id: string) => siteService.deleteStudy(id),
  onSuccess: () => {
    Logger.info("Delete Study done successfully");
  },
});

export const deleteClinicDepartmentMutation = (): UseMutationOptions<
    IApiResult<Clinic> | null,
    unknown,
    string
    > => ({
  mutationKey: ["delete"],
  mutationFn: async (id: string) => siteService.deleteClinicDepartment(id),
  onSuccess: () => {
    Logger.info("Delete Clinic Department done successfully");
  },
});

export const editClinicDepartmentMutation = (): UseMutationOptions<
    IApiResult<void> | null,
    unknown,
    { clinicDep: UpdateClinicDepartmentDto }
    > => ({
  mutationKey: ["edit"],
  mutationFn: ({clinicDep}) =>
      siteService.editClinicDepartment(clinicDep),
  onSuccess: () => {
    Logger.info("Edit Clinic done successfully");
  },
});

export const editClinicMutation = (): UseMutationOptions<
    IApiResult<ClinicResponse> | null,
    unknown,
    { clinic: ClinicEditParams }
    > => ({
  mutationKey: ["edit"],
  mutationFn: ({clinic}) =>
      siteService.editClinic(clinic),
  onSuccess: () => {
    Logger.info("Edit Clinic done successfully");
  },
});

export const addClinicMutation = (): UseMutationOptions<
  IApiResult<ClinicResponse> | null,
  unknown,
  ClinicParams
> => ({
  mutationKey: ["add clinic"],
  mutationFn: async (clinic: ClinicParams) =>
    await siteService.addClinic(clinic),
});
