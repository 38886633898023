import { useEffect } from "react";
import AddStudyIcon from "../../../../../assets/add_study.svg";
import { FormattedMessage, useIntl } from "react-intl";
import {
  addStudyMutation,
  editStudyMutation,
} from "../../../../../mutations/site.mutation";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { StudyClinicParams } from "../../../../../models/requests/site.request";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { FormModal } from "../../../../../components/form/FormModal/FormModal";
import { FormGroup } from "../../../../../components/form/FormGroup/FormGroup";
import FormInput from "../../../../../components/form/FormInput/FormInput";
import { studyStatuses } from "../../Studies/StudiesTable";
import { studyNamesQuery } from "../../../../../queries/study.query";
import { departmentStudiesQuery } from "../../../../../queries/site.query";
import { StudyDepartmentResponse } from "../../../../../models/responses/site.response";
import { makeDirtyObject } from "../../../../../util/form.util";
import { Button } from "../../../../../components/Button/Button";

type StudyDepartmentProps = {
  refetch: () => void;
  show: boolean;
  setShow: (show: boolean) => void;
  clinicName: string;
  clinicDepartmentName: string;
  clinicDepartmentId: string;
  studyDepartment?: StudyDepartmentResponse;
};
const StudyDepartmentModal = ({
  clinicName,
  clinicDepartmentName,
  clinicDepartmentId,
  refetch,
  show,
  setShow,
  studyDepartment,
}: StudyDepartmentProps) => {
  const { control, handleSubmit, reset, getFieldState } =
    useForm<StudyClinicParams>();

  useEffect(() => {
    if (studyDepartment) {
      const study = {
        email: studyDepartment.contactEmail,
        phone: studyDepartment.contactPhone,
        studyId: studyDepartment.studyId,
        contactPerson: studyDepartment.contactPersonName,
        principalInvestigator: studyDepartment.principalInvestigator,
        studyStatus: studyDepartment.status,
      };
      reset(study);
    }
  }, [studyDepartment, reset]);
  const header = (
    <header>
      <BaseIcon icon={AddStudyIcon} />{" "}
      <b>
        {clinicName}
        {">"} {clinicDepartmentName} {">"}{" "}
        <FormattedMessage
          id={studyDepartment ? "ENVIVO.SITES.STUDY.UPDATE" : "ENVIVO.SITES.STUDY.ADD"}
          defaultMessage={studyDepartment ? "Update Study"  : "Add Study" }
        />
      </b>
    </header>
  );

  const footer = (
    <footer className="d-flex">
      <Button variant="outline-dark" onClick={() => setShow(false)} className="me-1">
        <FormattedMessage id="BUTTON.CANCEL" defaultMessage="Cancel" />
      </Button>
      <Button variant="success" type="submit" form="studyDepartment">
        <FormattedMessage
          id={studyDepartment ? "BUTTON.UPDATE" : "BUTTON.CREATE"}
          defaultMessage={studyDepartment ? "Update" : "Create"}
        />
      </Button>
    </footer>
  );

  const { mutateAsync: mutateAddStudy } = useMutation(addStudyMutation());
  const { mutateAsync: mutateEditStudy } = useMutation(editStudyMutation());

  const { data: studiesNames } = useQuery(studyNamesQuery());
  const { refetch: studyRefetch } = useQuery(
    departmentStudiesQuery({ clinicDepartmentId: clinicDepartmentId })
  );
  const intl = useIntl();

  const getStudies = () =>
    studiesNames?.data?.map((item) => ({
      key: item.id,
      value: item.studyName,
    }));

  const onSubmit = async (formState: StudyClinicParams) => {
    if (studyDepartment) {
      const dirtyObject = makeDirtyObject(
        (key) => getFieldState(key).isDirty,
        formState
      );
      const studyStatus =
        dirtyObject.studyStatus && Number(dirtyObject?.studyStatus);
      await mutateEditStudy(
        { id: studyDepartment.id, ...dirtyObject, studyStatus },
        {
          onSuccess: async (response) => {
            if (response) {
              if (!response.success) alert(response.message);
              else {
                reset();
                setShow(false);
                await studyRefetch();
                refetch();
              }
            }
          },
        }
      );
    } else {
      await mutateAddStudy(
        {
          ...formState,
          parentId: clinicDepartmentId,
          studyStatus: Number(formState.studyStatus) || 0,
        },
        {
          onSuccess: async (response) => {
            if (response) {
              if (!response.success) alert(response.message);
              else {
                reset();
                setShow(false);
                await studyRefetch();
                refetch();
              }
            }
          },
        }
      );
    }
  };

  return (
    <FormModal
      show={show}
      header={header}
      footer={footer}
      id="studyDepartment"
      handleClose={() => setShow(false)}
      className="modal-lg mt-5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="mb-5 pb-5 w-50 m-auto">
        <FormGroup
          input={
            <FormInput
              defaultValue={studyDepartment?.studyId}
              type="select"
              name="studyId"
              control={control}
              rules={{ required: true }}
              input={{
                placeholder: intl.formatMessage({
                  id: "ENVIVO.SITES.STUDY.NAME",
                  defaultMessage: "Study",
                }),
                items: getStudies(),
              }}
            />
          }
        />
        <FormGroup
          input={
            <FormInput
              defaultValue={studyDepartment?.status}
              type="select"
              name="studyStatus"
              control={control}
              rules={{ required: true }}
              input={{
                placeholder: intl.formatMessage({
                  id: "ENVIVO.SITES.STUDY.STUDY_STATUS",
                  defaultMessage: "Study Status",
                }),
                items: studyStatuses.map((status: string, index: number) => ({
                  key: index,
                  value: status,
                })),
              }}
            />
          }
        />
        <FormGroup
          input={
            <FormInput
              defaultValue={studyDepartment?.principalInvestigator}
              type="text"
              name="principalInvestigator"
              control={control}
              rules={{ required: true }}
              input={{
                placeholder: intl.formatMessage({
                  id: "ENVIVO.SITES.STUDY.PI_NAME",
                  defaultMessage: "Principal Investigator Name",
                }),
              }}
            />
          }
        />
        <FormGroup
          input={
            <FormInput
              defaultValue={studyDepartment?.contactPersonName}
              type="text"
              name="contactPerson"
              control={control}
              rules={{ required: true }}
              input={{
                placeholder: intl.formatMessage({
                  id: "ENVIVO.SITES.STUDY.CONTACT_PERSON",
                  defaultMessage: "Contact Person",
                }),
              }}
            />
          }
        />
        <div className="d-flex justify-content-between">
          <FormGroup
            input={
              <FormInput
                defaultValue={studyDepartment?.contactEmail}
                type="text"
                name="email"
                control={control}
                rules={{ required: true }}
                input={{
                  type: "email",
                  placeholder: intl.formatMessage({
                    id: "ENVIVO.SITES.STUDY.EMAIL",
                    defaultMessage: "Email",
                  }),
                }}
              />
            }
          />
          <FormGroup
            input={
              <FormInput
                  defaultValue={studyDepartment?.contactPhone}
                type="text"
                name="phone"
                control={control}
                rules={{ required: true }}
                input={{
                  type: "tel",
                  pattern: "[+][0-9]*",
                  placeholder: intl.formatMessage({
                    id: "ENVIVO.SITES.STUDY.PHONE",
                    defaultMessage: "Phone",
                  }),
                }}
              />
            }
          />
        </div>
      </div>
    </FormModal>
  );
};

export default StudyDepartmentModal;
