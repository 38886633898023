import { UseMutationOptions } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import Logger from "../util/logger.util";
import { CreateBoxParams, UpdateBoxParams } from "../models/requests/storageLocation.request";
import { boxService } from "../services/box.service";
import { BoxMessageResponse } from "../api";

export const addBoxMutation = (): UseMutationOptions<
 BoxMessageResponse | null,
  unknown,
  CreateBoxParams
> => ({
  mutationKey: ["add"],
  mutationFn: async (box: CreateBoxParams) => await boxService.addBox(box),
  onSuccess: () => {
    Logger.info("Add box done successfully");
  },
});

export const editBoxMutation = (): UseMutationOptions<
BoxMessageResponse | null,
  unknown,
  { box: UpdateBoxParams }
> => ({
  mutationKey: ["edit"],
  mutationFn: async ({ box }) => await boxService.editBox(box),
  onSuccess: () => {
    Logger.info("Edit box done successfully");
  },
});

