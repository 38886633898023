import { useMutation } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import { addProcessOtherMutation } from "../mutations/processOther.mutation";
import { CreateProcessDto } from "../api";

const useProcessOther = () => {
    const { mutateAsync: addProcessOther } = useMutation(addProcessOtherMutation());
  
    async function addProcess(process: CreateProcessDto): Promise<IApiResult<void> | null> {
      return await addProcessOther(process, {
        onSuccess: (response) => {
          if (!response?.success) {
            alert(response?.message);
          }
          return response;
        },
      });
    }
    
  return { addProcess };
};

export default useProcessOther;
