import { Row, TableInstance } from "react-table";
import { DataType } from "../../../../../../components/Table/Table";
import styles from "./ExpandedRow.module.css";
import { UseFormWatch } from "react-hook-form";
import { FilterSamplesFormInputs } from "./CapscanReportTable";

interface ExpandedRowProps {
  row: Row<DataType>;
  table: TableInstance<DataType>;
  includeImages: boolean;
  watch: UseFormWatch<FilterSamplesFormInputs>;
}

const ExpandedRow = ({
  row,
  table,
  includeImages,
  watch,
}: ExpandedRowProps) => {
  const watchDeviceDimensions = watch("deviceDimensions");
  const watchSampleDimensions = watch("sampleDimensions");
  return (
    <tr>
      <td colSpan={table.visibleColumns.length}>
        <div
          className={`${styles.border} d-flex gap-4 ms-4 me-4 align-center`}
        >
          <div className={`d-flex mt-2`} onClick={(e) => e.stopPropagation()}>
            <div className={`${includeImages ? styles.imgContainer : ""} ms-3`}>
              {includeImages ? (
                <img
                  src={row.original["imageUrl"]?.toString() || ""}
                  alt="s"
                  className={styles.img}
                ></img>
              ) : (
                row.original["imageUrl"]?.toString()
              )}
            </div>
            <div className="ms-3">
              {includeImages ? (
                <img
                  src={row.original["deviceImageUrl"]?.toString() || ""}
                  alt="device"
                ></img>
              ) : (
                row.original["deviceImageUrl"]?.toString()
              )}
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default ExpandedRow;
