import { UseMutationOptions } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import Logger from "../util/logger.util";
import { CreateProcessDto } from "../api";
import { processOtherService } from "../services/processOther.service";

export const addProcessOtherMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  CreateProcessDto
> => ({
  mutationKey: ["add process other"],
  mutationFn: (process: CreateProcessDto) => processOtherService.addProcessOther(process),
  onSuccess: () => {
    Logger.info("Add process other done successfully");
  },
});