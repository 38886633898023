import { useEffect, useState } from "react";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import { FormModal } from "../form/FormModal/FormModal";
import { Button } from "react-bootstrap";
import { BaseIcon } from "../BaseIcon/BaseIcon";
import CompleteddIcon from "../../assets/completed.svg";

type ImageFormProps = {
  handleOcr: (src: string) => void;
  capturedImage: string | undefined;
  handleClose: () => void;
  show: boolean;
};

const ImageOcr = ({
  capturedImage,
  handleOcr,
  handleClose,
  show,
}: ImageFormProps) => {
  const [crop, setCrop] = useState<Crop>();
  const [crupedImage, setCrupedImage] = useState<string>();

  const doOCR = async () => {
    if (crupedImage) {
        handleOcr(crupedImage);
    }
    handleClose();
  };

  useEffect(()=> {
    if (!crop || !capturedImage)
      {
        return;
      }
    const image = new Image();
    image.src = capturedImage;
    image.onload = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = crop.width;
      canvas.height = crop.height;

      const ctx = canvas.getContext("2d");

      ctx?.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        crop.width,
        crop.height
      );
      const croppedImageUrl = canvas.toDataURL("image/jpeg");
      setCrupedImage(croppedImageUrl);

    }
  }, [crop]);
   

  return (
    <div style={{ zIndex: 20 }}>
      <FormModal
        handleClose={() => handleClose()}
        className="modal-xl"
        id="form"
        show={show}
        header={<></>}
        footer={
          <>
            <Button
              variant="outline-dark"
              className="border rounded-4 mb-2"
              onClick={() => doOCR()}
              size="sm"
            >
              <BaseIcon icon={CompleteddIcon} />{" "}
            </Button>
          </>
        }
      >
        <ReactCrop
          crop={crop}
          onChange={(pixel_crop, _) => setCrop(pixel_crop)}
        >
          <img src={capturedImage} alt="Captured" />
        </ReactCrop>
        {crupedImage && <img src={crupedImage} alt="Cropped" />}
      </FormModal>
    </div>
  );
};

export default ImageOcr;
