import { QueryType, ResolvedQuery } from "../models/query.model";
import { FilterProcessSamplesParams, FilterSamplesParams } from "../models/requests/sampleReport.request";
import { sampleReportService } from "../services/sampleReports.service";

type FilterSamplesQuery = QueryType<ResolvedQuery<typeof sampleReportService.getFilterSample>>;
type FilterProcessSamplesQuery = QueryType<ResolvedQuery<typeof sampleReportService.getFilterProcessSample>>;
type FilterSampleQueryOptions = Omit<FilterSamplesQuery, "queryKey" | "queryFn">;
type FilterProcessSampleQueryOptions = Omit<FilterProcessSamplesQuery, "queryKey" | "queryFn">;

export const filterSamplesQuery = (
    {study, patient, includeImages} : FilterSamplesParams,
    options?: FilterSampleQueryOptions
): FilterSamplesQuery => ({
  ...options,
  queryKey: ["studies", {study, patient, includeImages}],
  queryFn: () => sampleReportService.getFilterSample({study, patient, includeImages}),
  refetchOnWindowFocus: false,
});

export const filterProcessSamplesQuery = (
    { kitSubjectId, studyId } : FilterProcessSamplesParams,
    options?: FilterProcessSampleQueryOptions
): FilterProcessSamplesQuery => ({
  ...options,
  queryKey: ["samples", {kitSubjectId, studyId}],
  queryFn: () => sampleReportService.getFilterProcessSample({kitSubjectId, studyId}),
});
