import { QueryType, ResolvedQuery } from "../models/query.model";
import { boxService } from "../services/box.service";

type GetBoxQuery = QueryType<ResolvedQuery<typeof boxService.getBox>>;
type GetBoxQueryOptions = Omit<GetBoxQuery, "queryKey" | "queryFn">;


export const BoxQuery = (
  boxBarcode: string,
  options?: GetBoxQueryOptions
): GetBoxQuery => ({
  ...options,
  queryKey: ["get-box", boxBarcode],
  queryFn: () => boxService.getBox(boxBarcode),
  keepPreviousData: true,
});