import { RouteObject } from "react-router-dom";
import { Protected } from "../../components/ProtectedRoute/ProtectedRoute";
import { RouteConstants } from "../../constants";
import FactoryLayout from "../../layouts/FactoryLayout/FactoryLayout";
import FactoryPage from "../../pages/factory/FactoryPage/FactoryPage";
import PrintLabel from "../../pages/factory/FactoryPage/PrintLabel/PrintLabelForm/PrintLabel";
import RePrint from "../../pages/envivo/Home/Preparations/RePrint/RePrint";

export const factoryRoutes: RouteObject[] = [
  {
    path: RouteConstants.FACTORY,
    element: (
      <Protected>
        <FactoryLayout />
      </Protected>
    ),
    children: [
      {
        index: true,
        element: <FactoryPage />,
      },
      {
        path: "print",
        element: <PrintLabel />,
      },
      {
        path: "re-print",
        element: <RePrint />,
      },
    ],
  },
];
