import { FormModal } from "../../../../../components/form/FormModal/FormModal";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../../../../components/Button/Button";
import StorageLocationIcon from "../../../../../assets/storage_location.svg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import styles from "./StorageLocation.module.css";
import FormInput from "../../../../../components/form/FormInput/FormInput";
import RadioButtonGroup from "../../../../../components/form/RadioButtonGroup/RadioButtonGroup";
import { useEffect, useState } from "react";
import CaptureBox from "./CaptureBox";
import useStorageLocation from "../../../../../hooks/useStorageLocation";
import {
  BoxLocation,
  EnvivoLocation,
  VendorLocation,
} from "../../../../../models/requests/storageLocation.request";
import { useQuery } from "@tanstack/react-query";
import { BoxQuery } from "../../../../../queries/box.query";
import { makeDirtyObject } from "../../../../../util/form.util";
import { vendorsNamesQuery } from "../../../../../queries/vendor.query";
import { BOX_DEVICE } from "../../More/CameraSettings/CameraSettings";
import useLocalStorage from "../../../../../hooks/useLocalStorage";
import { Constants } from "../../../../../constants";
import BoxCapture from "./BoxCapture";

export type StorageLocationProps = {
  boxBarcode: string;
  boxType: number;
  boxSampleType: number;
  location: number;
  freezer?: number;
  rack?: number;
  vendor?: string;
  boxSamples?: string[][];
};

const boxType = [
  { value: 0, label: "9x9, 81 tubes" },
  { value: 1, label: "14x14,196 tubes" },
];
const boxSampleType = [
  { value: 0, label: "Capscan" },
  { value: 1, label: "Other" },
];

const location = [
  { value: 0, label: "Envivo" },
  { value: 1, label: "Vendor" },
];

const StorageLocation = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const {
    watch,
    handleSubmit,
    reset,
    control,
    setValue,
    getFieldState,
    getValues,
  } = useForm<StorageLocationProps>();
  const [imageUrl, setImageUrl] = useState("");
  const [captureBoxPosition, setCaptureBoxPosition] = useLocalStorage<{
    x: number;
    y: number;
  }>(Constants.CaptureBoxPosition);
  const [loading, setLoading] = useState(false);
  const [boxSize, setBoxSize] = useState(9);
  const selectedLocation = Number(watch("location"));
  const watchBoxBarcode = watch("boxBarcode");
  const watchBox = watch("boxSamples");
  const watchBoxType = watch("boxType");
  const { add, edit } = useStorageLocation();
  const { data: vendors } = useQuery(vendorsNamesQuery());
  useEffect(() => {
    setImageUrl("");
    setCaptureBoxPosition(captureBoxPosition || { x: 0, y: 0 });
  }, []);
  useEffect(() => {
    setBoxSize(Number(watchBoxType) === 1 ? 14 : 9);
  }, [watchBoxType]);

 
  const { data: box, refetch: refetchBox } = useQuery(
    BoxQuery(watchBoxBarcode, {
      enabled: false,
      refetchOnWindowFocus: false,
    })
  );

  useEffect(() => {
    if (box) {
      setValue("boxType", box?.data?.boxType || 0);

      const { location } = (box.data?.location as BoxLocation) || 0;
      setValue("location", location);
      if (location) {
        const { vendorId } = box.data?.location as VendorLocation;
        vendorId && setValue("vendor", vendorId);
      } else if (location === 0) {
        const { freezerNumber, rackNumber } = box.data
          ?.location as EnvivoLocation;
        freezerNumber && setValue("freezer", freezerNumber);
        rackNumber && setValue("rack", rackNumber);
      } else {
        setValue("freezer", undefined);
        setValue("rack", undefined);
      }
    }
  }, [box]);

  const handleEnterKit = async (e: React.KeyboardEvent<Element>) => {
    e.preventDefault();
    refetchBox();
  };


  const setBoxSample = (sample:string, x:number, y:number)=>{
    setValue(`boxSamples.${y}.${x}`, sample);
  }

  const renderLocationInput = () => {
    if (selectedLocation === 1) {
      return (
        <FormInput
          type="select"
          name={"vendor"}
          control={control}
          rules={{
            required: true,
          }}
          input={{
            placeholder: intl.formatMessage({
              id: "ENVIVO.STORAGE_LOCATION.VENDOR",
              defaultMessage: "Vendor",
            }),
            className: styles.vendorInput,
            items: vendors?.data?.map(({ id, vendorName }) => ({
              key: id,
              value: vendorName,
            })),
          }}
        />
      );
    } else {
      return (
        <>
          <FormInput
            type="text"
            name={"rack"}
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^(?:100|[1-9][0-9]?)$/,
                message: "Please enter a number between 1 and 100",
              },
            }}
            input={{
              placeholder: intl.formatMessage({
                id: "ENVIVO.STORAGE_LOCATION.RACK",
                defaultMessage: "Rack",
              }),
              className: styles.rackInput,
            }}
            defaultValue={""}
          />
          <FormInput
            type="text"
            name={"freezer"}
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^(?:100|[1-9][0-9]?)$/,
                message: "Please enter a number between 1 and 100",
              },
            }}
            input={{
              placeholder: intl.formatMessage({
                id: "ENVIVO.STORAGE_LOCATION.FREEZER",
                defaultMessage: "Freezer",
              }),
              className: styles.freezerInput,
            }}
            defaultValue={""}
          />
        </>
      );
    }
  };

  const onSubmit = async (formState: StorageLocationProps) => {
    const { location, boxType, freezer, rack, vendor, boxSamples,boxSampleType, ...rest } =
      formState;
    let loc: BoxLocation;
    const samples = boxSamples?.flat(1);
    if (!location) {
      loc = { freezerNumber: freezer!, rackNumber: rack!, location: 0 };
    } else {
      loc = { vendorId: vendor!, location: 1 };
    }
    if (box?.data) {
      const obj = makeDirtyObject(
        (key) => getFieldState(key).isDirty,
        formState
      );
      const { location, boxBarcode, boxSamples,...rest } = obj;
      const response = await edit({
        boxBarcode: box.data?.boxBarcode || getValues("boxBarcode"),
        boxSamples: samples,
        ...((location || freezer || rack || vendor) && { location: loc }),
        ...rest,
      });
      if (response?.success) {
        if (response?.data && (response?.data as unknown as string) !== "") {
          alert(`Box saved successfully.\n${response?.data}`);
        }
        reset();
      } else {
        alert(response?.message);
      }
    } else {
      const response = await add({
        ...rest,
        location: loc,
        boxSamples: samples || [],
        boxType: boxType ? boxType : 0,
        boxSampleType: boxSampleType ? boxSampleType : 0
      });
      if (response?.success) {
        if (response?.data && (response?.data as unknown as string) !== "") {
          alert(`Box saved successfully.\n${response?.data}`);
        }
        reset();
      } else {
        alert(response?.message);
      }
    }
  };

  return (
    <FormModal
      onSubmit={handleSubmit(onSubmit)}
      id="device"
      className={`modal-xl modal-dialog-centered modal-dialog-scrollable ${styles.customModel}`}
      dialogClassName="h-100"
      contentClassName= {`${styles.content} h-100`}
      formClassName="h-100"
      fullscreen={true}
      header={
        <div>
          <BaseIcon icon={StorageLocationIcon} />{" "}
          <b>
            <FormattedMessage
              id="ENVIVO.STORAGE_LOCATION"
              defaultMessage="Box"
            />
          </b>
        </div>
      }
      footer={
        <>
          <Button
            variant="outline-dark"
            className="border-dark"
            onClick={() => navigate(-1)}
          >
            <FormattedMessage id="ENVIVO.MODAL.EXIT" defaultMessage="Exit" />
          </Button>
        </>
      }
      show={true}
      handleClose={() => navigate(-1)}
    >
      <div style={{ paddingTop: 0 }}>
        <div
          className={`d-flex align-items-center justify-content-between ${styles.linesButtons}`}
        >
          <FormInput
            type="text"
            name="boxBarcode"
            control={control}
            rules={{
              required: true,
            }}
            input={{
              type: "text",
              placeholder: intl.formatMessage({
                id: "ENVIVO.STORAGE_LOCATION.BOX_BARCODE",
                defaultMessage: "Box Barcode",
              }),
              maxlength: 5,
              className: styles.boxBarcodeInput,
              onEnterKey: handleEnterKit,
            }}
          />
          <div className={styles.boxTypeButtons}>
            <div className={styles.boxTypeText}>Box Type</div>
            <RadioButtonGroup
              name="boxType"
              key="boxType"
              defValue={0}
              radioButtonsArray={boxType}
              control={control}
            />
          </div>
          <div className={styles.boxTypeButtons}>
            <div className={styles.boxTypeText}>Sample Type</div>
            <RadioButtonGroup
              name="boxSampleType"
              key="boxSampleType"
              defValue={0}
              radioButtonsArray={boxSampleType}
              control={control}
            />
          </div>
          <div className={styles.lineRadio} />
          <div className="d-flex">
            <div className={styles.locationText}>Location</div>
            <div
              className={`d-flex flex-column justify-content-center ${styles.locationButtons}`}
            >
              <RadioButtonGroup
                name="location"
                key="location"
                defValue={0}
                radioButtonsArray={location}
                control={control}
              />
            </div>
          </div>
          {renderLocationInput()}
        </div>
        <div className={`d-flex justify-content-evenly ${styles.captureBox}`}>
          <div className={styles.captureImage}>
            <BoxCapture
              width={430}
              height={430}
              boxSize={boxSize}
              disabled={false}
              imageUrl={imageUrl}
              ocrResult={setBoxSample}
              handleCapture={setImageUrl}
              handleCaptureStart={() => setImageUrl("")}
              className={styles.image}
              scale={4}
              deviceId={localStorage.getItem(BOX_DEVICE)}
            />
            <div
              className={styles.captureBoxOverlay}
              style={{
                left: `${-35 + (captureBoxPosition?.x || 0)}px`,
                top: `${45 + (captureBoxPosition?.y || 0)}px`,
              }}
            >
            </div>
          </div>
          <div className={styles.line} />
          <div className="d-flex flex-column">
            <div className={styles.captureBoxInfo}>
              <CaptureBox
                boxValues={watchBox}
                control={control}
                boxSize={boxSize}
                loading={loading}
                tubeWidth={78}
                tubeHeight={35}
              />
            </div>
            <div className={`d-flex justify-content-end ${styles.buttonSave}`}>
              <Button
                className="rounded-5"
                variant="light"
                size="sm"
                type="submit"
              >
                <FormattedMessage id="BUTTON.SAVE" defaultMessage="SAVE" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </FormModal>
  );
};

export default StorageLocation;
