import { QueryType, ResolvedQuery } from "../models/query.model";
import { TableParams } from "../models/requests/table.request";
import { vendorService } from "../services/vendor.service";

type VendorsQuery = QueryType<ResolvedQuery<typeof vendorService.getVendors>>;
type VendorsQueryOptions = Omit<VendorsQuery, "queryKey" | "queryFn">;

export const vendorQuery = (
  { pageNumber }: TableParams,
  options?: VendorsQueryOptions
): VendorsQuery => ({
  ...options,
  queryKey: ["vendors", pageNumber],
  queryFn: () => vendorService.getVendors({ pageNumber }),
  keepPreviousData: true,
});

type VendorsNamesQuery = QueryType<ResolvedQuery<typeof vendorService.getVendorsNames>>;
type VendorsNamesQueryOptions = Omit<VendorsNamesQuery, "queryKey" | "queryFn">;
export const vendorsNamesQuery = (
options?: VendorsNamesQueryOptions
): VendorsNamesQuery => ({
  ...options,
  queryKey: ["vendors names"],
  queryFn: () => vendorService.getVendorsNames()
});
