import { QueryType, ResolvedQuery } from "../models/query.model";
import { shipmentService } from "../services/shipment.service";

type ShipmentQuery = QueryType<ResolvedQuery<typeof shipmentService.getShipment>>;
type ShipmentQueryOptions = Omit<ShipmentQuery, "queryKey" | "queryFn">;

export const getShipmentQuery = (
  shipmentNumber: string,
  options?: ShipmentQueryOptions
): ShipmentQuery => ({
  ...options,
  queryKey: ["get shipment", shipmentNumber],
  queryFn: () => shipmentService.getShipment(shipmentNumber),
  keepPreviousData: true,
  refetchOnWindowFocus: false,
});

type ShipmentsQuery = QueryType<ResolvedQuery<typeof shipmentService.getShipments>>;
type ShipmentsQueryOptions = Omit<ShipmentsQuery, "queryKey" | "queryFn">;

export const shipmentsQuery = (
  pageNumber: number,
  options?: ShipmentsQueryOptions
): ShipmentsQuery => ({
  ...options,
  queryKey: ["get shipments", pageNumber],
  queryFn: () => shipmentService.getShipments(pageNumber),
  keepPreviousData: true,
});
