import { FormattedMessage } from "react-intl";
import FormInput from "../../../../components/form/FormInput/FormInput";
import { FormGroup } from "../../../../components/form/FormGroup/FormGroup";
import RadioButtonGroup from "../../../../components/form/RadioButtonGroup/RadioButtonGroup";
import { MetadataForm, SubjectDetailsType } from "./MetaData";
import { useLocation } from "react-router";
import { Control } from "react-hook-form";
import styles from "./Metadata.module.css";
import { MetadataParams } from "../../../../models/requests/metadata.request";

export const sexList = ["Male", "Female", "Other"];

type GeneralProps = {
  control: Control<MetadataForm, any>;
  metadata?: MetadataParams;
};

const General = ({ control, metadata }: GeneralProps) => {
  const { state } = useLocation();

  const subjectDetails: SubjectDetailsType[] = state.subjectDetails;
  return (
    <div className="ms-3">
      {subjectDetails?.length && (
        <>
          <h5 className="mt-5">
            <FormattedMessage
              id="CLINICAL.METADATA.GENERAL"
              defaultMessage="General"
            />
          </h5>
          {subjectDetails.includes("Sex") && (
            <div className="d-flex">
              <b className="ms-4">
                <FormattedMessage
                  id="CLINICAL.METADATA.SEX"
                  defaultMessage="Sex"
                />
              </b>
              <RadioButtonGroup
                radioButtonsArray={sexList.map((sex, index) => ({
                  label: sex,
                  value: index,
                }))}
                defValue={metadata?.sex || 0}
                name={"sex"}
                control={control}
                className="ms-3"
              />
            </div>
          )}
          <div className="d-flex flex-wrap">
            {subjectDetails.includes("BirthDate") && (
              <FormGroup
                className={styles.detail}
                input={
                  <FormInput
                    type="text"
                    name="dateOfBirth"
                    control={control}
                    input={{
                      type: "number",
                      className: `${styles.input} ${styles.general}`,
                    }}
                  />
                }
                label={
                  <FormattedMessage
                    id="CLINICAL.METADATA.AGE"
                    defaultMessage="Age"
                  />
                }
              />
            )}
            {subjectDetails.includes("Weight") && (
              <FormGroup
                className={styles.detail}
                input={
                  <FormInput
                    type="text"
                    name="weight"
                    control={control}
                    input={{
                      type: "number",
                      min: 0,
                      step: 0.01,
                      className: `${styles.input} ${styles.general}`,
                    }}
                  />
                }
                label={
                  <FormattedMessage
                    id="CLINICAL.METADATA.WEIGHT"
                    defaultMessage="Weight"
                  />
                }
              />
            )}
            {subjectDetails.includes("Height") && (
              <FormGroup
                className={styles.detail}
                input={
                  <FormInput
                    type="text"
                    name="height"
                    control={control}
                    input={{
                      type: "number",
                      min: 0,
                      step: 0.01,
                      className: `${styles.input} ${styles.general}`,
                    }}
                  />
                }
                label={
                  <FormattedMessage
                    id="CLINICAL.METADATA.HEIGHT"
                    defaultMessage="Height"
                  />
                }
              />
            )}
            {subjectDetails.includes("Education") && (
              <FormGroup
                className={styles.detail}
                input={
                  <FormInput
                    type="text"
                    name="education"
                    control={control}
                    input={{
                      type: "number",
                      min: 0,
                      className: `${styles.input} ${styles.general}`,
                    }}
                  />
                }
                label={
                  <FormattedMessage
                    id="CLINICAL.METADATA.EDUCATION"
                    defaultMessage="Education"
                  />
                }
              />
            )}
            {subjectDetails.includes("Pregnant") && (
              <FormGroup
                className={styles.detail}
                input={
                  <FormInput
                    type="switch"
                    name="pregnant"
                    control={control}
                    input={{ className: "ms-5" }}
                  />
                }
                label={
                  <FormattedMessage
                    id="CLINICAL.METADATA.PREGNANT"
                    defaultMessage="Pregnant"
                  />
                }
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default General;
