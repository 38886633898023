import { UseMutationOptions } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import { VendorResponse } from "../models/responses/vendor.response";
import { UpdateVendorParams, VendorParams } from "../models/requests/vendor.request";
import { vendorService } from "../services/vendor.service";
import {VendorDto} from "../api";

export const addVendorMutation = (): UseMutationOptions<
  IApiResult<VendorDto> | null,
  unknown,
  VendorParams
> => ({
  mutationKey: ["add vendor"],
  mutationFn: async (vendor: VendorParams) =>  await  vendorService.addVendor(vendor)});

export const editVendorMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  { vendor: UpdateVendorParams }
> => ({
  mutationKey: ["edit vendor"],
  mutationFn: ({ vendor }: { vendor: UpdateVendorParams }) =>
   vendorService.editVendor(vendor)
});

export const deleteVendorMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  string
> => ({
  mutationKey: ["delete vendor"],
  mutationFn: (id: string) =>
     vendorService.deleteVendor(id)
});
