import { IApiResult } from "../models/responses/api-result.response";
import Logger from "../util/logger.util";
import { FilterProcessSamplesParams, FilterSamplesParams } from "../models/requests/sampleReport.request";
import { FullSampleDataDto, SampleDto } from "../api/models";
import { SampleCapscanApi } from "../api/apis/sample-capscan-api";
import { SampleProcessApi } from "../api/apis/sample-process-api";

const logger = new Logger({ source: "Sample Service" });

export const sampleReportService = {
    getFilterSample: async (
        {study, patient, includeImages}: FilterSamplesParams
    ): Promise<IApiResult<FullSampleDataDto[]> | null> => await getFilterSample({includeImages, study, patient}),
    getFilterProcessSample: async (
        { kitSubjectId, studyId }: FilterProcessSamplesParams
    ): Promise<IApiResult<SampleDto[]> | null> => await getFilterProcessSample({ kitSubjectId, studyId }),
};

const getFilterSample= async (
    {includeImages, study, patient}: FilterSamplesParams
): Promise<IApiResult<FullSampleDataDto[]> | null> => {
    const api = new SampleCapscanApi();
    const { data: response } = await api.getFilterSamples( includeImages, study, patient);

    const { success, statusCode } = response;
    if (!success) {
        logger.error("Sample Filter invalid API response", { statusCode });
        return null;
    }
    return response;
};


const getFilterProcessSample = async (
    { kitSubjectId, studyId }: FilterProcessSamplesParams
): Promise<IApiResult<SampleDto[]> | null> => {
    const api = new SampleProcessApi();
    const { data: response } = await api.getFilterProcessSamples( kitSubjectId, studyId );

    const { success, statusCode } = response;
    if (!success) {
        logger.error("Process Sample Filter invalid API response", { statusCode });
        return null;
    }
    return response;
};
