import { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import Table, { DataType } from "../../../../components/Table/Table";
import { useQuery } from "@tanstack/react-query";
import { studiesQuery } from "../../../../queries/study.query";
import AddIcon from "../../../../assets/add.svg";
import EditIcon from "../../../../assets/edit.svg";
import styles from "./StudiesTable.module.css";
import useTableHelper from "../../../../hooks/useTableHelper";
import Study from "./Study/Study";
import { statuses } from "../../../../components/Status/Status";
import { Row } from "react-table";
import { StudyResponse } from "../../../../models/responses/study.response";

const columnsNames = ["studyName", "protocolNumber", "letter"];

export const studyStatuses = [
  statuses.ACTIVE,
  statuses.PENDING,
  statuses.COMPLETED,
  statuses.TERMINATED,
];
export type StudyStatus = typeof studyStatuses[number];

const StudiesTable = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { isLoading, isError, data: response, refetch } = useQuery(
    studiesQuery({ pageNumber })
  );
  const [selectedStudy, setSelectedStudy] = useState<StudyResponse | undefined>(
    undefined
  );

  const { getColumns, handleRowActionClick } = useTableHelper();

  const buttons = [
    {
      label: <FormattedMessage id="TABLE.BUTTON.ADD" defaultMessage="Add" />,
      onClick: () => {
        setShowModal(true);
      },
      icon: AddIcon,
    },
    {
      label: <FormattedMessage id="TABLE.BUTTON.EDIT" defaultMessage="Edit" />,
      onClick: (
        setSelectedRow: (row?: Row<DataType>) => void,
        row?: Row<DataType>
      ) => {
        row &&
          handleRowActionClick(
            setShowModal,
            setSelectedStudy,
            setSelectedRow,
            row,
            response
          );
      },
      icon: EditIcon,
      disabled: true,
    },
  ];

  const handlePaginate = useCallback((page: number) => {
    setPageNumber(page);
  }, []);

  const getData = () => {
    if (response?.data?.items) {
      const { items } = response?.data;
      return items!.map((study) => ({
        ...study,
      }));
    }
    return undefined;
  };

  return (
    <div className={styles.studies}>
      <div>
        <Table
          columns={getColumns("ENVIVO.STUDY.TABLE.COLUMN", columnsNames)}
          data={getData()}
          isLoading={isLoading}
          isError={isError}
          className={styles.table}
          pageCount={response?.data?.pageCount || 0}
          onPaginate={handlePaginate}
          buttons={buttons}
        />
        {showModal && <Study
          show={showModal}
          handleHide={() => {
            setShowModal(false);
            setSelectedStudy(undefined);
          }}
          refetch={refetch}
          study={selectedStudy}
        />}
      </div>
    </div>
  );
};

export default StudiesTable;
