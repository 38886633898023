import { Stepper } from "react-form-stepper";
import "./WizardStepper.module.css";

type WizardStepperProps = {
  steps: { label: string }[];
  currentStep: number;
};

function WizardStepper({ steps, currentStep }: WizardStepperProps) {
  return (
    <Stepper
      styleConfig={{
        activeBgColor: "#106FCD",
        completedBgColor: "#8AB3DB",
        inactiveBgColor: "#ffffff",
        activeTextColor: "#ffffff",
        borderRadius: "50%",
        inactiveTextColor: "#4D6278",
        completedTextColor: "#4D6278",
        size: "2em",
        circleFontSize: "1rem",
        labelFontSize: "0.875rem",
        fontWeight: 500,
      }}
      steps={steps}
      activeStep={currentStep}
    />
  );
}

export default WizardStepper;
