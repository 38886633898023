import { UseMutationOptions } from "@tanstack/react-query";
import { UpdateUserParams, UserParams } from "../models/requests/user.request";
import { IApiResult } from "../models/responses/api-result.response";
import { UserResponse } from "../models/responses/user.response";
import { userService } from "../services/user.service";

export const addUserMutation = (): UseMutationOptions<
  IApiResult<UserResponse> | null,
  unknown,
  UserParams
> => ({
  mutationKey: ["add user"],
  mutationFn: async (user: UserParams) =>  await  userService.addUser(user)});

export const editUserMutation = (): UseMutationOptions<
  IApiResult<UserResponse> | null,
  unknown,
  { user: UpdateUserParams }
> => ({
  mutationKey: ["edit user"],
  mutationFn: ({ user }: { user: UpdateUserParams }) =>
   userService.editUser(user)
});

export const deleteUserMutation = (): UseMutationOptions<
  IApiResult<void> | null,
  unknown,
  string
> => ({
  mutationKey: ["delete user"],
  mutationFn: (id: string) =>
     userService.deleteUser(id)
});

