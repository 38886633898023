import { SiteApi } from "../api/apis/site-api";
import {
  ClinicParams,
  StudyClinicParams,
  StudyQueryParams,
  ClinicDepartmentParams,
  ClinicEditParams,
  StudyClinicEditParams,
} from "../models/requests/site.request";
import { IApiResult } from "../models/responses/api-result.response";
import {
  ClinicResponse,
  StudyDepartmentResponse,
  ClinicDepartmentResponse,
  Clinic,
} from "../models/responses/site.response";
import Logger from "../util/logger.util";
import { UpdateClinicDepartmentDto } from "../api";

const logger = new Logger({ source: "site Service" });

export const siteService = {
  getAll: async (): Promise<IApiResult<ClinicResponse[]> | null> =>
    await getAll(),
  getStudies: async ({
    clinicDepartmentId,
  }: StudyQueryParams): Promise<IApiResult<StudyDepartmentResponse[]> | null> =>
    await getStudies({ clinicDepartmentId }),
  getClinicDepartments: async (): Promise<IApiResult<
    ClinicDepartmentResponse[]
  > | null> => await getClinicDepartments(),
  getClinicDepartmentsWithClinicNames: async (): Promise<IApiResult<
    ClinicDepartmentResponse[]
  > | null> => await getClinicDepartmentsWithClinicNames(),
  addClinic: async ({
    name,
  }: ClinicParams): Promise<IApiResult<ClinicResponse> | null> =>
    await addClinic({ name }),
  addClinicDepartment: async ({
    name,
    shipmentAddress,
    clinicId,
  }: ClinicDepartmentParams): Promise<IApiResult<ClinicDepartmentResponse> | null> =>
    await addClinicDepartment({ name, shipmentAddress, clinicId }),
  addStudy: async (
    study: StudyClinicParams
  ): Promise<IApiResult<StudyDepartmentResponse> | null> =>
    await addStudy(study),
  editStudy: async (
    study: StudyClinicEditParams
  ): Promise<IApiResult<void> | null> => await editStudy(study),
  deleteStudy: async (id: string): Promise<IApiResult<Clinic> | null> =>
      await deleteStudy(id),
  deleteClinic: async (id: string): Promise<IApiResult<Clinic> | null> =>
    await deleteClinic(id),
  editClinic: async (
    clinic: ClinicEditParams
  ): Promise<IApiResult<ClinicResponse> | null> => await editClinic(clinic),
  deleteClinicDepartment: async (
    id: string
  ): Promise<IApiResult<Clinic> | null> => await deleteClinicDepartment(id),
  editClinicDepartment: async (
    clinicDep: UpdateClinicDepartmentDto
  ): Promise<IApiResult<void> | null> => await editClinicDepartment(clinicDep),
};

const getAll = async (): Promise<IApiResult<ClinicResponse[]> | null> => {
  logger.info(`get clinics and sub clinics`);
  const api = new SiteApi();
  const { data: response } = await api.getAllClinicsAndClinicDepartments();

  const { success, statusCode } = response;

  if (!success) {
    logger.error("Get sites invalid API response", { statusCode });
    return null;
  }

  return response;
};

const getStudies = async ({
  clinicDepartmentId,
}: StudyQueryParams): Promise<IApiResult<StudyDepartmentResponse[]> | null> => {
  logger.info(`get studies of sub clinic ${clinicDepartmentId}`);
  const api = new SiteApi();
  const { data: response } = await api.getDepartmentStudiesOfClinicDepartment(
    clinicDepartmentId
  );
  const { success, statusCode } = response;

  if (!success) {
    logger.error("Get sites invalid API response", { statusCode });
    return null;
  }
  return response;
};

const getClinicDepartments = async (): Promise<IApiResult<
  ClinicDepartmentResponse[]
> | null> => {
  const api = new SiteApi();
  const { data: response } = await api.getAllClinicDepartments();
  const { success, statusCode } = response;

  if (!success) {
    logger.error("Get sites invalid API response", { statusCode });
    return null;
  }
  return response;
};

const getClinicDepartmentsWithClinicNames = async (): Promise<IApiResult<
  ClinicDepartmentResponse[]
> | null> => {
  const api = new SiteApi();
  const { data: response } = await api.getAllClinicDepartmentsWithClinicNames();
  const { success, statusCode } = response;

  if (!success) {
    logger.error("Get sites invalid API response", { statusCode });
    return null;
  }
  return response;
};

const deleteClinicDepartment = async (
  id: string
): Promise<IApiResult<Clinic> | null> => {
  const api = new SiteApi();
  const { data: response } = await api.deleteClinicDepartment(id);
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Delete Clinic Department invalid API response", {
      statusCode,
    });
    return response;
  }
  return response;
};

const editClinic = async (
  clinic: ClinicEditParams
): Promise<IApiResult<ClinicResponse> | null> => {
  const api = new SiteApi();
  const { data: response } = await api.updateClinic(clinic);
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Edit Clinic invalid API response", { statusCode });
    return response;
  }
  return response;
};

const deleteClinic = async (id: string): Promise<IApiResult<Clinic> | null> => {
  const api = new SiteApi();
  const { data: response } = await api.deleteClinic(id);
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Delete Clinic invalid API response", { statusCode });
    return response;
  }
  return response;
};

const deleteStudy = async (id: string): Promise<IApiResult<Clinic> | null> => {
  const api = new SiteApi();
  const { data: response } = await api.deleteStudy(id);
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Delete Study invalid API response", { statusCode });
    return response;
  }
  return response;
};

const addClinic = async ({
  name,
}: ClinicParams): Promise<IApiResult<ClinicResponse> | null> => {
  logger.info(`add clinic ${name}`);
  const api = new SiteApi();
  const { data: response } = await api.createClinic({ name });
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Get sites invalid API response", { statusCode });
    return response;
  }
  return response;
};

const editClinicDepartment = async (
  clinicDep: UpdateClinicDepartmentDto
): Promise<IApiResult<void> | null> => {
  const api = new SiteApi();
  const { data: response } = await api.updateClinicDepartment(clinicDep);
  const { success, statusCode } = response;
  if (!success) {
    logger.error("Edit Clinic invalid API response", { statusCode });
    return response;
  }
  return response;
};

const addClinicDepartment = async ({
  name,
  shipmentAddress,
  clinicId,
}: ClinicDepartmentParams): Promise<IApiResult<ClinicDepartmentResponse> | null> => {
  const api = new SiteApi();
  const { data: response } = await api.addClinicDepartment({
    name,
    shipmentAddress,
    parentId: clinicId,
  });
  const { success, statusCode } = response;

  if (!success) {
    logger.error("Get sites invalid API response", { statusCode });
    return response;
  }
  return response;
};
const addStudy = async (
  study: StudyClinicParams
): Promise<IApiResult<StudyDepartmentResponse> | null> => {
  logger.info(`add study ${study.studyId}`);
  const api = new SiteApi();
  const { data: response } = await api.addDepartmentStudy({
    contactEmail: study.email,
    contactPhone: study.phone,
    studyId: study.studyId,
    contactPersonName: study.contactPerson,
    principalInvestigator: study.principalInvestigator,
    parentId: study.parentId,
    status: study.studyStatus,
  });
  const { success, statusCode } = response;

  if (!success) {
    logger.error("Get sites invalid API response", { statusCode });
    return response;
  }
  return response;
};

const editStudy = async (
  study: StudyClinicEditParams
): Promise<IApiResult<void> | null> => {
  logger.info(`add study ${study.studyId}`);
  const api = new SiteApi();
  const { data: response } = await api.updateDepartmentStudy({
    id: study.id,
    contactEmail: study.email,
    contactPhone: study.phone,
    studyId: study.studyId,
    contactPersonName: study.contactPerson,
    principalInvestigator: study.principalInvestigator,
    status: study.studyStatus,
  });
  const { success, statusCode } = response;

  if (!success) {
    logger.error("Get sites invalid API response", { statusCode });
    return response;
  }
  return response;
};
