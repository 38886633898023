import { useMutation } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import { ProcessCapscanProps } from "../pages/envivo/Home/After/ProcessCapscan/ProcessCapscan";
import { addProcessCapscanMutation } from "../mutations/processCapscan.mutation";
import { ProcessCapscanParams } from "../models/requests/processCapscan.request";

const useProcessCapscan = () => {
    const { mutateAsync: addProcessCapscan } = useMutation(addProcessCapscanMutation());
  
    async function add(processCapscan: ProcessCapscanParams): Promise<IApiResult<void> | null> {
      return await addProcessCapscan(processCapscan, {
        onSuccess: (response) => {
          if (!response?.success) {
            alert(response?.message);
          }
          return response;
        },
      });
    }
    
  return { add };
};

export default useProcessCapscan;
