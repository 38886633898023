import { useMutation } from "@tanstack/react-query";
import { UpdateUserParams, UserParams } from "../models/requests/user.request";
import {
  addUserMutation,
  editUserMutation,
  deleteUserMutation,
} from "../mutations/user.mutation";
import { IApiResult } from "../models/responses/api-result.response";
import { UserResponse } from "../models/responses/user.response";


function useUser() {
  const { mutateAsync: mutateAddUser } = useMutation(addUserMutation());
  const { mutateAsync: mutateEditUser } = useMutation(editUserMutation());
  const { mutateAsync: mutateDeleteUser } = useMutation(deleteUserMutation());

  async function addUser(user: UserParams): Promise<IApiResult<UserResponse> |null>  {
  return  await mutateAddUser(user, {
      onSuccess: (response) => {
        if (!response?.success) {
          alert(response?.message);
        }
        return response!;
      },
    });
  }

  async function editUser(
    user: UpdateUserParams): Promise<IApiResult<UserResponse> |null> {
   return await mutateEditUser(
     { user },
     {
       onSuccess: (response) => {
         if (!response?.success) {
           alert(response?.message);
         }
         return response;
       },
     }
   );
  }

  async function deleteUser(id: string): Promise<IApiResult<void> | null> {
    return await mutateDeleteUser(id, {
      onSuccess: (response) => {
        if (!response?.success) {
          alert(response?.message);
        }
        return response
      },
    });
  }

  return { addUser, editUser, deleteUser };
}

export default useUser;
