import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { BaseIcon } from "../../../../components/BaseIcon/BaseIcon";
import AddMetadata from "../../../../assets/add_metadata.svg";
import { Button } from "../../../../components/Button/Button";
import { FormModal } from "../../../../components/form/FormModal/FormModal";
import FormInput from "../../../../components/form/FormInput/FormInput";
import styles from "./Metadata.module.css";
import General from "./General";
import Details, { DetailsType } from "./Details";
import JsonFormBuilder from "../../../../components/JsonFormBuilder/JsonFormBuilder";
import { useLocation } from "react-router";
import useKit from "../../../../hooks/useKit";
import { MetadataParams } from "../../../../models/requests/metadata.request";
import { FormGroup } from "../../../../components/form/FormGroup/FormGroup";

export type MetadataForm = {
  study: string;
  tId: string;
  sex: number;
  dateOfBirth: number;
  weight: number;
  height: number;
  pregnant: boolean;
  education: number;
  medicine: DetailsType[];
  conditions: DetailsType[];
  questions: object;
};

export type SubjectDetailsType =
  | "Sex"
  | "Pregnant"
  | "BirthDate"
  | "Weight"
  | "Height"
  | "Education";

type MetadataProps = {
  studyName: string;
  tid: string;
  metadata: MetadataParams;
  setShow: (show: boolean) => void;
  refetch: () => void;
};

export const MetadataModal = ({
  studyName,
  tid,
  metadata,
  setShow,
  refetch,
}: MetadataProps) => {
  const { control, setValue, handleSubmit, reset } = useForm<MetadataForm>();
  const { state } = useLocation();
  const { editMetadata } = useKit();

  useEffect(() => reset({ ...metadata }), []);

  const header = (
    <div>
      <BaseIcon icon={AddMetadata} />{" "}
      <b className="fs-5 mt-1">
        <FormattedMessage
          id="CLINICAL.METADATA"
          defaultMessage="Subject Metadata"
        />
      </b>
    </div>
  );

  const footer = (
    <>
      <Button variant="outline-dark" onClick={() => setShow(false)}>
        <FormattedMessage id="BUTTON.CANCEL" defaultMessage="Cancel" />
      </Button>
      <Button
        type="submit"
        variant="outline-dark"
        className="border-dark"
        form="metadata"
      >
        <FormattedMessage id="BUTTON.SAVE" defaultMessage="Save" />
      </Button>
    </>
  );

  const onSubmit = async (formState: MetadataForm) => {
    const education = formState.education && Number(formState.education);
    const weight = formState.weight && Number(formState.weight);
    const height = formState.height && Number(formState.height);
    const sex = formState.sex !== undefined ? Number(formState.sex) : undefined;

    const response = await editMetadata({
      ...formState,
      education,
      weight,
      height,
      sex,
      kitBarcode: metadata.kitBarcode,
    });
    if (response?.success) {
      refetch();
      setShow(false);
    }
  };

  return (
    <FormModal
      className={`modal-lg modal-dialog-centered modal-dialog-scrollable`}
      onSubmit={handleSubmit(onSubmit)}
      header={header}
      footer={footer}
      show={true}
      id="metadata"
      handleClose={() => setShow(false)}
    >
      <div className="d-flex">
        <FormGroup
          label={
            <h6>
              <FormattedMessage
                id="CLINICAL.STUDY.SELECT.STUDY"
                defaultMessage="Study"
              />
            </h6>
          }
          input={
            <FormInput
              type="text"
              name="study"
              control={control}
              defaultValue={studyName}
              rules={{
                required: true,
              }}
              input={{
                type: "text",
                disabled: true,
                className: `${styles.input} ${styles.study} me-3`,
              }}
            />
          }
        />
        <FormGroup
          label={
            <h6>
              <FormattedMessage
                id="CLINICAL.STUDY.SELECT.TID"
                defaultMessage="TID"
              />
            </h6>
          }
          input={
            <FormInput
              type="text"
              name="tId"
              control={control}
              defaultValue={tid}
              input={{
                type: "text",
                disabled: true,
                className: `${styles.input} ${styles.tid}`,
              }}
            />
          }
        />
      </div>
      <General control={control} metadata={metadata} />
      <Details
        setValue={setValue}
        conditions={metadata.conditions || []}
        medicine={metadata.medicine || []}
      />
      {state.jsonQuestions && (
        <JsonFormBuilder
          json={state.jsonQuestions}
          onChange={(state) => {
            setValue("questions", state);
          }}
          defaultValues={metadata.questions}
        />
      )}
    </FormModal>
  );
};
