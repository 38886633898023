import { QueryType, ResolvedQuery } from "../models/query.model";
import { TableParams } from "../models/requests/table.request";
import { userService } from "../services/user.service";

type UsersQuery = QueryType<ResolvedQuery<typeof userService.getUsers>>;
type UsersQueryOptions = Omit<UsersQuery, "queryKey" | "queryFn">;

export const usersQuery = (
  { pageNumber }: TableParams,
  options?: UsersQueryOptions
): UsersQuery => ({
  ...options,
  queryKey: ["users", pageNumber],
  queryFn: () => userService.getUsers({ pageNumber }),
  keepPreviousData: true,
});