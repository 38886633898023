import { useMutation } from "@tanstack/react-query";
import { UpdateUserParams, UserParams } from "../models/requests/user.request";
 
import { IApiResult } from "../models/responses/api-result.response";
import { UserResponse } from "../models/responses/user.response";
import { LinkDataParams } from "../models/requests/linkData.request";
import { linkDataMutation } from "../mutations/linkData.mutation";
import { DataLinkResponse } from "../models/responses/dataLink.response";


function useLinkData() {
  const { mutateAsync: mutateLinkData } = useMutation(linkDataMutation());

  async function linkData(linkData: LinkDataParams): Promise<IApiResult<DataLinkResponse> |null>  {
  return  await mutateLinkData(linkData, {
      onSuccess: (response) => {
        if (!response?.success) {
          alert(response?.message);
        }
        return response;
      },
    });
  }
  return { linkData }
}
 

export default useLinkData;