import { FormattedMessage, useIntl } from "react-intl";
import Status, { statuses } from "../../../../../../components/Status/Status";
import { useForm } from "react-hook-form";
import { makeDirtyObject } from "../../../../../../util/form.util";
import EditableCell from "../../../../../../components/EditTable/EditableCell/EditableCell";
import { Button } from "react-bootstrap";
import styles from "../ShipmentsTable.module.css";
import FormInput from "../../../../../../components/form/FormInput/FormInput";
import { ShipmentResponse } from "../../../../../../models/responses/shipment.response";
import useShipment from "../../../../../../hooks/useShipment";
import EditIcon from "../../../../../../assets/edit.svg";
import {
  getFormattedDate,
  getFormattedDates,
} from "../../../../../../util/date.util";
import { useEffect } from "react";
import { BaseIcon } from "../../../../../../components/BaseIcon/BaseIcon";

const couriersList = [
  { key: "FedEx", value: "FedEx" },
  { key: "DHL", value: "DHL" },
  { key: "World Courier", value: "World Courier" },
];

const shipmentStatuses = [statuses.SHIPPED, statuses.RECEIVED];

type ShipmentFormProps = {
  id: string;
  clinicDepartmentId: string;
  studyDepartmentId: string;
  shipmentNumber: string;
  kits: string[];
  dateOfReceiptBySite?: string;
  courier?: string;
  shipmentDate?: string;
  deliveryDate?: string;
  currentStatus: number;
  clinic: string
};

type RowProps = {
  shipment: ShipmentResponse ;
  refetch: () => void;
  setShow: (show: boolean) => void;
  setReadOnly: (readOnly: boolean) => void;
  setShipment: (shipment: ShipmentResponse) => void;
};

const ShipmentRow = ({
  shipment,
  refetch,
  setShow,
  setReadOnly,
  setShipment,
}: RowProps) => {
  const { editShipment } = useShipment();
  const intl = useIntl();
  const {
    clinic,
    shipmentNumber,
    kits,
    dateOfReceiptBySite,
    courier,
    shipmentDate,
    deliveryDate,
    currentStatus,
    studyName
  } = shipment;
  const format = "YYYY-MM-DD";

  const { exportShipment } = useShipment();

  const convertDatesToStrings = () => ({
    shipmentNumber,
    kits,
    courier,
    currentStatus,
    ...getFormattedDates(
      { dateOfReceiptBySite, shipmentDate, deliveryDate },
      format
    ),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    getFieldState,
    setValue,
    reset
  } = useForm<ShipmentFormProps>({
    defaultValues: convertDatesToStrings(),
  });

  const handleExport = async () => {
    try {
      const anchorElement = document.createElement("a");
      anchorElement.style.display = "none";
      document.body.appendChild(anchorElement);
      const fileContent = await exportShipment(shipmentNumber);
      if (fileContent?.success && fileContent.data) {
        const blobFile = new Blob([fileContent?.data || ""], { type: "csv" });
        const url = window.URL.createObjectURL(blobFile);
        anchorElement.href = url;
        anchorElement.download = `shipment_${shipmentNumber}.csv`;
        anchorElement.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const onsubmit = async (formState: ShipmentFormProps) => {
    const dirtyObject = makeDirtyObject(
      (key: keyof ShipmentFormProps) => getFieldState(key).isDirty,
      formState
    );
    const response = await editShipment({
      ...dirtyObject,
      id: shipment.id,
      studyDepartmentId: shipment.studyDepartmentId,
    });
    if (response?.success) {
      await refetch();
      reset();
    }
  };

  useEffect(() => {
    setValue("courier", shipment.courier, { shouldDirty: false });
  }, [shipment,setValue]);

  return (
    <>
      <tr>
        <td width={"10%"}>{shipmentNumber}</td>
        <td width={"10%"}>{clinic}</td>
        <td width={"10%"}>{studyName}</td>
        <td width={"10%"}>
          <button
            className={`text-primary border-primary ${styles.linkButton}`}
            onClick={() => {
              setShipment(shipment);
              setReadOnly(true);
              setShow(true);
            }}
          >
            {kits.length}
          </button>
        </td>
        <td width={"12%"}>
          <EditableCell
            defaultValue={
              dateOfReceiptBySite &&
              getFormattedDate(dateOfReceiptBySite, format)
            }
            type={"date"}
            control={control}
            name="dateOfReceiptBySite"
            required={true}
          />
        </td>
        <td width={"15%"}>
          <FormInput
            type={"select"}
            name="courier"
            control={control}
            defaultValue={couriersList.find((c) => courier === c.value)?.key}
            input={{
              items: couriersList,
              placeholder: intl.formatMessage({
                id: "ENVIVO.SHIPMENT.TABLE.COURIER",
                defaultMessage: "Courier",
              }),
              className: styles.input,
            }}
          />
        </td>
        <td width={"12%"}>
          <EditableCell
            defaultValue={
              shipmentDate && getFormattedDate(shipmentDate, format)
            }
            type={"date"}
            control={control}
            name="shipmentDate"
            required={true}
          />
        </td>
        <td width={"12%"}>
          <EditableCell
            defaultValue={
              deliveryDate && getFormattedDate(deliveryDate, format)
            }
            type={"date"}
            control={control}
            name="deliveryDate"
            required={true}
          />
        </td>
        <td width={"10%"}>
          <Status status={shipmentStatuses[currentStatus]} />
        </td>
        <td>
          <Button
            variant="outline-dark"
            className="border-dark rounded-5 text-nowrap"
            onClick={handleExport}
          >
            <FormattedMessage
              id="ENVIVO.SHIPMENT.TABLE.EXPORT"
              defaultMessage="Export to CSV"
            />
          </Button>
        </td>
        <td>
          <BaseIcon
            icon={EditIcon}
            onClick={() => {
              setShipment(shipment);
              setReadOnly(false);
              setShow(true);
            }}
            className="mt-2"
          />
        </td>
        <td>
          {isDirty && (
            <Button
              variant="success"
              className="rounded-5"
              onClick={handleSubmit(onsubmit)}
            >
              <FormattedMessage id="BUTTON.SAVE" defaultMessage="Save" />
            </Button>
          )}
        </td>
      </tr>
    </>
  );
};

export default ShipmentRow;
