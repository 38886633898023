import { AutheticationApi } from "../api/apis/authetication-api";
import { UserLogin } from "../api/models/user-login";
import { LoginParams } from "../models/requests/login.request";
import { IApiResult } from "../models/responses/api-result.response";
import Logger from "../util/logger.util";

const logger = new Logger({ source: "Authentication Service", off: true });

export const authService = {
  login: async ({
    username,
    password,
  }: LoginParams): Promise<IApiResult<UserLogin> | null> =>
    await login({ username, password }),
};
const authApi = new AutheticationApi();
const login = async ({
  username,
  password,
}: LoginParams): Promise<IApiResult<UserLogin> | null> => {
  logger.info(`login ${username}...`);

  const response = await authApi.login({ userName: username, password });//
  
  const { data, success, statusCode } = response.data;

  if (!success) {
    logger.error("login Invalid API response", { data, statusCode });
    return null;
  }

  return response.data;
};
