import Tesseract from "tesseract.js";

export const toIntlId = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, "$1_$2").toUpperCase();
};

export const itnlIdToString = (str: string) => {
  const formattedString = str.replace(/([a-z])([A-Z])/g, "$1 $2");
  return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
};

export const getOcrWorker = async () => {
  var ret = await Tesseract.createWorker("eng", Tesseract.OEM.LSTM_ONLY);
  return ret;
};

export const recognizeOcr = async (
  worker: Tesseract.Worker,
  croppedImage: string,
  whiteList?: string
) => {
  if (whiteList) {
    await worker.setParameters({
      tessedit_char_whitelist: whiteList,
      tessedit_pageseg_mode: Tesseract.PSM.SINGLE_BLOCK,
    });
  }
  const {
    data: { text },
  } = await worker.recognize(croppedImage);
  return text;
};
