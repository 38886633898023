import { QueryType, ResolvedQuery } from "../models/query.model";
import { TableParams } from "../models/requests/table.request";
import { studyService } from "../services/study.service";

type StudiesQuery = QueryType<ResolvedQuery<typeof studyService.getStudies>>;
type StudyNames = QueryType<ResolvedQuery<typeof studyService.getStudyNames>>;
type StudiesQueryOptions = Omit<StudiesQuery, "queryKey" | "queryFn">;
type StudyNamesQueryOptions = Omit<StudyNames, "queryKey" | "queryFn">;

export const studiesQuery = (
  { pageNumber }: TableParams,
  options?: StudiesQueryOptions
): StudiesQuery => ({
  ...options,
  queryKey: ["studies", pageNumber],
  queryFn: () => studyService.getStudies({ pageNumber }),
  keepPreviousData: true,
});


export const studyNamesQuery = (
  options?: StudyNamesQueryOptions
): StudyNames => ({
  ...options,
   queryKey: ["studies"],
   queryFn: () => studyService.getStudyNames(),
  keepPreviousData: true,
});