import { Button, Modal } from "react-bootstrap";
import { BaseIcon } from "../BaseIcon/BaseIcon";
import DeleteIcon from "../../assets/delete.svg";
import { FormattedMessage } from "react-intl";

export type DeleteModalPros = {
  handleDelete: () => void,
  handleClose?: () => void,
  show: boolean,
  InternationalizationKey:string
}

export default function DeleteModal({
  show,
  handleClose,
  handleDelete,
  InternationalizationKey
}: DeleteModalPros) {

  return (<Modal
    show={show}
    onHide={handleClose}
  >
    <Modal.Header closeButton>

      {
        <div>
          <BaseIcon icon={DeleteIcon} />
          <FormattedMessage
          id={InternationalizationKey}
          defaultMessage="Delete"
        />
        </div>
      }
    </Modal.Header>
    <Modal.Body>
      <p>
        <FormattedMessage
          id="MODAL.DELETE.BODY"
          defaultMessage="Are you sure you want to delete?"
        />
      </p>
    </Modal.Body>
    <Modal.Footer>{

      <>
        <Button variant="outline-dark" onClick={() =>handleClose && handleClose()}>
          <FormattedMessage id={"ENVIVO.NO"} defaultMessage="No" />
        </Button>
        <Button
          variant="danger"
          className="border-dark"
          onClick={() => handleDelete()}
        >
          <FormattedMessage id="ENVIVO.YES" defaultMessage="Yes" />
        </Button>
      </>

    }</Modal.Footer>
  </Modal>)
}
