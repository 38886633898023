import React, { ReactNode } from "react";
import { Form, Modal } from "react-bootstrap";

type FormModalProps = {
  header: ReactNode;
  footer: ReactNode;
  className?: string;
  dialogClassName?: string;
  contentClassName?: string;
  formClassName?: string;
  onSubmit?: () => {};
  show: boolean;
  handleClose?: () => void;
  id: string;
  children: ReactNode;
  fullscreen?: string | true | undefined;
  backdrop?: boolean | "static" | undefined
};

export const FormModal = ({
  header,
  footer,
  onSubmit,
  className,
  dialogClassName,
  contentClassName,
  formClassName,
  show,
  handleClose,
  id,
  fullscreen,
  backdrop,
  children,
}: FormModalProps) => {
  return (
    <Modal
      backdrop={backdrop}
      dialogClassName={dialogClassName}
      contentClassName={contentClassName}
      className={className}
      show={show}
      fullscreen={fullscreen}
      onHide={handleClose}
      
    >
      <Modal.Header closeButton>{header}</Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit} id={id} className={formClassName}>
          {children}
        </Form>
      </Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
};
