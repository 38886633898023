import { useMutation } from "@tanstack/react-query";
import { IApiResult } from "../models/responses/api-result.response";
import { CreateBoxParams, UpdateBoxParams } from "../models/requests/storageLocation.request";
import { addBoxMutation, editBoxMutation } from "../mutations/storageLocation.mutation";
import { BoxMessageResponse } from "../api";

const useStorageLocation = () => {
  const { mutateAsync: addBox } = useMutation(addBoxMutation());
  const { mutateAsync: editBox } = useMutation(editBoxMutation());

  async function add(box: CreateBoxParams): Promise<BoxMessageResponse | null> {
    return await addBox(box, {
      onSuccess: (response) => {
        if (!response?.success) {
          alert(response?.message);
        }
        return response;
      },
    });
  }

  async function edit(
    box: UpdateBoxParams
  ): Promise<BoxMessageResponse | null> {
    return await editBox(
      { box: box },
      {
        onSuccess: (response) => {
          if (!response?.success) {
            alert(response?.message);
          }
          return response;
        },
      }
    );
  }

  return { add, edit };
};

export default useStorageLocation;
