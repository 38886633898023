import { ButtonGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { BaseIcon } from "../../../../../components/BaseIcon/BaseIcon";
import { StudyDepartmentResponse } from "../../../../../models/responses/site.response";
import { TreeNode } from "./TreeNode";
import EditIcon from "../../../../../assets/edit.svg";
import PhoneIcon from "../../../../../assets/phone.svg";
import ClinicalIcon from "../../../../../assets/clinic.svg";
import { IconButton } from "../../../../../components/IconButton/IconButton";
import Status, { statuses } from "../../../../../components/Status/Status";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../../../constants";
import styles from "./TreeNode.module.css";
import { useState } from "react";
import StudyDepartmentModal from "./StudyDepartmentModal";
import { studyStatuses } from "../../Studies/StudiesTable";
import DeleteIcon from "../../../../../assets/delete.svg";
import DeleteModal from "../../../../../components/DeleteModal/DeleteModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteStudyMutation } from "../../../../../mutations/site.mutation";
import { departmentStudiesQuery } from "../../../../../queries/site.query";

type StudyProps = {
  clinicName: string;
  clinicDepartmentName: string;
  clinicDepartmentId: string;
  refetch: () => void;
  study: StudyDepartmentResponse;
};

export const Study = ({
  clinicName,
  clinicDepartmentName,
  clinicDepartmentId,
  refetch,
  study,
}: StudyProps) => {
  const {
    id,
    studyName,
    studyProtocolNumber,
    status,
    principalInvestigator,
    contactPersonName,
    contactPhone,
    subjectDetails,
    jsonQuestions,
  } = study;
  const navigateToClinical = () => {
    navigate(`/${RouteConstants.CLINICAL}`, {
      state: {
        studyDepartmentId: id,
        studyName,
        subjectDetails,
        jsonQuestions,
      },
    });
  };
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { mutateAsync: mutateDeleteStudy } = useMutation(
        deleteStudyMutation()
    );
  const { refetch: studyRefetch } = useQuery(
        departmentStudiesQuery({ clinicDepartmentId: clinicDepartmentId })
    );


    async function removeStudy(id: string) {
        await mutateDeleteStudy(id, {
            onSuccess: (response) => {
                if (response) {
                    if (!response?.success) {
                        alert(response?.message);
                    }
                    studyRefetch();
                    refetch();
                }
            },
        });
    }

  const buttons = (
    <ButtonGroup>
      <IconButton
        icon={ClinicalIcon}
        className={styles.clinic}
        onClick={navigateToClinical}
      />
      <IconButton icon={EditIcon} onClick={() => setShowModal(true)} />
      <IconButton icon={DeleteIcon} onClick={() => setShowDeleteModal(true)} />
    </ButtonGroup>
  );

  const details = (
    <label className="d-flex justify-content-between w-50">
      <div>
        <b>
          <FormattedMessage id="ENVIVO.SITES.STUDY.PI" defaultMessage="PI" />:{" "}
        </b>
        {principalInvestigator}
      </div>
      <div></div>
      <div>
        <b>
          <FormattedMessage
            id="ENVIVO.SITES.STUDY.CONTACT"
            defaultMessage="Contact"
          />
          :
        </b>{" "}
        {contactPersonName}
      </div>
      <div>
        <BaseIcon icon={PhoneIcon} />
        {contactPhone}
      </div>
      <Status status={status ? studyStatuses[status] : statuses.ACTIVE} />
    </label>
  );
  return (
    <>
      <TreeNode
        name={`${studyProtocolNumber} ${studyName}` || ""}
        buttons={buttons}
        details={details}
      />{" "}
      {showModal && (
        <StudyDepartmentModal
          clinicName={clinicName}
          clinicDepartmentName={clinicDepartmentName}
          clinicDepartmentId={clinicDepartmentId}
          refetch={refetch}
          show={showModal}
          setShow={setShowModal}
          studyDepartment={study}
        />
      )}
        {showDeleteModal && (
            <DeleteModal
                handleDelete={async () => {
                    setShowDeleteModal(false);
                    await removeStudy(study.id);
                }}
                show={showDeleteModal}
                InternationalizationKey="ENVIVO.SITES.STUDY.DELETE"
                handleClose={() => setShowDeleteModal(false)}
            />
        )}
    </>
  );
};
