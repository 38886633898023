import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Button, Spinner } from "react-bootstrap";
import useAuthentication from "../hooks/useAuthentication";
import { AuthActionType, useAuthContext } from "../context/AuthContext";

function LogOut() {
    const { dispatch} = useAuthContext();
    const { loading } = useAuthentication();
    const navigate = useNavigate();

    const handleLogOut = async () => {
        dispatch({ type: AuthActionType.LOGOUT });
        localStorage.clear()
        navigate("/auth/login",  { replace: true });
    }

    return (
            <Button style={{color: "black"}} variant="link" onClick={() => handleLogOut()}>
                <FormattedMessage id={"ENVIVO.LOGOUT"} defaultMessage="Log Out" />
                {loading && (
                    <div className="ps-2">
                        <Spinner as="span" animation="border" size="sm" />
                    </div>
                )}
            </Button>
    );
}

export default LogOut;
