import { Outlet } from "react-router-dom";
import { BrandCard } from "../../components/BrandCard/BrandCard";
import { RouteConstants } from "../../constants";
import useRoutes from "../../hooks/useRoutes";
import styles from "./AuthLayout.module.css";

function AuthLayout() {
  const { getCurrentBaseRoute } = useRoutes();

  const baseRoute = getCurrentBaseRoute();
  const header = (() => {
    switch (baseRoute) {
      case RouteConstants.CLINICAL:
        return "Clinical Login Header";
      case RouteConstants.ENVIVO:
        return "Envivo Login Header";
      case RouteConstants.FACTORY:
        return "Factory Login Header";
      default:
        return null;
    }
  })();

  return (
    <div
      className={`${styles.wrapper} vh-100 overflow-auto d-flex align-items-center justify-content-center`}
    >
      <BrandCard
        className={styles.cardWrapper}
        header={<h3>{header}</h3>}
        showBottomLogo
      >
        <Outlet />
      </BrandCard>
    </div>
  );
}

export default AuthLayout;
